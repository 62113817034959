export class ToggleModel {
    protected isOpen: boolean;
    constructor(state = false) {
        this.isOpen = state;
    }

    get isActive() {
        return this.isOpen;
    }
    toggle(event?: Event) {
        event?.preventDefault();
        event?.stopImmediatePropagation();
        this.isOpen = !this.isOpen;
    }
    open(event?: Event) {
        event?.preventDefault();
        event?.stopImmediatePropagation();
        this.isOpen = true;
        return false;
    }
    close(event?: Event) {
        event?.preventDefault();
        event?.stopImmediatePropagation();
        this.isOpen = false;
        return false;
    }
    serialize() {
        return { isOpen: this.isOpen };
    }
}
