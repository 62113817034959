import type { DatabaseDescriptors } from './api/api-query-service';
import { QueryServiceAPI } from './api';
import { CachedService } from './model/model-service-cached';

export type { DatabaseDescriptors };
export type IDatabaseDescriptorsService = typeof DatabaseDescriptorsService;
export const DatabaseDescriptorsService = CachedService(async () => {
    const api = await QueryServiceAPI.get();
    return await api.organizations.getDescriptors({});
});
