import { debounce } from 'lodash';
import { ICustomerView, ICustomerSearch } from './customer.controller';

interface CustomerListScope extends angular.IScope {
    view: { search: ICustomerSearch };
    model?: ICustomerView;
    customersURL: string;
    sortable: unknown;
}

export const CustomerListDirective = () => [
    'ROUTES',
    function CustomerList(ROUTES: any): angular.IDirective<CustomerListScope> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                model: '=',
                sortable: '=',
            },
            template: `
                <article class="customer-list">
                    <article class="ui-table supertable customers-table" supertable sortable-container>
                        <main>
                            <div class="loadable" ng-class="{loading: !model.table.customers.data }"></div>
                            <table class="table-normal">
                                <tbody>
                                    <tr ng-repeat="d in model.table.customers.data">
                                        <td class="column-name" title="{{ d.label }}">
                                            <a ng-href="{{ customersURL + '/' + d.customer_id }}">{{ d.label }}</a>
                                        </td>
                                        <td class="column-transaction-count">{{ d.transaction_count | number }}</td>
                                        <td class="column-total-spent">{{ d.total_spent | money }}</td>
                                        <td class="column-average-spent">{{ d.avg_spent | money }}</td>
                                        <td class="column-latest-visit">{{ d.latest_visit | date:'MMM dd, yyyy' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </main>
                        <header>
                            <table>
                                <thead>
                                    <tr>
                                        <th class="column-name" title="Name" sortable-property="name">NAME</th>
                                        <th
                                            class="column-transaction-count"
                                            title="Number of Visits"
                                            sortable-property="transaction_count"
                                        >
                                            VISITS
                                        </th>
                                        <th class="column-total-spent" title="Total Spent" sortable-property="total_spent">
                                            TOTAL SPENT
                                        </th>
                                        <th class="column-average-spent" title="Average Spent" sortable-property="avg_spent">
                                            AVG. SPENT
                                        </th>
                                        <th class="column-latest-visit" title="Latest Visit" sortable-property="latest_visit">
                                            LATEST VISIT
                                        </th>
                                    </tr>
                                    <tr class="row-search">
                                        <th class="column-name" title="Name">
                                            <input type="search" ng-model="view.search.name" />
                                        </th>
                                        <th class="column-transaction-count" title="Number of Visits">
                                            <input type="search" ng-model="view.search.transaction_count" />
                                        </th>
                                        <th class="column-total-spent" title="Total Spent">
                                            <input type="search" ng-model="view.search.total_spent" />
                                        </th>
                                        <th class="column-average-spent" title="Average Spent">
                                            <input type="search" ng-model="view.search.avg_spent" />
                                        </th>
                                        <th class="column-latest-visit" title="Latest Visit"></th>
                                    </tr>
                                </thead>
                            </table>
                        </header>
                        <footer>
                            <table class="table-normal">
                                <tfoot>
                                    <tr>
                                        <td class="column-paginator" colspan="5">Showing <em>{{ model.table.customers.data.length }}</em> customers</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </footer>
                    </article>
                </article>
            `,
            link: scope => {
                scope.view = { search: {} };
                scope.customersURL = ROUTES.customers.url;
                const updateModel = debounce((customerSearch: ICustomerSearch) => {
                    scope.model?.setSearch(customerSearch);
                }, 400);
                const updateView = () => {
                    scope.view.search = { ...scope.model?.search };
                };
                scope.$watch('view.search', updateModel, true);
                scope.$watch('model.search', updateView);
            },
        };
    },
];
