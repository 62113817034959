import _ from 'lodash'
import * as QueryBuilder from '../../lib/query/query-builder'
import { createCalendarModelService } from '../../modules/datepicker/datepicker'
import { SmartGroupsModelService } from '../../modules/smart-groups/smart-groups.service'

module = angular.module '42.main.models', []
export default module


module.service 'DashboardSidebarToggleModel', [() ->
    class DashboardSidebarToggleModel
        isOpen: true
        animating: false
    return new DashboardSidebarToggleModel()
]

module.service 'DashboardSmartGroupsModel', ->
    promise = null
    instance = null
    init: ->
        return @fetch().then((x) -> (instance = x))
    fetch: ->
        promise ?= SmartGroupsModelService.fetch()
        return promise
    get: ->
        return instance


module.service 'DashboardQuery', ->
    state: {query:{options:{}}}
    get: ->
        return _.cloneDeep(@state.query)
    update: (cb) ->
        @set(cb(@state.query))
        return @get()
    set: (query) ->
        return if query is @state.query
        return if not query
        throw new Error("Query must be an object.") if not _.isPlainObject(query)
        @state = {query}
        return


# State of the calendar in the dashboard's sidebar
module.service 'DashboardCalendarModel', ['DashboardQuery', (DashboardQuery) ->
    CalendarModelService = createCalendarModelService()
    fetch: -> CalendarModelService.fetch()

    ###*
    # FIXME: refactor this somehow...
    # This is specifically for working around the fact that the timerange
    # selection is done as a transaction filter, which must be merged with
    # the Segments (smart groups).
    # @argument {import('../../modules/datepicker/datepicker').ICalendarModel} model
    # @argument {import('../../lib/types').IQuery} query
    ###
    updateQueryFilters: (model, filters) ->
        state = model.getState().datepicker
        selection = QueryBuilder.TimerangeFilter.fromTimerange(state.selection)
        query = QueryBuilder.QueryTimestampSelection.set({filters}, selection)
        return query.filters

    ###*
    # @argument {import('../../modules/datepicker/datepicker').ICalendarModel} model
    ###
    updateQuery: (model, query) ->
        {calendarId, datepicker} = model.getState()
        selection = do ->
            return QueryBuilder.TimerangeFilter.fromTimerange(datepicker.selection)
        comparison = do ->
            return null if not datepicker.comparison
            return QueryBuilder.TimerangeFilter.fromTimerange(datepicker.comparison)
        query = QueryBuilder.QueryTimestampSelection.set(query, selection)
        query = QueryBuilder.QueryTimestampComparison.set(query, comparison)
        query = QueryBuilder.QueryModifiersCalendarId.set(query, calendarId)
        return query

    updateDashboard: (model) ->
        DashboardQuery.update((q) => @updateQuery(model, q))
]

# State of the currency in the dashboard's sidebar
module.service 'DashboardCurrencyModel', ['DashboardQuery', 'UserCurrencyModel', (DashboardQuery, CurrencyModel) ->
    promise = null
    fetch: ->
        promise ?= CurrencyModel.fetch()
        return promise
    updateQuery: (model, query) ->
        currency = model?.selected?.id
        return QueryBuilder.QueryModifiersCurrencyId.set(query, currency)
    updateDashboard: (model) ->
        DashboardQuery.update((q) => @updateQuery(model, q))
]

# State of the maxTimestamp in the dashboard's sidebar
module.service 'DashboardMaxTimestampModel', ['DashboardQuery', (DashboardQuery) ->
    updateQuery: (maxTimestamp, query) ->
        return QueryBuilder.QueryModifiersMaxTimestamp.set(query, maxTimestamp)
    updateDashboard: (maxTimestamp) ->
        DashboardQuery.update((q) => @updateQuery(maxTimestamp, q))
]

# State of the filter in the dashboard's sidebar
module.service 'DashboardFilterModel', ['DashboardQuery', 'DashboardCalendarModel', (DashboardQuery, DashboardCalendarModel) ->
    updateQuery: (calendarModel, filters, query) ->
        # FIXME: refactor this somehow...
        # The Segments (smart groups) can overwrite the calendar selection filter, since it's a transactions.timestamp filter,
        # so we restore it here... See note in DashboardCalendarModel.updateQueryFilters
        filters = DashboardCalendarModel.updateQueryFilters(calendarModel, filters)
        return query if query.filters is filters
        return ({...query, filters})
    updateDashboard: (calendarModel, filters) ->
        DashboardQuery.update((q) => @updateQuery(calendarModel, filters, q))
]


module.service 'DashboardDatabaseStatus', [() ->
    state: {status: null}
    set: (status) ->
        @state.status = _.cloneDeep(status)
        return @get()
    get: ->
        return _.cloneDeep(@state.status)
]
