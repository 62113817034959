import _ from 'lodash';
import { ConfigAPI } from '../../lib/config-api';
import { ISelectModel, SelectModel } from '../../lib/model/model-select';
import { CachedService } from '../../lib/model/model-service-cached';
import { getPropertyDefinitions } from '../../lib/config-hierarchy';
import { getHierarchyDefinitions, IHierarchyDefinition } from '../../lib/config-hierarchy-definitions';
import { DatabaseDescriptorsService } from '../../lib/config-database-descriptors';

// When we have a multi-hierarchy configuration, this is the model that's used
// to select the hierarchy we want to use for the dashboard...
export type IHierarchySelectModel = ISelectModel<IHierarchyDefinition>;

const HierarchySelectModelService = CachedService(() => {
    return ConfigAPI.get()
        .then(api => api.organization.get())
        .then((config): null | IHierarchySelectModel => {
            const defs = getHierarchyDefinitions(config);
            if (!defs) return null;
            return new SelectModel(defs);
        });
});

// These are the properties available to the dashboard
export type IHierarchyService = ReturnType<typeof HierarchyService>;
export const HierarchyService = () => {
    const cache = CachedService(() => {
        return Promise.all([
            ConfigAPI.get().then(api => Promise.all([api.organization.get(), api.user.getInternal()])),
            DatabaseDescriptorsService.fetch(),
        ]).then(([[orgConfig, userConfig], descriptors]) => {
            return [orgConfig, userConfig, descriptors];
        });
    });
    void cache.init();
    return {
        fetch(hierarchyId?: string) {
            const hierarchyIdPromise = Promise.resolve(
                hierarchyId ?? HierarchySelectModelService.fetch().then(x => x?.getSelectedId()),
            );
            return Promise.all([cache.fetch(), hierarchyIdPromise]).then(
                ([[orgConfig, userConfig, descriptors], hierarchyId]) => {
                    return getPropertyDefinitions(userConfig, orgConfig, descriptors, hierarchyId);
                },
            );
        },
    };
};

const module = angular.module('42.modules.hierarchy', []);
export default module;

interface HierarchySelectDirectiveScope extends angular.IScope {
    availableHierarchies: IHierarchyDefinition[];
    selectedHierarchy: IHierarchyDefinition;
    model: IHierarchySelectModel;
}
module.directive('hierarchySelect', [
    function HierarchySelectDirective(): angular.IDirective<HierarchySelectDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
            <article class="hierarchy-select">
                <div class="select-title">
                    Hierarchy
                </div>
                <div class="hierarchy-select-dropdown">
                    <i class="icon-down-open-mini"></i>
                    <select ng-options="hierarchy as hierarchy.label for hierarchy in availableHierarchies" ng-model="selectedHierarchy">
                </div>
            </article>
            `,
            link: scope => {
                scope.availableHierarchies = scope.model.getAvailable();
                const selectedId = scope.model.getId(scope.model.view.selected.model);
                scope.selectedHierarchy =
                    scope.availableHierarchies.find(hierarchy => hierarchy.id === selectedId) ??
                    scope.availableHierarchies[0];
                scope.$watch('selectedHierarchy', (selectedHierarchy: undefined | IHierarchyDefinition) => {
                    if (selectedHierarchy) scope.model.select(selectedHierarchy.id);
                });
            },
        };
    },
]);

module.service('HierarchySelectModel', [() => HierarchySelectModelService]);
module.service('Hierarchy', [
    '$q',
    ($q: angular.IQService) => {
        const instance = HierarchyService();
        const fetch = () => $q.when(instance.fetch());
        return { fetch };
    },
]);
