import _ from 'lodash';
import { createSortable } from '../../lib/dom/sortable';
import { IOutsideElementClick } from '../../directives/outside-element-click.directive';
import { ISmartGroupsViewModel, SmartGroupViewModel } from './smart-groups.service';
import { isHTMLElement } from '../../lib/dom/html';

export interface IGroupFilterViewModel {
    label: string;
    selected: boolean;
    isActive: boolean;
    select: () => void;
}

export interface IGroupViewModel {
    name?: string;
    selected?: boolean;
    select?: () => void;
    save?: (name: string) => void;
    delete?: () => void;
    filters?: IGroupFilterViewModel[];
}

interface SmartGroupsPanelDirectiveScope extends angular.IScope {
    model: ISmartGroupsViewModel;
}
export const SmartGroupsPanelDirective = (module: angular.IModule) =>
    module.directive('smartGroupsPanel', [
        (): angular.IDirective<SmartGroupsPanelDirectiveScope> => ({
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
            <div class="smart-groups-panel">
                <div class="smart-group-actions">
                    <div class="smart-group-actions-label">Segments</div>
                    <div class="ui-button smart-group-action-create" ng-click="model.create()">
                        <i class="icon-plus-circled"></i>
                        <span>Create New Segment</span>
                    </div>
                </div>
                <ul class="smart-groups sortable-ui">
                    <li class="smart-group-container" ng-repeat="group in model.groups.view.available track by group.id">
                        <smart-groups-panel-item model="group.model"></smart-groups-panel-item>
                    </li>
                </ul>
            </div>
            `,
            link: (scope, element: angular.IRootElementService) => {
                const listElement = element[0]?.querySelector<HTMLElement>('.smart-groups');
                if (!listElement) throw new Error('Could not find list element');

                // We add a class when we add a new group to trigger a css transition...
                const observer = new MutationObserver(mutations => {
                    for (const mutation of mutations) {
                        if (mutation.type !== 'childList') continue;
                        mutation.addedNodes.forEach(node => {
                            if (!isHTMLElement(node)) return;
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            const el = node.querySelector<HTMLElement>('.smart-group');
                            if (node.attributes.getNamedItem('draggable')?.value === 'true') return;
                            // scroll to newly added node
                            el?.classList.add('new');
                            setTimeout(() => {
                                listElement.scrollTop = node.parentElement?.parentElement?.offsetTop ?? 0;
                                el?.classList.remove('new');
                            });
                        });
                    }
                });
                const observe = () => {
                    observer?.disconnect();
                    observer.observe(listElement, { childList: true });
                };
                observe();
                scope.$on('$destroy', () => observer.disconnect());

                const sortable = createSortable(listElement, {
                    ghostClass: 'placeholder',
                    draggable: '.smart-group-container',
                    filter: '.edit',
                    preventOnFilter: false,
                    onStart() {
                        observer.disconnect();
                    },
                    onEnd(event) {
                        setTimeout(observe, 0);
                        const prev = event.oldIndex;
                        const curr = event.newIndex;
                        if (typeof prev !== 'number' || typeof curr !== 'number') {
                            throw new Error(`Cannot re-order groups: prev=${prev}, curr=${curr}`);
                        } else {
                            scope.model.reorder(prev, curr);
                            scope.$apply();
                        }
                    },
                });
                scope.$on('$destroy', () => sortable.destroy());
            },
        }),
    ]);

interface SmartGroupsPanelCompactDirectiveScope extends angular.IScope {
    model: ISmartGroupsViewModel;
}
export const SmartGroupsPanelCompactDirective = (module: angular.IModule) => {
    return module.directive(
        'smartGroupsPanelCompact',
        (): angular.IDirective<SmartGroupsPanelCompactDirectiveScope> => ({
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
            <article class="compact-group-select">
                <div class="select-title">Filter Group</div>
                <div class="compact-group-select-dropdown">
                    <i class="icon-down-open-mini"></i>
                    <select
                        ng-options="x as x.model.name for x in model.groups.view.available"
                        ng-model="model.groups.view.selected"
                    >
                    </select>
                </div>
            </article>
            `,
            link: scope => {
                scope.$watch('model.groups.view.selected.id', (id: unknown) => {
                    if (typeof id !== 'string') return;
                    scope.model.selectGroup(id);
                });
            },
        }),
    );
};

export interface ISmartGroupFilterDropdownActions {
    title: string;
    action: () => void;
}

export const SmartGroupsItemDirective = (module: angular.IModule) => {
    class EditModeViewModel {
        draft: null | { name: string } = null;
        enable(model: SmartGroupViewModel) {
            this.draft = { name: model.name || '' };
        }
        disable() {
            this.draft = null;
        }
    }
    interface Scope extends angular.IScope {
        isNew: boolean;
        isDeleting: boolean;
        isSelected: boolean;
        model: SmartGroupViewModel;
        editMode: EditModeViewModel;
        select: () => void;
        save: () => void;
        smartGroupFilterDropdownActions: ISmartGroupFilterDropdownActions[];
    }
    return module.directive('smartGroupsPanelItem', [
        '$timeout',
        'OutsideElementClick',
        ($timeout: angular.ITimeoutService, OutsideElementClick: IOutsideElementClick): angular.IDirective<Scope> => ({
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <div
                    class="smart-group"
                    ng-click="select()"
                    ng-class="{
                        remove: isDeleting,
                        selected: isSelected,
                        edit: editMode.draft
                    }"
                >
                    <header class="smart-group-info">
                        <section class="smart-group-info-normal animation-fade" ng-if="!editMode.draft">
                            <h1 class="smart-group-name" ng-dblclick="editMode.enable(model)">
                                <i class="icon-right-open-mini"></i>
                                <span>{{ model.name }}</span>
                            </h1>
                            <button-smart-group-edit-dropdown
                                model="smartGroupFilterDropdownActions"
                                ng-if="isSelected"
                            ></button-smart-group-edit-dropdown>
                        </section>
                        <section class="smart-group-info-edit animation-fade" ng-if="editMode.draft">
                            <div class="smart-group-edit-actions animation-slide-down">
                                <button class="button-yellow smart-group-edit-save-button" ng-click="save($event)">rename</button>
                                <button class="smart-group-edit-cancel-button" ng-click="editMode.disable()">cancel</button>
                            </div>
                            <input
                                focus
                                type="text"
                                ng-model="editMode.draft.name"
                                placeholder="{{ model.name }}"
                                ng-enter="save()"
                                ng-esc="editMode.disable()"
                            />
                        </section>
                    </header>
                    <ul class="smart-group-filters">
                        <li
                            class="smart-group-filter"
                            ng-repeat="filter in model.filters track by filter.id"
                            ng-click="filter.select()"
                            ng-class="{selected: filter.isEditing, active: !filter.isEmpty}"
                        >
                            <span>{{ filter.descriptor.label }}</span>
                        </li>
                    </ul>
                </div>
            `,
            link: (scope, $element) => {
                scope.editMode = new EditModeViewModel();

                scope.isSelected = false;
                scope.isDeleting = false;
                scope.$watch('model.parent.groups.view.selected.id', id => {
                    const selected = scope.model?.id === id;
                    $timeout(() => (scope.isSelected = selected), 0);
                });

                scope.select = () => {
                    if (scope.editMode.draft) return;
                    if (scope.isSelected) return;
                    if (scope.isDeleting) return;
                    scope.editMode.disable();
                    scope.model.select();
                };

                scope.save = () => {
                    if (scope.editMode.draft && scope.editMode.draft.name !== scope.model.name) {
                        const name = scope.editMode.draft.name;
                        scope.model.update({ name });
                    }
                    scope.editMode.disable();
                };

                scope.smartGroupFilterDropdownActions = [
                    {
                        title: 'RENAME',
                        action: () => {
                            scope.editMode.enable(scope.model);
                        },
                    },
                    {
                        title: 'DUPLICATE',
                        action: () => {
                            scope.editMode.disable();
                            scope.model.duplicate();
                        },
                    },
                    {
                        title: 'DELETE',
                        action: () => {
                            scope.isDeleting = true;
                            scope.editMode.disable();
                            $timeout(() => {
                                scope.isDeleting = false;
                                scope.model.delete();
                            }, 333);
                        },
                    },
                ];

                let unsub: null | (() => void);
                scope.$watch('editMode.draft', (draft: never) => {
                    if (unsub) unsub();
                    if (!draft) return;
                    unsub = OutsideElementClick(scope, $element.find('.smart-group-info .smart-group-info-edit'), () =>
                        scope.editMode.disable(),
                    );
                });
            },
        }),
    ]);
};

export const SmartGroupEditDropdownDirective = (module: angular.IModule) =>
    module.directive('buttonSmartGroupEditDropdown', [
        'OutsideElementClick',
        (OutsideElementClick: IOutsideElementClick) => ({
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
            <div class="button-smart-group-edit">
                <div
                    class="smart-group-edit-button"
                    ng-class="{ opened: opened }"
                    ng-click="toggleDropdown()">
                    <i class="icon-dot-3-vertical"></i>
                </div>
                <div ng-if="opened" class="smart-group-edit-items-container">
                    <div class="smart-group-edit-item"
                        ng-repeat="item in model"
                        ng-click="actionClick($event, item)">{{ item.title }}</div>
                </div>
            </div>
            `,
            link: (
                $scope: angular.IScope & {
                    opened: boolean;
                    actionClick: ($event: MouseEvent, item: ISmartGroupFilterDropdownActions) => void;
                    toggleDropdown: () => void;
                },
                $element: angular.IRootElementService,
            ) => {
                $scope.opened = false;

                $scope.actionClick = ($event, item) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                    item.action();
                    $scope.opened = false;
                };

                $scope.toggleDropdown = () => {
                    $scope.opened = !$scope.opened;
                };

                let unsub: null | (() => void) = null;
                $scope.$watch('opened', opened => {
                    if (unsub) unsub();
                    if (!opened) return;
                    unsub = OutsideElementClick($scope, $element, () => {
                        $scope.opened = false;
                    });
                });
            },
        }),
    ]);
