import _ from 'lodash';
import type { ECharts } from 'echarts';
import { init as echartsInit } from 'echarts';
import { ResizeObserver } from '@juggle/resize-observer';
import type { SeriesOption, EChartsOption } from 'echarts';
import hexToRgba from 'hex-to-rgba';

export type I42CustomSeriesOption = SeriesOption & { data?: ISeriesData[] };
export type I42CustomEchartsOptions = EChartsOption & { series: I42CustomSeriesOption[] };

import type { IMetricDefinition } from '../../lib/types';
import {
    shouldGroupSeries,
    SLICE_VALUE_THRESHOLD,
    SLICE_NUMBER_THRESHOLD,
    hasGroupSeries,
    validateThresholds,
} from './chart-echarts.helper';

export interface ISeriesData {
    metric: IMetricDefinition;
    percent?: number;
    name: string;
    value: number;
    isNegative?: boolean;
    groupedSeries?: ISeriesData[];
    itemStyle: {
        color: string;
        [x: string]: unknown;
    };
}

export interface EChartsCustomOptions {
    showPercentage: boolean;
    multiline: boolean;
    maxNumberOfSlices: number;
    groupSlicesThresholdValue: number;
}

export const EchartsCustomFactory = () => [
    '$window',
    function EChartsCustom() {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                options: '=',
            },
            template: `
            <div class="echarts-container">
                <div class="echarts-main"></div>
            </div>
            `,
            link: function EchartsCustomLink(
                scope: angular.IScope & { options: { customOptions: EChartsCustomOptions } },
                element: angular.IRootElementService,
            ) {
                const chartElement = element.find('.echarts-main');
                const eChartsInstance = echartsInit(chartElement[0]);

                const observer = (() => {
                    const resizeChart = () => {
                        return eChartsInstance.resize({
                            silent: true,
                            animation: { duration: 0 },
                        });
                    };
                    const prevOverflow = element[0].style.overflow;
                    const restoreOverflow = _.debounce(() => (element[0].style.overflow = prevOverflow), 100);
                    return new ResizeObserver(() => {
                        element[0].style.overflow = 'hidden';
                        restoreOverflow();
                        resizeChart();
                    });
                })();
                observer.observe(element[0]);

                scope.$on('$destroy', () => {
                    observer.disconnect();
                    eChartsInstance.dispose();
                });

                const shouldGroupSlices = (
                    options: I42CustomEchartsOptions,
                    sliceNumberThreshold: number,
                    sliceValueThreshold: number,
                ) => {
                    const series = options.series;
                    if (!options || !series || !Array.isArray(series)) {
                        return false;
                    }

                    return (
                        series.length === 1 &&
                        (series[0].type === 'pie' || series[0].type === 'bar') &&
                        !hasGroupSeries(series[0]) &&
                        shouldGroupSeries(series[0], sliceNumberThreshold, sliceValueThreshold)
                    );
                };

                scope.$watch(
                    'options',
                    (echartsOptions: I42CustomEchartsOptions) => {
                        const options = _.cloneDeep(echartsOptions);

                        if (options) {
                            const customOptions: EChartsCustomOptions = scope.options.customOptions || {};

                            const maxNumberOfSlicesThreshold = validateThresholds(
                                customOptions.maxNumberOfSlices,
                                SLICE_NUMBER_THRESHOLD,
                            );
                            const sliceValueThreshold = validateThresholds(
                                customOptions.groupSlicesThresholdValue,
                                SLICE_VALUE_THRESHOLD,
                            );

                            if (
                                Array.isArray(options.series) &&
                                options.series.length > 0 &&
                                shouldGroupSlices(options, maxNumberOfSlicesThreshold, sliceValueThreshold)
                            ) {
                                const data = options.series[0].data || [];

                                const totalValue = data.reduce((acc: number, item: ISeriesData) => acc + item.value, 0);
                                const slicesAboveThreshold: ISeriesData[] = [];
                                const slicesBelowThreshold: ISeriesData[] = [];
                                let slicesBelowThresholdTotalSum = 0;

                                data.forEach((item, index) => {
                                    const isBelowValueThreshold = item.value / totalValue < sliceValueThreshold;
                                    const isBelowSliceNumberThreshold =
                                        maxNumberOfSlicesThreshold !== 0 && index >= maxNumberOfSlicesThreshold;

                                    if (isBelowSliceNumberThreshold && isBelowValueThreshold) {
                                        const percentValue = Math.abs(item.value) / totalValue;

                                        slicesBelowThreshold.push({
                                            ...item,
                                            percent: percentValue,
                                        });

                                        slicesBelowThresholdTotalSum += item.value;

                                        return;
                                    }

                                    slicesAboveThreshold.push(item);
                                });

                                if (slicesBelowThreshold.length) {
                                    const firstNegativeIndex = slicesAboveThreshold.findIndex(item => item.isNegative);
                                    const groupedItem: ISeriesData = {
                                        metric: data[0].metric,
                                        value: slicesBelowThresholdTotalSum,
                                        name: 'Other',
                                        groupedSeries: slicesBelowThreshold,
                                        itemStyle: {
                                            color: '#BABCC8',
                                            borderRadius: [4, 4, 0, 0],
                                        },
                                        isNegative: slicesBelowThresholdTotalSum < 0,
                                    };

                                    if (groupedItem.isNegative) {
                                        groupedItem.itemStyle.borderRadius = [0, 0, 4, 4];
                                        groupedItem.itemStyle.borderColor = groupedItem.itemStyle.color;
                                        groupedItem.itemStyle.color = hexToRgba(groupedItem.itemStyle.color, 0.1);
                                        groupedItem.itemStyle.borderType = 'dashed';
                                    }

                                    if (firstNegativeIndex < 0) {
                                        slicesAboveThreshold.push(groupedItem);
                                    } else {
                                        slicesAboveThreshold.splice(firstNegativeIndex, 0, groupedItem);
                                    }
                                }

                                options.series[0].data = slicesAboveThreshold;

                                const slicesNames = slicesAboveThreshold.map(s => s.name);

                                if (options.legend && !Array.isArray(options.legend)) {
                                    options.legend.data = slicesNames;
                                }

                                if (
                                    options.series[0].type === 'bar' &&
                                    options.xAxis &&
                                    !Array.isArray(options.xAxis) &&
                                    'data' in options.xAxis
                                ) {
                                    options.xAxis.data = slicesNames;
                                }
                            }

                            eChartsInstance.clear();
                            eChartsInstance.setOption(options);
                        }
                    },
                    true,
                );
            },
        };
    },
];
