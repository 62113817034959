import './main-controller.coffee';
import './grid/items-controller.coffee';
import './metrics/metrics-controller.coffee';
import './store/store-controller.coffee';
import './transactions/transactions-controller.coffee';
import './customer/customer-module.ts';
import './counter/counter-controller.coffee';
import './store-status-controller.coffee';
import './map';
import './ads';
import OverviewModule from './overview';
import SchedulingPageModule from './scheduling';

angular.module('42.controllers', [
    '42.controllers.main',
    '42.controllers.items',
    '42.controllers.map',
    '42.controllers.metrics',
    OverviewModule.name,
    SchedulingPageModule.name,
    '42.controllers.store',
    '42.controllers.transactions',
    '42.controllers.customer',
    '42.controllers.counter',
    '42.controllers.store-status',
    '42.controllers.ads',
]);
