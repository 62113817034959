import _ from 'lodash';
import { getUser, getOrganization } from './auth';
import type { AppConfig } from './config-app';
import { AppConfigService } from './config-app';
import { UserServiceAPI } from './api/api-user-service';
import type { IConfigObj } from './types';
import { deepStripAngularProperties } from './angular';
import { isObject } from './utils';
import { resolve as resolveOrgConfigRoutes } from './config-routes';

const ORGANIZATION_CONFIG_CACHE: Record<string, undefined | Promise<IConfigObj>> = {};
const USER_CONFIG_CACHE: Record<string, undefined | Promise<unknown>> = {};

function mergeOrganizationConfigs(
    configs: { internal: Record<string, unknown>; external: Record<string, unknown>; app: AppConfig },
    organizationId: string,
) {
    const config = { ...configs.external, ...configs.internal };
    const organization = resolveOrgConfigOrganization({ organization: config }, organizationId);
    const services = resolveOrgConfigServices({ organization: config, app: configs.app }, organizationId);
    const routes = resolveOrgConfigRoutes({ organization: config });
    const resolved = { ...config, organization, routes, services };
    return resolved;
}

async function fetchOrganizationConfig(api: UserServiceAPI, organizationId: string) {
    const [app, internal, external] = await Promise.all([
        AppConfigService.get(),

        api.organizations.GetOrganizationInternalConfig({ organizationId }).then(config => {
            if (!isObject(config)) return {};
            return config;
        }),

        api.organizations.GetOrganizationExternalConfig({ organizationId }).then(config => {
            if (!isObject(config)) return {};
            return config;
        }),
    ]);

    return mergeOrganizationConfigs({ app, internal, external }, organizationId);
}

async function getOrganizationConfig(api: UserServiceAPI, organizationId: string): Promise<IConfigObj> {
    let promise: Promise<IConfigObj>;
    promise ??= ORGANIZATION_CONFIG_CACHE[organizationId];
    promise ??= fetchOrganizationConfig(api, organizationId);
    ORGANIZATION_CONFIG_CACHE[organizationId] = promise;
    try {
        const config = await promise;
        return _.cloneDeep(config);
    } catch (error) {
        delete ORGANIZATION_CONFIG_CACHE[organizationId];
        throw error;
    }
}

const getUserInternalConfig = async (
    api: UserServiceAPI,
    organizationId: string,
    userId: string,
): Promise<Record<string, unknown>> => {
    const cacheKey = `${organizationId}/${userId}`;
    let promise: Promise<unknown>;
    promise ??= USER_CONFIG_CACHE[cacheKey];
    promise ??= api.organizations.GetUserInternalConfig({ organizationId, userId });
    USER_CONFIG_CACHE[cacheKey] = promise;
    try {
        const config = await promise;
        return isObject(config) ? _.cloneDeep(config) : {};
    } catch (error) {
        delete USER_CONFIG_CACHE[cacheKey];
        throw error;
    }
};

const getUserExternalConfig = async (
    api: UserServiceAPI,
    organizationId: string,
    userId: string,
): Promise<Record<string, unknown>> => {
    return api.organizations
        .GetUserExternalConfig({ organizationId, userId })
        .then((config: unknown): Record<string, unknown> => {
            if (!isObject(config)) return {};
            delete config.experiments;
            delete config.accessControl;
            delete config.flags;
            delete config.routes;
            // Angular $$hashKey were persisted accidentally in the external config, so we have to strip them out.
            return deepStripAngularProperties(config);
        });
};

const getUserConfig = (api: UserServiceAPI, organizationId: string, userId: string): Promise<Record<string, unknown>> =>
    Promise.all([
        getUserInternalConfig(api, organizationId, userId),
        getUserExternalConfig(api, organizationId, userId),
    ]).then(([internal, external]) => {
        return { ...external, ...internal };
    });

export const setUserConfig = async (
    api: UserServiceAPI,
    organizationId: string,
    userId: string,
    data: Record<string, unknown>,
): Promise<void> => {
    // These are being added downstream as a side effect of merging the user config.
    // We remove these here as a safeguard.
    delete data.experiments;
    delete data.accessControl;
    delete data.flags;

    // Angular $$hashKey are added sometimes added to the config objects, so we strip them out.
    data = deepStripAngularProperties(data);
    await api.organizations.SetUserExternalConfig({ organizationId, userId }, { data });
};

export const ConfigAPI = (() => {
    const promise = Promise.all([
        UserServiceAPI.get(),
        getUser(),
        getOrganization(),
        //
    ]);
    return {
        async get(organizationIdOverride?: string) {
            const [api, user] = await promise;
            let [, , organizationId] = await promise;
            organizationId = organizationIdOverride ?? organizationId;
            const userId = user?.id;
            if (!userId) throw new Error('[config-api] missing required: userId');
            if (!organizationId) throw new Error('[config-api] missing required: organizationId');
            return {
                organization: {
                    get: () => {
                        return getOrganizationConfig(api, organizationId);
                    },
                },
                user: {
                    set: (data: Record<string, unknown>) => {
                        return setUserConfig(api, organizationId, userId, data);
                    },
                    get: () => {
                        return getUserConfig(api, organizationId, userId);
                    },
                    getExternal: () => {
                        return getUserExternalConfig(api, organizationId, userId);
                    },
                    getInternal: () => {
                        return getUserInternalConfig(api, organizationId, userId);
                    },
                },
            };
        },
    };
})();

export type IConfigAPI = typeof ConfigAPI;

function resolveOrgConfigServices<OrgConfig extends { services?: unknown }>(
    configs: {
        app: AppConfig;
        organization: OrgConfig;
    },
    organizationId: string,
): Record<string, unknown> {
    const appConfig = _.cloneDeep(configs.app);
    const orgConfig = _.cloneDeep(configs.organization);

    const orgServices = isObject(orgConfig.services) ? orgConfig.services : {};
    const serviceNames = new Set<string>([...Object.keys(appConfig.services), ...Object.keys(orgServices)]);

    const services: Record<string, unknown> = {};
    serviceNames.forEach(serviceName => {
        const appService = appConfig.services[serviceName];
        const orgService = orgServices[serviceName];
        services[serviceName] = {
            organization: organizationId,
            ...(appService ?? {}),
            ...(isObject(orgService) ? orgService : {}),
            ...(appService?.override ? appService : {}),
        };
    });

    return services;
}

function resolveOrgConfigOrganization(
    { organization: orgConfig }: { organization: Record<string, unknown> },
    organizationId: string,
) {
    const org = isObject(orgConfig.organization) ? orgConfig.organization : {};
    const label = typeof org.label === 'string' ? org.label : organizationId;
    return { ...org, label, id: organizationId };
}
