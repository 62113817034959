require './chart-echarts-query-directive.coffee'

{ EchartsMapChartModelInstance } = require('./chart-echarts-map-chart-model')
{ EchartsCustomFactory } = require('./chart-echarts-wrapper.directive')
{ EchartsWithQueryViewModelFactory } = require('./chart-echarts-with-query-view-model')
{ EchartsBarChartModelFactory } = require('./chart-echarts-bar-chart-model')
{ EchartsPieChartModelFactory } = require('./chart-echarts-pie-chart-model')
{ EChartsLineChartModelFactory } = require('./chart-echarts-line-chart-model')

module = angular.module('42.directives.charts', ['42.directives.charts.directives'])
module.directive('echartsCustom', EchartsCustomFactory())

module.factory('EchartsWithQueryViewModel', EchartsWithQueryViewModelFactory())
module.factory('EchartsBarChartModel', EchartsBarChartModelFactory())
module.factory('EchartsPieChartModel', EchartsPieChartModelFactory())
module.factory('EchartsLineChartModel', EChartsLineChartModelFactory())
module.factory('EchartsMapChartModel', EchartsMapChartModelInstance())

export default module
