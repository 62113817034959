import _ from 'lodash';

export function clamp(min: number, x: number, max: number) {
    return Math.min(Math.max(x, min), max);
}

export function copy<T>(object: T): T {
    return _.cloneDeep(object);
}

export function uuid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
