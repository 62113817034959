import _ from 'lodash';
import { isObject } from '../../lib/utils/utils-object';
import { visitTableColumnFilterContainsOperators } from '../../lib/query/query-reader';
import { IPropertyDefinition } from '../../lib/config-hierarchy';
import { IQueryTableFilter, IQueryFilters, IQueryTableFilterOperatorAnd } from '../../lib/types';
import { ISegmentProperty } from './smart-group-filter.directive';
import { IExportedPropertyView } from './smart-group-filter-property-item.directive';

export const compilePropertyFilters = (propertyFilters: IExportedPropertyView[], tables: string[]) => {
    const queryFilters = propertyFilters.reduce(
        (result: Record<string, IQueryTableFilter>, propertyFilter: IExportedPropertyView) => {
            const { table, column, values, excludeMode } = propertyFilter;

            let tableFilter = { ...result[table] };
            let $and: IQueryTableFilterOperatorAnd['$and'];
            $and = Array.isArray(tableFilter?.$and) ? tableFilter.$and : [];
            $and = $and.filter(x => !isObject(x) || Object.keys(x)[0] !== column);
            delete tableFilter.$and;

            if (!values.length) return result;

            const ids = values.map(x => x.id);
            const val = excludeMode ? { $nin: ids } : { $in: ids };
            $and.push({ [column]: val });
            tableFilter = { ...tableFilter, $and };

            result = {
                ...result,
                [table]: tableFilter,
            };

            return result;
        },
        {},
    );

    return tables.reduce((acc: IQueryFilters, table) => {
        acc[table] ??= {};

        return acc;
    }, queryFilters);
};

function hasTableColumnFilters(value: Record<string, any>): value is { $and: unknown[] } {
    return _.isPlainObject(value) && value['$and'] && Array.isArray(value['$and']);
}

export const isFilterMissing = (tables: string[], filters: Record<string, any>) => {
    if (!_.isPlainObject(filters)) {
        return true;
    }
    return tables.every((table: string) => {
        const filter = filters[table];
        return !hasTableColumnFilters(filter) || filter.$and.length === 0;
    });
};

// Decompile Query Filters
export function* decompileQueryFilters(
    queryFilters: IQueryFilters,
    properties: IPropertyDefinition[],
    tables: string[],
    // ): Iterable<ISmartGroupPopupFilterProperty> {
): Iterable<ISegmentProperty> {
    const propertiesById: Record<string, IPropertyDefinition> = _.keyBy(properties, 'id');

    for (const { tableName, columnName, operator, value } of visitTableColumnFilterContainsOperators(queryFilters)) {
        if (operator !== '$and') continue;
        if (!tables.includes(tableName)) continue;

        const id = `${tableName}.${columnName}`;
        const property = propertiesById[id];
        if (!property) continue;

        const excluded = '$nin' in value;
        const values = '$nin' in value ? value.$nin : '$in' in value ? value.$in : [];
        yield {
            ...property,
            values: values.map(valueId => ({
                id: valueId.toString(),
                label: valueId.toString(),
                excluded,
                selected: true,
            })),
        };
    }
}
