import _ from 'lodash';
import { titleize } from 'inflected';
import type { IQuery } from '../../lib/types';
import type { IMetricDefinition } from '../../lib/types';
import { StorageAPI } from '../../lib/storage-user-config';
import type { IQueryMetrics, DashboardRootScope } from '../main-controller';
import { IAdPlatformConfig, IAdsConfig, IAdsConfigService, IAdsService } from './ads.service';
import { processMetrics } from './ads.helper';
import { AdGrid } from './ads-grid.directive';
import { IAdStatsItem } from './ads-stats.directive';
import { AdChart } from './ads-chart.directive';

export interface IAdView {
    stats: IAdStatsItem[] | null;
    title: string;
    platform: string;
    config: IAdsConfig;
    grid: AdGrid;
    chart: AdChart;
    availableMetrics: IMetricDefinition[];
    metrics: IMetricDefinition[];
    properties: any;
    property: any;
}

export interface IAdsStorage {
    chart?: string[];
}

export interface IAdsView {
    adsViews: IAdView[] | null;
    platforms: string[];
    config: IAdsConfig | undefined;
}

export interface IAdsViewFactory {
    fetchView(query: IQuery, organizationId: string): IAdsView;
}

export interface IAdViewOptions {
    availableMetrics: IMetricDefinition[];
    platform: string;
    config: IAdPlatformConfig;
    query: IQuery;
    properties: any;
    metrics: (IMetricDefinition & { color: string })[];
    chartMetrics: (IMetricDefinition & { color: string })[];
}

const METRICS_COLORS = ['#40e0d0', '#7b68ee', '#ff6666', '#9FE18D', '#FFB665', '#FB85D0', '#CBACF6'];

// This is done so the Metric color is the same across different platforms
const getKpiByColor = (platforms: string[], config: Record<string, IAdPlatformConfig>) => {
    const kpiByColor = _.uniq(
        platforms.reduce((acc: string[], platform) => {
            return acc.concat(config[platform].kpis);
        }, []),
    ).reduce((acc: Record<string, string>, kpi, index) => {
        acc[kpi] = METRICS_COLORS[index % METRICS_COLORS.length];
        return acc;
    }, {});

    return kpiByColor;
};

export const AdsViewFactory = () => [
    '$q',
    'AdsConfigService',
    'AdsService',
    'MetricsHierarchy',
    'QueryMetrics',
    'MetricsFunnelNode',
    function AdsViewModel(
        $q: angular.IQService,
        AdsConfigService: IAdsConfigService,
        AdsService: IAdsService,
        MetricsHierarchy: any,
        QueryMetrics: IQueryMetrics,
    ) {
        class AdView {
            stats: IAdStatsItem[] | null = null;
            title: string;
            platform: string;
            config: IAdPlatformConfig;
            grid: AdGrid;
            chart: AdChart;
            availableMetrics: IMetricDefinition[];
            metrics: (IMetricDefinition & { color: string })[];
            properties: any;
            property: any;

            constructor(options: IAdViewOptions) {
                const { platform, config, query, availableMetrics, properties, metrics, chartMetrics } = options;
                this.platform = platform;
                this.availableMetrics = availableMetrics;
                this.metrics = metrics;
                this.properties = properties;
                this.title = titleize(platform);
                this.config = config;

                const propertyId = `${config.property}.${config.propertyFilter}`;
                this.property = this.properties.find(p => p.id === propertyId);

                this.chart = new AdChart({
                    platform: this.platform,
                    config: this.config,
                    property: this.property,
                    title: this.title,
                    properties,
                    metrics: chartMetrics,
                    allMetrics: this.metrics,
                });

                this.grid = new AdGrid({
                    title: this.title,
                    properties,
                    metrics: this.metrics,
                    property: this.property,
                    platform: this.platform,
                    data: null,
                });

                this.init(query);
            }

            init(query: IQuery) {
                AdsService.fetch(this.availableMetrics, this.metrics, this.config, this.property, query).then(
                    adsData => {
                        const { stats, grid } = adsData;
                        this.stats = stats;
                        this.grid.data = grid;
                    },
                );
            }
        }
        class AdsView {
            adsViews: AdView[] | null = null;
            platforms: string[] = [];
            config: IAdsConfig | undefined;
            organizationId: string;
            adsStorage: Record<string, IAdsStorage> = {};

            constructor(query: IQuery, organizationId: string) {
                this.organizationId = organizationId;
                this.init(query);
            }

            init(query: IQuery) {
                $q.all([
                    AdsConfigService.fetch(),
                    QueryMetrics.fetch(),
                    MetricsHierarchy.fetch(),
                    StorageAPI<{ [key: string]: IAdsStorage }>('ads').then(api => api.get()),
                ]).then(([config, availableMetrics, properties, adsStorage]) => {
                    this.adsStorage = (adsStorage as Record<string, IAdsStorage>) || this.adsStorage;
                    this.config = config;
                    this.platforms = Object.keys(config.platforms);
                    const kpiByColorDictionary = getKpiByColor(this.platforms, config.platforms);
                    const adsViews: AdView[] = [];

                    this.platforms.forEach(platform => {
                        this.adsStorage[platform] ??= {};
                        const platformKpis = config.platforms[platform].kpis;
                        const metrics = processMetrics(availableMetrics, platformKpis, kpiByColorDictionary);

                        // Get Chart Metrics
                        const chartKpis =
                            this.adsStorage[platform].chart || config.platforms[platform].chart?.kpis || platformKpis;

                        const adView = new AdView({
                            availableMetrics,
                            config: config.platforms[platform],
                            platform,
                            properties,
                            query,
                            metrics,
                            chartMetrics: metrics.filter(metric => chartKpis.indexOf(metric.field) > -1),
                        });

                        this.adsStorage[platform].chart = chartKpis;
                        adsViews.push(adView);
                    });

                    this.adsViews = adsViews;

                    StorageAPI<{ [key: string]: IAdsStorage }>('ads').then(api => api.put(this.adsStorage));
                });
            }
        }

        return {
            fetchView: (query: IQuery, organizationId: string) => new AdsView(query, organizationId),
        };
    },
];

interface AdsControllerScope extends angular.IScope {
    view: IAdsView | null;
}
export const AdsControllerFactory = () => [
    '$rootScope',
    '$scope',
    'AdsView',
    function AdsController($rootScope: DashboardRootScope, $scope: AdsControllerScope, AdsView: IAdsViewFactory) {
        $scope.view = null;
        $rootScope.$watch('initialized', (initialized: boolean) => {
            if (!initialized) return;

            const unwatchQueryRefresh = $rootScope.$on('query.refresh', () => {
                $scope.view = AdsView.fetchView($rootScope.query, $rootScope.organizationId);
            });
            $scope.$on('$destroy', unwatchQueryRefresh);
            $scope.view = AdsView.fetchView($rootScope.query, $rootScope.organizationId);
        });
    },
];
