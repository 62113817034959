import _ from 'lodash';
import type { AngularInjected } from '../../../lib/angular';
import { IQueryServiceAPI } from '../../../modules/services/query-service.types';
import type { IMetricDefinition, IQuery } from '../../../lib/types';
import { ConfigAPI } from '../../../lib/config-api';
import type { IPropertyDefinition } from '../../../lib/config-hierarchy';
import { HierarchyService } from '../../../modules/hierarchy';
import type { IQueryMetrics } from '../../main-controller';
import type { ISalesChart } from '../overview-card-charts';
import { IOverviewChartMetricsConfigService, joinMetricIdsWithMetricDescriptors } from '../overview-metrics.service';
import { normalizeDefaultsItemsGroupByConfig } from '../config-views-sales';
import { ITopItemsConfig, normalizeTopItemsConfig } from './card-top-items-config';

export interface ITopItemsProperty {
    id: string;
    label: string;
}

export interface ITopItemsResolvedConfig {
    properties: ITopItemsProperty[];
    metrics: IMetricDefinition[];
    sort: IMetricDefinition[] | null;
    limit: number;
}

export const DEFAULT_GROUP_BY_ITEM_PROPERTY = 'items.name';
export const DEFAULT_TOP_ITEMS_LIMIT = 6;

export type IOverviewTopItemsConfigService = AngularInjected<typeof OverviewTopItemsConfigServiceFactory>;
export const OverviewTopItemsConfigServiceFactory = () => [
    '$q',
    'QueryMetrics',
    'OverviewChartMetricsConfigService',
    function OverviewTopItemsConfigService(
        $q: angular.IQService,
        QueryMetrics: IQueryMetrics,
        OverviewChartMetricsConfigService: IOverviewChartMetricsConfigService,
    ) {
        const fetchHierarchy = () => {
            return $q.when(HierarchyService().fetch()).then(({ all, stores, items }) => {
                return _.keyBy(
                    _.flatten([stores, items, all].map(propertiesSet => Object.values(propertiesSet))),
                    'id',
                );
            });
        };

        const joinPropertiesWithHierarchy = (
            availableProperties: Record<string, IPropertyDefinition>,
            properties: string[],
        ): ITopItemsProperty[] => {
            return properties.map(property => {
                const newProperty = availableProperties[property] ?? {
                    id: property,
                    plural: property,
                    label: property,
                };
                const label = newProperty.plural ?? newProperty.label ?? newProperty.id;
                return { id: newProperty.id, label };
            });
        };

        const fetchDefaultKpis = (
            salesChartMetrics: IMetricDefinition[] | null,
            availableMetrics: IMetricDefinition[],
            salesChartSelectedConfigKpis: { field: string; [x: string]: unknown }[] = [],
        ) => {
            const config = joinMetricIdsWithMetricDescriptors(availableMetrics, salesChartSelectedConfigKpis);
            const salesKpis = config.filter(item => ['Sales', 'Inventory', 'Demand'].includes(item.category ?? ''));
            const kpis = (salesChartMetrics ?? salesKpis).slice(0, 6);
            return kpis.map(x => x.field);
        };

        const getTopItemsConfig = (
            userConfig: unknown,
            orgConfig: unknown,
            salesChartMetrics: IMetricDefinition[] | null,
            availableMetrics: IMetricDefinition[],
        ) => {
            const defaults = {
                kpis: fetchDefaultKpis(salesChartMetrics, availableMetrics),
                properties: normalizeDefaultsItemsGroupByConfig(userConfig) ??
                    normalizeDefaultsItemsGroupByConfig(orgConfig) ?? [DEFAULT_GROUP_BY_ITEM_PROPERTY],
            };
            const userTopItemsConfig = normalizeTopItemsConfig(userConfig, defaults);
            const orgTopItemsConfig = normalizeTopItemsConfig(orgConfig, defaults);

            const defaultConfig: ITopItemsConfig = {
                kpis: defaults.kpis,
                properties: defaults.properties,
                sort: null,
                limit: DEFAULT_TOP_ITEMS_LIMIT,
            };

            return userTopItemsConfig ?? orgTopItemsConfig ?? [defaultConfig];
        };

        return {
            fetch: () => {
                const configPromise = ConfigAPI.get();
                return $q
                    .all([
                        QueryMetrics.fetch(),
                        fetchHierarchy(),
                        OverviewChartMetricsConfigService.fetch(),
                        configPromise.then(config => config.user.getInternal()),
                        configPromise.then(config => config.organization.get()),
                    ])
                    .then(([availableMetrics, availableProperties, salesChartMetrics, userConfig, orgConfig]) => {
                        const overviewTopItemsConfig = getTopItemsConfig(
                            userConfig,
                            orgConfig,
                            salesChartMetrics,
                            availableMetrics,
                        );

                        const topItemsConfigs: ITopItemsResolvedConfig[] = overviewTopItemsConfig.map(config => {
                            const metrics = (() => {
                                const metrics = joinMetricIdsWithMetricDescriptors(availableMetrics, config.kpis);
                                return QueryMetrics.applyCurrencyToMetrics(metrics);
                            })();

                            const properties = joinPropertiesWithHierarchy(availableProperties, config.properties);
                            const sort = (() => {
                                const sort = config.sort;
                                if (_.isNil(sort)) return null;
                                const metrics = joinMetricIdsWithMetricDescriptors(availableMetrics, sort);
                                return QueryMetrics.applyCurrencyToMetrics(metrics);
                            })();

                            return { metrics, properties, sort, limit: config.limit };
                        });

                        return topItemsConfigs;
                    });
            },
        };
    },
];

export type ITopItemsService = AngularInjected<typeof TopItemsServiceFactory>;
export const TopItemsServiceFactory = () => [
    '$q',
    'QueryServiceAPI',
    function TopItemsService($q: angular.IQService, QueryServiceAPI: IQueryServiceAPI) {
        return {
            fetch: (query: IQuery, config: ITopItemsResolvedConfig) => {
                query = _.cloneDeep(query);
                delete query.comparison;
                delete query.sort;
                query.limit = config.limit;
                query.options ??= {};
                query.options.includeTotals = false;
                query.options.properties = config.properties.map(property => property.id);
                query.options.metrics = config.metrics.map(metric => metric.field);
                query.options.sort = config.sort?.map(metric => ({
                    property: query.options?.properties[0],
                    field: metric.field,
                    limit: config.limit,
                    order: -1,
                }));
                return $q.when(QueryServiceAPI().then(api => api.query.metricsFunnel(query)));
            },
        };
    },
];

const orderHeaderGroups = (metrics: IMetricDefinition[]): string[] => {
    const seen = new Set<string>();
    const result: string[] = [];
    for (const metric of metrics) {
        if (seen.has(metric.headerGroup)) continue;
        seen.add(metric.headerGroup);
        result.push(metric.headerGroup);
    }
    return result;
};

const getValueClass = (value: unknown, metric: IItemInfoCellMetric) => {
    const hasPercentageCellClass =
        Array.isArray(value) && value.length > 0 && (metric.cellClass || '').indexOf('percent') !== -1;

    if (hasPercentageCellClass && typeof metric.value === 'number') {
        const rawValue = metric.cellClass === 'percent-inverted' ? metric.value * -1 : metric.value;
        if (rawValue > 0) return 'percent-positive';
        if (rawValue < 0) return 'percent-negative';
        return '';
    }

    return Array.isArray(value) && value.length === 0 ? 'blank' : '';
};

const mapMetrics = ($filter: angular.IFilterFunction, metrics: IItemInfoCellMetric[]) => {
    const metricsHtml = metrics.map(metric => {
        let value = metric.value ?? null;

        if (metric.cellFilter) {
            const [filter, ...filterArgs] = metric.cellFilter.split(':');
            value = $filter(filter)(value, ...filterArgs);
            value = _.isNil(value) ? '' : value;
        }

        if (value && !(typeof value === 'string')) {
            value = (value.toString && value.toString()) || value;
        }

        const headerName = metrics.length === 1 && metric.headerName === 'TY' ? '' : metric.headerName;
        const headerNameHTML = headerName ? `<span class="item-metric-header-name">${headerName}</span>` : '';
        const valueClass = getValueClass(value, metric);
        const valueString = typeof value === 'string' && value.length === 0 ? 'n/a' : value;
        const valueHTML = `<span class='item-metric-value ${valueClass}'>${valueString}</span>`;

        return `
            <span class="item-metric">
                ${headerNameHTML}
                ${valueHTML}
            </span>
        `;
    });

    return metricsHtml.join('\n');
};

const renderItemMetrics = ($filter: angular.IFilterFunction, itemMetrics: IItemInfoCellMetric[]) => {
    const groupedMetrics = _.groupBy(itemMetrics, metric => metric.headerGroup);
    const orderedHeaderGroups = orderHeaderGroups(itemMetrics);
    const itemHtml = _.flatten(
        orderedHeaderGroups.map(headerGroup => {
            const metrics = groupedMetrics[headerGroup] ?? [];
            const isBare = metrics.length === 1;
            return `
            <span class='item-metric-group ${isBare ? 'bare' : ''}'>
                <span class='item-metric-header-group'>${headerGroup}</span>
                ${mapMetrics($filter, metrics)}
            </span>
            `;
        }),
    );
    return itemHtml.join('');
};

interface IItemInfoCellMetric<T = unknown> extends IMetricDefinition {
    value: T;
}

interface IItemInfoCell<T = unknown> {
    data: Record<string, T>;
    metrics: undefined | IItemInfoCellMetric<T>[];
}

export const ItemInfoCellRenderer = ($filter: angular.IFilterFunction, item: IItemInfoCell) => {
    const metrics = item.metrics ?? [];
    const itemImageHtml =
        typeof item.data.item_image === 'string'
            ? `<div class='item-image' style='background-image: url("${item.data.item_image}")'></div>`
            : "<div class='item-image'></div>";

    const metricsHtml =
        metrics.length > 0 ? `<div class='item-metrics'>${renderItemMetrics($filter, metrics)}</div>` : '';

    const name = typeof item.data.property0 === 'string' ? item.data.property0 : '';
    return `
        ${itemImageHtml}
        <div class='item-name'>${name}</div>
        <div class='item-info'>${metricsHtml}</div>
    `;
};

export const TopItemViewItemDirectiveFactory = () => [
    '$filter',
    function TopItemViewItemDirective($filter: angular.IFilterFunction) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                model: '=',
            },
            template: '<div class="item" ng-bind-html="item"></div>',
            link: function TopItemViewItemLink($scope: angular.IScope & { model: IItemInfoCell; item: string }) {
                $scope.item = ItemInfoCellRenderer($filter, $scope.model);

                $scope.$watch('model', () => {
                    $scope.item = ItemInfoCellRenderer($filter, $scope.model);
                });
            },
        };
    },
];

export const CardTopItemsDirectiveFactory = () => [
    function CardTopItemsDirective(): angular.IDirective<angular.IScope & { itemsUrl?: string; model?: unknown }> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                itemsUrl: '=',
                model: '=',
            },
            template: `
                <div class="top-items card" ng-class="{loading: model.view.items === null, empty: model.view.items.length == 0 }">
                    <div class="top-items-header">
                        <div class="header-label">
                            <h1 class="card-title">
                                <span class="metric" ng-repeat="x in model.view.properties track by x.id">{{ x.label }}</span>
                                <span ng-show="model.view.properties && model.view.sort">by</span>
                                <span class="metric" ng-repeat="x in model.view.sort track by x.id">{{ x.label }}</span>
                            </h1>
                        </div>
                        <div class="more-info-label">
                            <a ng-href="{{ itemsUrl }}">click here to see more item info</a>
                        </div>
                    </div>
                    <section class="items">
                        <ul class="top-items-list">
                            <li class="item-container" ng-repeat="item in model.view.items track by item.id">
                                <top-item-view-item model="item.info"></top-item-view-item>
                            </li>
                        </ul>
                    </section>
                </div>
            `,
            link: function CardTopItemsDirectiveLink(scope, $element: angular.IRootElementService) {
                const TOP_ITEMS_MIN_WIDTH = 640;
                const listEl = $element[0]?.querySelector('.items > .top-items-list');
                const containerEl = listEl?.parentElement;
                if (!listEl || !containerEl) throw new Error('Could not find top items list element');
                const resizeObserver = new ResizeObserver(elements => {
                    const containerWidth = elements[0]?.contentRect?.width ?? 0;
                    containerWidth <= TOP_ITEMS_MIN_WIDTH
                        ? listEl.classList.add('collapsed')
                        : listEl.classList.remove('collapsed');
                });
                resizeObserver.observe(containerEl);
                scope.$on('$destroy', () => resizeObserver.disconnect());
            },
        };
    },
];

export interface ITopItemsViewModelView {
    readonly properties: ITopItemsProperty[];
    readonly sort: { id: string; label: string }[] | null | undefined;
    items: null | { id: unknown; info: IItemInfoCell }[];
}

export interface ITopItemsViewModel {
    readonly config: ITopItemsResolvedConfig;
    readonly sort: IMetricDefinition[] | null | undefined;
    readonly metrics: IMetricDefinition[] | undefined;
    readonly properties: ITopItemsProperty[] | null | undefined;
    readonly view: ITopItemsViewModelView;
}

export interface ITopItemsViewFactoryOptions {
    query: IQuery;
    config: ITopItemsResolvedConfig[];
    chart: ISalesChart;
}

export type TopItemsView = AngularInjected<typeof TopItemsViewFactory>;
export type ITopItemsView = InstanceType<TopItemsView>;
export const TopItemsViewFactory = () => [
    'TopItemsService',
    function TopItemsViewFactory(TopItemsService: ITopItemsService) {
        class TopItemsViewModel implements ITopItemsViewModel {
            readonly sort: IMetricDefinition[] | null;
            readonly metrics: IMetricDefinition[];
            readonly properties: ITopItemsProperty[];
            readonly config: ITopItemsResolvedConfig;
            readonly view: ITopItemsViewModelView;

            constructor(query: IQuery, config: ITopItemsResolvedConfig) {
                this.config = _.cloneDeep(config);
                this.sort = config.sort;
                this.metrics = config.metrics;
                this.properties = config.properties;
                const sort =
                    config.sort &&
                    _.uniqBy(config.sort, x => x.field).map(x => ({ id: x.field, label: x.headerGroup }));
                this.view = {
                    items: null,
                    properties: this.properties,
                    sort,
                };
                void this.init(query);
            }

            protected init(query: IQuery) {
                const config = _.cloneDeep(this.config);
                return TopItemsService.fetch(query, config).then(rows => {
                    this.view.items = rows.map(data => {
                        const dimensions = this.properties.map((_, index) => data[`property${index}`]);
                        const metrics = this.metrics.map(metric => ({ value: data[metric.field], ...metric }));
                        return {
                            id: dimensions.join('+'),
                            info: { data, metrics },
                        };
                    });
                });
            }
        }

        class TopItemsView {
            readonly config: ITopItemsResolvedConfig[];
            readonly items: ITopItemsViewModel[];
            constructor({ config, chart, query }: ITopItemsViewFactoryOptions) {
                this.config = config;
                this.items = config.map(c => {
                    const field = chart.metricId;
                    const headerGroup = chart.title;
                    const sort = c.sort ?? [{ field, headerGroup }];
                    return new TopItemsViewModel(query, { ...c, sort });
                });
            }
        }

        return TopItemsView;
    },
];

export default angular
    .module('42.controllers.overview.card-top-items', ['42.filters'])
    .service('OverviewTopItemsConfigService', OverviewTopItemsConfigServiceFactory())
    .directive('cardTopItems', CardTopItemsDirectiveFactory())
    .directive('topItemViewItem', TopItemViewItemDirectiveFactory())
    .service('TopItemsService', TopItemsServiceFactory())
    .factory('TopItemsView', TopItemsViewFactory());
