import _ from 'lodash';
import {
    IQueryColumnFilterValue,
    IQueryColumnFilterValueContains,
    IQueryFilters,
    IQueryTableFilter,
    IQueryTableFilterOperatorAnd,
    IQueryTableFilterOperatorOr,
} from '../types';
import Utils from '../utils';

function normalizeContainsOperatorValues(x: unknown[]): (string | number)[] {
    if (!Array.isArray(x)) return [];
    return x.filter(x => typeof x === 'string' || typeof x === 'number');
}

export function* findContainsOperators(value: IQueryColumnFilterValue): Generator<IQueryColumnFilterValueContains> {
    if (typeof value === 'string' || typeof value === 'number') {
        yield* findContainsOperators({ $eq: value });
        return;
    }
    if (!Utils.Object.isObject(value)) return null;
    for (const [operatorName, operator] of Object.entries(value)) {
        if (operatorName === '$eq') {
            yield* findContainsOperators({ $in: [operator] });
            continue;
        }
        if (operatorName === '$neq') {
            yield* findContainsOperators({ $nin: [operator] });
            continue;
        }
        if (operatorName === '$in' || operatorName === '$nin') {
            const values = normalizeContainsOperatorValues(operator);
            if (values.length === 0) continue;
            if (operatorName === '$in') yield { $in: values };
            if (operatorName === '$nin') yield { $nin: values };
        }
    }
}

export function* visitTableColumnFilterValue(
    columnFilters: Iterable<{
        [x: string]: IQueryColumnFilterValue;
    }>,
): Iterable<{
    columnName: string;
    value: IQueryColumnFilterValue;
}> {
    for (const columnFilter of columnFilters) {
        if (!Utils.Object.isObject(columnFilter)) continue;
        const columnName = Object.keys(columnFilter)[0];
        if (typeof columnName !== 'string') continue;
        const columnFilterValue = columnFilter[columnName];
        if (!columnFilterValue) continue;
        // table.$and[{column:value}]
        yield { columnName, value: columnFilterValue };
    }
}

export function* visitTableColumnFilterContainsOperators(queryFilters: IQueryFilters): Iterable<{
    tableName: string;
    columnName: string;
    operator: '$and' | '$or';
    value: IQueryColumnFilterValueContains;
}> {
    for (const entry of visitTableColumnFilters(queryFilters)) {
        for (const contains of findContainsOperators(entry.value)) {
            yield { ...entry, value: contains };
        }
    }
}

export function* visitTableColumnFilters(queryFilters: IQueryFilters): Iterable<{
    tableName: string;
    columnName: string;
    operator: '$and' | '$or';
    value: IQueryColumnFilterValue;
}> {
    for (const [tableName, tableFilters] of Object.entries(queryFilters)) {
        if (!Utils.Object.isObject(tableFilters)) continue;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { $and, $or, ...columns } = tableFilters;
        for (const operator of ['$and', '$or'] as const) {
            const tableValue = tableFilters[operator];
            if (!Array.isArray(tableValue)) continue;
            for (const { columnName, value } of visitTableColumnFilterValue(tableValue)) {
                yield { tableName, operator, columnName, value };
            }
        }
        // implicit and
        for (const [columnName, value] of Object.entries(columns)) {
            if (columnName.startsWith('$')) continue;
            if (Array.isArray(value)) continue;
            if (_.isNil(value)) continue;
            yield { tableName, operator: '$and', columnName, value };
        }
    }
}

/** WARNING: This probably needs more testing + it probably will get rid of non $and filters */
export function unionAllFilters(...queryFilters: IQueryFilters[]) {
    const merged: Record<string, IQueryTableFilter> = {};
    let deduplicated: Record<string, Record<'$and' | '$or', Record<string, IQueryColumnFilterValue>>> = {};
    for (const filter of queryFilters) {
        const tableFilters = visitTableColumnFilters(filter);
        // tableFilters = findTableColumnsWithContainsOperators(tableFilters);
        for (const { tableName, columnName, operator: tableFilterOperator, value } of tableFilters) {
            const path = [tableName, tableFilterOperator, columnName];
            deduplicated = _.set(deduplicated, path, value);
        }
    }
    for (const [tableName, columnOperators] of Object.entries(deduplicated)) {
        for (const [columnOperator, columnNames] of Object.entries(columnOperators)) {
            const table = merged[tableName] ?? { [columnOperator]: [] };
            let tableOp: undefined | IQueryTableFilterOperatorAnd['$and'] | IQueryTableFilterOperatorOr['$or'];
            tableOp = '$and' in table ? table.$and : '$or' in table ? table.$or : [];
            tableOp = Array.isArray(tableOp) ? tableOp : [];
            for (const [columnName, columnFilterValue] of Object.entries(columnNames)) {
                tableOp.push({ [columnName]: columnFilterValue });
                merged[tableName] = table;
            }
        }
    }
    return merged;
}

export function compactQueryFilters(queryFilters: IQueryFilters) {
    return unionAllFilters(queryFilters);
}

export function isOperator(value: string): value is `$${string}` {
    return typeof value === 'string' && value.startsWith('$');
}
