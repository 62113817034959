export type ToggleText = {
    label: string;
    id: number;
};

export type IToggleTextModel = [ToggleText, ToggleText];

interface ToggleTextDirectiveScope extends angular.IScope {
    model: IToggleTextModel;
    selected: number;
    onClick: (id: number) => void;
    clickItem: ($event: MouseEvent, textItem: ToggleText) => void;
}
export const ToggleTextDirective = () => [
    function ToggleTextDirective(): angular.IDirective<ToggleTextDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                onClick: '=',
                model: '=',
                selected: '=',
            },
            replace: true,
            template: `
                <div class="toggle-text">
                    <div class="toggle-left-text toggle-label"
                        ng-class="{selected: model[0].id === selected}"
                        ng-click="clickItem($event, model[0])">
                        {{ model[0].label }}
                    </div>
                    <div class="toggle-right-text toggle-label"
                        ng-class="{selected: model[1].id === selected}"
                        ng-click="clickItem($event, model[1])">
                        {{ model[1].label }}
                    </div>
                </div>
            `,
            link: function ToggleTextLink(scope) {
                scope.clickItem = ($event, textItem) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();

                    if (textItem.id === scope.selected) return;

                    scope.onClick(textItem.id);
                };
            },
        };
    },
];

const ToggleTextModule = angular.module('42.components.toggle-text', []).directive('toggleText', ToggleTextDirective());

export default ToggleTextModule;
