import Sortable from 'sortablejs';

export const createSortable = (element: HTMLElement, options: Sortable.Options = {}) => {
    const defaultOptions: Sortable.Options = {
        animation: 100,
        delay: 200,
        delayOnTouchOnly: true,
    };
    return Sortable.create(element, { ...defaultOptions, ...options });
};
