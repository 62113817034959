import _ from 'lodash';
import type { I42CustomSeriesOption, ISeriesData } from './chart-echarts-wrapper.directive';
import type { MapSeriesOption } from 'echarts';

export const SLICE_VALUE_THRESHOLD = 0.02;
export const SLICE_NUMBER_THRESHOLD = 10;

// TODO - Study best slice strategy.
// At the moment, `maxNumberOfSlices` and `groupSlicesThresholdValue` are "OR" conditions
// When both options enabled, `maxNumberOfSlices` prevails

export interface IGroupSeriesOptions {
    maxNumberOfSlices?: number;
    groupSlicesThresholdValue?: number;
}

export const validateThresholds = (value: number | undefined, defaultValue: number) =>
    _.isNumber(value) && value > 0 ? value : defaultValue;

function getSlicesTotalValue(slices: ISeriesData[]): number {
    return slices.reduce((acc, item) => acc + Math.abs(item.value || 0), 0);
}

export const shouldGroupSeries = (
    serie: I42CustomSeriesOption,
    sliceNumberThreshold: number,
    sliceValueThreshold: number,
) => {
    if (!serie) return false;
    const slices = serie.data || [];

    if (sliceNumberThreshold > 0) {
        return slices.length > sliceNumberThreshold;
    }

    const totalValue = getSlicesTotalValue(slices);

    return !slices.every(item => Math.abs(item.value / totalValue) >= sliceValueThreshold);
};

export const hasGroupSeries = (serie: I42CustomSeriesOption): boolean => {
    if (!serie) return false;
    if (!serie.data) return false;
    const data: ISeriesData[] = serie.data || [];
    return Boolean(data[data.length - 1]?.groupedSeries);
};

export function getSeriesMaxMinValue(serie: MapSeriesOption) {
    const data = serie.data ?? [];
    const values = _.compact(
        data.reduce((acc: number[], elem) => {
            if (!Array.isArray(elem) && typeof elem === 'object' && typeof elem.value === 'number') {
                acc.push(elem.value);
            }
            return acc;
        }, []),
    );

    return {
        max: _.max(values) ?? 0,
        min: _.min(values) ?? 0,
    };
}
