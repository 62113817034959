import _ from 'lodash'
import { preventOverscroll } from '../../../lib/dom/scroll'

module = angular.module('42.controllers.scheduling.common')


module.filter 'reportParamsMetricSelectAvailableFilter', -> (metricGroups, filter) ->
    filter = filter.toLowerCase().trim()
    return metricGroups if not filter
    return metricGroups.filter (metricGroup) ->
        value = metricGroup.label.toLowerCase().trim()
        filterIndex = 0
        for c in value
            filterIndex++ if c is filter[filterIndex]
            return true if filterIndex is filter.length
        return false


module.directive 'reportParamsMetricSelect', ($q, $filter, Utils, promiseTracker, ReportingState, ReportParamsMetricSelectModel) ->
    moved = false

    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-metric-select" style="position:relative" promise-tracker="reportParamsMetricSelect">
        <header>
            <h1>What metrics should be included?</h1>
        </header>
        <main>
            <section class="available">
                <header>
                    <div class="row row-title">
                        <div class="search-container" ng-class="{filtered:view.filter.length > 0}">
                            <i class="icon-clear-filter icon-cancel-circled" ng-click="view.filter = ''"></i>
                            <input type="text" placeholder="Filter..." ng-model="view.filter"></input>
                            <i class="icon-search"></i>
                        </div>
                    </div>
                    <div class="row row-info">
                        <span class="info available-info">{{ getAvailableInfo() }}</span>
                        <button class="button-bare" ng-click="actions.selectAll()" ng-if="view.available.length > 0">select all</button>
                    </div>
                </header>
                <main>
                    <ul class="metric-groups">
                        <li class="metric-group"
                        ng-repeat="metricGroup in view.available track by metricGroup.id"
                        ng-click="actions.select(metricGroup)">
                            <div class="metrics-container">
                                <span class="label">{{ metricGroup.label }}</span>
                                <span class="metrics">
                                    <span class="metric" ng-repeat="metric in metricGroup.metrics track by metric.field">{{ metric.headerName }}</span>
                                </span>
                                <i class="move-icon move-icon-right icon-right-open-mini"></i>
                            </div>
                        </li>
                    </ul>
                </main>
            </section>
            <section class="selected">
                <header>
                    <div class="row row-title">
                        <h1>Selected</h1>
                    </div>
                    <div class="row row-info">
                        <span class="info selected-info" ng-if="model.selected.length > 1">{{ model.selected.length }} metrics selected</span>
                        <span class="info selected-info" ng-if="model.selected.length == 1">{{ model.selected.length }} metric selected</span>
                        <span class="info selected-info" ng-if="model.selected.length == 0">Click a metric on the left to select it</span>
                        <button class="button-bare" ng-click="actions.reset()" ng-if="model.selected.length > 0">reset</button>
                    </div>
                </header>
                <main>
                    <ul class="metric-groups"
                        dnd-list="model.selected"
                        dnd-drop="drop(event, index, item)"
                        dnd-horizontal-list="false">
                        <li class="metric-group"
                            dnd-draggable="metricGroup"
                            dnd-effect-allowed="move"
                            dnd-moved="moved($index, event)"
                            ng-repeat="metricGroup in model.selected track by metricGroup.id">
                            <div dnd-nodrag>
                                <div class="metrics-container" ng-click="actions.remove(metricGroup)">
                                    <i class="move-icon move-icon-left icon-left-open-mini"></i>
                                    <span class="label">{{ metricGroup.label }}</span>
                                    <span class="metrics">
                                        <span class="metric" ng-repeat="metric in metricGroup.metrics track by metric.field">{{ metric.headerName }}</span>
                                    </span>
                                </div>
                                <i dnd-handle class="drag-icon icon-menu"></i>
                            </div>
                        </li>
                    </ul>
                </main>
            </section>
        </main>
    </article>
    """
    link: (scope, element) ->

        scope.drop = (event, nextIndex, item) ->
            currentIndex = Utils.indexOf scope.model.selected, (x) -> x.id is item.id
            # if the property is in the list already, then we move it
            if currentIndex >= 0
                moved = true
                # The `nextIndex` that's returned by the framework will be wrong if the item is moved
                # after it's current location because it is counting the index of the placeholder element.
                nextIndex = nextIndex - 1 if nextIndex > currentIndex
                scope.model.selected = Utils.move(scope.model.selected, currentIndex, nextIndex)
            # Otherwise we just insert the element.
            else
                moved = false
                scope.model.selected = Utils.insertAt(scope.model.selected, nextIndex, item)
            return true

        scope.moved = (index) ->
            scope.model.selected = Utils.removeAt(scope.model.selected, index) if not moved
            moved = false
            return true

        watchers = []
        tracker = promiseTracker('reportParamsMetricSelect')

        scope.view = {filter:"", available:[]}

        listElements =
            available: $($(element).find('.available main'))
            selected:  $($(element).find('.selected main'))

        Object.values(listElements).forEach (el) ->
            unsub = preventOverscroll(el)
            scope.$on('$destroy', unsub)

        updateAvailableView = ->
            scope.view.available = do ->
                return [] if _.isUndefined(scope.model?.available)
                available = scope.model.available.filter (x) -> not scope.model.isSelected(x)
                return $filter('reportParamsMetricSelectAvailableFilter')(available, scope.view.filter)

        scope.getAvailableInfo = ->
            {view, model} = scope
            metrics = if view.available.length is 1 then "metric" else "metrics"
            filtered = if view.filter then "found (#{ model.available.length - model.selected.length - view.available.length } filtered)" else "available"
            count = if view.available.length is 0 then "No" else view.available.length
            return "#{count} #{metrics} #{filtered}"

        scope.actions =
            reset: ->
                scope.model.reset()
                updateAvailableView()
            remove: (item) ->
                scope.model.remove(item)
                updateAvailableView()
            select: (item) ->
                scope.model.select(item)
                updateAvailableView()
                setTimeout (-> listElements.selected.scrollTop(listElements.selected[0].scrollHeight)), 0
            selectAll: ->
                scope.view.available.forEach (x) -> scope.model.select(x)
                updateAvailableView()

        scope.$watch 'view.filter', updateAvailableView

        scope.$watch 'model.selected.length', ->
            return if not scope.model
            ReportingState.report.updateInvalidFields(scope.model.getInvalidFields())

        initModel = do ->
            promise = null
            return (params) ->
                scope.model ?= new ReportParamsMetricSelectModel(params)
                promise ?= scope.model.init()
                return promise.then ->
                    scope.model.updateModelFromParams(params)
                    return scope.model

        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            watchers.forEach (x) -> x() # unregisters the existing watchers
            watchers = []

            modelPromise = initModel(params)
            tracker.addPromise(modelPromise)

            modelPromise.then ->
                updateAvailableView()
                watchers.push \
                scope.$watch 'model.selected', ((properties) ->
                    return if _.isUndefined(properties)
                    scope.model.updateParamsFromModel()
                ), true
                watchers.push \
                scope.$watch 'params.metrics', (->
                    scope.model.updateModelFromParams()
                ), true
                watchers.push \
                scope.$watch 'params.currency', ((currency) ->
                    return if not currency
                    scope.model.refresh().then(updateAvailableView)
                ), true


module.directive 'reportParamsViewerMetricSelect', ($q, ReportParamsMetricSelectModel) ->
    restrict: 'E'
    scope:
        params: '='
    # replace: true
    template: \
    """
    <article class="report-params-viewer report-params-metric-select-viewer" ng-if="view.metrics && view.metrics.length > 0">
        <header>
            <h1>Selected Metrics</h1>
        </header>
        <main>
            <ul class="ui-pellets">
                <li class="ui-pellet active disabled" ng-repeat="x in view.metrics">
                    <span class="ui-pellet-value">{{ x.label }}</span>
                    <span class="ui-pellet-detail metric" ng-repeat="metric in x.metrics">{{ metric.headerName }}</span>
                </li>
            </ul>
        </main>
    </article>
    """
    link: (scope) ->
        scope.view = {metrics:[]}

        initModel = do ->
            promise = null
            return (params) ->
                scope.model ?= new ReportParamsMetricSelectModel(params)
                promise ?= scope.model.init()
                return promise.then ->
                    scope.model.updateModelFromParams(params)
                    return scope.model

        scope.$watch 'params', (params) ->
            if _.isUndefined(params)
                scope.view.metrics = []
                return

            initModel(params).then ->
                scope.view.metrics = scope.model.selected


module.factory 'ReportParamsMetricSelectModel', ($q, $rootScope, Utils, QueryMetrics) ->
    class ReportParamsMetricSelectModel

        constructor: (@params) ->
            @available = []
            @selected = []

        init: ->
            @refresh()

        refresh: ->
            @_fetchMetrics().then (metrics) =>
                @available = @_getAvailableFromMetrics(metrics)
                @updateModelFromParams()

        getInvalidFields: ->
            result = {}
            result['Metrics'] = @selected.length is 0
            return result

        reset: ->
            @selected = []

        select: (item) ->
            return if @isSelected(item)
            @selected.push(item)

        remove: (item) ->
            return if not @isSelected(item)
            @selected = Utils.remove @selected, (x) -> x.id is item.id

        toggle: (item) ->
            return @remove(item) if @isSelected(item)
            return @select(item)

        selectAll: ->
            @available.forEach(@select)

        isSelected: (item) ->
            return !!_.find @selected, (x) -> x.id is item.id

        _fetchMetrics: ->
            QueryMetrics.fetch(@params.currency)

        _getAvailableFromMetrics: (metrics) ->
            seen = {}
            return metrics.reduce ((result, metric) ->
                key = "#{metric.headerGroup} - #{metric.headerName}"
                available = seen[key] or do ->
                    x = {id:key, label:metric.headerGroup, group:metric.headerGroup, metrics:[]}
                    result.push(x)
                    seen[key] = x
                    return x
                available.metrics.push(metric)
                return result
            ), []

        updateParamsFromModel: ->
            @params.metrics = _.flatten @selected.map (x) -> Utils.copy(x.metrics)

        updateModelFromParams: (params) ->
            @params = params if params
            availableByField = _.flatten(@available).reduce ((result, available) ->
                available.metrics.forEach (x) -> result[x.field] = available
                return result
            ), {}
            seen = {}
            @selected = (@params.metrics ? []).reduce ((result, x) ->
                field = if _.isString(x) then x else x.field
                available = availableByField[field]
                return result if not available or seen[available.id]
                result.push(available)
                seen[available.id] = true
                return result
            ), []
