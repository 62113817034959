import _ from 'lodash';
import { getSeriesMaxMinValue } from './chart-echarts.helper';
import { USA } from './maps';
import { ISeriesData } from './chart-echarts-wrapper.directive';
import { ECHARTS_THEME } from './chart-echarts.config';
import { EChartsOption, LegendComponentOption, MapSeriesOption, TooltipComponentOption } from 'echarts';
import type { VisualMapComponentOption } from 'echarts/components';
import type { IMetricDefinition } from '../../lib/types';

export interface IMapSeries extends MapSeriesOption {
    data: ISeriesData[];
}

export type ITooltipParams = {
    metric: string;
    color: string;
    name: string;
    value?: string | number;
};

const ECHARTS_TOOLTIP: TooltipComponentOption = {
    trigger: 'item',
    transitionDuration: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    axisPointer: {
        type: 'shadow',
        shadowStyle: {
            color: 'rgba(0,0,0,0.03)',
        },
    },
    textStyle: {
        fontFamily: ECHARTS_THEME.fontFamily,
        fontSize: 12,
    },
};

const ECHARTS_VISUAL_MAP_OPTIONS: VisualMapComponentOption = {
    orient: 'horizontal',
    inRange: {
        color: ['#aaa', '#40e0d0', '#7b68ee'],
    },
    text: ['High', 'Low'],
    textStyle: {
        color: ECHARTS_THEME.axisLabelColor,
        fontSize: 12,
    },
};

const ECHARTS_LEGEND: LegendComponentOption = {
    show: false,
    height: 0,
    inactiveColor: ECHARTS_THEME.axisLineColor,
    textStyle: {
        color: ECHARTS_THEME.axisLabelColor,
        fontSize: 10,
    },
};

const ECHARTS_DEFAULT_MAP_OPTIONS: echarts.EChartsOption = {
    scaleLimit: {
        min: 1,
        max: 1,
    },
    textStyle: {
        fontFamily: ECHARTS_THEME.fontFamily,
    },
    series: {},
    legend: ECHARTS_LEGEND,
    visualMap: ECHARTS_VISUAL_MAP_OPTIONS,
    tooltip: ECHARTS_TOOLTIP,
    emphasis: {
        label: {
            show: true,
        },
    },
};

const defaultSeriesObj: MapSeriesOption = {
    type: 'map',
    roam: true,
    map: 'USA',
    showLegendSymbol: false,
    emphasis: {
        label: {
            show: false,
        },
    },
    data: [],
};

export const EchartsMapChartModelInstance = () => [
    '$filter',
    function EchartsMapChartModelMethod($filter: angular.IFilterService) {
        class EchartsMapChartModel {
            chartOptions: EChartsOption;

            constructor(metrics: IMetricDefinition[], data: Record<string, string | number>[] = []) {
                const chartOptions = _.cloneDeep(ECHARTS_DEFAULT_MAP_OPTIONS);

                const rows = data.map(row => {
                    const metricValue = row[metrics[0].field];
                    const value = _.isNumber(metricValue) && !_.isNaN(metricValue) ? metricValue : 0;
                    const property = typeof row['property0'] === 'string' ? row['property0'] : null;
                    const state = property ? USA.getStateFullName(property) : null;
                    return {
                        name: state ?? property ?? 'n/a',
                        metric: metrics[0],
                        value,
                    };
                });
                const total = _.sumBy(rows, x => x.value);

                const seriesObj = _.cloneDeep(defaultSeriesObj);
                seriesObj.data = rows;

                if (chartOptions.visualMap && !Array.isArray(chartOptions.visualMap)) {
                    chartOptions.visualMap.formatter = valueParam => {
                        const value = _.isNumber(valueParam) && !_.isNaN(valueParam) ? valueParam : 0;
                        return $filter('metric')(value ? value : 0, metrics[0]);
                    };

                    const { min, max } = getSeriesMaxMinValue(seriesObj);
                    chartOptions.visualMap.min = min;
                    chartOptions.visualMap.max = max;
                }

                if (chartOptions.tooltip && !Array.isArray(chartOptions.tooltip)) {
                    chartOptions.tooltip.formatter = params => {
                        if ('color' in params && 'name' in params) {
                            const value = _.isNumber(params.value) && !_.isNaN(params.value) ? params.value : 0;
                            const percentage = $filter('percent')(value / total);
                            const absolute = $filter('metric')(value, metrics[0]);

                            return `
                                <div class='tooltip'>
                                    <h1 class='tooltip-header' style='color:${params.color}'>${params.name}</h1>
                                    <div class='tooltip-series'>
                                        <span class='tooltip-series-value'>${absolute}</span>
                                        <span class='tooltip-series-value'>(${percentage})</span>
                                    </div>
                                </div>
                            `;
                        }

                        return '';
                    };
                }

                chartOptions.series = seriesObj;
                this.chartOptions = chartOptions;
            }
        }

        return EchartsMapChartModel;
    },
];
