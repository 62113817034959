import { Browser } from './services';
import { ConfigAPI } from './config-api';
import { IConfigExperiments, IConfigObj } from './types';

export type ConfigPageExperiments = ReturnType<typeof normalize>;

export const normalize = (
    orgConfig: { experiments?: Partial<IConfigExperiments> },
    userInternalConfig?: { experiments?: Partial<IConfigExperiments> },
) => {
    const orgExperiments = orgConfig?.experiments ?? {};
    const userExperiments = userInternalConfig?.experiments ?? {};

    const get = (key: keyof IConfigExperiments): boolean | null => {
        return userExperiments[key] ?? orgExperiments[key] ?? null;
    };

    return {
        segmentsInMetricsPage: Browser.isLocalhost() && get('enableSegmentsInMetricsPage'),
        sortToggleInGridPage: get('enableSortToggleInGridPage'),
    };
};

export async function fetch(overrides?: { organization?: IConfigObj; user?: IConfigObj }) {
    return ConfigAPI.get().then(api => {
        return Promise.all([
            overrides?.organization ?? api.organization.get(),
            overrides?.user ?? api.user.getInternal(),
            //
        ]).then(([orgConfig, userConfig]) => {
            return normalize(orgConfig, userConfig);
        });
    });
}
