import _ from 'lodash';
export { omitDeep } from './utils-object-omit-deep';

type PickObject<T> = T extends Record<string, any>
    ? Exclude<T, string | number | boolean | Date | symbol | any[]>
    : Record<string, unknown>;

// FIXME: any
export const isObject = <T>(input: any): input is PickObject<T> => {
    return typeof input === 'object' && input !== null && !Array.isArray(input);
};

const FLATTENED_OBJECT_SEPARATOR = '';
function flatten(object: any, result: Record<string, unknown> = {}, prefix = '') {
    // Unlikely to happen given our separator... skipping for maybe performance...
    // Object.keys(object).forEach (key) ->
    //     invalid = key.includes(separator)
    //     throw new Error("Key `#{key}` contains separator `#{separator}`") if invalid
    for (const key in object) {
        const value = object[key];
        if (_.isPlainObject(value)) {
            flatten(value, result, `${prefix}${key}${FLATTENED_OBJECT_SEPARATOR}`);
        } else {
            result[`${prefix}${key}`] = value;
        }
    }
    return result;
}

export function hash(object: any) {
    if (object === null || object === undefined) return 'null';
    if (['string', 'number', 'boolean'].includes(typeof object)) return JSON.stringify(object);
    if (!_.isObject(object)) throw new Error('Argument must be a string, number, boolean, array or object.');
    object = flatten(object, {});
    return JSON.stringify(
        Object.keys(object)
            .sort()
            .reduce((result: any[], key: string | number) => result.concat([[key, object[key]]]), []),
    );
}
