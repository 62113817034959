import _ from 'lodash';
import { IMetricDefinition } from '../../lib/types';
import { IQuery } from '../../lib/types';
import { MultiCheckDropdownItem, MultiCheckDropdownModel } from '../../components/multi-check-dropdown';
import { IAdsStorage, IAdView } from './ads.controller';
import { ICardHeaderModel } from './ads-card-header.directive';
import { StorageAPI } from '../../lib/storage-user-config';
import { IAdPlatformConfig } from './ads.service';

export interface IAdChartOptions {
    platform: string;
    title: string;
    properties: any;
    property: any;
    config: IAdPlatformConfig;
    metrics: IMetricDefinition[];
    allMetrics: IMetricDefinition[];
}

export class AdChart {
    platform: string;
    title: string;
    properties: any;
    property: any;
    config: IAdPlatformConfig;
    metrics: IMetricDefinition[];
    allMetrics: IMetricDefinition[];

    constructor({ platform, title, properties, metrics, allMetrics, property, config }: IAdChartOptions) {
        this.platform = platform;
        this.title = title;
        this.properties = properties;
        this.metrics = metrics;
        this.allMetrics = allMetrics;
        this.property = property;
        this.config = config;
    }

    updateMetrics(selectedMetrics: IMetricDefinition[]) {
        this.metrics = selectedMetrics;
        this.saveToStorage();
    }

    private saveToStorage() {
        StorageAPI<IAdsStorage>('ads').then(api => {
            return api.get().then(adsStorage => {
                adsStorage[this.platform].chart = this.metrics.map(m => m.field);
                api.put(adsStorage);
            });
        });
    }
}

interface AdsChartDirectiveFactoryScope extends angular.IScope {
    chart: AdChart | null;
    bucket: { id: string; label: string };
    selectedMetrics: IMetricDefinition[];
    model: any;
    dropdown: MultiCheckDropdownModel;

    onMetricsDropdownClick: (item: MultiCheckDropdownItem) => void;
}
export const AdsChartDirectiveFactory = () => [
    '$rootScope',
    'EchartsWithQueryViewModel',
    function AdsChartDirective(
        $rootScope: angular.IRootScopeService & { query: IQuery },
        EchartsWithQueryViewModel: any,
    ): angular.IDirective<AdsChartDirectiveFactoryScope> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                chart: '=',
            },
            template: `
                <div class="ads-chart-container echarts-item-single" ng-class="{loading: model.isLoading, empty:model.isBlank}">
                    <div class="card-title ad-chart-header">
                        <div class="ad-grid-platform-title">{{ model.title }}</div>
                        <div class="ad-chart-selections">
                            <multi-check-dropdown ng-if="dropdown" model="dropdown"></multi-check-dropdown>
                            <bucket-picker selected="bucket"></bucket-picker>
                        </div>
                    </div>
                    <main>
                        <echarts-custom ng-if="!model.isBlank" options="model.options" resizer="setResizer"></echart-custom>
                    </main>
                </div>
            `,
            link: function AdsChartDirectiveLink($scope) {
                let selectedMetrics = _.cloneDeep($scope.chart?.metrics) || [];

                const createModel = () => {
                    const properties = [`calendar.${$scope.bucket.id}`];
                    if ($scope.bucket.id === 'week') properties.push('calendar.year');

                    const baseModel = {
                        title: $scope.chart?.title,
                        type: 'line',
                        properties,
                        metrics: selectedMetrics.map(metrics => metrics.field),
                        overrides: {
                            lineChart: {
                                nameFn: (m: IMetricDefinition) => m.headerGroup,
                                areaStyle: null,
                                lineStyle: { width: 1 },
                                multipleYAxis: true,
                                timeRangeAsHeader: true,
                                displayTitle: true,
                                colorsByMetric: selectedMetrics.reduce((acc: Record<string, string>, metric) => {
                                    acc[metric.field] = metric.color;
                                    return acc;
                                }, {}),
                                bucket: $scope.bucket?.id,
                            },
                        },
                    };

                    return baseModel;
                };

                const createDropdownModel = (metrics: IMetricDefinition[], allMetrics: IMetricDefinition[]) => {
                    return allMetrics.reduce((acc: MultiCheckDropdownItem[], metric) => {
                        acc.push({
                            id: metric.field,
                            title: metric.title,
                            selected: metrics.findIndex(m => m.field === metric.field) > -1,
                        });

                        return acc;
                    }, []);
                };

                const updateMetrics = (item: MultiCheckDropdownItem) => {
                    const { id } = item;
                    const metricsIds = selectedMetrics.map(m => m.field);
                    const index = metricsIds.findIndex(m => m === id);

                    if (index > -1) {
                        metricsIds.splice(index, 1);
                    } else {
                        metricsIds.push(id);
                    }

                    selectedMetrics = $scope.chart?.allMetrics.filter(m => metricsIds.indexOf(m.field) > -1) || [];
                    $scope.chart?.updateMetrics(selectedMetrics);
                    updateModel();
                };

                const updateModel = () => {
                    if (!$scope.chart || !$scope.bucket) return;

                    const query = _.cloneDeep($rootScope.query);
                    query.filters = query.filters ?? {};
                    if ($scope.chart?.property && $scope.chart?.config?.filterValue) {
                        const property = $scope.chart?.property;
                        query.filters[property.table] = {
                            ...(query.filters[property.table] ?? {}),
                            ...{ [property?.column]: $scope.chart.config.filterValue },
                        };
                    }

                    $scope.model = new EchartsWithQueryViewModel(createModel(), query);
                    $scope.dropdown = {
                        list: createDropdownModel(selectedMetrics, $scope.chart.allMetrics),
                        message: 'Choose the Metrics',
                        onItemClick: (item: MultiCheckDropdownItem) => updateMetrics(item),
                    };
                };

                const unWatchQueryRefresh = $rootScope.$on('query.refresh', updateModel);
                $scope.$watch('chart', updateModel);
                $scope.$watch('bucket', updateModel);
                $scope.$on('$destroy', () => {
                    unWatchQueryRefresh();
                });
            },
        };
    },
];

export const AdsSectionChartDirectiveFactory = () => [
    function AdsSectionChartDirective(): angular.IDirective<
        angular.IScope & { views: IAdView[]; model: IAdView[]; header: ICardHeaderModel }
    > {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                views: '=',
                updateMetrics: '&',
            },
            template: `
                <div class="ads-page-chart-container card">
                    <ads-card-header ng-if="header" model="header"></ads-card-header>
                    <div class="ads-page-chart-block" ng-repeat="adView in model">
                        <ads-chart chart="adView.chart" update-metrics="updateMetrics()"></ads-chart>
                        <div class="separator" ng-if="!$last"></div>
                    </div>
                </div>
            `,
            link: function AdsSectionChartDirectiveLink($scope) {
                const init = () => {
                    $scope.model = $scope.views.filter(view => view.chart !== undefined);
                    $scope.header = {
                        title: `Chart ${$scope.model.length > 1 ? ' Comparison' : ''}`,
                    };
                };

                init();

                $scope.$watch('views', views => views && init());
            },
        };
    },
];
