import _ from 'lodash';
import { titleize } from 'inflected';
import { IPromise } from 'angular';
import * as AgGrid from '@ag-grid-community/core';
import { getOrganization } from '../../lib/auth';
import { IQuery, IMetricDefinition } from '../../lib/types';
import FontWidthCalculator from '../../lib/dom/font-width-calculator';
import { IPropertyDefinition } from '../../lib/config-hierarchy';
import { isTotalRow } from './metrics-utils';
import { FilterExpressionParser } from '../../lib/parsers/filter-expression-parser';
import { hash } from '../../lib/utils/utils-object';

interface IMetricsKPIsService {
    fetch: (query: IQuery) => IPromise<(IMetricDefinition & { _cellClass?: string })[]>;
}

type IMetricsFunnelNodeGridViewModelAssociatedColumns = (
    node: any,
    organizationId: string,
) => {
    columns?: AgGrid.ColDef[];
    width?: number | undefined | null;
};

const GRID_HEADER_ROW_HEIGHT = 40;
const GRID_DATA_ROW_HEIGHT = 60;

interface MetricsFunnelNodeViewDirectiveRootScope extends angular.IRootScopeService {
    query: IQuery;
}

export const MetricsFunnelNodeViewDirectiveInstance = () => [
    '$rootScope',
    '$q',
    'Utils',
    'MetricsFunnelNodeGridViewModel',
    'MetricsKPIsService',
    function MetricsFunnelNodeViewDirective(
        $rootScope: MetricsFunnelNodeViewDirectiveRootScope,
        $q: angular.IQService,
        Utils: any,
        MetricsFunnelNodeGridViewModel: any,
        MetricsKPIsService: IMetricsKPIsService,
    ) {
        return {
            restrict: 'E',
            scope: {
                funnel: '=',
                selected: '=',
                exportSetter: '=',
                organization: '=',
            },
            replace: true,
            template: `
                <article class="metrics-funnel-node">
                    <div class="grid-container" ng-if="grid.options">
                        <div class="ag-42 grid grid-new ag-theme-alpine" ag-grid="grid.options"></div>
                    </div>
                </article>
            `,
            link: function MetricsFunnelNodeViewDirectiveLink($scope: angular.IScope & any) {
                $scope.grid = new MetricsFunnelNodeGridViewModel($scope);

                const isProperty = (field: string) => /^property\d+/.test(field);

                const isItemMetric = (field: string) => field.startsWith('item_');

                const getQuerySortFromGrid = () => {
                    const columnsState = $scope.grid.options?.columnApi.getColumnState();

                    const gridSortModel: { colId?: string; sort?: string | null | undefined } = {};

                    if (columnsState) {
                        const sortedColumn: AgGrid.ColumnState | undefined = columnsState.find(
                            (column: AgGrid.ColumnState) => column.sort,
                        );

                        if (sortedColumn) {
                            gridSortModel.colId = sortedColumn.colId;
                            gridSortModel.sort = sortedColumn.sort;
                        }
                    }

                    if (!gridSortModel.colId) {
                        return null;
                    }

                    return {
                        type: $scope.selected.node.property.type,
                        order: gridSortModel.sort === 'desc' ? -1 : 1,
                        limit: null,
                        field: !isProperty(gridSortModel.colId)
                            ? gridSortModel.colId
                            : $scope.selected.node.property.id,
                    };
                };

                const getRefreshId = () => {
                    if (!$scope.selected?.node?.property?.id) {
                        return;
                    }

                    const property = $scope.selected.node.property.id;
                    const queryHash = hash($rootScope.query || {});

                    return `${queryHash}:${property}`;
                };

                const refresh = () => {
                    if (!$scope.selected?.node) {
                        return;
                    }

                    $scope.grid.updateData($scope.selected.node, null, $scope.organization);
                    const query = Utils.copy($rootScope.query || {});
                    const refreshId = getRefreshId();

                    $scope.selected.node.fetch(query).then((data: IMetricsFunnelRowData) => {
                        if (refreshId !== getRefreshId()) {
                            return;
                        }

                        $scope.grid.updateData($scope.selected.node, data, $scope.organization);
                    });
                };

                $scope.select = (value: string) => {
                    $scope.funnel?.select($scope.selected.node, value);
                };

                $scope.export = () => {
                    if (!$scope.selected?.node) {
                        return;
                    }

                    const query = _.cloneDeep($rootScope.query || {});

                    return $q
                        .all([MetricsKPIsService.fetch(query), getOrganization()])
                        .then(([metrics, organizationId]) => {
                            const metricsByField = _.keyBy(metrics, x => x.field);

                            const queryProps = $scope.selected.node._getAssociatedProperties();
                            const metricProps = metrics
                                .filter(x => isItemMetric(x.field))
                                .map(x => x.field.replace(/^item_/, 'items.'));
                            query.options.associatedProperties = queryProps.concat(metricProps);

                            const columnDefs: IColumnDef[] = $scope.grid.getColumnDefs(
                                $scope.selected.node,
                                null,
                                organizationId,
                            );

                            const buildQuery = () => {
                                const sort = getQuerySortFromGrid();

                                if (sort) {
                                    query.options ??= {};
                                    query.options.sort = [sort];
                                    delete query.sort;
                                }

                                const exportedColumnDefs = columnDefs.reduce<IColumnDef[]>((result, x) => {
                                    if (x.field) {
                                        if (isProperty(x.field) || x.field === 'item_image__left') return result;
                                        x.cellClass = metricsByField[x.field]?._cellClass;
                                        delete x._cellClass;
                                        delete x.cellRenderer;
                                        delete x.drilldown;
                                        delete x.filter;
                                        delete x.columnViewName;
                                        delete x.category;
                                        result.push(x);
                                    }
                                    return result;
                                }, []);

                                query.export = {
                                    properties: _.cloneDeep($scope.funnel.properties),
                                    columnStyle: 'tabular',
                                    columnDefs: exportedColumnDefs,
                                };

                                query.options.metrics = _.compact(exportedColumnDefs.map(columnDef => columnDef.field));
                                return query;
                            };

                            return $scope.selected.node.export(buildQuery());
                        });
                };

                $scope.$watch('exportSetter', () => $scope.exportSetter($scope.export));

                $scope.$watch(
                    'funnel.metrics.selected',
                    (selectedMetrics: IColumnDef[]) => {
                        if (!selectedMetrics) {
                            return;
                        }

                        const columnSorting = $scope.grid.columnSortOrder;

                        if (columnSorting && columnSorting.length === selectedMetrics.length) {
                            const hasSameOrder = selectedMetrics.every(
                                (metric, index) => columnSorting[index] === metric.field,
                            );

                            if (hasSameOrder) {
                                return;
                            }

                            const hasMoved = $scope.grid.moveColumns($scope.funnel.metrics?.selected);

                            if (hasMoved) {
                                return;
                            }
                        }

                        $scope.grid.updateColumns(
                            $scope.selected.node,
                            $scope.funnel.metrics?.selected,
                            $scope.organization,
                        );
                    },
                    true,
                );

                $scope.$watch('grid.columnSortOrder', (columnSortOrder: string) => {
                    if ($scope.funnel?.metrics?.selected) {
                        $scope.funnel.metrics.selected = _.sortBy($scope.funnel?.metrics.selected, item =>
                            columnSortOrder.indexOf(item.field),
                        );
                    }
                });

                function initialize() {
                    $scope.$watch(
                        'selected.property',
                        (property: { id: string }) => {
                            if (!property) return;
                            if (!$scope.selected.node?.property) return;
                            if ($scope.selected.node.property.id === property.id) return;

                            $scope.selected.node.property = property;
                            delete $scope.selected.node.value;
                            $scope.funnel.nodes = $scope.funnel.nodes.slice(0, $scope.selected.node.level + 1);
                            refresh();
                        },
                        true,
                    );

                    $scope.$watch('selected.node', (node: IMetricsFunnelNode) => {
                        if (!node) return;
                        $scope.selected.property = _.find(
                            $scope.selected.node.properties,
                            property => property.id === $scope.selected.node.property.id,
                        );
                        refresh();
                    });

                    $scope.$on(
                        '$destroy',
                        $rootScope.$on('query.refresh', () => refresh()),
                    );
                }

                $scope.$on(
                    '$destroy',
                    $rootScope.$watch('initialized', initialized => {
                        if (!initialized) return;
                        initialize();
                    }),
                );
            },
        };
    },
];

export interface IMetricsFunnelNodeGridViewModel {
    options?: AgGrid.GridOptions;
    updateData: (node: IMetricsFunnelNode, data: IMetricsFunnelRowData | null, organizationId: string) => void;
    resetColumnDefs: () => void;
    getColumnDefs: (node: IMetricsFunnelNode, columns: IColumnDef[], organizationId: string) => IColumnDef[];
    updateColumns: (node: IMetricsFunnelNode, columns: IColumnDef[], organizationId: string) => void;
    moveColumns: (data: IColumnDef[]) => boolean;
    columnSortOrder: string[];
}

export interface IMetricsFunnelNodeGridViewModelOptions {
    suppressSorting?: boolean | undefined;
    suppressFiltering?: boolean | undefined;
}

export const MetricsFunnelNodeGridViewModelFactory = () => [
    'MetricsGridCellRenderers',
    'MetricsGridDefaultCellRenderer',
    'MetricsFunnelNodeGridViewModelAssociatedColumns',
    'METRICS_TABLE_ROW_HEIGHT',
    function MetricsFunnelNodeGridViewModelInstance(
        MetricsGridCellRenderers: any,
        MetricsGridDefaultCellRenderer: any,
        MetricsFunnelNodeGridViewModelAssociatedColumns: IMetricsFunnelNodeGridViewModelAssociatedColumns,
        METRICS_TABLE_ROW_HEIGHT: number,
    ) {
        // make sure this matches the CSS
        const metricHeaderGroupFont = new FontWidthCalculator({
            font: '700 10px "Open Sans"',
            uppercase: true,
            letterSpacing: 1,
        });
        const metricHeaderNameFont = new FontWidthCalculator({
            font: '400 10px "Open Sans"',
            uppercase: true,
            letterSpacing: 1,
        });
        return (
            actions: { select: (value: string) => void },
            options: IMetricsFunnelNodeGridViewModelOptions = {},
        ): IMetricsFunnelNodeGridViewModel => {
            let dataColumns: IColumnDef[] = [];

            const gridOptions: AgGrid.GridOptions = {
                applyColumnDefOrder: true,
                angularCompileFilters: true,
                headerHeight: GRID_HEADER_ROW_HEIGHT,
                suppressDragLeaveHidesColumns: true,
                sortingOrder: options.suppressSorting ? [null] : ['desc', 'asc', null],
                getRowHeight: (params: { data: Record<string, unknown> }) => {
                    return isTotalRow(params.data) ? GRID_HEADER_ROW_HEIGHT : GRID_DATA_ROW_HEIGHT;
                },
                onDragStopped: () => updateColumnSortOrder(),
            };

            const updateColumnSortOrder = () => {
                const columns = gridOptions.api?.getColumnDefs() ?? [];
                const children: AgGrid.ColDef[] = columns.flatMap(x => ('children' in x ? x.children : []));
                const currSortOrder = children.flatMap(x => (x.field && !x.pinned ? [x.field] : []));
                const prevSortOrder = modelFields.columnSortOrder;
                const orderHasChanged = !_.isEqual(currSortOrder, prevSortOrder);
                if (orderHasChanged) {
                    modelFields.columnSortOrder = currSortOrder;
                    // Preserve Order if user changes order of columns during rows update
                    if (columns.length > 0) {
                        const fields = columns.reduce((acc: string[], group) => {
                            if ('children' in group) {
                                return acc.concat(group.children.map(c => c.field));
                            }
                            return acc;
                        }, []);

                        dataColumns.sort((a, b) => {
                            return fields.indexOf(a.field) - fields.indexOf(b.field);
                        });
                    }
                }
            };

            const getImageColumnDef = (node: IMetricsFunnelNode): null | AgGrid.ColDef => {
                if (!node.property.id.startsWith('items.') || node.property.id === 'items.season') return null;
                return {
                    headerName: '',
                    field: 'item_image__left', // This is used as a column ID
                    width: METRICS_TABLE_ROW_HEIGHT,
                    cellClass: 'item-image-render',
                    lockPinned: true,
                    lockPosition: true,
                    sortable: false,
                    pinned: true,
                    autoHeight: true,
                    flex: 0,
                    cellRenderer: params => {
                        return isTotalRow(params.data) ? '' : MetricsGridCellRenderers.image(params.data);
                    },
                };
            };

            const getGroupByColumnDef = (node: IMetricsFunnelNode): AgGrid.ColDef => {
                const columnDef: AgGrid.ColDef = {
                    field: 'property0',
                    filter: options.suppressFiltering ? false : 'agTextColumnFilter',
                    sortable: options.suppressSorting ? false : true,
                    headerName: node.property.label,
                    lockPinned: true,
                    lockPosition: true,
                    cellRenderer: params => {
                        return isTotalRow(params.data) ? 'Total' : params.data.property0;
                    },
                    onCellClicked: cell => {
                        if (isTotalRow(cell.data)) return;
                        // it does not drilldown in Ads Page
                        actions?.select(cell.data.property0);
                    },
                };
                return columnDef;
            };

            const getDimensionColumnDefs = (node: IMetricsFunnelNode, organizationId: string): AgGrid.ColDef[] => {
                const groupByColumn = getGroupByColumnDef(node);
                let { columns, width } = MetricsFunnelNodeGridViewModelAssociatedColumns(node, organizationId);
                groupByColumn.width = typeof width === 'number' ? width : 200;
                columns = [groupByColumn, ...(columns ?? [])];
                return columns.map(column => ({
                    flex: 1,
                    wrapText: true,
                    ...column,
                }));
            };

            const mergeColumnDefs = (
                node: IMetricsFunnelNode,
                metrics: IColumnDef[],
                organizationId: string,
            ): IColumnDef[] => {
                const imageColumn = getImageColumnDef(node);
                const pinnedColumns: AgGrid.ColDef[] = [
                    ...(imageColumn ? [imageColumn] : []),
                    ...getDimensionColumnDefs(node, organizationId),
                ].map(column => ({
                    pinned: 'left',
                    suppressMovable: true,
                    lockPinned: true,
                    lockPosition: true,
                    ...column,
                }));
                metrics = _.compact(metrics).map(metric => ({
                    ...metric,
                }));
                return pinnedColumns.concat(metrics);
            };

            const getColumnDefs = (
                node: IMetricsFunnelNode,
                columns: IColumnDef[] | null | undefined,
                organizationId: string,
            ): IColumnDef[] => {
                columns = columns || _.cloneDeep(dataColumns);
                columns = mergeColumnDefs(node, columns, organizationId);

                columns.forEach((columnDef: IColumnDef) => {
                    if (columnDef.field) {
                        columnDef.headerName ??= titleize(columnDef.field);
                    }
                    columnDef.cellRenderer ??= MetricsGridDefaultCellRenderer;
                    columnDef.columnGroupShow = 'open';
                    columnDef.resizable ??= true;
                    columnDef.sortable ??= options.suppressSorting ? false : true;
                    columnDef.filter ??= (() => {
                        if (options.suppressFiltering) return false;
                        if (!columnDef.cellFilter) return false;
                        const cellFilterValue = columnDef.cellFilter.split(':')[0];
                        return cellFilterValue && ['number', 'percent', 'money'].includes(cellFilterValue)
                            ? 'agNumberColumnFilter'
                            : false;
                    })();

                    if (columnDef.filter === 'agNumberColumnFilter') {
                        const filterType = (() => {
                            const cellFilterValue = columnDef?.cellFilter?.split(':')[0];
                            if (cellFilterValue) {
                                return ['number', 'percent', 'money'].find(filterType =>
                                    filterType.includes(cellFilterValue),
                                );
                            }
                        })();

                        columnDef.filterParams = {
                            allowedCharPattern: '\\d\\-\\%',
                            numberParser: (text: string | null | number) => {
                                if (text !== null && filterType) {
                                    switch (filterType) {
                                        case 'number':
                                            text = FilterExpressionParser.number(Number(text))?.value ?? text;
                                            break;
                                        case 'money':
                                            text = FilterExpressionParser.money(text)?.value ?? text;
                                            break;
                                        case 'percent':
                                            text = FilterExpressionParser.percent(Number(text))?.value ?? text;
                                            break;
                                    }
                                }

                                return text;
                            },
                        };
                    }
                });

                return columns;
            };

            // Helper to append one or more classes to an existing cell class, that works iteratively.
            const CellClassExtender = () => {
                const __originalCellClass__ = Symbol();

                const isWrappedCellClassFn = (
                    x: unknown,
                ): x is CallableFunction & { [__originalCellClass__]: AgGrid.ColDef['cellClass'] } => {
                    return _.isFunction(x) && __originalCellClass__ in x;
                };

                const normalizeCellClass = (cellClass: AgGrid.ColDef['cellClass']) => {
                    const getter = _.isFunction(cellClass) ? cellClass : () => cellClass;
                    return (params: AgGrid.CellClassParams) => {
                        const result = getter(params);
                        return Array.isArray(result)
                            ? result
                            : typeof result === 'string' && result.length > 0
                            ? [result]
                            : [];
                    };
                };

                return (cellClass: AgGrid.ColDef['cellClass'], extendedCellClass: AgGrid.ColDef['cellClass']) => {
                    const originalCellClass = isWrappedCellClassFn(cellClass)
                        ? cellClass[__originalCellClass__]
                        : cellClass;
                    // if there's nothing to extend, then we exit early as an optimization...
                    if (!extendedCellClass || (Array.isArray(extendedCellClass) && extendedCellClass.length === 0)) {
                        return originalCellClass;
                    }
                    const originalCellClassFn = normalizeCellClass(originalCellClass);
                    const extendedCellClassFn = normalizeCellClass(extendedCellClass);
                    const wrappedCellClassFn = (params: AgGrid.CellClassParams) => {
                        const original = originalCellClassFn(params);
                        const extended = extendedCellClassFn(params);
                        return [...original, ...extended];
                    };
                    Object.defineProperty(wrappedCellClassFn, __originalCellClass__, { value: originalCellClass });
                    return wrappedCellClassFn;
                };
            };

            const updateCellValueGroupEndCellClass = (() => {
                const extendCellClass = CellClassExtender();
                return (columns: IColumnDef[]): IColumnDef[] => {
                    return columns.map((column, index, array) => {
                        const nextColumn: IColumnDef | undefined = array[index + 1];
                        const prevColumn: IColumnDef | undefined = array[index - 1];
                        const nextHeaderGroup = nextColumn?.headerGroup;
                        const prevHeaderGroup = prevColumn?.headerGroup;
                        const currHeaderGroup = column.headerGroup;
                        const isEnd = nextHeaderGroup !== currHeaderGroup;
                        const isStart = prevHeaderGroup !== currHeaderGroup;
                        const cellClass = [
                            ...(isStart ? ['column-group-start'] : []),
                            ...(isEnd ? ['column-group-end'] : []),
                        ];

                        if (cellClass.length === 0) return column;
                        return { ...column, cellClass: extendCellClass(column.cellClass, cellClass) };
                    });
                };
            })();

            const updatePinnedNameColumn = (name: string) => {
                const gridColumnDefs = gridOptions.api?.getColumnDefs() || [];
                const pinnedColumnGroup =
                    gridColumnDefs.find(columnDef => 'groupId' in columnDef && columnDef.groupId === ' ') || {};

                if ('children' in pinnedColumnGroup && pinnedColumnGroup.children?.length) {
                    const size = pinnedColumnGroup.children.length;
                    pinnedColumnGroup.children[size - 1].headerName = name;
                    gridOptions.api?.setColumnDefs(gridColumnDefs);
                }
            };

            const updateColumns = (node: IMetricsFunnelNode, columns: IColumnDef[], organizationId: string) => {
                dataColumns = _.cloneDeep(columns);

                const columnDefs = updateCellValueGroupEndCellClass(getColumnDefs(node, columns, organizationId));
                const gridColumnDefs = gridOptions.api?.getColumnDefs() ?? [];
                const gridColumnDefsByGroupId = _.cloneDeep(gridColumnDefs).reduce<Record<string, AgGrid.ColGroupDef>>(
                    (acc, column) => {
                        if ('groupId' in column) {
                            acc[column.groupId] = column;
                        }
                        return acc;
                    },
                    {},
                );

                const columnDefGroups = columnDefs.reduce<Record<string, AgGrid.ColGroupDef>>((acc, column) => {
                    column.headerGroup = column.headerGroup ?? ' ';
                    const headerName = column.headerGroup;

                    acc[headerName] =
                        acc[headerName] ??
                        (gridColumnDefsByGroupId[headerName]
                            ? _.omit(gridColumnDefsByGroupId[headerName], 'children')
                            : {
                                  groupId: headerName,
                                  headerName: headerName,
                                  marryChildren: true,
                                  headerClass: ['column-group-start', 'column-group-end'],
                                  children: [],
                              });

                    acc[headerName].children = acc[headerName].children ?? [];
                    const existingGridChild = gridColumnDefsByGroupId[headerName]?.children.find(
                        child => child.field === column.field,
                    );

                    if (existingGridChild) {
                        acc[headerName].children.push(existingGridChild);
                    } else {
                        const child = _.omit(column, 'headerGroup');
                        acc[headerName].children.push({
                            ...child,
                            headerClass: column.cellClass,
                            groupId: headerName,
                        });
                    }

                    return acc;
                }, {});

                const columnDefsResult = Object.values(columnDefGroups);
                columnDefsResult.forEach(c => fixColumnWidths(c, gridColumnDefs));

                gridOptions.api?.setColumnDefs(_.cloneDeep(columnDefsResult));
                updateColumnSortOrder();
            };

            const fixColumnWidths = (columnDef: AgGrid.ColGroupDef, gridColumnDefs: AgGrid.ColGroupDef[]) => {
                if (!columnDef.children || isSameColumnDef(columnDef, gridColumnDefs)) return;

                let childTotalWidth = 0;

                columnDef.children.forEach((child: AgGrid.ColDef) => {
                    const text = (child.headerName || '').trim();
                    child.width ??= metricHeaderNameFont.getPixelWidth(text) + 16 + 2 * 22;
                    child.width = Math.max(120, child.width);
                    childTotalWidth += child.width;
                });

                const headerGroupText = (columnDef.headerName || '').trim();
                const headerGroupTextWidth = metricHeaderGroupFont.getPixelWidth(headerGroupText) + 36;
                if (headerGroupTextWidth > childTotalWidth) {
                    const diff = headerGroupTextWidth - childTotalWidth;
                    const toAdd = diff / columnDef.children.length;
                    columnDef.children.forEach((c: AgGrid.ColDef) => (c.width = (c.width ?? 0) + toAdd));
                }
            };

            const isSameColumnDef = (
                columnGroupDef: AgGrid.ColGroupDef,
                gridColumnDefs: (AgGrid.ColDef | AgGrid.ColGroupDef)[] | undefined,
            ) => {
                const colId = columnGroupDef.groupId;

                gridColumnDefs = gridColumnDefs ?? [];
                const gridColumn = gridColumnDefs.find(col => 'groupId' in col && col.groupId === colId);

                if (!gridColumn) {
                    return false;
                }

                if ('children' in gridColumn) {
                    const columnGroupDefChildren = columnGroupDef.children;
                    const gridColumnChildren = gridColumn.children;

                    if (columnGroupDefChildren.length !== gridColumnChildren.length) {
                        return false;
                    }

                    return columnGroupDefChildren.every(child => {
                        return gridColumnChildren.findIndex(gridChild => gridChild.field === child.field) > -1;
                    });
                }

                return false;
            };

            const updateData = (
                node: IMetricsFunnelNode,
                data: IMetricsFunnelRowData | null,
                organizationId: string,
            ) => {
                const { rows, total } = { ...data };
                if (_.isNil(rows)) {
                    gridOptions.api?.setPinnedTopRowData([]);
                    gridOptions.api?.setRowData([]);
                    gridOptions.api?.showLoadingOverlay();

                    if (dataColumns.length === 0) {
                        updateColumns(node, dataColumns, organizationId);
                    } else {
                        updatePinnedNameColumn(node.property?.label || '');
                    }
                } else {
                    gridOptions.api?.setPinnedTopRowData(total ?? []);
                    gridOptions.api?.setRowData(rows);
                    updateColumns(node, dataColumns, organizationId);
                    gridOptions.api?.hideOverlay();
                }
            };

            const moveColumns = (data: IColumnDef[]): boolean => {
                const metricIndex = data.findIndex(
                    (metric, index) => metric.field !== modelFields.columnSortOrder[index],
                );

                const columns = gridOptions.columnApi?.getAllColumns();

                if (columns) {
                    const columnToSwapIndex = columns.findIndex(
                        column => data[metricIndex].field === column.getColId(),
                    );
                    const columnToSwap = columns[columnToSwapIndex];

                    const columnToSwapParent = columnToSwap?.getParent();
                    const columnDestinationParent = columns[metricIndex + 1].getParent();

                    if (
                        columnToSwapParent &&
                        columnDestinationParent &&
                        columnToSwapParent.getGroupId() === columnDestinationParent.getGroupId()
                    ) {
                        gridOptions.columnApi?.moveColumn(columnToSwap, metricIndex + 1);
                        modelFields.columnSortOrder = data.map(metric => metric.field || '');
                        return true;
                    }
                }

                return false;
            };

            const resetColumnDefs = () => gridOptions.api?.setColumnDefs([]);

            const modelFields: IMetricsFunnelNodeGridViewModel = {
                options: gridOptions,
                updateData,
                resetColumnDefs,
                getColumnDefs,
                updateColumns,
                moveColumns,
                columnSortOrder: [],
            };

            return modelFields;
        };
    },
];

export interface IColumnDef extends AgGrid.ColDef {
    headerGroup?: string;
    cellFilter?: string;
    _cellClass?: string;
    drilldown?: boolean;
    columnViewName?: string;
    category?: string;
}

export interface IMetricsFunnelNode {
    level: number;
    parent?: IMetricsFunnelNode;
    properties: IPropertyDefinition[];
    property: IPropertyDefinition;
    value: string | number | null | undefined;
}

export type IRowDataMetrics = Record<string, any>;
export interface IMetricsFunnelRowData {
    rows: IRowDataMetrics[];
    total: IRowDataMetrics[];
}
