import _ from 'lodash';
import { getCalendarBounds, CalendarDateFactory, ICalendarDate, ICalendarDateStatic } from '@42technologies/calendar';
import { ICalendar } from '../../lib/types';
import { isObject } from '../../lib/utils/utils-object';
import { IModelStateTracker, stateTracker } from '../../lib/model/model-state';
import { CachedService } from '../../lib/model/model-service-cached';
import { ISelectModel, SelectModel } from '../../lib/model/model-select';
import { CalendarConfigService } from '../../lib/config-calendar';
import { DatabaseTimeSpanService } from '../../lib/config-timespan';
import { DateSelectModelFactory, IDateSelectModel, IDateSelectModelTimeRange } from './lib/datepicker-timerange.model';
import { DatepickerActionViewModel, DatepickerActionsFactory } from './lib/datepicker-actions';
import {
    ComparisonModeSelectModel,
    ComparisonModeSelectModelFactory,
    IComparisonModeSelectModel,
} from './lib/datepicker-comparison-mode.model';
import { StorageDatepickerActionId } from './datepicker-storage-selected-action';
import { StorageDatepickerCalendarId } from './datepicker-storage-selected-calendar';
import { getDatepickerCalendar, IDatepickerCalendar } from './view/datepicker-calendar';

// This creates a service which gives us a shared calendar "global" state
export const createCalendarModelService = () =>
    CachedService(async () => {
        const CalendarModel = await CalendarModelFactoryService.fetch();
        return new CalendarModel();
    });

export const CalendarModelFactoryService = CachedService(() => {
    return Promise.all([
        DatabaseTimeSpanService.fetch(),
        CalendarConfigService.fetch(),
        StorageDatepickerActionId.get(),
        StorageDatepickerCalendarId.get().then(val => val ?? undefined),
    ]).then(([bounds, calendars, actionId, calendarId]) => {
        const DatepickerModels = calendars.map(config => {
            return DatepickerModelFactory(_.cloneDeep(config), { ...bounds });
        });
        return CalendarModelFactory(DatepickerModels, {
            calendarId,
            datepicker: { actionId, defaultActionId: actionId },
        });
    });
});

export type CalendarModelInitialState = {
    calendarId?: undefined | ICalendar['id'];
    datepicker: DatepickerModelInitialState;
};

export type CalendarModel = ReturnType<typeof CalendarModelFactory>;
export type CalendarModelState = {
    calendarId: null | string;
    datepicker: IDatepickerModelState;
};

export interface ICalendarDraftModel {
    readonly datepickers: null | ISelectModel<DatepickerModel>;
    readonly datepicker: IDatepickerModel;
    serialize(): CalendarModelState;
    getCalendarId(): ICalendar['id'];
    getDatepicker(): IDatepickerModel;
    setCalendar(calendarId: string): ICalendarDraftModel;
}

export interface ICalendarModel {
    getState(): CalendarModelState;
    setState(draft: { serialize(): CalendarModelState }): void;
    getDatepicker(state?: CalendarModelInitialState): IDatepickerModel;
    getDraft(state?: CalendarModelInitialState): ICalendarDraftModel;
}

export const CalendarModelFactory = (DatepickerModels: DatepickerModel[], initialState: CalendarModelInitialState) => {
    if (DatepickerModels.length === 0) throw new Error('No datepicker models provided.');

    // calendarId:
    // - null means there's no available calendar id
    // - undefined means, use the default selection
    // - string means we want to select a specific calendar id
    const createDatepickerSelectModel = (calendarId: undefined | null | string) => {
        if (calendarId === null) return null;

        // The datepickers have their own ids, which are 1-1 with the calendarId.
        // Here, if we specify a calendarId, we'll find the corresponding datepicker id.
        // The reason there's a difference is because, the calendarId is a backend thing,
        // the datepicker.id is a frontend thing. A datepicker must have an id, but it's
        // calendarId can be NULL (but only when there's 1 datepicker).
        const selected = (() => {
            if (typeof calendarId !== 'string') return;
            const selected = DatepickerModels.find(x => x.calendarId === calendarId)?.id;
            if (typeof selected === 'string') return selected;
            const available = DatepickerModels.map(x => x.calendarId);
            console.error('Calendar was not found:', calendarId, 'Available:', available);
            return;
        })();

        return new SelectModel({ available: DatepickerModels, selected });
    };

    class CalendarDraftModel implements ICalendarDraftModel {
        public datepicker: InstanceType<DatepickerModel>;
        public datepickers: null | SelectModel<DatepickerModel<string>> = null;
        constructor(state: CalendarModelInitialState) {
            const datepickers = createDatepickerSelectModel(state.calendarId);
            const DatepickerModel = datepickers ? datepickers.getSelected() : DatepickerModels[0];
            if (!DatepickerModel) throw new Error('No datepickers available');
            this.datepickers = datepickers;
            this.datepicker = new DatepickerModel(state.datepicker);
        }
        serialize() {
            return {
                calendarId: this.getCalendarId(),
                datepicker: this.datepicker.serialize(),
            };
        }
        getDatepicker() {
            return this.datepicker;
        }
        getCalendarId() {
            return this.datepicker.calendarId;
        }
        setCalendar(calendarId: string): CalendarDraftModel;
        setCalendar(calendarId: unknown) {
            if (typeof calendarId !== 'string') throw new Error('Missing/invalid argument: calendarId');
            if (this.datepicker.calendarId === calendarId) return this;
            const state = this.datepicker.serialize();
            const datepickers = createDatepickerSelectModel(calendarId);
            if (!datepickers) throw new Error('Cannot set calendar; not supported.');
            const DatepickerModel = datepickers.getSelected();
            this.datepicker = new DatepickerModel(state);
            this.datepickers = datepickers;
            return this;
        }
    }

    class CalendarModel implements ICalendarModel {
        // NOTE: this is public, because we will watch it in angular
        readonly state: IModelStateTracker<CalendarModelState>;
        constructor(state?: CalendarModelInitialState) {
            const datepicker = { ...initialState.datepicker, ...state?.datepicker };
            const draft = new CalendarDraftModel({ ...initialState, ...state, datepicker });
            this.state = stateTracker({ ...draft.serialize() });
        }
        getState() {
            return _.cloneDeep(this.state.get());
        }
        setState(draft: CalendarDraftModel) {
            const state = draft.serialize();
            return this.state.set(state);
        }
        getDatepicker(state?: CalendarModelInitialState) {
            return this.getDraft(state).datepicker;
        }
        getDraft(state?: CalendarModelInitialState) {
            const currentState = this.state.get();
            const datepicker = { ...currentState.datepicker, ...state?.datepicker };
            return new CalendarDraftModel({ ...currentState, ...state, datepicker });
        }
    }

    return CalendarModel;
};

interface IDatepickerBounds<CalendarDate extends ICalendarDate = ICalendarDate> {
    start: CalendarDate;
    end: CalendarDate;
}

interface ITimeSpan {
    start: string;
    end: string;
}

// Must specify and action or a timerange selection to initialize a datepicker...
type DatepickerModelInitialState = (
    | { actionId: string }
    | { selection: IDateSelectModelTimeRange<unknown> | IDateSelectModel }
) & {
    defaultActionId?: null | string;
    comparison?: null | IDateSelectModelTimeRange<unknown> | IDateSelectModel;
    comparisonMode?: null | string | IComparisonModeSelectModel;
};

export interface IDatepickerModelState {
    id: string;
    actionId: null | string;
    defaultActionId: null | string;
    comparisonMode: null | string;
    selection: { start: string; end: string };
    comparison?: { start: string; end: string };
}

interface DatepickerModel<CalendarDateType extends string = string> {
    /** Unique identifier for the datepicker class, used by the frontend */
    readonly id: string;
    /** The date class type */
    readonly type: CalendarDateType;
    /** The corresponding backend calendar id for this datepicker, if any */
    readonly calendarId: null | string;
    new (state: DatepickerModelInitialState): IDatepickerModel<CalendarDateType>;
}

interface IDatepickerModel<CalendarDateType extends string = string> {
    /** @see DatepickerModel These variables are copied from the class' static vars... */
    readonly id: string;
    readonly type: CalendarDateType;
    readonly calendarId: null | string;

    /** @deprecated use getBounds() */
    readonly bounds: IDatepickerBounds;
    getBounds(): IDatepickerBounds;
    getAction(): null | string;
    serialize(): IDatepickerModelState;
}

function DatepickerModelFactory<CalendarDateType extends string>(
    config: ICalendar<CalendarDateType>,
    timespan: ITimeSpan,
) {
    // TODO: refactor this...
    const bounds = getCalendarBounds(config.datepicker, timespan);

    const CalendarDate = CalendarDateFactory<CalendarDateType>(config.datepicker);
    const DatepickerActions = DatepickerActionsFactory(config.datepicker.actions);
    const DatepickerSelectModel = DateSelectModelFactory(CalendarDate);
    const ComparisonModeSelectModel = ComparisonModeSelectModelFactory(config.datepicker, bounds);

    // TODO: Ideally, we shouldn't generate the view in this class.
    // It should be done in the directive. However, for performance, we do it here...
    const view = getDatepickerCalendar(bounds);

    return class DatepickerModel implements IDatepickerModel<CalendarDateType> {
        static readonly id = config.datepicker.id;
        static readonly type = config.datepicker.type;
        static readonly label = config.label;
        static readonly calendarId = config.id;
        static readonly bounds = { ...bounds };
        protected static readonly config = _.cloneDeep(config);
        protected static readonly view = view;

        /** The id used by the view / frontend */
        readonly id = DatepickerModel.id;
        readonly view: IDatepickerCalendar = DatepickerModel.view;

        /** The id for the query service / backend */
        readonly calendarId = DatepickerModel.calendarId;

        readonly type = DatepickerModel.type;
        readonly label = DatepickerModel.label;
        readonly bounds: IDatepickerBounds = { ...DatepickerModel.bounds };

        readonly selection: IDateSelectModel;
        readonly comparison: IDateSelectModel;
        readonly comparisonMode: ComparisonModeSelectModel;

        // This is set to null when a selection/comparison is made manually
        public actionId: null | string;
        public actions: DatepickerActionViewModel[];
        readonly defaultActionId: null | string;

        constructor(state: DatepickerModelInitialState) {
            this.bounds = { ...bounds };
            this.comparisonMode = new ComparisonModeSelectModel(
                typeof state.comparisonMode === 'string' ? state.comparisonMode : state.comparisonMode?.mode.id,
            );
            this.selection = new DatepickerSelectModel(this);
            this.comparison = new DatepickerSelectModel(this);
            this.defaultActionId = state.defaultActionId ?? null;
            this.actions = DatepickerActions(this);
            this.actionId = null;

            // helper to pull out the timerange from the selection/comparison args
            const getTimerange = (value: unknown | IDateSelectModelTimeRange<unknown> | IDateSelectModel) => {
                try {
                    if (!isObject(value)) return null;
                    const selected = isObject(value) && 'selected' in value ? value.selected : value;
                    const range = DatepickerSelectModel.IsRange(selected) ? selected : null;
                    return range ? DatepickerSelectModel.ParseRange(range) : null;
                } catch (error) {
                    // This happens when the range is out-of-bounds / the dates can't be built for whatever reason
                    console.debug(error);
                    return null;
                }
            };

            const getAction = (actionId: unknown | string) => {
                if (typeof actionId !== 'string') return null;
                return this.actions.find(x => x.id === actionId) ?? null;
            };

            const selection = 'selection' in state ? getTimerange(state.selection) : null;
            const action = 'actionId' in state ? getAction(state.actionId) : null;
            if (action) {
                this.setAction(action.id);
            } else if (selection) {
                const comparison = state.comparison ? getTimerange(state.comparison) : null;
                this.setRange({ selection, comparison });
                this.actions = DatepickerActions(this);
                this.actionId = null;
            } else {
                let action: DatepickerActionViewModel | null;
                action ??= getAction(this.defaultActionId);
                action ??= getAction('mtd');
                action ??= this.actions[0] ?? null;
                if (!action) throw new Error('Cannot initialize datepicker; no selection or actions');
                this.setAction(action.id);
            }
        }

        serialize(): IDatepickerModelState {
            const selection = this.selection.serialize();
            if (!selection) throw new Error('Missing required selection; cannot serialize');
            const comparison = this.comparison.serialize();
            return {
                id: this.id,
                actionId: this.actionId,
                defaultActionId: this.defaultActionId,
                comparisonMode: this.comparisonMode.mode.id,
                selection,
                ...(comparison ? { comparison } : {}),
            };
        }

        getDate(value: Parameters<ICalendarDateStatic<ICalendarDate<CalendarDateType>>['CreateFromDate']>['0']) {
            return CalendarDate.CreateFromDate(value);
        }

        getBounds() {
            return { ...this.bounds };
        }

        getAction() {
            return this.actionId;
        }

        getSelection() {
            const selection = this.selection.getSelected();
            if (!selection) return null;
            return { ...selection };
        }

        getComparison() {
            const comparison = this.comparison.getSelected();
            if (!comparison) return null;
            return { ...comparison };
        }

        setSelection(selection: IDateSelectModelTimeRange, updateComparison = true) {
            const comparison = !updateComparison ? this.getComparison() : null;
            this.setRange({ selection, comparison });
            this.actions = DatepickerActions(this);
            this.actionId = null;
        }

        setComparison(comparison: null | IDateSelectModelTimeRange) {
            this.comparison.select(comparison);
            this.actionId = null;
        }

        setComparisonMode(modeId: string) {
            (() => {
                const currentModeId = this.comparisonMode.getMode().id;
                if (modeId === currentModeId) return;
                const selection = this.getSelection();
                if (!selection) return;
                this.comparisonMode.setMode(modeId);
                this.setComparisonFromComparisonMode();
            })();
            return this.comparisonMode.getMode();
        }

        protected setComparisonFromComparisonMode() {
            const selection = this.getSelection();
            if (!selection) return;
            const comparison = this.comparisonMode.getComparisonTimerange(selection);
            this.setComparison(comparison);
        }

        setAction(actionId: string) {
            const action = this.actions.find(action => action.id === actionId);
            if (!action) throw new Error(`Datepicker action not found: ${actionId}`);
            const { selection, comparison } = action.getRange();
            this.setRange({ selection, comparison });
            this.actionId = action.id;
            this.actions = DatepickerActions(this);
            // TODO: save the action id?
        }

        protected setRange(range: {
            selection?: null | IDateSelectModelTimeRange;
            comparison?: null | IDateSelectModelTimeRange;
        }) {
            if (range.selection) {
                this.selection.select(range.selection);
            }
            if (range.comparison) {
                this.comparison.select(range.comparison);
            } else {
                this.setComparisonFromComparisonMode();
            }
        }
    };
}
