import _ from 'lodash';
import { ICardHeaderModel } from './ads-card-header.directive';
import { IAdView } from './ads.controller';

export interface IAdStatsItem {
    title: string;
    value: number;
    growth: number;
    growth_value: number;
}
interface AdsStatsDirectiveScope extends angular.IScope {
    model: IAdView;
    stats: IAdStatsItem[];
}

export const AdsStatsDirectiveFactory = () => [
    '$rootScope',
    function AdsStatsDirective($rootScope: angular.IRootScopeService): angular.IDirective<AdsStatsDirectiveScope> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                model: '=',
            },
            template: `
                <div class="stats ads-stats" ng-class="{empty:isEmpty}">
                    <div class="loadable" ng-class="{loading: model.stats === null}"></div>
                    <div class="ad-platform-title">{{ model.title }}</div>
                    <ul class="ads-stats-list">
                        <li ng-repeat="stat in stats">
                            <div class="stats-content">
                                <div class="title"><span>{{ stat.title }}</span></div>
                                <div class="value" ng-class="{blank:stat.value === null}">{{ stat.value === null ? '–' : stat.value }}</div>
                                <div class="growth">
                                    <span ng-hide="{{ stat.growth === null }}" ng-class="{'percent-invert-color': stat.inverted}" percent="{{ stat.growth }}">
                                        {{ stat.growth | percent:true:false }}
                                        (vs. {{ stat.growth_value_prefix }} {{ stat.growth_value === null ? '–' : stat.growth_value }})
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            `,
            link: function AdsStatsDirective(scope, element: angular.IRootElementService) {
                // FIXME: I don't think it's possible that the model is always available
                const count = Math.max(1, scope.model.metrics.length || 12);
                scope.stats = _.range(count).map(() => {
                    return {
                        title: '-',
                        value: 0.0,
                        growth: 0.0,
                        growth_value: 0.0,
                    };
                });

                const refresh = () => {
                    if (!scope.model || !scope.model.stats) return;
                    scope.stats = scope.model.stats;
                };

                const resizeObserver = new ResizeObserver(entries => {
                    for (const el of entries) {
                        const width = el.target.getBoundingClientRect().width;
                        const sizeClass = width >= 920 ? 'large' : width >= 600 ? 'medium' : 'small';
                        const sizeChanged = !el.target.classList.contains(sizeClass);
                        if (!sizeChanged) return;
                        el.target.classList.remove('large', 'medium', 'small');
                        el.target.classList.add(sizeClass);
                    }
                });
                resizeObserver.observe(element[0]);

                scope.$watch('model.stats', refresh);

                // REVIEW:
                // This shouldn't be necessary, since we don't query anything in this directive
                // The model in theory changes on query refresh, in the parent controller.
                const unwatchQueryRefresh = $rootScope.$on('query.refresh', refresh);

                scope.$on('$destroy', () => {
                    unwatchQueryRefresh();
                    resizeObserver.disconnect();
                });
            },
        };
    },
];

export const AdsSectionStatsDirectiveFactory = () => [
    function AdsSectionStatsDirective(): angular.IDirective<
        angular.IScope & { views: IAdView[]; model: IAdView[]; header: ICardHeaderModel }
    > {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                views: '=',
            },
            template: `
                <div class="ads-page-stats-container card">
                    <ads-card-header ng-if="header" model="header"></ads-card-header>
                    <div class="ads-page-stats-block" ng-repeat="adView in model">
                        <ads-stats model="adView"></ads-stats>
                        <div class="separator" ng-if="!$last"></div>
                    </div>
                </div>
            `,
            link: function AdsSectionStatsDirectiveLink($scope) {
                const init = () => {
                    $scope.model = $scope.views.filter(view => view.stats !== undefined);
                    $scope.header = {
                        title: `Stats ${$scope.model.length > 1 ? ' Comparison' : ''}`,
                    };
                };

                init();

                $scope.$watch('views', views => views && init());
            },
        };
    },
];
