import _ from 'lodash'
import moment from 'moment'
import * as echarts from 'echarts'
import { titleize } from 'inflected'
import { ECHARTS_THEME } from './chart-echarts.config'

ECHARTS_DEFAULT_LINE_OPTIONS =
    color: [
        '#00FF00', '#5DA5DA', '#40e0d0', '#ff6666', '#ffa500', '#40e0d0', '#ff6666', '#ffa500', '#40e0d0'
    ]
    blendMode: 'screen'
    grid:
        containLabel: true
        top: 10
        bottom: 10
        left: '1%'
        right: '3%'
    textStyle:
        fontFamily: ECHARTS_THEME.fontFamily
    xAxis:
        type: 'category'
        data: []
        animation: false
        nameLocation: 'center'
        minorSplitLine:
            show: true
            lineStyle:
                opacity: 0.3
                width: 1
                type: 'solid'
                color: ECHARTS_THEME.axisLineColor
        splitLine:
            alignWithLabel: true
            show: true
            lineStyle:
                width: 0.5
                type: 'dashed'
                color: ECHARTS_THEME.axisLabelColor
        axisTick:
            alignWithLabel: true
            show: true
            lineStyle:
                width: 1
                opacity: 1
                color: ECHARTS_THEME.axisLabelColor
        axisLine:
            alignWithLabel: true
            show: true
            lineStyle:
                width: 1
                opacity: 0.2
                color: 'black'
        axisLabel:
            alignWithLabel: true
            hideOverlap: true
            color: ECHARTS_THEME.axisLabelColor
            fontSize: 11
            lineHeight: 15
    yAxis:
        type: 'value'
        nameLocation: 'center'
        animation: false
        axisPointer:
            z: 1000
            lineStyle:
                color: ECHARTS_THEME.axisLineColor
        axisLine:
            lineStyle:
                width: 0.8
                color: 'black'
        splitLine:
            lineStyle:
                opacity: 0.1
                width: 0.5
                type: 'solid'
                color: 'black'
        axisLabel:
            color: ECHARTS_THEME.axisLabelColor
    series: []
    legend:
        show: false
        height: 0
        inactiveColor: ECHARTS_THEME.axisLineColor
        textStyle:
            color: ECHARTS_THEME.axisLabelColor
            fontSize: 10
    tooltip:
        trigger: 'axis'
        snap: true
        transitionDuration: 0
        backgroundColor: "rgba(0, 0, 0, 0.7)"
        axisPointer:
            type: 'shadow'
            shadowStyle:
                color: "rgba(0,0,0,0.03)"
        textStyle:
            fontFamily: ECHARTS_THEME.fontFamily
            fontSize: 12


export EChartsLineChartModelFactory = () -> [
    '$filter',
    #
    ###* @param {import('angular').IFilterService} $filter ###
    ($filter) ->

        class EchartsLineChartModel
            constructor: (metrics, properties, data, overrides) ->
                chartOptions = _.cloneDeep(ECHARTS_DEFAULT_LINE_OPTIONS)
                chartOptions.tooltip ?= {}
                chartOptions.tooltip.formatter = (paramsBySeries) ->
                    formatTimestamp = (x) ->
                        timestamp = moment.utc(x)
                        return null if not timestamp.isValid()
                        return timestamp.format('ddd, MMM DD')
                    formatTimerange = (calendar) ->
                        return "" if _.isEmpty(calendar)
                        year = calendar.year
                        month = $filter('inflector')(calendar.month_label)
                        week = parseInt(calendar.week) + 1
                        dayLabel = $filter('inflector')(calendar.day_of_week_label)[..2]
                        timerange = do ->
                            isSame = calendar.timerange_start is calendar.timerange_end
                            return formatTimestamp(calendar.timerange_start) if isSame
                            return _.compact(['start', 'end'].map((x) ->
                                formatTimestamp(calendar["timerange_#{x}"])
                            )).join(' - ')
                        return "#{year} - #{month} W#{week}" if not timerange
                        return "#{year} - #{month} W#{week} (#{timerange})"
                    renderHeader = (title) ->
                        return "<h1 class='tooltip-header'>#{title}</h1>"
                    renderSeries = (params) ->
                        value = $filter('metric')(params.value, params.metric)
                        label = if params.title then params.title else formatTimerange(params.calendar)
                        # label = "#{formatTimerange(params.calendar)}#{labelTitle}"

                        """
                        <div class='tooltip-series'>
                            <span class='tooltip-series-label' style='color:#{params.color}'>#{label}</span>
                            <span class='tooltip-series-value'>#{value}</span>
                        </div>
                        """

                    buildSeriesPayloadToRender = (series) ->
                        series.map((serie) ->
                            baseObj =
                                value: serie.value
                                metric: serie.data.metric
                                calendar: serie.data.calendar
                                color: serie.color

                            if overrides and overrides.displayTitle
                                baseObj.title = serie.name

                            return baseObj
                        )

                    buildHeaderToRender = (series) ->
                        data = series[0]?.data
                        if overrides and overrides.timeRangeAsHeader and data
                            return formatTimerange(data.calendar)

                        return data.metric.headerGroup

                    rows = _.flatten([renderHeader(buildHeaderToRender(paramsBySeries)), buildSeriesPayloadToRender(paramsBySeries).map(renderSeries)])
                    return "<div class='tooltip'>#{rows.join('')}</div>"

                # Hacky metric specific coloring
                metrics.forEach (metric, idx) ->
                    chartOptions.color[idx] = do ->
                        if overrides and overrides.colors and overrides.colors[idx]
                            return overrides.colors[idx]

                        return '#A5A3FF' if metric.field.indexOf('budget') > -1 or metric.field.indexOf('latest_estimate') > -1
                        return '#F17CB0' if metric.field.indexOf('growth_') is 0
                        return '#5DA5DA'

                data = data.filter((x) -> x["calendar__timestamp"])

                allSeries = Object.values(data.reduce ((series, row, rowIdx) ->
                    # Create a series for each metric
                    metrics.forEach (m, idx) ->
                        name = "#{m.headerName} #{m.headerGroup}"

                        if overrides?.nameFn
                            name = overrides.nameFn(m)

                        series[m.field] ?=
                            metric: m
                            type: 'line'
                            animation: false
                            animationDuration: (-> return 0)
                            showAllSymbol: data.length < 200
                            smooth: true
                            smoothMonotone: 'x'
                            cursor: 'unset'
                            symbol: 'emptyCircle'
                            # symbol: 'path://M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                            # symbol: 'path://M 50 10 A 40 40 0 1 0 50 90 A 40 40 0 1 0 50 10 Z M 50 30 A 20 20 0 1 1 50 70 A 20 20 0 1 1 50 30 Z'
                            symbolSize: 3.5
                            lineStyle:
                                width: overrides?.lineStyle?.width ? 1
                            areaStyle: {opacity: 1.2/(idx+3)}
                            data: []
                            name: name
                            emphasis:
                                scale: false
                                disabled: true
                            select:
                                disabled: true
                            selectedMode: false

                        cell = do ->
                            calendarFieldPrefix = do ->
                                return "comparison__calendar__" if m.headerName is 'LY'
                                return "calendar__"
                            value: row[m.field] or 0
                            metric: m
                            name: series[m.field].name
                            timerange: do ->
                                start: row["#{calendarFieldPrefix}timerange_start"]
                                end:   row["#{calendarFieldPrefix}timerange_end"]
                            calendar: Object.keys(row).reduce ((result, field) ->
                                return result if field.indexOf(calendarFieldPrefix) isnt 0
                                return result if row[field] is null
                                result[field.replace(calendarFieldPrefix, "")] = row[field]
                                return result
                            ), {}

                        if overrides?.colorsByMetric and overrides.colorsByMetric[m.field]
                            # series[m.field].lineStyle.color = overrides.colorsByMetric[m.field]
                            series[m.field].color = overrides.colorsByMetric[m.field]

                        series[m.field].data.push(cell) if cell.timerange.start


                    return series
                ), {})

                if overrides?.multipleYAxis
                    allSeries.forEach((serie, index) ->
                        serie.yAxisIndex = index
                        serie.showSymbol = false

                        if overrides?.colorsByMetric and overrides.colorsByMetric[serie.metric.field]
                            color = overrides.colorsByMetric[serie.metric.field]
                            serie.areaStyle.opacity = 0.2
                            serie.areaStyle.color = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: color
                                },
                                {
                                    offset: 1,
                                    color: 'white'
                                }
                            ])
                    )

                # Edge case:
                # Display bar charts if all series have 1 data point
                shouldDisplayAsBarChart = _.every do ->
                    allSeries.filter((s) -> s.data.length > 0).map((s) -> s.data.length is 1)

                if shouldDisplayAsBarChart
                    allSeries.forEach (s) ->
                        s.type = "bar"
                        s.tooltip ?= {}
                        s.tooltip.axisPointer = false
                        s.itemStyle = {...s.itemStyle, opacity:0.85, borderRadius:[4,4,0,0]}

                chartOptions.series = allSeries
                chartOptions.xAxis ?= {}

                if overrides?.multipleYAxis
                    # chartOptions.xAxis.boundaryGaps = ['10%', '10%']
                    # chartOptions.xAxis.boundaryGaps = false

                    chartOptions.tooltip ?= {}
                    chartOptions.tooltip.axisPointer ?= {}
                    chartOptions.tooltip.axisPointer.type = 'cross'
                    chartOptions.tooltip.trigger = 'axis'


                    numberOfYAxis = allSeries.length
                    remainder = (numberOfYAxis % 2)
                    rightGridSpacing = (numberOfYAxis - remainder) / 2
                    leftGridSpacing = rightGridSpacing + remainder

                    chartOptions.grid.right  = "#{rightGridSpacing * 4}%"
                    chartOptions.grid.left  = "#{leftGridSpacing * 4}%"

                    chartOptions.xAxis.splitLine.show = false
                    chartOptions.xAxis.axisLabel.fontWeight = 'bold'
                    chartOptions.xAxis.axisLabel.color = '#aaa'
                    # chartOptions.xAxis.axisLine.lineStyle.color = '#888'
                    chartOptions.xAxis.axisLine.lineStyle.color = '#e6eaef'

                    yAxisCount = 0
                    multipleYAxisConfig = allSeries.map((serie, index) ->
                        baseYAxis = _.cloneDeep(chartOptions.yAxis)
                        baseYAxis.axisLabel ?= {}
                        baseYAxis.axisLabel.fontSize = '14px'
                        # baseYAxis.axisLabel.fontWeight = 'bold'
                        baseYAxis.axisLabel.formatter = (value) -> $filter('metric')(value, serie.metric)

                        baseYAxis.name = serie.name
                        baseYAxis.nameGap = 50
                        baseYAxis.nameTextStyle ?= {}
                        baseYAxis.nameTextStyle.fontWeight = 'bold'
                        baseYAxis.alignTicks = true
                        baseYAxis.axisLine.show = false
                        baseYAxis.splitLine.show = false

                        if overrides?.colorsByMetric and overrides.colorsByMetric[serie.metric.field]
                            color = overrides.colorsByMetric[serie.metric.field]
                            baseYAxis.axisLine.lineStyle.color = color
                            baseYAxis.axisLabel.color = color

                        # Calculate gap between Yaxis axis
                        if index > 1 and index % 2 == 0
                            yAxisCount++

                        baseYAxis.offset = 80 * yAxisCount
                        baseYAxis.position = 'left' if index % 2 == 0
                        baseYAxis.position = 'right' if index % 2 != 0

                        return baseYAxis
                    )

                    chartOptions.yAxis = multipleYAxisConfig
                else
                    chartOptions.yAxis.axisLabel ?= {}
                    chartOptions.yAxis.axisLabel.formatter = (value) -> $filter('metric')(value, metrics[0])


                parseWeek = _.memoize (row) ->
                    value = parseInt(row?['calendar__week'])
                    return if Number.isNaN(value)
                    return value

                parseMonth = _.memoize (row) ->
                    value = parseInt(row?['calendar__month'])
                    return if Number.isNaN(value)
                    return value

                parseYear = _.memoize (row) ->
                    value = parseInt(row?['calendar__year'])
                    return if Number.isNaN(value)
                    return if 100 < value < 1900
                    value = value * 1000 if value < 1000
                    return value

                parseWeekLabel = _.memoize (row) ->
                    week = parseWeek(row)
                    return "" if typeof week isnt 'number'
                    return "W#{week + 1}"

                parseMonthLabel = _.memoize (row) ->
                    value = row?['calendar__month_label']
                    if typeof value is 'string'
                        value = value.trim()
                        return titleize(value.slice(0,3)) if /^(jan|feb|mar|apr|may|jun|jul|aug|oct|sep|nov|dec)/i.test(value)
                        return value
                    else
                        month = parseMonth(row)
                        return "" if typeof month isnt 'number'
                        return "M#{month+1}"

                parseYearLabel = _.memoize (row) ->
                    value = parseYear(row)
                    return if typeof value isnt 'number'
                    return "'" + value.toString().slice(2,4)

                parseDayLabel = _.memoize (row) ->
                    value = row['calendar__day_of_week_label']
                    return if typeof value isnt 'string'
                    return titleize(value).slice(0,3)

                threshold = 26 * do ->
                    return 7 if ['day','timestamp'].includes(overrides?.bucket)
                    return 1

                if data.length >= threshold and ['day','timestamp'].includes(overrides?.bucket)
                    chartOptions.series.forEach (s) ->
                        s.showSymbol = false
                        s.lineStyle = {...s.lineStyle, borderWidth:(s.lineStyle?.borderWidth ? 1.5)/3}

                chartOptions.xAxis.splitLine.interval = (index) ->
                    return parseYear(data[index-1]) isnt parseYear(data[index]) if overrides?.bucket is 'month'
                    return parseMonth(data[index-1]) isnt parseMonth(data[index]) if overrides?.bucket is 'week'
                    return parseWeek(data[index-1]) isnt parseWeek(data[index]) if ['day','timestamp'].includes(overrides?.bucket)
                    return false

                chartOptions.xAxis.axisLabel.interval = do ->
                    return 1 if shouldDisplayAsBarChart
                    prev = undefined
                    return (index, value) ->
                        curr = value?.split('\n')[0]
                        return false if curr is prev
                        prev = curr
                        return true

                chartOptions.xAxis.axisTick.interval = chartOptions.xAxis.axisLabel.interval

                chartOptions.xAxis.data = data.map (row, index) ->
                    row = data[index]

                    prev =
                        year  : parseYear(data[index-1])
                        month : parseMonth(data[index-1])
                        week  : parseWeek(data[index-1])

                    curr =
                        year  : parseYear(data[index])
                        month : parseMonth(data[index])
                        week  : parseWeek(data[index])

                    formatMonthlyTimerange = (row) ->
                        year  = parseYearLabel(row) # if prev.year isnt curr.year
                        month = parseMonthLabel(row)
                        return _.compact([month, year]).join(' ')

                    formatWeeklyTimerange = (row, threshold = 52 * 2) ->
                        return formatMonthlyTimerange(row) if data.length > threshold
                        month = formatMonthlyTimerange(row) if prev.month isnt curr.month
                        week = parseWeekLabel(row)
                        return _.compact([week, month]).join('\n')

                    formatDailyTimerange = (row) ->
                        return formatWeeklyTimerange(row, 52*2*7) if data.length >= 7 * 13
                        week = formatWeeklyTimerange(row, 52*2*7) if prev.week isnt curr.week or prev.month isnt curr.month or prev.year isnt curr.year
                        week = _.reverse(week.split('\n')).join(' – ') if week
                        day  = parseDayLabel(row)
                        return _.compact([day, week]).join('\n')

                    return formatMonthlyTimerange(row) if overrides?.bucket is 'month'
                    return formatDailyTimerange(row) if ['day','timestamp'].includes(overrides?.bucket)
                    return formatWeeklyTimerange(row)

                chartOptions.xAxis.type = 'category'
                chartOptions.xAxis.boundaryGap = true
                @chartOptions = chartOptions
]
