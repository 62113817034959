import _ from 'lodash';
import $ from 'jquery';
import { ResizeObserver } from '@juggle/resize-observer';
import { IQuery } from '../../lib/types';
import type { IPropertyDefinition } from '../../lib/config-hierarchy';
import type { IHierarchyService } from '../hierarchy';
import { ISmartGroupsViewModel } from '../smart-groups/smart-groups.service';
import {
    INaturalLanguagePropertyFilterModel,
    getPropertyFiltersFromQuery,
    getTimerangeFilterFromQuery,
    RenderedQueryFilterListModel,
} from './natural-language-filter.helper';
import { DashboardRootScope } from '../../controllers/main-controller';

const module = angular.module('42.modules.natural-language', []);

interface INaturalLanguageFilterDisplayModelOptions {
    query: IQuery;
    showTimestampOnly: boolean;
}
type INaturalLanguageFilterDisplayModel = InstanceType<ReturnType<typeof NaturalLanguageFilterDisplayModelFactory>>;
const NaturalLanguageFilterDisplayModelFactory = (Hierarchy: IHierarchyService) => {
    class NaturalLanguageFilterDisplayModel {
        public timestamp: { start: string; end: string } | null = null;
        public view: RenderedQueryFilterListModel;

        constructor(public options: INaturalLanguageFilterDisplayModelOptions) {
            this.view = new RenderedQueryFilterListModel([]);
            this.options = _.cloneDeep(options);
            this.init();
        }

        init() {
            const query = this.options.query;
            this.setTimestampFilter(query);
            if (this.options.showTimestampOnly) return;
            void Hierarchy.fetch().then(hierarchies => this.setPropertyFilters(query, hierarchies.all));
        }

        protected setTimestampFilter(query: IQuery) {
            this.timestamp = getTimerangeFilterFromQuery(query);
        }

        protected setPropertyFilters(query: IQuery, properties: IPropertyDefinition[]) {
            const filters = Array.from(getPropertyFiltersFromQuery(query, properties));
            this.view = new RenderedQueryFilterListModel(filters);
        }
    }

    return NaturalLanguageFilterDisplayModel;
};

interface NaturalLanguageDirectiveScope extends angular.IScope {
    query: undefined | IQuery;
    model: undefined | INaturalLanguageFilterDisplayModel;
    smartGroups: undefined | ISmartGroupsViewModel;
    drawing: boolean;
    openFiltersPopup: ($event: MouseEvent, filter: INaturalLanguagePropertyFilterModel) => void;
}
module.directive('naturalLanguage', [
    '$rootScope',
    '$timeout',
    'Hierarchy',
    'CONFIG',
    function NaturalLanguage(
        $rootScope: DashboardRootScope,
        $timeout: angular.ITimeoutService,
        Hierarchy: IHierarchyService,
    ): angular.IDirective<NaturalLanguageDirectiveScope> {
        const NaturalLanguageFilterDisplayModel = NaturalLanguageFilterDisplayModelFactory(Hierarchy);
        return {
            restrict: 'E',
            scope: {
                query: '=',
                smartGroups: '=',
            },
            replace: true,
            template: `
                <div class="natural-language-filter-display">
                    <div class="display-bar-content" ng-if="model && model.view">
                        <div ng-if="model.options.showTimestampOnly && model.timestamp" class="display-bar-message">
                            Viewing data between
                            <div class="display-bar-single-title">{{model.timestamp.start}}</div>
                            and
                            <div class="display-bar-single-title">{{model.timestamp.end}}</div>
                        </div>
                        <div ng-if="!model.options.showTimestampOnly && model.view.filters.length === 0" class="display-bar-message" ng-class="{ 'drawing': drawing }">
                            Viewing data
                            <div class="display-bar-single-title">without any filters</div>
                        </div>
                        <div ng-if="!model.options.showTimestampOnly && model.view.filters.length !== 0" class="display-bar-message" ng-class="{ 'drawing': drawing }">
                            Viewing data for
                            <div ng-repeat="propertyFilter in model.view.filters" class="display-bar-property-filter">
                                <div
                                    class="display-bar-property-filter-items"
                                    ng-class="{'excluded': !propertyFilter.included}"
                                    ng-click="openFiltersPopup($event, propertyFilter.model)">

                                    <div class="property">{{ propertyFilter.label }}</div>

                                    <div class="value" ng-if="propertyFilter.showLength || propertyFilter.showValues">
                                        <div ng-if="!propertyFilter.showValues && propertyFilter.showLength" class="property-value-item-collapsed">
                                            {{ propertyFilter.values.length }} filters
                                        </div>
                                        <div class="property-value-item"
                                            ng-if="propertyFilter.showValues"
                                            ng-repeat="value in propertyFilter.values track by $index">
                                            {{ value }}
                                            <div ng-if="!$last" class="value-separator">,</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            `,
            link: (scope, $element: angular.IRootElementService) => {
                scope.drawing = false;
                scope.model = undefined;

                scope.openFiltersPopup = ($event: MouseEvent, filter: INaturalLanguagePropertyFilterModel) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                    const table = filter.property.table;
                    const selectedGroupsFilters = scope.smartGroups?.groups.getSelected()?.filters ?? [];
                    const selectedFilter = selectedGroupsFilters.find(model => model.descriptor.tables.includes(table));

                    selectedFilter?.select();
                };

                let timer: ReturnType<typeof $timeout>;
                let prevAdjustment: null | boolean = null;
                const DECREASE_SIZE = true;
                const INCREASE_SIZE = false;
                const adjustWidth = () => {
                    $timeout.cancel(timer);
                    timer = $timeout(() => {
                        if (!scope.model) return;
                        const naturalLanguageElementHeight = $element.width();
                        const renderedQueryElementHeight = $($element).find('.display-bar-message').width();

                        if (_.isNil(naturalLanguageElementHeight) || _.isNil(renderedQueryElementHeight)) {
                            return;
                        }

                        const shouldDecrease = renderedQueryElementHeight > naturalLanguageElementHeight;

                        if (prevAdjustment === null) {
                            prevAdjustment = shouldDecrease;
                        }

                        if (shouldDecrease === DECREASE_SIZE && prevAdjustment === DECREASE_SIZE) {
                            scope.model.view.decreaseSize();
                            return;
                        }

                        if (shouldDecrease === INCREASE_SIZE && prevAdjustment === INCREASE_SIZE) {
                            scope.model.view.increaseSize();
                            return;
                        }

                        if (shouldDecrease === INCREASE_SIZE && prevAdjustment === DECREASE_SIZE) {
                            prevAdjustment = null;
                            scope.drawing = false;
                            return;
                        }

                        if (shouldDecrease === DECREASE_SIZE && prevAdjustment === INCREASE_SIZE) {
                            prevAdjustment = DECREASE_SIZE;
                            scope.model.view.decreaseSize();
                            return;
                        }
                    }, 100);
                };
                scope.$on('$destroy', () => $timeout.cancel(timer));

                const refresh = () => {
                    const isMetricsPage = $rootScope.activeRoute?.id === 'metrics';
                    const hasMetricSegments = Boolean($rootScope.Experiments.segmentsInMetricsPage);
                    scope.model = new NaturalLanguageFilterDisplayModel({
                        query: _.cloneDeep(scope.query ?? {}),
                        showTimestampOnly: isMetricsPage && !hasMetricSegments,
                    });
                };

                scope.$watch('model.view.size', adjustWidth);
                const debouncedRefresh = _.debounce(refresh, 100);
                const debouncedOnResize = _.debounce(adjustWidth, 200);

                scope.$watch('query', debouncedRefresh);

                const resizeObserver = new ResizeObserver(debouncedOnResize);
                if ($element[0]) resizeObserver.observe($element[0]);
                scope.$on('$destroy', () => resizeObserver.disconnect());

                const unsubRoute = $rootScope.$watch('activeRoute.id', (activeRouteId: unknown) => {
                    if (typeof activeRouteId !== 'string') return;
                    debouncedRefresh();
                });

                scope.$on('$destroy', unsubRoute);
            },
        };
    },
]);
