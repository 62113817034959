import buttonExportModule from './export-button';
import multiCheckDropdownModule from './multi-check-dropdown';
import TooltipModule from './tooltip';
import bucketPickerModule from './bucket-picker';
import MetricSelectorTreeModule from './metrics-selector-tree';
import buttonCloseModule from './close-button';

angular.module('42.components', [
    buttonExportModule.name,
    buttonCloseModule.name,
    multiCheckDropdownModule.name,
    TooltipModule.name,
    bucketPickerModule.name,
    MetricSelectorTreeModule.name,
]);
