import _ from 'lodash';
import { IConfigObj } from '../../lib/types';
import { StorageAPI } from '../../lib/storage-user-config';
import { QueryServiceAPI } from '../../lib/api';
import { getCurrencies, ICurrency } from './currencies';
import { CachedService } from '../../lib/model/model-service-cached';

const CURRENCIES = getCurrencies();

const getCurrencyStorage = () => {
    return StorageAPI<string>('currency');
};

export class CurrencyModel {
    public available: ICurrency[] = [];
    public selected: undefined | ICurrency;

    constructor(available: ICurrency[], selected: string) {
        this.available = _.cloneDeep(available);
        this.selected = this.available.find(currency => currency.id === selected);
        this.selected ??= this.available[0];
        this.save();
    }

    save() {
        const selected = this.selected?.id;
        if (!selected) return;
        getCurrencyStorage().then(api => api.put(selected));
    }
}

export const CurrenciesService = CachedService(() =>
    QueryServiceAPI.get()
        .then(api => api.organizations.doQuery({ queryId: 'findCurrencies', query: {} }))
        .then(result => {
            const currencies = result.flatMap(currency => {
                currency = typeof currency === 'string' ? { id: currency } : currency;
                const value = CURRENCIES[currency.id];
                if (!value) console.error(`Missing currency data for currency ${currency.id}`);
                return value || [];
            });
            return _.sortBy(currencies, currency => currency.id);
        }),
);

export type ICurrenciesService = typeof CurrenciesService;

export const UserCurrencyModelService = () => [
    '$q',
    'CONFIG',
    function CurrencyModelService($q: angular.IQService, CONFIG: IConfigObj) {
        return {
            fetch: () => {
                return $q
                    .all([CurrenciesService.fetch(), getUserSelectedCurrency()])
                    .then(([available, userCurrencyId]) => {
                        const selected = resolveSelectedCurrency(available, userCurrencyId, CONFIG.defaults?.currency);
                        return new CurrencyModel(available, selected);
                    });
            },
        };
    },
];

const findCurrency = (available: ICurrency[], currencyId: string) => {
    if (typeof currencyId !== 'string') return;
    const currency = currencyId.toLowerCase();
    const found = available.find(currencyInfo => currencyInfo.id === currency);
    return found ? _.cloneDeep(found) : null;
};

const resolveSelectedCurrency = (available: ICurrency[], userCurrencyId: unknown, defaultCurrencyId: string) => {
    let currency: string | undefined;
    currency = typeof userCurrencyId === 'string' ? findCurrency(available, userCurrencyId)?.id : undefined;
    currency ??= findCurrency(available, defaultCurrencyId)?.id;
    currency ??= (available[0] ?? {}).id;
    currency ??= CURRENCIES['usd'].id;
    return currency;
};

const getUserSelectedCurrency = () => {
    return getCurrencyStorage()
        .then(api => api.get())
        .then(currencyId => (typeof currencyId === 'string' ? currencyId.toLowerCase() : null));
};
