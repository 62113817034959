import * as EVENTS from './events';
export { EVENTS };

export function track(
    event: string,
    properties?: Record<string, unknown> | undefined,
    options?: SegmentAnalytics.SegmentOpts | undefined,
    callback?: () => void | undefined,
) {
    try {
        if (!event) throw new Error("Missing required 'event' argument.");
        window.analytics.track(event, properties, options, callback);
    } catch (error) {
        console.error(error);
    }
}
