import _ from 'lodash';
import { ICellRendererComp, ICellRendererParams } from '@ag-grid-community/core';
import type { IPropertyDefinition } from '../../../lib/config-hierarchy';
import type { IMetricDefinition } from '../../../lib/types';

export interface IItemActionToggleModel {
    active: boolean;
    toggle: () => void;
    serialize: () => { active: boolean };
}

export interface IItemsSortBy {
    group: string;
    id: string;
    label: string;
}

export interface IGridActionsModel {
    dropdown: boolean;
    editMode: boolean;
    fillNameIfNeeded: () => void;
    id: string;
    name: string;
    parentSave: () => void;
    save: () => void;
    selected: {
        groupBy: IPropertyDefinition;
        itemsExtraInfo: any[];
        itemsGroupBy: IPropertyDefinition;
        itemsLimitBy: number;
        itemsSortBy: IItemsSortBy;
        metrics: string[];
    };
    values: {
        groupBy: IPropertyDefinition[];
        hierarchyIndex: {
            groupBy: Record<string, IPropertyDefinition>;
            indexGroupBy: Record<string, IPropertyDefinition>;
        };
        itemsGroupBy: Record<string, IPropertyDefinition>;
        itemsLimitBy: number[];
        itemsSortBy: IItemsSortBy[];
        metrics: IMetricDefinition[];
    };
    views: {
        images: boolean;
        metrics: IItemActionToggleModel;
        panel: IItemActionToggleModel;
    };
}

export interface IGridItemCellRenderImage {
    klass: string;
    value: string;
}

export interface IGridItemCellRenderGroupBy {
    klass: string;
    value: string;
}

const renderItemMetrics = (
    $filter: angular.IFilterService,
    itemMetrics: (IMetricDefinition | undefined)[],
    getter: (data: any) => string | number,
): string => {
    if (!itemMetrics || itemMetrics.length < 1) {
        return '';
    }

    const groupedMetrics = _.groupBy(itemMetrics, x => x?.headerGroup || '');
    const orderedHeaderGroups: string[] = [];

    itemMetrics.reduce((acc, metric) => {
        if (metric && acc.indexOf(metric.headerGroup) === -1) {
            acc.push(metric.headerGroup);
        }

        return acc;
    }, orderedHeaderGroups);

    const result = orderedHeaderGroups.map(headerGroup => {
        const metrics = groupedMetrics[headerGroup];
        const isBare = metrics.length === 1;

        const itemMetricsHTML = `
            <span class='item-metric-group ${isBare ? 'bare' : ''}'>
            <span class='item-metric-header-group'>${headerGroup}</span>
        `;

        const metricsLinesHTML = metrics.map(metric => {
            if (!metric) {
                return '';
            }

            let value = getter(metric);
            value = _.isUndefined(value) || _.isNull(value) ? '' : value;

            if (metric.cellFilter) {
                const [filterName, ...filterArgs] = metric.cellFilter.split(':');

                const rawValue = $filter<any>(filterName)(value, ...filterArgs);
                value = _.isUndefined(value) || _.isNull(value) ? '' : rawValue;
            }

            value = typeof value === 'number' ? value.toString() : value;

            const headerName = isBare && metric.headerName === 'TY' ? '' : metric.headerName;
            const hasPercentageCellClass = value.length > 0 && (metric.cellClass || '').indexOf('percent') !== -1;

            let valueClass = '';

            if (hasPercentageCellClass) {
                let rawValue = getter(metric);

                if (typeof rawValue === 'number') {
                    rawValue = metric.cellClass === 'percent-inverted' ? rawValue * -1 : rawValue;

                    if (rawValue > 0) {
                        valueClass = 'percent-positive';
                    }

                    if (rawValue < 0) {
                        valueClass = 'percent-negative';
                    }
                }
            }

            const valueHTML =
                value.length === 0 ? 'n/a' : `<span class='item-metric-value ${valueClass}'>${value}</span>`;

            const headerNameHTML = headerName ? `<span class="item-metric-header-name">${headerName}</span>` : '';

            return `
                <span class="item-metric">
                    ${headerNameHTML}
                    ${valueHTML}
                </span>
            `;
        });

        const metricsHTML = metricsLinesHTML.join('');
        const headerGroupHTML = itemMetricsHTML.concat(metricsHTML).concat('</span>');

        return headerGroupHTML;
    });

    return result.join('');
};

const getItemExtraPropertiesHTML = (actionsModel: IGridActionsModel, item: any) => {
    const itemExtraProperties: { klass: string[]; value: string }[] = [];

    actionsModel.selected.itemsExtraInfo.reduce((result, descriptor) => {
        const column = descriptor.column || descriptor.id.replace(/^.*?\./, '');
        const columnKey = `item_${column}`;

        if (item[columnKey]) {
            result.push({
                klass: ['item-property', 'item-property-' + column.replace(/_/g, '-')].join(' '),
                value: item[columnKey] || '',
            });
        }

        return result;
    }, itemExtraProperties);

    let rendererHTML = null;

    if (itemExtraProperties.length > 0) {
        const itemExtraPropertiesHTML = _.flatten(
            itemExtraProperties.map(({ klass, value }) => `<span class='${klass}'>${value}</span>`),
        );

        rendererHTML = `
            <div class='item-extra-properties'>
                ${itemExtraPropertiesHTML}
            </div>
        `;
    }

    return rendererHTML;
};

const getMetricsHTML = (
    $filter: angular.IFilterService,
    metrics: (IMetricDefinition | undefined)[],
    item: Record<string, string | number>,
) => {
    if (!metrics) {
        return null;
    }

    return `
        <div class='item-metrics'>
            ${renderItemMetrics($filter, metrics, (metric: IMetricDefinition) => item[metric.field])}
        </div>
    `;
};

type ICustomDataCellRendererParams = Record<string, string | number> & {
    items: Record<string, string | number>[];
};
interface ICustomCellRendererParams extends ICellRendererParams {
    data: ICustomDataCellRendererParams;
}

function GridItemCellRenderFactory($filter: angular.IFilterService, index: number, actionsModel: IGridActionsModel) {
    return class GridItemCellRenderer implements ICellRendererComp {
        params: ICellRendererParams | undefined;
        eGuiElement: HTMLDivElement | undefined;
        eGuiImageElement: HTMLDivElement | undefined;
        eGuiMetricsElement: HTMLDivElement | undefined;
        eGuiPropertiesElement: HTMLDivElement | undefined;

        item: any;
        groupByConfig: IGridItemCellRenderGroupBy = { klass: 'item-group-by-value', value: '' };
        metrics: (IMetricDefinition | undefined)[] = [];
        imageConfig: IGridItemCellRenderImage | null = null;
        itemExtraPropertiesHTML: string | null = null;
        metricsHTML: string | null = null;
        imagesEnabled = true;

        createImageElement(imageConfig: IGridItemCellRenderImage) {
            const eGuiImageElement = document.createElement('div');
            eGuiImageElement.classList.add(imageConfig.klass);
            eGuiImageElement.style.backgroundImage = `url('${imageConfig.value}')`;
            return eGuiImageElement;
        }

        createPropertiesElement(itemExtraPropertiesHTML: string) {
            const eGuiPropertiesElement = document.createElement('div');
            eGuiPropertiesElement.classList.add('item-extra-properties');
            eGuiPropertiesElement.insertAdjacentHTML('beforeend', itemExtraPropertiesHTML);

            return eGuiPropertiesElement;
        }

        createMetricElement(metricsHTML: string) {
            const eGuiMetricsElement = document.createElement('div');
            eGuiMetricsElement.classList.add('item-metrics');
            eGuiMetricsElement.insertAdjacentHTML('beforeend', metricsHTML);

            return eGuiMetricsElement;
        }

        createGroupByDivElement(groupByConfig: IGridItemCellRenderGroupBy) {
            const eGuiGroupByLabelElement = document.createElement('div');
            eGuiGroupByLabelElement.classList.add(groupByConfig.klass);
            eGuiGroupByLabelElement.innerHTML = groupByConfig.value;

            return eGuiGroupByLabelElement;
        }

        createGroupByConfig(property: string | number) {
            let value = '';
            if (!_.isNil(property)) {
                value = typeof property === 'number' ? property.toString() : property;
            }

            return {
                klass: 'group-by-value',
                value,
            };
        }

        createImageConfig(item: any): IGridItemCellRenderImage | null {
            return this.imagesEnabled && item.item_image ? { klass: 'item-image', value: item.item_image } : null;
        }

        init(params: ICustomCellRendererParams) {
            const data = params.data;
            const item = data.items[index];
            this.item = item;

            if (item) {
                this.imagesEnabled = actionsModel.views.images;
                this.imageConfig = this.createImageConfig(item);
                this.groupByConfig = this.createGroupByConfig(item.property1);

                this.metrics = (actionsModel?.selected?.metrics || []).map((metric: any) =>
                    _.find(actionsModel.values.metrics, ['field', metric]),
                );

                this.itemExtraPropertiesHTML = getItemExtraPropertiesHTML(actionsModel, item);
                this.metricsHTML = getMetricsHTML($filter, this.metrics, item);
            }

            // Build HTML
            const eGuiElement = document.createElement('div');
            eGuiElement.classList.add('item');

            // Add IMAGE
            if (this.imageConfig) {
                this.eGuiImageElement = this.createImageElement(this.imageConfig);
                eGuiElement.appendChild(this.eGuiImageElement);
            }

            const eGuiPropertiesAndMetricsListElement = document.createElement('div');
            eGuiPropertiesAndMetricsListElement.classList.add('item-info');

            const eGuiGroupByLabelElement = this.createGroupByDivElement(this.groupByConfig);
            eGuiPropertiesAndMetricsListElement.appendChild(eGuiGroupByLabelElement);

            // Add Item Extra Properties
            if (this.itemExtraPropertiesHTML) {
                this.eGuiPropertiesElement = this.createPropertiesElement(this.itemExtraPropertiesHTML);
                eGuiPropertiesAndMetricsListElement.appendChild(this.eGuiPropertiesElement);
            }

            // Add Metrics
            if (this.metricsHTML) {
                this.eGuiMetricsElement = this.createMetricElement(this.metricsHTML);
                eGuiPropertiesAndMetricsListElement.appendChild(this.eGuiMetricsElement);
            }

            eGuiElement.appendChild(eGuiPropertiesAndMetricsListElement);
            this.eGuiElement = eGuiElement;
        }

        update = (metrics: string[]) => {
            this.metrics = (metrics || []).map((metric: any) => _.find(actionsModel.values.metrics, ['field', metric]));

            if (this.item) {
                this.metricsHTML = getMetricsHTML($filter, this.metrics, this.item);
            }

            if (this.eGuiMetricsElement) {
                this.eGuiMetricsElement.innerHTML = this.metricsHTML || '';
            }
        };

        refresh() {
            return true;
        }

        toggleImages = (imagesEnabled: boolean) => {
            if (!this.imageConfig || !this.eGuiImageElement || this.imagesEnabled === imagesEnabled) {
                return;
            }

            if (imagesEnabled) {
                this.eGuiImageElement.classList.add(this.imageConfig.klass);
                this.eGuiImageElement.style.backgroundImage = imagesEnabled ? `url('${this.imageConfig.value}')` : ``;
            } else {
                this.eGuiImageElement.classList.remove(this.imageConfig.klass);
                this.eGuiImageElement.style.backgroundImage = 'none';
            }

            this.imagesEnabled = imagesEnabled;
        };

        getGui() {
            return this.eGuiElement || document.createElement('div');
        }
    };
}

function GridInfoCellRenderFactory($filter: angular.IFilterService, actionsModel: IGridActionsModel) {
    return class GridItemCellRenderer implements ICellRendererComp {
        eGuiElement: HTMLDivElement | undefined;
        eGuiMetricsElement: HTMLDivElement | undefined;
        eGuiGroupByElement: HTMLDivElement | undefined;

        groupByConfig: IGridItemCellRenderGroupBy | undefined;
        metrics: (IMetricDefinition | undefined)[] = [];
        metricsHTML = '';
        data: ICustomDataCellRendererParams | undefined;

        createMetricElement(metricsHTML: string) {
            const eGuiMetricsElement = document.createElement('div');
            eGuiMetricsElement.classList.add('item-metrics');
            eGuiMetricsElement.insertAdjacentHTML('beforeend', metricsHTML);

            return eGuiMetricsElement;
        }

        createGroupByConfig(property: string | number) {
            let value = '';
            if (!_.isNil(property)) {
                value = typeof property === 'number' ? property.toString() : property;
            }

            return {
                klass: 'group-by-value',
                value,
            };
        }

        init(params: ICustomCellRendererParams) {
            this.data = params.data;

            this.groupByConfig = this.createGroupByConfig(params.data.property0);

            const metrics: string[] = actionsModel?.selected?.metrics || [];

            this.metrics = metrics.map(metric => _.find(actionsModel.values.metrics, ['field', metric]));

            if (this.metrics.length > 0) {
                this.metricsHTML = renderItemMetrics($filter, this.metrics, (metric: IMetricDefinition) =>
                    this.data ? this.data[metric.field] : '',
                );

                this.eGuiMetricsElement = this.createMetricElement(this.metricsHTML);
            }

            const groupByLabelHTML = `<div class='${this.groupByConfig.klass}'><span>${this.groupByConfig.value}</span></div>`;

            const eGuiItemInfoElement = document.createElement('div');
            eGuiItemInfoElement.classList.add('item-info');

            if (this.eGuiMetricsElement) {
                eGuiItemInfoElement.insertAdjacentElement('beforeend', this.eGuiMetricsElement);
            }

            this.eGuiElement = document.createElement('div');
            this.eGuiElement.classList.add('item-total');
            this.eGuiElement.insertAdjacentHTML('beforeend', groupByLabelHTML);
            this.eGuiElement.insertAdjacentElement('beforeend', eGuiItemInfoElement);
        }

        refresh() {
            return true;
        }

        update = (metrics: string[]) => {
            this.metrics = (metrics || []).map((metric: any) => _.find(actionsModel.values.metrics, ['field', metric]));
            this.metricsHTML = renderItemMetrics($filter, this.metrics, (metric: IMetricDefinition) =>
                this.data ? this.data[metric.field] : '',
            );

            if (this.eGuiMetricsElement) {
                this.eGuiMetricsElement.innerHTML = this.metricsHTML || '';
            }
        };

        getGui() {
            return this.eGuiElement || document.createElement('div');
        }
    };
}

export { GridItemCellRenderFactory, GridInfoCellRenderFactory };
