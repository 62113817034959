import { isObject } from '../lib/utils/utils';

export const hasApproxPattern = (word: string, pattern: string) => {
    // cheaper version of indexOf; instead of creating each
    // iteration new str.
    function indexOf(word: string, p: number, c: string) {
        let j = 0;
        while (p + j <= word.length) {
            if (word.charAt(p + j) === c) return j;
            j++;
        }
        return -1;
    }
    let p = 0;
    for (let i = 0; i <= pattern.length; i++) {
        const index = indexOf(word, p, pattern.charAt(i));
        if (index === -1) return false;
        p += index + 1;
    }
    return true;
};

function toArray(object: Record<string, string> | string[]) {
    return Array.isArray(object)
        ? object
        : Object.keys(object).map(function (key) {
              return object[key];
          });
}

/**
 * checks if object has key{string} that match
 * to fuzzy search pattern
 * @param object
 * @param search
 * @returns {boolean}
 * @private
 */
const hasApproximateKey = (object: Record<string, string>, search: string, sensitive: boolean) => {
    const properties = Object.keys(object);
    let prop: string;
    let flag: boolean;

    return (
        0 <
        properties.filter(function (elm) {
            prop = object[elm];

            //avoid iteration if we found some key that equal[performance]
            if (flag) return true;

            if (typeof prop === 'string') {
                prop = sensitive ? prop : prop.toLowerCase();
                return (flag = hasApproxPattern(prop, search) !== false);
            }

            return false;
        }).length
    );
};

export const FuzzyFilterFactory = () => [
    function FuzzyFilterFn() {
        return (collection: string[] | Record<string, string>, search: string, csensitive?: boolean) => {
            const sensitive = csensitive || false;
            collection = isObject(collection) ? toArray(collection) : collection;

            if (!Array.isArray(collection) || search === undefined) {
                return collection;
            }

            search = sensitive ? search : search.toLowerCase();

            return collection.filter(function (elm) {
                if (typeof elm === 'string') {
                    elm = sensitive ? elm : elm.toLowerCase();
                    return hasApproxPattern(elm, search) !== false;
                }
                return isObject(elm) ? hasApproximateKey(elm, search, sensitive) : false;
            });
        };
    },
];

export const FuzzyByFilterFactory = () => [
    '$parse',
    function FuzzyByFilterFn($parse: angular.IParseService) {
        return function (
            collection: Record<string, string> | string[],
            property: string,
            search: string,
            csensitive?: boolean,
        ) {
            const sensitive = csensitive || false;
            let prop: string;

            collection = isObject(collection) ? toArray(collection) : collection;

            if (!Array.isArray(collection) || !property || !search) {
                return collection;
            }

            const getter = $parse(property);

            return collection.filter(elm => {
                prop = getter(elm);
                if (!(typeof prop === 'string')) {
                    return false;
                }

                prop = sensitive ? prop : prop.toLowerCase();
                search = sensitive ? search : search.toLowerCase();

                return hasApproxPattern(prop, search) !== false;
            });
        };
    },
];

export default angular
    .module('42.filters.fuzzy', [])
    .filter('fuzzy', FuzzyFilterFactory())
    .filter('fuzzyBy', FuzzyByFilterFactory());
