import _ from 'lodash';
import * as ConfigFlags from '../lib/config-flags';
import { ConfigAPI } from '../lib/config-api';
import { CachedService } from '../lib/model/model-service-cached';
import { ISelectModelView, SelectModel } from '../lib/model/model-select';

interface IBucket {
    id: string;
    label: string;
}

interface BucketPickerDirectiveScope extends angular.IScope {
    view: ISelectModelView<IBucket>;
    select: (bucket: IBucket) => void;
    selected: undefined | IBucket;
}

const resolveBucketId = (config: unknown): null | string => {
    const value: unknown = _.get(config, 'defaults.overviewBucket');
    return parseBucketId(value);
};

const parseBucketId = (value: unknown): null | 'day' | 'week' | 'month' => {
    value = typeof value === 'string' ? value.toLowerCase() : null;
    if (value === 'day' || value === 'timestamp') return 'day';
    if (value === 'week') return 'week';
    if (value === 'month') return 'month';
    return null;
};

const fetchDefaultBucketId = async () => {
    const config = await ConfigAPI.get();
    return Promise.all([
        config.organization.get(),
        config.user.getInternal(),
        //
    ]).then(([orgConfig, userConfig]) => {
        return resolveBucketId(userConfig) ?? resolveBucketId(orgConfig) ?? 'week';
    });
};

const fetchAvailableBuckets = async () => {
    const flags = await ConfigFlags.fetch();
    const available = [
        { id: 'timestamp', label: 'Day' },
        { id: 'week', label: 'Week' },
    ];
    if (flags.showMonthBucket) {
        available.push({ id: 'month', label: 'Month' });
    }
    return available;
};

const BucketPickerService = CachedService(async function fetch() {
    return Promise.all([
        fetchAvailableBuckets(),
        fetchDefaultBucketId(),
        //
    ]).then(([available, selected]) => {
        return new SelectModel({ available, selected });
    });
});

export const BucketPickerDirectiveFactory = () => [
    '$q',
    function BucketPickerDirective($q: angular.IQService): angular.IDirective<BucketPickerDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                selected: '=',
            },
            replace: true,
            template: `
                <article class="bucket-picker">
                    <ul>
                        <li ng-repeat="bucket in view.available track by bucket.id"
                            ng-click="select(bucket)"
                            ng-class="{active: view.selected.id && view.selected.id === bucket.id}"
                        >{{ bucket.model.label }}</li>
                    </ul>
                </article>
            `,
            link: function BucketPickerDirectiveLink(scope) {
                void $q.when(BucketPickerService.fetch()).then(model => {
                    scope.select = bucket => {
                        model.select(bucket.id);
                        scope.view = _.cloneDeep(model.view);
                        scope.selected = model.getSelected();
                    };
                    scope.select(model.getSelected());
                });
            },
        };
    },
];

const bucketPickerModule = angular
    .module('42.components.bucket-picker', [])
    .directive('bucketPicker', BucketPickerDirectiveFactory());

export default bucketPickerModule;
