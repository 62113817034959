import { titleize } from 'inflected';
import { isObject } from './utils';
import { ConfigAPI } from './config-api';

// This is when a dashboard has multiple hierarchies...

/** Describes a hierarchy, i.e. a group of properties/metrics */
export interface IHierarchyDefinition {
    id: string;
    label: string;
}

export function fetchHierarchyDefinitions() {
    return ConfigAPI.get()
        .then(api => api.organization.get())
        .then(getHierarchyDefinitions);
}

export function getHierarchyDefinitions(config: {
    hierarchies?: (string | Partial<IHierarchyDefinition>)[];
}): null | IHierarchyDefinition[];
export function getHierarchyDefinitions(config: { hierarchies?: unknown }): null | IHierarchyDefinition[];
export function getHierarchyDefinitions(config?: { hierarchies?: unknown }): null | IHierarchyDefinition[] {
    if (!isObject(config)) return null;
    const hierarchies = Array.from(normalizeHierarchyDefinitions(config.hierarchies));
    if (hierarchies.length === 0) return null;
    return hierarchies;
}

function normalizeHierarchyDefinition(entry: unknown): IHierarchyDefinition {
    if (typeof entry === 'string') {
        return { id: entry, label: titleize(entry) };
    }
    if (isObject(entry)) {
        if (typeof entry.id !== 'string') throw new Error('Invalid hierarchy descriptor entry; missing id;');
        const label = typeof entry.label === 'string' ? entry.label : titleize(entry.id);
        return { id: entry.id, label };
    }
    throw new Error('Invalid hierarchy descriptor entry; unhandled type');
}

function* normalizeHierarchyDefinitions(config: unknown): Generator<IHierarchyDefinition> {
    if (!Array.isArray(config)) return;
    for (const entry of config) {
        try {
            yield normalizeHierarchyDefinition(entry);
        } catch (error) {
            console.group('Error parsing hierarchy definition entry:', entry);
            console.error(error);
            console.groupEnd();
        }
    }
}
