import _ from 'lodash'
import { titleize } from 'inflected'

export EchartsWithQueryViewModelFactory = -> [
    '$q',
    'QueryServiceAPI',
    'QueryMetrics',
    'Hierarchy',
    'CalendarProperties',
    'EchartsBarChartModel',
    'EchartsPieChartModel',
    'EchartsLineChartModel',
    'EchartsMapChartModel',
    ($q, QueryServiceAPI, QueryMetrics, Hierarchy, CalendarProperties, EchartsBarChartModel, EchartsPieChartModel, EchartsLineChartModel, EchartsMapChartModel) ->

        class EchartsWithQueryViewModel

            constructor: (model, rootQuery) ->
                {properties, metrics, type, options, overrides} = _.cloneDeep(model or {})
                @displayOptions = (options or {})
                @displayOptions.expandedTheme ?= false
                @overrides = overrides or {}
                @isLoading = true
                @type = type

                return if not _.every([properties, metrics, type])
                @init(model, properties, metrics, type, @displayOptions, rootQuery)
                .then ({@properties, @metrics, @options, @data}) =>
                    @isLoading = false
                    @isBlank = @isBlankModel(@data, @metrics, @options)
                    @options = {} if @isBlank
                .catch (error) =>
                    @isLoading = false
                    throw error

            init: (model, properties, metrics, type, displayOptions, rootQuery) ->
                propertiesPromise = @fetchProperties(properties)
                metricsPromise = @fetchMetrics(metrics)

                titlePromise = do =>
                    return $q.when(model.title) if model.title
                    return $q.all([
                        propertiesPromise
                        metricsPromise
                    ]).then ([properties, metrics]) =>
                        return @getTitle(properties, metrics)
                titlePromise.then (title) =>
                    @title = title

                $q.all([
                    propertiesPromise,
                    metricsPromise,
                    titlePromise,
                    @fetchData(type, properties, metrics, rootQuery)
                ]).then ([properties, metrics, title, data]) =>
                    metrics = metrics.filter (m) ->
                        dataByMetric = data.map((x) -> x[m.field])
                        return not _.every(dataByMetric, _.isUndefined)
                    options = @getChartOptions(metrics, properties, type, displayOptions, data) if not (metrics.length is 0)
                    return {properties, metrics, title, options, data}

            isBlankModel: (data = [], metrics = [], options) ->
                return true if data.length is 0 or metrics.length is 0

                if options and Array.isArray(options.series)
                    series = options.series
                    return series.every (serie) -> !serie.data or serie.data.length == 0

                return false

            getTitle: (properties, metrics) ->
                return {metrics:[], properties:[]} if not properties or not metrics
                metrics = _.uniqBy(metrics, (x) -> x.headerGroup).map((x) -> {id:x.field, label:x.headerGroup})
                properties = _.compact properties.map (x) ->
                    return null if not x
                    return {id:x.id, label:x.label} if typeof x isnt 'string'
                    label = x.trim().split('.').join(' ').replace('calendar', '').trim()
                    label = titleize(label)
                    return {id:x, label}
                properties = _.uniqBy(properties, (x) -> x.label)
                # Hack: for the overview page chart, we don't want to show the "Year" property, but we need to group by it
                properties = do ->
                    return properties if properties.length isnt 2
                    nonYearProperties = properties.filter((x) -> x.label?.toLowerCase() isnt 'year')
                    return nonYearProperties
                properties: properties
                metrics: metrics

            getChartOptions: (metrics, properties, type, displayOptions, data) ->
                switch type
                    when "pie"  then return new EchartsPieChartModel(metrics, data, displayOptions).chartOptions
                    when "line" then return new EchartsLineChartModel(metrics, properties, data, @overrides['lineChart']).chartOptions
                    when "bar"  then return new EchartsBarChartModel(metrics, data, displayOptions).chartOptions
                    when "map" then return new EchartsMapChartModel(metrics, data).chartOptions


            fetchMetrics: (metrics) ->
                QueryMetrics.fetch().then (available) ->
                    available = _.keyBy available, (x) -> x.field
                    return _.compact metrics.map (x) -> available[x]

            fetchProperties: (properties) ->
                $q.all([
                    CalendarProperties.fetch()
                    Hierarchy.fetch()
                ]).then ([calendar, hierarchy]) ->
                    # This is a workaround so that we have a descriptor for queries that are requesting 'calendar.month'
                    # It's used by the getTitle method, and by the overview page.
                    calendar = calendar.concat(calendar.filter((x) -> x.id is 'calendar.month_label').map((x) -> ({...x, id:'calendar.month'})))
                    all = hierarchy.all.concat(calendar or [])
                    available = _.keyBy all, (x) -> x.id
                    result = _.compact properties.map (x) -> available[x]
                    return properties if not result or result.length is 0
                    return result

            getBaseQueryOptions = (rootQuery) ->
                query = _.cloneDeep(rootQuery)
                query.options.includeItemInformation = false
                return query

            getGenericQueryOptions = (properties, metrics, baseQuery) ->
                query = _.cloneDeep(baseQuery)
                query.options.includeTotals = false
                query.options.metrics = metrics
                query.options.properties = properties
                query.options.sort = properties.map((prop) -> {property:prop, field:metrics[0], order:-1, limit:null})
                return query

            getLineQueryOptions = (properties, metrics, baseQuery) ->
                query = _.cloneDeep(baseQuery)
                query.options.includeTotals = false
                query.options.fillCalendarGaps = true
                query.options.metrics = metrics
                query.options.properties = properties
                query.options.sort = properties.map((prop) -> {property:prop, field:prop, order:1, limit:null})
                return query

            fetchData: (type, properties, metrics, rootQuery) ->
                baseQuery = getBaseQueryOptions(rootQuery)
                query = do -> switch type
                    when "pie"  then return getGenericQueryOptions(properties, metrics, baseQuery)
                    when "bar"  then return getGenericQueryOptions(properties, metrics, baseQuery)
                    when "map"  then return getGenericQueryOptions(properties, metrics, baseQuery)
                    when "line" then return getLineQueryOptions(properties, metrics, baseQuery)
                    else throw new Error("Unknown chart type `#{type}`.")
                return QueryServiceAPI().then (api) ->
                    return api.query.metricsFunnel(query)

            switchTheme: (enableExpandedTheme) ->
                @displayOptions.expandedTheme = enableExpandedTheme
                @options = @getChartOptions(@metrics, @properties, @type, @displayOptions, @data)

            setType: (type) ->
                @type = type
                @options = @getChartOptions(@metrics, @properties, type, @displayOptions, @data)
                @isBlank = @isBlankModel(@data, @metrics, @options)

            getType: -> @type
]
