class FontWidthCalculator {
    uppercase: boolean;
    spacing: number;
    element: HTMLCanvasElement;
    context: CanvasRenderingContext2D | null;

    constructor({ letterSpacing, font, uppercase }: { letterSpacing?: number; font?: string; uppercase: boolean }) {
        this.uppercase = !!uppercase;
        this.spacing = Math.max(0, letterSpacing || 0);
        this.element = document.createElement('canvas');
        this.context = this.element.getContext('2d');
        if (this.context) {
            this.context.font = font || this.context.font;
        }
    }

    getPixelWidth(text: string, options: { padding: number } = { padding: 0 }) {
        text = text || '';
        if (this.uppercase) text = text.toUpperCase();
        const size = this.context?.measureText(text).width || 0;
        return Math.ceil(size) + text.length * this.spacing + options.padding;
    }
}

export default FontWidthCalculator;
