import _ from 'lodash'

export default (module) ->

    module.service 'TableProperties', ['Hierarchy', (Hierarchy) ->
        fetch: (table) ->
            Hierarchy.fetch().then (hierarchies) ->
                hierarchy = hierarchies[table]
                hierarchy = if Array.isArray(hierarchy) then hierarchy else []
                hierarchy = hierarchy.filter (x) ->
                    return false if not x.id or not (x.table and x.column)
                    propertyId = x.id ? "#{x.table}.#{x.column}"
                    return false if propertyId.startsWith('transactions.')
                    return false if ["transactions"].includes(x.table)
                    return false if ["stores.company", "stores.aggregate"].includes(x.id)
                    # TODO: check if we can put that in the x.table includes list
                    return false if x.id?.startsWith("calendar_periods.")
                    return false if x.table? is 'calendar_periods'
                    return false if not x.filterable
                    return true
                return hierarchy
    ]


    module.service 'TablePropertyValues', (EmployeePropertyValues, CustomersPropertyValues, ItemPropertyValues, TransactionItemPropertyValues,
        TransactionPropertyValues, StorePropertyValues, AcquisitionPropertyValues, GiftCardsPropertyValues, ItemTimeseriesPropertyValues,
        AccountPropertyValues, WarehousePropertyValues, DemandItemPropertyValues, DemandPropertyValues, OrderItemPropertyValues, CampaignPropertyValues) ->
            queries =
                "employees":         EmployeePropertyValues
                "customers":         CustomersPropertyValues
                "accounts":          AccountPropertyValues
                "warehouses":        WarehousePropertyValues
                "items":             ItemPropertyValues
                "transaction_items": TransactionItemPropertyValues
                "transactions":      TransactionPropertyValues
                "demand_items":      DemandItemPropertyValues
                "demand":            DemandPropertyValues
                "stores":            StorePropertyValues
                "acquisitions":      AcquisitionPropertyValues
                "gift_cards":        GiftCardsPropertyValues
                "item_timeseries":   ItemTimeseriesPropertyValues
                "order_items":       OrderItemPropertyValues,
                "campaigns":         CampaignPropertyValues

            fetch: (table, property, filter) ->
                query = queries[table]
                query.fetch(property, filter)

            availableTables: Object.keys(queries)


    module.service 'ItemPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            sort = do ->
                return value: -1 if property is 'season'
                return count: -1
            query =
                options:{property}
                filters:{items:filter}
                sort: sort
            itemFilters = filter?.$and
            if itemFilters
                query.filters.items.$and = _.filter itemFilters, (filter) ->
                    return Object.keys(filter)[0] isnt property
            QueryServiceAPI().then (api) -> api.query.filterItems(query)


    module.service 'TransactionItemPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{transaction_items:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterTransactionItems(query)


    module.service 'TransactionPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{transactions:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterTransactions(query)


    module.service 'DemandItemPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{demand_items:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterDemandItems(query)


    module.service 'DemandPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{demand:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterDemand(query)


    module.service 'OrderItemPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{orders:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterOrderItems(query)


    module.service 'CampaignPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{campaigns:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterCampaigns(query)


    module.service 'AcquisitionPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{acquisitions:filter}
                sort: count: -1

            return QueryServiceAPI().then (api) -> api.query.filterAcquisitions(query)


    module.service 'AccountPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            query =
                options:{property}
                filters:{accounts:filter}
                sort: value: 1
            accountFilters = filter?.$and
            if accountFilters
                query.filters.accounts.$and = _.filter accountFilters, (filter) ->
                    return Object.keys(filter)[0] isnt property
            QueryServiceAPI().then (api) -> api.query.filterAccounts(query)


    module.service 'WarehousePropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            query =
                options:{property}
                filters:{warehouses:filter}
                sort: value: 1
            warehouseFilters = filter?.$and
            if warehouseFilters
                query.filters.warehouses.$and = _.filter warehouseFilters, (filter) ->
                    return Object.keys(filter)[0] isnt property
            QueryServiceAPI().then (api) -> api.query.filterWarehouses(query)


    module.service 'ItemTimeseriesPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            query =
                options:{property}
                filters:{item_timeseries:filter}
                sort: value: -1
            QueryServiceAPI().then (api) -> api.query.filterItemTimeseries(query)


    module.service 'EmployeePropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{employees:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterEmployees(query)


    module.service 'CustomersPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            query =
                options:{property}
                filters:{customers:filter}
                sort: count: -1
            customerFilters = filter?.$and
            if customerFilters
                query.filters.customers.$and = _.filter customerFilters, (filter) ->
                    return Object.keys(filter)[0] isnt property
            QueryServiceAPI().then (api) -> api.query.filterCustomers(query)


    module.service 'GiftCardsPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{gift_cards:filter}
                sort: value: 1
            QueryServiceAPI().then (api) -> api.query.filterGiftCards(query)


    module.service 'StorePropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            query =
                options:{property}
                filters:{stores:filter}
                sort: {count:-1}

            storeFilters = filter?.$and
            if storeFilters
                query.filters.stores.$and = _.filter storeFilters, (filter) ->
                    return Object.keys(filter)[0] isnt property
            QueryServiceAPI().then (api) -> api.query.filterStores(query)
