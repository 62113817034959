import _ from 'lodash';
import type { AngularInjected } from '../../lib/angular';
import * as ConfigFlags from '../../lib/config-flags';
import * as Auth from '../../lib/auth';
import type { IMetricDefinition } from '../../lib/types';
import type { IQueryMetrics } from '../main-controller';
import type { IOverviewChartMetricsConfigService } from './overview-metrics.service';

export interface ISalesChart {
    /** This is the metricId, incredibly bad name */
    title: string;
    inverted?: boolean;
    type?: string;
    metricId: string;
    metric: IMetricDefinition;
    comparisonMetric?: {
        headerGroup: string;
    };
    tracker?: angular.promisetracker.PromiseTracker;
}

export type ISaleChartsService = AngularInjected<typeof SaleChartsServiceFactory>;

export const SaleChartsServiceFactory = () => [
    '$q',
    'promiseTracker',
    'QueryMetrics',
    'SalesChartMetricsConfig',
    function SaleChartsService(
        $q: angular.IQService,
        promiseTracker: angular.promisetracker.PromiseTrackerService,
        QueryMetrics: IQueryMetrics,
        SalesChartMetricsConfig: IOverviewChartMetricsConfigService,
    ) {
        const fetchAllSaintsCharts = async (): Promise<ISalesChart[]> => {
            const [user, metricDefinitions, flags] = await Promise.all([
                Auth.getUser(),
                QueryMetrics.fetch(),
                ConfigFlags.fetch(),
            ]);
            const metricDefinitionsByField = _.keyBy(metricDefinitions, x => x.field);
            return [
                {
                    title: 'Gross Sales',
                    metricId: 'net_sales',
                    metric: metricDefinitionsByField['net_sales'],
                    type: 'money',
                },
                {
                    title: 'Nett Sales',
                    metricId: 'net_sales_without_taxes',
                    metric: metricDefinitionsByField['net_sales_without_taxes'],
                    type: 'money',
                },
                {
                    title: 'Gross Sales Excl. Returns',
                    metricId: 'gross_sales',
                    metric: metricDefinitionsByField['gross_sales'],
                    type: 'money',
                },
                {
                    title: 'Gross Return',
                    metricId: 'returned_sales',
                    metric: metricDefinitionsByField['returned_sales'],
                    type: 'money',
                    inverted: true,
                },
                ...(!flags.showBudget
                    ? []
                    : [
                          {
                              title: 'Forecast',
                              metricId: 'budget',
                              metric: metricDefinitionsByField['budget'],
                              comparisonMetric: { headerGroup: 'Nett Sales' },
                              type: 'money',
                          },
                      ]),
                {
                    title: 'Footfall',
                    metricId: 'traffic',
                    metric: metricDefinitionsByField['traffic'],
                    type: 'number:0',
                },
                {
                    title: 'Conversion',
                    metricId: 'conversion',
                    metric: metricDefinitionsByField['conversion'],
                    type: 'percent',
                },
                {
                    title: 'Transactions',
                    metricId: 'transaction_count',
                    metric: metricDefinitionsByField['transaction_count'],
                    type: 'number',
                },
                {
                    title: 'Gross Sales Units',
                    metricId: 'net_sales_units',
                    metric: metricDefinitionsByField['net_sales_units'],
                    type: 'number',
                },
                {
                    title: 'Gross UPT',
                    metricId: 'gross_sales_units_per_transaction',
                    metric: metricDefinitionsByField['gross_sales_units_per_transaction'],
                    type: 'number:2',
                },
                {
                    title: 'Gross ATV',
                    metricId: 'gross_dollar_per_transaction',
                    metric: metricDefinitionsByField['gross_dollar_per_transaction'],
                    type: 'money',
                },
                ...(user.name === 'digital dashboard'
                    ? []
                    : [
                          {
                              title: 'Gross Margin %',
                              metricId: 'net_sales_margin',
                              metric: metricDefinitionsByField['net_sales_margin'],
                              type: 'percent',
                          },
                      ]),
            ];
        };

        const fetchCharts = async (): Promise<ISalesChart[]> => {
            const [config, metrics] = await Promise.all([SalesChartMetricsConfig.fetch(), QueryMetrics.fetch()]);

            // FIXME: [DEV-1811] Hardcoded metrics
            const netSalesMetric = _.find(metrics, { field: 'net_sales' });
            const grossSalesMetric = _.find(metrics, { field: 'gross_sales' });
            const profitMetric = _.find(metrics, { field: 'profit' });

            return config.map(metric => {
                const type = typeof metric.cellFilter === 'string' ? metric.cellFilter : null;

                const options: ISalesChart = {
                    title: metric.headerGroup,
                    metricId: metric.field,
                    metric: metric,
                    inverted: metric.inverted,
                    ...(type ? { type } : {}),
                };

                // FIXME: [DEV-1811] Hardcoded metrics
                const METRICS = [
                    'budget',
                    'latest_estimate',
                    'budget_profit',
                    'store_budget',
                    'gross_sales_rof',
                    'gross_sales_plan',
                ];
                if (!METRICS.includes(metric.field)) return options;

                const comparisonMetric = (() => {
                    if (metric.field === 'budget_profit') return profitMetric;
                    if (metric.field.includes('gross_sales')) return grossSalesMetric;
                    return netSalesMetric;
                })();

                return { ...options, ...(comparisonMetric ? { comparisonMetric } : {}) };
            });
        };

        const fetch = async () => {
            const orgId = await Auth.getOrganization();
            const charts = await (Auth.isAllsaintsOrganizationId(orgId) ? fetchAllSaintsCharts() : fetchCharts());
            const resolved = charts.map(chart => ({ ...chart, tracker: promiseTracker() }));
            return _.uniqBy(resolved, x => x.metricId);
        };

        return {
            fetch: (): angular.IPromise<ISalesChart[]> => {
                return $q.when(fetch());
            },
        };
    },
];
