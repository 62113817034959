import _ from 'lodash';
import $ from 'jquery';

type EmailDescriptor = { address: string; label: string };

const DOMAIN = '42technologies.com';

const SUPPORT_EMAIL: EmailDescriptor = {
    address: `support@${DOMAIN}`,
    label: `support@${DOMAIN}`,
} as const;

const REACTIVATION_EMAIL: EmailDescriptor = {
    address: `support+reactivate@${DOMAIN}`,
    label: `support@${DOMAIN}`,
} as const;

const ORGANIZATIONS_SUPPORT_EMAILS: Record<string, EmailDescriptor> = {
    sportsdirect: { address: 'daniel.drew@frasers.group', label: 'daniel.drew@frasers.group' },
};

const getOrganizationSupportEmail = (organization?: null | undefined | string): undefined | EmailDescriptor => {
    if (typeof organization !== 'string') return undefined;
    const orgs = Object.keys(ORGANIZATIONS_SUPPORT_EMAILS);
    const prefix = orgs.find(org => organization.startsWith(org));
    return prefix ? ORGANIZATIONS_SUPPORT_EMAILS[prefix] : undefined;
};

const getErrorMessage = ({ href, label }: { href: string; label: string }) => `
    <h1>Whoops, something went wrong while loading your dashboard :(</h1>
    <h2>Try to <a id="#refresh" href="javascript:location.reload()">refresh the dashboard</a> in a minute or two.</h2>
    <p class="contact-support">If the problem persists, please contact
    <a href="${href}">${label}</a></p>
`;

const getBrowserCompatibilityMessage = ({ href, label }: { href: string; label: string }) => `
    <h1>Sorry, your browser is not supported :(</h1>
    <h2>Please switch to <a href="https://www.google.com/chrome/">Google Chrome</a> or <a href="https://www.mozilla.org/firefox/">Firefox</a></h2>
    <p class="contact-support">If you have any concerns about browser support, please contact
    <a href="${href}">${label}</a></p>
`;

export const LoadingStatusMessages = {
    //

    DashboardSetupMessage: (organization?: null | undefined | string) => {
        const email = getOrganizationSupportEmail(organization) ?? SUPPORT_EMAIL;
        return `
            <h1>Our team is setting up your dashboard!</h1>
            <h2>This shouldn't take more than a day or two.</h2>
            <h2>If you have questions or need help, please contact<br /><br /><a href="mailto:${email.address}">${email.label}</a><br /><br /></h2>
            <a href="mailto:${SUPPORT_EMAIL.address}">${SUPPORT_EMAIL.label}</a></p>
        `;
    },

    SuspendedDashboardMessage: (organization?: string) => {
        const email = REACTIVATION_EMAIL;
        return `
        <h1>Your dashboard has been suspended</h1>
        <h2>To re-activate it, please contact<br /><br />
        <a href="mailto:${email.address}">${email.label}</a><br /><br />
        </h2>
        `;
    },

    SuspendedUserMessage: (organization?: string, email?: EmailDescriptor) => {
        email = email ?? getOrganizationSupportEmail(organization) ?? SUPPORT_EMAIL;
        return `
        <h1>Your account has been suspended.</h1>
        <h2>If you need to access the dashboard, please contact<br /><br />
        <a href="mailto:${email.address}">${email.label}</a><br /><br />
        </h2>
        `;
    },

    ConfigurationError: (organization?: string, type?: string) => {
        organization ??= '42';
        const address = _.compact(['support', organization, 'bootstrap', type]).join('+') + `@${DOMAIN}`;
        const subject = `[${organization}] dashboard configuration error`;
        return getErrorMessage({
            href: `mailto:${address}?subject=${subject}`,
            label: SUPPORT_EMAIL.address,
        });
    },

    InitializationError: (organization?: string) => {
        organization ??= '42';
        const address = `support+${organization}+app+init@${DOMAIN}`;
        const subject = `[${organization}] dashboard initialization error`;
        return getErrorMessage({
            href: `mailto:${address}?subject=${subject}`,
            label: SUPPORT_EMAIL.label,
        });
    },

    BrowserCompatibilityError: (organization?: string) => {
        organization ??= '42';
        const address = `support+${organization}+browser@${DOMAIN}`;
        const subject = `[${organization}] my browser is not supported`;
        return getBrowserCompatibilityMessage({
            href: `mailto:${address}?subject=${subject}`,
            label: SUPPORT_EMAIL.label,
        });
    },

    LoadingDataFromAppInit: () => `
        <h1>The dashboard is currently loading up some new data.</h1>
        <h2>We should be back online soon!</h2>
        <p>The page will be refreshed once the loading is done,<br/>but you can also
        <a id="refresh" href="javascript:location.reload()">click here</a>
        to refresh manually.</p>
    `,

    LoadingDataFromAppStarted: () => `
        <h1>The dashboard has started loading up some new data.</h1>
        <h2>Sorry about the interruption!</h2>
        <p>The page will be refreshed once the loading is done,<br/>but you can also
        <a id="refresh" href="javascript:location.reload()">click here</a>
        to refresh manually.</p>
    `,
};

export class LoadingStatusView {
    $body: JQLite;
    $status: JQLite;
    $message: JQLite;
    $spinner: JQLite;
    oldStatus: string | null = null;

    constructor() {
        this.$body = $('body');
        this.$status = this.$body.find('.loading-status');
        this.$status.children().remove();
        this.$spinner = $('<div class="spinner"></div>');
        this.$message = $('<div class="message message-loading">Initializing Dashboard</div>');
        this.$status.append(this.$spinner);
        this.$status.append(this.$message);
    }

    clear(model: LoadingStatusService) {
        this.$spinner.css({ display: 'none' });
        model.STATUSES.map(status => this.$message.removeClass(`message-${status}`));
        model.STATUSES.map(status => this.$body.removeClass(`status-${status}`));
    }

    private updateModel(model: LoadingStatusService) {
        this.clear(model);
        this.$body.addClass(`status-${model.status}`);
        this.$status.css({ display: 'block' });
        this.$message.addClass(`message-${model.status}`);
        this.$message.html(model.message ?? '');
        this.$spinner.css({
            display: model.isError() ? 'none' : 'block',
        });
    }

    updateFromModel(model: LoadingStatusService) {
        if (model.isDone()) {
            this.$status.css({ opacity: '1' });
            this.$status.animate({ opacity: '0' }, 300, () => {
                this.updateModel(model);
                this.$status.css({ display: 'none' });
            });
        } else if (this.oldStatus && this.oldStatus === 'done' && !model.isDone()) {
            this.$status.css({ opacity: '0' });
            this.updateModel(model);
            this.$status.animate({ opacity: '1' }, 300);
        } else {
            this.updateModel(model);
        }

        this.oldStatus = model.status;
    }
}

export class LoadingStatusService {
    loadingStatusView: LoadingStatusView;

    constructor() {
        this.loadingStatusView = new LoadingStatusView();
    }

    status = 'loading';
    Messages = LoadingStatusMessages;
    STATUSES = ['loading', 'error', 'done'];
    message?: string;

    isLoading = () => this.status === 'loading';
    isError = () => this.status === 'error';
    isDone = () => this.status === 'done';

    private update = (message = '') => {
        this.message = message;
        this.loadingStatusView.updateFromModel(this);
    };

    loading(message: string) {
        this.status = 'loading';
        this.update(message);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return { add(id: string) {} };
    }

    error(message: string) {
        this.status = 'error';
        this.update(message);
    }

    done() {
        this.status = 'done';
        this.update();
    }
}
