import _ from 'lodash';
import { IConfigObj, IQuery } from '../../lib/types';
import { ICustomersReportColumnDefs } from './customer.types';
import { ICustomer, IQueryServiceAPI, IQueryServiceExport } from '../../modules/services/query-service.types';
import { DatabaseDescriptorsService } from '../../lib/config-database-descriptors';
import { ExportedQueryResult } from '../../lib/api/api-query-service';

export const CostumerStatsServiceInstance = () => [
    'QueryServiceAPI',
    function CustomerStatsService(QueryServiceAPI: IQueryServiceAPI) {
        return {
            fetch: (query: IQuery) => {
                query = _.cloneDeep(query);
                const queryServiceAPIInstance = QueryServiceAPI();
                return queryServiceAPIInstance.then(api => {
                    return api.query.customerStats(query).then(data => data[0]);
                });
            },
        };
    },
];

export const CustomerCountServiceInstance = () => [
    'QueryServiceAPI',
    function CustomerCountService(QueryServiceAPI: IQueryServiceAPI) {
        return {
            fetch: (query: IQuery) => {
                query = _.cloneDeep(query);
                return QueryServiceAPI().then(api => {
                    return api.query.customerCount(query).then(data => {
                        return data?.pop()?.count ?? 0;
                    });
                });
            },
        };
    },
];

export const CustomersReportColumnDefsInstance = () => [
    '$q',
    '$filter',
    'CONFIG',
    function CustomersReportColumnDefs($q: angular.IQService, $filter: angular.IFilterService, CONFIG: IConfigObj) {
        return {
            fetch: () => {
                let propertiesConfig: string[] = _.get(CONFIG, 'views.customers.properties');
                if (!Array.isArray(propertiesConfig) || propertiesConfig.length === 0) return $q.when(null);
                propertiesConfig = propertiesConfig.map(c => c.replace('customers.', ''));

                return DatabaseDescriptorsService.fetch()
                    .then(descriptors => {
                        if (!descriptors.customers) return $q.when(null);
                        const descriptorsByName = _.keyBy(descriptors.customers, c => c.name);
                        return propertiesConfig
                            .flatMap(p => descriptorsByName[p] ?? [])
                            .map(descriptor => ({
                                field: descriptor.name,
                                headerName: $filter('inflector')(descriptor.name),
                            }));
                    })
                    .catch(err => {
                        console.error('Error while fetching customer report column defs:', err);
                        return $q.when(null);
                    });
            },
        };
    },
];

export const CustomersReportServiceInstance = () => [
    'QueryServiceAPI',
    'QueryServiceExport',
    'CustomersReportColumnDefs',
    function CustomersReportService(
        QueryServiceAPI: IQueryServiceAPI,
        QueryServiceExport: IQueryServiceExport,
        CustomersReportColumnDefs: ICustomersReportColumnDefs,
    ) {
        function cleanString(value: unknown) {
            if (typeof value !== 'string' && typeof value !== 'number') return null;
            const trimmed = value.toString().trim();
            return trimmed.length === 0 ? null : trimmed;
        }

        function getCustomerLabel(customer: ICustomer) {
            const name = cleanString(customer.name);
            const email = cleanString(customer.email);
            const id = cleanString(customer.customer_id);
            return name ?? email ?? id ?? 'Unknown Customer';
        }

        const fetchCustomersReport = (query: IQuery): Promise<ICustomer[] | ExportedQueryResult> => {
            const queryServiceAPIInstance = QueryServiceAPI();
            return queryServiceAPIInstance.then(api => {
                return api.query.customersReport(query);
            });
        };

        return {
            fetch: (query: IQuery): Promise<(ICustomer & { label: string })[]> => {
                return fetchCustomersReport(query).then(customers => {
                    const isCustomersList = (list: ICustomer[] | ExportedQueryResult): list is ICustomer[] => {
                        return Array.isArray(list);
                    };

                    if (!isCustomersList(customers)) {
                        return [];
                    }

                    // We add a label to the customer record because the backend sometimes gives us
                    // empty strings, which is problematic we can't click these rows.
                    return customers.map(customer => ({ ...customer, label: getCustomerLabel(customer) }));
                });
            },
            export: (query: IQuery) => {
                query = query ? _.cloneDeep(query) : {};
                return CustomersReportColumnDefs.fetch()
                    .then(columnDefs => {
                        delete query.limit;
                        delete query.offset;
                        query.sort = { total_spent: -1 };
                        query.type = 'xlsx';
                        query.export = columnDefs ? { columnDefs } : {};
                        return fetchCustomersReport(query);
                    })
                    .then(result => {
                        const isCustomersExportingQueryResult = (
                            payload: ICustomer[] | ExportedQueryResult,
                        ): payload is ExportedQueryResult => {
                            return _.isPlainObject(payload);
                        };

                        return isCustomersExportingQueryResult(result)
                            ? QueryServiceExport.downloadAs('42-export-customers.xlsx')(result)
                            : null;
                    });
            },
        };
    },
];

export const CustomerAPIFactory = () => [
    '$q',
    'QueryServiceAPI',
    function CustomerAPI($q: angular.IQService, QueryServiceAPI: IQueryServiceAPI) {
        const queryServiceAPIInstance = QueryServiceAPI();

        return () =>
            queryServiceAPIInstance.then(api => {
                const buildTransactions = (customer: ICustomer) => {
                    const result = _.sortBy(customer.transactions, x => x.timestamp).reverse();

                    return result
                        .map(transaction => {
                            if (!transaction.items[0] || !transaction.items[0].item) {
                                return transaction;
                            }

                            // normalizing the new hana result to work with old postgres result
                            transaction.items = transaction.items.map(transactionItem => {
                                transactionItem = _.extend(transactionItem, transactionItem.item);
                                delete transactionItem.item;
                                return transactionItem;
                            });
                            delete transaction.transactionItems;
                            return transaction;
                        })
                        .map((transaction, index) => {
                            const items = transaction.items.map(item => {
                                const showQuantity = Math.abs(item.quantity ?? 0) > 1;
                                return { ...item, showQuantity };
                            });
                            const itemsPurchased = transaction.items.reduce<number>(
                                (sum: number, x) => sum + (x.quantity ?? 0),
                                0,
                            );
                            return { ...transaction, _index: index, itemsPurchased, items };
                        });
                };

                const buildQuery = (customerId: string, modifiers: any): IQuery => {
                    return {
                        filters: {
                            customers: {
                                id: customerId,
                            },
                        },
                        modifiers,
                    };
                };

                return {
                    getDetails: (customerId: string, modifiers: any) => {
                        if (!customerId) return $q.reject(new Error('Missing required customerId argument.'));
                        const query = buildQuery(customerId, modifiers);
                        return api.query.getCustomerDetails(query).then(result => {
                            const customer = result[0] || null;
                            if (!customer) return customer;
                            customer.transactions = buildTransactions(customer);
                            return customer;
                        });
                    },
                };
            });
    },
];
