import _ from 'lodash'
import { ResizeObserver } from '@juggle/resize-observer'
import Highcharts from './highcharts/index.coffee'
import {
    ChartPageRouteConfigFactory,
    StoreControllerFactory,
    ChartPageTabsFactory,
    ChartViewTabServiceFactory,
    StoreActionsPanelDirective
} from './index'
import { TimeGroupings } from './store-chart-time-groupings'

module = angular.module '42.controllers.store', []

module.config(ChartPageRouteConfigFactory())
module.controller('StoreController', StoreControllerFactory())
module.factory('ChartPageTabs', ChartPageTabsFactory())
module.factory('ChartViewTabService', ChartViewTabServiceFactory())
module.directive('storeActionsPanel', StoreActionsPanelDirective())


module.directive 'storeActionsPanelOrder', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: """
        <article class="model-select model-select-small">
            <span class="label">Showing</span>
            <span class="selected">
                <span class="property">
                    {{ model.selected.sortOrder.label }}
                    <i class="icon-down-open-mini"></i>
                </span>
            </span>
            <select ng-options="order.label for order in model.available.sortOrder"
                    ng-model="model.selected.sortOrder"> </select>
        </article>
    """

module.filter 'maximum', -> (arr, max, enable) ->
    arr?.filter (x) -> enable or x <= max


module.directive 'storeActionsPanelLimit', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="model-select model-select-small">
        <span class="label">Limit</span>
        <span class="selected">
            <span class="property">
                {{ model.selected.limitBy }}
                <i class="icon-down-open-mini"></i>
            </span>
        </span>
        <select ng-options="limit for limit in limits" ng-model="limitBy"></select>
    </article>
    """
    link: ($scope) ->
        $scope.limitBy = 20
        $scope.limits = []

        unWatchLimitBy = null

        fillLimits = (arr, max, enable) ->
            arr?.filter (x) -> enable or x <= max

        isBarChart = ->
            return $scope.model.selected.timeGrouping.chartType is 'barchart'

        $scope.$watch 'model.selected', () ->
            unWatchLimitBy?()
            return if not $scope.model or not $scope.model?.selected

            $scope.limits = fillLimits($scope.model.available.limitBy, 50, isBarChart())

            if $scope.model.selected?.limitBy
                $scope.limitBy = $scope.model.selected.limitBy

            unWatchLimitBy = $scope.$watch 'limitBy', (limitBy) ->
                $scope.model.selected.limitBy = Number(limitBy)


module.directive 'storeActionsPanelMetric', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: """
        <article class="model-select model-select-large">
            <span class="label" ng-if="model.selected.timeGrouping.chartType == 'barchart'">Sort By</span>
            <span class="label" ng-if="model.selected.timeGrouping.chartType == 'timeseries'">Metric</span>
            <span class="selected">
                <span class="property">
                    {{ model.selected.metric.label }}
                    <i class="icon-down-open-mini"></i>
                </span>
            </span>
            <select ng-options="property as (property.label) group by (property.group) for property in model.available.metric"
                    ng-model="model.selected.metric"></select>
        </article>
        """

module.directive 'storeTimeGrouping', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class='model-select model-select-small'>
        <span class="label">Time Grouping</span>
        <span class="selected">
            <span class="property">
                {{ model.selected.timeGrouping.label }}
                <i class='icon-down-open-mini'></i>
            </span>
        </span>
        <select ng-options="timegroup.label for timegroup in model.available.timeGrouping"
            ng-model="model.selected.timeGrouping">
        </select>
    </article>
    """

module.directive 'storeFunnelState', ->
    restrict: 'E'
    scope:
        model: '='
        label: '='
    replace: true
    template: \
    """
    <article class="metrics-funnel-breadcrumb">
        <section class="funnel-state">
            <header>
                <h1>Selected Filters</h1>
                <button class="reset"
                    ng-if="model.funnel.nodes.length > 0"
                    ng-click="model.resetFilters()">reset
                </button>
            </header>
            <ul class="ui-pellets">
                <li class="funnel-node">
                    <span class="ui-pellet active disabled">
                        <span class="ui-pellet-property">Selected Group</span>
                        <span class="ui-pellet-value">{{ label }}</span>
                    </span>
                </li>
                <li class="funnel-node" ng-repeat="node in model.funnel.nodes"
                    ng-click="model.applyFilters(node)"
                    ng-class="{'funnel-node-selected': model.funnel.selected == node}">
                    <div class="ui-pellet" ng-class="{active: model.funnel.selected == node}">
                        <span class="ui-pellet-property">{{ node.label }}</span>
                        <span class="ui-pellet-value">{{ node.choice }}</span>
                    </div>
                </li>
            </ul>
        </section>
    </article>
    """


module.directive 'storeFunnelProperties', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="metrics-funnel-breadcrumb">
        <section class="available-properties">
            <header>
                <h1>Group By</h1>
                <span class="hint">(click a pellet to view the values, and click on a chart column to drill down)</span>
            </header>
            <ul class="ui-pellets">
                <li class="ui-pellet available-property"
                    ng-repeat="property in model.available.grouping"
                    ng-click="model.selected.grouping = property"
                    ng-if="!model.nodeInFunnel(property)"
                    ng-class="{selected:model.selected.grouping.id == property.id}">
                    <span class="property-label">{{ property.label }}</span>
                </li>
            </ul>
        </section>
    </article>
    """



module.directive 'storeTextRow', ->
    restrict: 'E'
    scope:
        model: '='
        onTogglePanelClick: '='
        togglePanelActionsEnabled: '='
    replace: true
    template: """
        <article class="store-text-row">
            <p class="natural-language">
                Showing the
                <span class="limit"> {{ model.selected.limitBy }}</span>
                <span class="grouping"> {{ model.selected.grouping.plural }}</span>
                with the
                <span class="order">{{ model.selected.sortOrder.label }}</span>
                <span class="metric"> {{ model.selected.metric.label }}</span>
            </p>

            <section class="actions" ng-class="{'full': togglePanelActionsEnabled}">
                <div class="default-actions">
                    <button class="chart-export">
                        <i class="icon-export">export chart</i>
                    </button>

                    <button class="stacked-area" ng-class="{selected:model.selected.stacking}"
                        ng-click="model.selected.stacking = true"
                        ng-if="model.selected.timeGrouping.chartType == 'timeseries'">
                        <i class="icon-chart-area">Stacked Area</i>
                    </button>

                    <button class="line" ng-class="{selected:!model.selected.stacking}"
                        ng-click="model.selected.stacking = false"
                        ng-if="model.selected.timeGrouping.chartType == 'timeseries'">
                        <i class="icon-chart-line">Line</i>
                    </button>
                </div>
                <button class="reversed"
                    ng-class="{'show': togglePanelActionsEnabled}"
                    ng-click="onTogglePanelClick()">
                    <i class="icon-down-open">Show Panel</i>
                </button>
            </section>
        </article>
        """


module.directive 'storeHighcharts', ['HighchartsModel', 'HighchartsChartFactory', (HighchartsModel, HighchartsChartFactory) ->
    restrict: 'E'
    scope:
        view: '='
        save: '=',
    replace: true
    template: \
    """
    <article class="store-chart">
      <div class="loadable" ng-class="{'loading': view.model === null || view.model.tracker.active()}"></div>
      <main class="store-chart-container"></main>
    </article>
    """
    link: (scope, element) ->
        chartContainer = $(element).find('.store-chart-container')
        chart = new HighchartsChartFactory(chartContainer, 'barchart')

        resizeObserver = new ResizeObserver (_.debounce((-> chart.reflow()), 50))
        resizeObserver.observe(element[0])

        unWatchSelectedChart = null
        unWatchSelectedParams = null

        scope.$watch 'view', ->
            unWatchSelectedChart?()
            unWatchSelectedParams?()
            unWatchSelectedParams = scope.$watch 'view.params', ->
                unWatchSelectedChart?()
                return if not scope.view?.params
                { groupBy, metrics } = scope.view.params
                scope.view.model = new HighchartsModel(groupBy, chart, scope.view.model, metrics)
                unWatchSelectedChart = scope.$watch('view.model.selected', ((newModel, oldModel) ->
                    if newModel and oldModel and oldModel.timeGrouping.id isnt newModel.timeGrouping.id
                        scope.view.model?.updateAvailableMetrics()
                    scope.view.model?.refreshQuery()
                    scope.save()
                ), true)

        scope.$on '$destroy', ->
            chart.cleanup()
            resizeObserver.disconnect()
]


module.factory 'HighchartsChartFactory', ['Utils', 'HighchartsConfig', (Utils, HighchartsConfig) ->
    return (element, type) ->  # element is the chart container
        chart = null
        latestQueryHash = null
        refresh: (query, model) ->
            latestQueryHash = Utils.object.hash(query)
            chart = null

            HighchartsConfig.fetch(query, model).then (config) ->
                return if Utils.object.hash(query) isnt latestQueryHash
                config.chart.renderTo = $(element).get(0)
                chart = new Highcharts.Chart(config)
                $('.highcharts-button').remove()
                exportButton = $('.chart-export').off('click')
                exportButton.on('click', -> chart.exportChartLocal(type:'image/png'))
            .catch (error) ->
                console.error error
                chart?.destroy()
                chart = null
        reflow: ->
            chart?.reflow()
        cleanup: ->
            chart?.destroy()
            $('.chart-export').off('click')
            $(element).empty()
]


module.factory 'HighchartsModel', ($rootScope, promiseTracker, Utils, CONFIG) -> class HighchartsModel

    constructor: (all, chart, previousModel, availableMetrics) ->
        @timeseriesAvailableMetrics = do ->
            return _.compact availableMetrics.map (metric) ->
                return if metric.field.indexOf('growth') isnt -1
                return if not metric
                id:    metric.field
                type:  metric.cellFilter
                group: metric.category ? 'Uncategorized'
                name:  metric.headerName
                label: do ->
                    return "#{metric.headerGroup} - #{metric.headerName}" if metric.headerGroup and metric.headerName
                    return metric.headerGroup or metric.headerName

        @nonTimeseriesAvailableMetrics = do ->
            metrics = CONFIG.defaults?.stores?.kpis or CONFIG.views?.stores?.kpis
            metrics ?= do ->
                defaults = CONFIG.views?.sales?.kpis or []
                return defaults.flatMap (x) -> [
                    x,
                    "growth_#{x}_prev"
                ]
            metrics = _.uniq(metrics)

            return _.compact metrics.map (x) ->
                metric = _.find availableMetrics, {field: x}
                return null if not metric
                id:    metric.field
                type:  metric.cellFilter
                group: metric.category ? 'Uncategorized'
                name:  metric.headerName
                label: do ->
                    return "#{metric.headerGroup} - #{metric.headerName}" if metric.headerGroup and metric.headerName
                    return metric.headerGroup or metric.headerName

        @available =
            grouping: Utils.copy(all)
            limitBy: [5, 10, 20, 50, 100, 1000]
            sortOrder: [
                {id: -1, label: 'Highest'}
                {id:  1, label: 'Lowest'}
            ]
            timeGrouping: TimeGroupings
            stacking: [false, true]
            select: (key, defaultIndex = 0) ->
                defaultValue = do =>
                    return defaultIndex if not _.isNumber(defaultIndex)
                    return @[key][defaultIndex]
                return defaultValue if not previousModel
                prev = previousModel?.selected?[key]
                return prev if typeof prev == 'boolean'
                result = _.find @[key], (x) ->
                    return x is prev if _.isNumber(x)
                    return x.id is prev?.id
                return result or defaultValue

        timeGrouping = @available.select('timeGrouping')

        @available.metric = do =>
            return _.cloneDeep(@nonTimeseriesAvailableMetrics) if timeGrouping.chartType == 'barchart'
            return _.cloneDeep(@timeseriesAvailableMetrics)

        @selected =
            filters: previousModel?.selected?.filters or {}
            grouping: @available.select 'grouping', do =>
                result = _.find @available.grouping, (x) -> x.id is 'stores.name'
                return result or @available.grouping[0]
            limitBy:      @available.select('limitBy', 2)
            metric:       @available.select('metric') # metric to sort by for bar charts, to display for timeseries
            sortOrder:    @available.select('sortOrder')
            timeGrouping: timeGrouping
            stacking:     @available.select('stacking', true)

        @funnel = {nodes: previousModel?.funnel?.nodes ? [], selected: previousModel?.funnel?.selected ? {}}
        @tracker = promiseTracker()
        @chart = chart
        @chart.labelFormatterOn = false
        @visible = @available.metric.map (x, index) -> index < 2
        Highcharts.wrap Highcharts.Series.prototype, 'setVisible', do =>
            model = this
            return (proceed) ->
                proceed.apply(@, Array.prototype.slice.call(arguments, 1))
                model.visible = @chart.series.map (x) -> x.visible

                if model.selected.timeGrouping.chartType is 'barchart' and not model.selected.stacking
                    # This updates the y-axis labels based on the metric selection
                    @chart.yAxis.forEach (axis) ->
                        series = axis.series.filter((x) -> x.visible)
                        return if series.length is 0
                        metrics = series.map (x) -> x.options.metric
                        metricGroups = _.groupBy metrics, (x) -> x.group
                        axis.update title: text: Object.keys(metricGroups).map((key) ->
                            "#{key}: " + metricGroups[key].map((x) -> x.name).join(' · ')
                        ).join(', ')

    findIndexById: (array, id) ->
        return _.findIndex(array, {'id': id})

    resetFilters: ->
        @selected.filters = {}
        @selected.grouping = _.find @available.grouping, ((x) -> x.id is 'stores.name') or @available.grouping[0]
        @funnel = {nodes: [], selected: {}}

    applyFilters: (selectedNode) ->
        # apply all filters only up to that node
        @selected.filters = {}
        for node in @funnel.nodes
            if node.id == selectedNode.id
                @funnel.selected = node
                @selected.grouping = selectedNode
                return
            @selected.filters[node.table] ?= {}
            @selected.filters[node.table][node.column] = node.choice

    nodeInFunnel: (node) ->
        activeFilters = @funnel.nodes
        activeFilters = @funnel.nodes[0 ... @findIndexById(@funnel.nodes, @funnel.selected.id)] if not _.isEmpty(@funnel.selected)
        return not _.isUndefined(_.find(activeFilters, {'id': node.id}))

    groupByNext: ->
        # change the groupby level to the next one not in the funnel
        groupIndex = initIndex = @findIndexById(@available.grouping, @selected.grouping.id)
        groupIndex = ++groupIndex % @available.grouping.length
        while @nodeInFunnel(@available.grouping[groupIndex]) and groupIndex != initIndex
            groupIndex = ++groupIndex % @available.grouping.length
        @selected.grouping = @available.grouping[groupIndex]

    updateAvailableMetrics: =>
        @available.metric = do =>
            return _.cloneDeep(@nonTimeseriesAvailableMetrics) if @selected.timeGrouping.chartType is 'barchart'
            return _.cloneDeep(@timeseriesAvailableMetrics)

        metricToSelect = @available.metric.find((metric) => metric.id == @selected.metric.id)
        @selected.metric = metricToSelect ? @available.metric[0]


    refreshQuery: (rootQuery) =>
        selected = _.cloneDeep(@selected)

        query = _.cloneDeep(rootQuery or $rootScope.query)
        query.limit = selected.limitBy
        query.options = {}
        query.options.fillCalendarGaps = do ->
            return selected.timeGrouping.chartType is 'timeseries'
        query.options.metrics = do =>
            return (@available.metric.map (x) -> x.id) if selected.timeGrouping.chartType is 'barchart'
            return [@selected.metric.id]
        query.options.properties = do ->
            return [selected.grouping.id].concat selected.timeGrouping.property

        selectedFilters = selected.filters
        transactionsFilter = query.filters.transactions
        Object.keys(selectedFilters or {}).forEach (tableKey) ->
            Object.keys(selectedFilters[tableKey]).forEach (columnKey) ->
                return if tableKey is 'transactions' and columnKey is 'timestamp'
                query.filters[tableKey] ?= {$and:[]}
                columnIndex = (query.filters[tableKey]?.$and or []).findIndex (x) -> Object.keys(x)[0] is columnKey
                value = selectedFilters[tableKey][columnKey]
                if columnIndex is -1
                    column = {}
                    column[columnKey] = {$in:[value]}
                    query.filters[tableKey] ?= {}
                    query.filters[tableKey].$and ?= []
                    query.filters[tableKey].$and.push(column)
                else
                    column = query.filters[tableKey]?.$and[columnIndex]
                    column[columnKey] ?= {$in:[]}
                    column[columnKey].$in = _.union(column.$in, [value])
        query.filters.transactions = transactionsFilter
        query.sort =
            field: selected.metric.id
            order: selected.sortOrder.id
        @tracker.addPromise @chart.refresh(query, @)


# This is the new function to use once the db-growth query service branch is deployed
module.service 'HighchartsSeriesData', ['$q', 'Utils', 'QueryServiceAPI', ($q, Utils, QueryServiceAPI) ->

    removeTotals = (rows) ->
        return rows if rows.length is 0
        properties = Object.keys(rows[0]).filter((x) -> x.indexOf('property') is 0)
        return rows.filter (row) ->
            for property in properties
                return false if row[property] is '$total'
            return true

    fetch: (model, rootQuery) ->
        {chartType} = model.selected.timeGrouping
        switch chartType
            when 'barchart' then return @fetchBarchart(model, rootQuery)
            when 'timeseries' then return @fetchTimeseries(model, rootQuery)
            else throw new Error("Unknown chart type `#{chartType}`.")

    fetchBarchart: (model, rootQuery) ->
        query = Utils.copy(rootQuery)
        query.options ?= {}
        query.options.includeTotals = false
        return QueryServiceAPI()
        .then (api) ->
            return api.query.metricsFunnel(query)
        .then (series) ->
            return {series}
        .catch (err) ->
            setTimeout((() -> throw err), 0)
            return {series: []}

    fetchTimeseries: (model, rootQuery) ->
        query = Utils.copy(rootQuery)
        query.options ?= {}
        query.options.includeTotals = false
        query.options.includeItemInformation = false

        return QueryServiceAPI().then (api) ->

            totalQueryPromise = api.query.metricsFunnel do ->
                totalQuery = Utils.copy(query)
                totalQuery.options ?= {}
                totalQuery.options.includeTotals = false
                totalQuery.options.fillCalendarGaps = true
                totalQuery.options.property = ['stores.aggregate', ...model.selected.timeGrouping.property]
                delete totalQuery.limit
                return totalQuery

            # first extract the top n series by total
            seriesQueryPromise = api.query.metricsFunnel({
                ...query
                options: {...query.options, properties: [model.selected.grouping.id]}
            }).then (topDimensions) ->
                topDimensions = removeTotals(topDimensions)
                # then get the time series data for each
                return api.query.metricsFunnel do ->
                    seriesQuery = Utils.copy(query)
                    seriesQuery.options ?= {}
                    seriesQuery.options.includeTotals = false
                    seriesQuery.options.fillCalendarGaps = true
                    seriesQuery.options.properties = [model.selected.grouping.id, ...model.selected.timeGrouping.property]
                    {table, column} = model.selected.grouping
                    seriesQuery.filters ?= {}
                    seriesQuery.filters[table] ?= {}
                    seriesQuery.filters[table][column] ?= {}
                    seriesQuery.filters[table][column].$in = do ->
                        return _.uniq(topDimensions.map((x) -> x.property0).filter (x) -> x != '$total')
                    delete seriesQuery.limit # the limit does not exist!
                    return seriesQuery

            return \
            $q.all([seriesQueryPromise, totalQueryPromise])
            .then ([series, totalSeries]) ->
                totalSeries: removeTotals(totalSeries)
                series: removeTotals(series)
            .catch (err) ->
                setTimeout((() -> throw err), 0)
                return { series: [], totalSeries: [] }
]

module.service 'HighchartsConfig', ['$filter', 'Utils', 'HighchartsSeriesData', ($filter, Utils, HighchartsSeriesData) ->
    fetch: (query, model) ->
        HighchartsSeriesData.fetch(model, query).then ({series, totalSeries}) ->
            valueFormatter = (type, x) ->
                [filter, args...] = type.split(':')
                return $filter(filter)(x, args...)

            filterTotal = (d) ->
                dimensionValues = [d["value#{i}"] for i in [0 .. d.property_count]]
                return not dimensionValues.includes('$total')

            parseMetricValue = (x) ->
                x = parseFloat(x)
                return 0 if _.isNaN(x) or not _.isNumber(x)
                return parseFloat(x.toFixed(2))

            isTimeseries = model.selected.timeGrouping.chartType is 'timeseries'

            # Create yAxes for each type of measurement
            types = do ->
                availableMetrics = do ->
                    return [model.selected.metric] if isTimeseries
                    return model.available.metric
                return _.groupBy availableMetrics, (x) -> x.type.split(':')[0]

            typesArray = _.compact _.map types, (metrics) ->
                metricType = metrics[0].type.split(':')[0]
                return [metricType, metrics[0].type, metrics]

            yAxes = _.compact _.map typesArray, ([type, filter, metrics], index) ->
                animation: false
                metricGroup: type
                title: text: do ->
                    return null
                    # return model.selected.metric.label if model.selected.timeGrouping.chartType is 'timeseries'
                    # metricGroups = _.groupBy metrics, (x) -> x.group
                    # return Object.keys(metricGroups).map((key) ->
                    #     "#{key}: " + metricGroups[key].map((x) -> x.name).join(' · ')
                    # ).join(', ')
                labels: formatter: ->
                    return valueFormatter(filter, @value)
                opposite: !!(index % 2)

            xAxisCategories = undefined
            combinedSeries = []

            if model.selected.timeGrouping.chartType is 'barchart'
                combinedSeries = _.map model.available.metric, (metric, index) ->
                    name: metric.label
                    colorByPoint: false
                    metric: metric
                    type: 'column'
                    data: series.filter(filterTotal).map (point) ->
                        name:       point.value0
                        y:          parseMetricValue(point[metric.id])
                        drilldown:  true
                        fullInfo:   point
                    yAxis: _.findIndex typesArray, ([type]) ->
                        return type is metric.type.split(':')[0]
                    dataLabels:
                        formatter: -> valueFormatter(metric.type, @y)
                    visible: do ->
                        return model.visible[index] if model.visible
                        return metric.id in ['net_sales', 'growth_net_sales_prev']


            else if model.selected.timeGrouping.chartType is 'timeseries'
                series = series.filter(filterTotal)

                totalData = totalSeries.filter(filterTotal).map (x) ->
                    x.value0 = "Total"
                    return x

                xAxisCategories = do ->
                    categories = _.sortBy(totalData, "calendar__timestamp")
                    categories = categories.map(model.selected.timeGrouping.pointFormatter)
                    categories = _.uniq(categories)
                    return categories

                # FIXME: The total series is currently broken... removing it for now
                totalData = []
                series = totalData.concat(series)
                isTotal = (point) -> return point.value0 is "Total"

                # create a data series for each grouping
                groupedSeries = _.groupBy(series, 'value0')
                mappedSeries = _.mapValues groupedSeries, (grouping, value0) ->
                    mappedPoints = grouping.map (point) ->
                        _.mapValues point, (val, key) ->
                            return val if not key.startsWith('value') or isNaN(parseFloat(val))
                            return parseFloat(val)

                    sortedMappedPoints = _.sortBy(mappedPoints, "calendar__timestamp")

                    data = sortedMappedPoints.map (point) -> # create data points
                        name:       model.selected.timeGrouping.pointFormatter(point)
                        y:          point[model.selected.metric.id] ? null
                        drilldown:  not isTotal(point)
                        fullInfo:   point

                    return
                        yAxis: 0
                        color: '#888' if isTotal(grouping[0])
                        dashStyle: 'longdash' if isTotal(grouping[0])
                        turboThreshold: 1000000
                        dataLabels:
                            formatter: -> valueFormatter(model.selected.metric.type, @y)
                        name: grouping[0].value0
                        data: data
                        type: do ->
                            return 'areaspline' if model.selected.stacking and not isTotal(grouping[0])
                            return 'line'

                combinedSeries = _.values(mappedSeries)

            chart:
                zoomType: 'x'
                events:
                    drilldown: (e) ->
                        chartType = model.selected.timeGrouping.chartType

                        selectedDrilldown = do =>
                            if chartType is 'barchart'
                                return e.point.name
                            if chartType is 'timeseries'
                                seriesIndex = do ->
                                    seriesPointIndex = e.point?.series?.index
                                    return seriesPointIndex if _.isNumber(seriesPointIndex)

                                    # workaround for bug in highcharts: http://stackoverflow.com/questions/38534164/highcharts-drilldown-on-area-chart
                                    return _.findIndex @series, (series) ->
                                        return $(e.originalEvent.composedPath[1]).children().is(series.area?.element)
                                return @series[seriesIndex]?.name

                        # apply the current selected level as a filter
                        {table, column} = model.selected.grouping
                        model.selected.filters[table] ?= {}
                        model.selected.filters[table][column] = selectedDrilldown

                        # remove funnel.selected and everything after it
                        if not _.isEmpty(model.funnel.selected)
                            model.funnel.nodes = model.funnel.nodes[0 ... model.findIndexById(model.funnel.nodes, model.funnel.selected.id)]
                            model.funnel.selected = {}

                        # add drilldown to the funnel
                        model.funnel.nodes.push Utils.copy model.selected.grouping
                        _.last(model.funnel.nodes).choice = selectedDrilldown

                        model.groupByNext()

                        # destroy the chart to avoid triggering drilldown twice
                        @destroy()
            exporting:
                fallbackToExportServer: false
                scale: 2
                sourceHeight: 600,
                sourceWidth: 1000

            title:
                text: null

            xAxis:
                type: 'category'
                categories: xAxisCategories
                labels:
                    rotation: -45
                    align: 'right'

            yAxis: yAxes

            legend:
                enabled: true
                verticalAlign: "top"
                symbolRadius: "50px"
                squareSymbol: true
                symbolWidth:  10
                symbolHeight: 10
                itemDistance: 15
                padding: 0
                margin: 35
                marginBottom: 15
                y: 0
                itemHiddenStyle:
                    color: "#aaa"
                itemStyle:
                    fontSize: "11px"
            tooltip:
                do (model, query) ->
                    if model.selected.timeGrouping.chartType is 'timeseries'
                        shared: true
                        useHTML: true
                        formatter: ->
                            metricLabel = model.selected.metric.group + " for "
                            fullInfo = @points[0].point.fullInfo
                            headLabel = model.selected.timeGrouping.tooltipFormatter(fullInfo)
                            pointsHTML = @points.map((p) ->
                                return "" if not fullInfo
                                """
                                <tr>
                                    <td style='color:#{p.point.color}'>#{p.point.series.name}:&nbsp;</td>
                                    <td> #{valueFormatter(model.selected.metric.type, p.y)} </td>
                                </tr>
                                """
                            ).join('\n')
                            """
                            <span>#{metricLabel} #{headLabel} </span>
                            <br>
                            <table>
                            #{pointsHTML}
                            </table>
                            """
                    else if model.selected.timeGrouping.chartType is 'barchart'
                        headerFormat: "<span>#{model.selected.metric.group} for <em>{point.key}</em> </span> <br> <table>",
                        pointFormatter: (point) ->
                            metric = _.find model.available.metric, {label: @series.name}
                            name = @series.name.trim()
                            """
                            <tr>
                                <td style='color:#{@color}'>#{name}:&nbsp;</td>
                                <td>#{valueFormatter(metric.type, @y)}</td>
                            </tr>
                            """
                        footerFormat: "</table>"
                        shared: true
                        useHTML: true
            plotOptions:
                series:
                    connectNulls: true
                    borderRadiusTopLeft: '4px'
                    borderRadiusTopRight: '4px'
                    cursor: 'pointer'
                    borderWidth: 0
                    marker:
                        radius: 2
                    lineWidth: 1
                areaspline:
                    connectNulls: true
                    trackByArea: true
                    stacking: if model.selected.stacking then "normal" else undefined

            series: combinedSeries
]
