import _ from 'lodash';
import { IMetricDefinition } from '../../lib/types';

export const parseNumber = (value: string) => {
    if (_.isNil(value)) {
        return null;
    }

    const resultValue = parseFloat(value);
    return Number.isNaN(resultValue) ? null : value;
};

export const processMetrics = (
    availableMetrics: IMetricDefinition[],
    selectedMetrics: string[],
    metricByColor: Record<string, string>,
) => {
    const metrics = _.keyBy(availableMetrics, metric => metric.field);
    const mappedMetrics = selectedMetrics.map(metricName => {
        if (!metrics[metricName]) {
            console.error(`Sales page metric '${metricName}' not found.`);
            return null;
        }

        const metric = _.cloneDeep(metrics[metricName]);
        metric.inverted = (metrics[`growth_${metricName}`] || metric)?.cellClass === 'percent-inverted';
        return _.extend(metric, {
            field: metricName,
            title: metric.headerGroup,
            property: metric.field,
            type: metric.cellFilter,
            color: metricByColor[metricName],
        });
    });

    return _.compact(mappedMetrics);
};

const getMetricValue = ($filter: angular.IFilterService, chart, data, property?: string) => {
    if (!data) {
        return null;
    }

    const value = parseNumber(data[property || chart.property]);

    if (value === null || value === undefined || !chart.type) {
        return value;
    }

    const args = chart.type.split(':');
    const filterType = args.shift();

    return $filter(filterType)(value, ...args);
};

export const remapData = ($filter: angular.IFilterFunction, metrics: IMetricDefinition[], data: any) => {
    return metrics.map(metric => {
        const { property, inverted, growth_value_prefix, title } = metric;

        return {
            chart: metric,
            title,
            value: getMetricValue($filter, metric, data),
            growth: !data ? null : parseNumber(data[`growth_${property}`]),
            growth_value_prefix,
            inverted,
            growth_value: getMetricValue($filter, metric, data, `growth_${property}_prev`),
        };
    });
};
