export const USER_EXPORTED_PAGE_METRICS = 'User Exported Metrics Page';
export const USER_EXPORTED_PAGE_GRID = 'User Exported Grid Page';
export const USER_RUN_REPORT = 'User Ran Report Manually';
export const USER_RUN_REPORT_FAILED = 'User Manual Report Run Failed';
export const USER_RUN_SCHEDULE = 'User Ran Schedule Manually';
export const USER_CREATE_REPORT = 'User Created Report';
export const USER_CREATE_SCHEDULE = 'User Created Schedule';
export const USER_UPDATE_REPORT = 'User Updated Report';
export const USER_EXPORT_REPORT = 'User Exported Report';
export const USER_IMPORT_REPORT = 'User Imported Report';
export const USER_IMPORT_REPORT_FAILED = 'User Report Import Failed';
export const USER_UPDATE_SCHEDULE = 'User Updated Schedule';
export const USER_CREATE_VIEW_METRICS = 'User Created View Metrics Page';
export const USER_CREATE_VIEW_GRID = 'User Created View Grid Page';
export const USER_CREATE_VIEW_CHART = 'User Created View Chart Page';
export const USER_NAVIGATED_HANDBOOK = 'User Navigated to Handbook';
