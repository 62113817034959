import { IMetricDefinition } from '../../lib/types';
import { ICardHeaderModel } from './ads-card-header.directive';
import { IAdView } from './ads.controller';

export interface IAdGridOptions {
    platform: string;
    title: string;
    properties: any[];
    property: any;
    data: any;
    metrics: IMetricDefinition[];
}

export class AdGrid {
    node: any;
    platform: string;
    metrics: IMetricDefinition[];
    data: any = null;
    title: string;

    constructor({ platform, title, properties, property, data, metrics }: IAdGridOptions) {
        this.metrics = metrics;
        this.platform = platform;
        this.title = title;
        this.node = {
            properties,
            property,
            parent: null,
            level: 0,
            getDrilldownProperties: () => [],
        };
        this.data = data || null;
    }
}

export const AdsGridDirectiveFactory = () => [
    '$rootScope',
    'MetricsFunnelNodeGridViewModel',
    function AdsGridDirective($rootScope: angular.IRootScopeService, MetricsFunnelNodeGridViewModel: any) {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                organizationId: '=',
            },
            replace: true,
            template: `
                <article class="ads-grid-container">
                    <div class="ad-grid-header">
                        <div class="ad-grid-platform-title">{{ model.title }}</div>
                        <div class="more-info-label">
                            <a ng-href="{{ gridPagePlatformUrl }}">click here to see more item info</a>
                        </div>
                    </div>
                    <div class="grid-container" ng-if="grid.options">
                        <div class="ag-42 grid grid-new ag-theme-alpine" ag-grid="grid.options"></div>
                    </div>
                </article>
            `,
            link: function AdsGridDirectiveLink(
                $scope: angular.IScope & { grid: any; model: any; organizationId: string; gridPagePlatformUrl: string },
                $element: angular.IRootElementService,
            ) {
                const gridPageUrl = $rootScope.routes.grid?.url || $rootScope.routes.items?.url;

                // Init Grid default state)
                $scope.gridPagePlatformUrl = `${gridPageUrl}?platform=${$scope.model.platform}`;
                $scope.grid = new MetricsFunnelNodeGridViewModel($scope);
                $scope.grid.updateColumns($scope.model.node, $scope.model.metrics, $scope.organizationId);
                $scope.grid.updateData($scope.model.node, $scope.model.data, $scope.organizationId);

                const removeExceedingHeight = () => {
                    $scope.grid.options.api?.setDomLayout('autoHeight');
                    // auto height will get the grid to fill the height of the contents,
                    // so the grid div should have no height set, the height is dynamic.
                    $element[0].getElementsByClassName('ag-42')[0].style.height = '';
                };

                const refresh = () => {
                    if (!$scope.model?.data) {
                        return;
                    }

                    $scope.grid.resetColumnDefs();
                    $scope.grid.updateData($scope.model.node, $scope.model.data, $scope.organizationId);
                    removeExceedingHeight();
                };

                $scope.$watch('model', () => {
                    if ($scope.model?.node) {
                        $scope.grid.updateColumns($scope.model.node, $scope.model.metrics, $scope.organizationId);
                        refresh();
                    }
                });

                $scope.$watch('model.data', () => {
                    if ($scope.model?.data) {
                        refresh();
                    }
                });
            },
        };
    },
];

export const AdsSectionGridDirectiveFactory = () => [
    function AdsSectionGridDirective(): angular.IDirective<
        angular.IScope & { views: IAdView[]; model: IAdView[]; header: ICardHeaderModel }
    > {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                views: '=',
                organizationId: '=',
            },
            template: `
                <div class="ads-page-grid-container card">
                    <ads-card-header ng-if="header" model="header"></ads-card-header>
                    <div class="ads-page-grid-block" ng-repeat="adView in model">
                        <ads-grid model="adView.grid" organization-id="organizationId"></ads-grid>
                        <div class="separator" ng-if="!$last"></div>
                    </div>
                </div>
            `,
            link: function AdsSectionGridDirectiveLink($scope) {
                const init = () => {
                    $scope.model = $scope.views.filter(view => view.stats !== undefined);
                    $scope.header = {
                        title: `Grid ${$scope.model.length > 1 ? ' Comparison' : ''}`,
                    };
                };

                init();

                $scope.$watch('views', views => views && init());
            },
        };
    },
];
