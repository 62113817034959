import _ from 'lodash';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios, { AxiosError } from 'axios';
import { v1 as ServiceV1 } from '@42technologies/service-client';
import { retry } from '@42technologies/retry';
import { AppConfigService, AppConfig } from '../config-app';
import { AuthService, logout } from '../auth';
import { CustomError, isObject, seconds } from '../utils';

const ServiceHTTPClient = (auth = true) => {
    const isJSONResponse = (response: AxiosResponse) =>
        typeof response.headers['content-type'] === 'string' &&
        response.headers['content-type'].includes('application/json');

    const onError = (options: ServiceV1.ServiceAPIClientHttpOptions, start: number) => (cause: unknown) => {
        if (options.signal?.aborted || axios.isCancel(cause)) {
            throw new ServiceRequestCancelledError(cause);
        }

        if (isRequestAbortedError(cause)) {
            throw new ServiceRequestAbortedError(cause, { cause });
        }

        if (isRetryableError(cause)) {
            return;
        }

        if (isRequestErrorWithStatus(cause)) {
            const status = cause.response.status;
            if (status === 401) return logout();
        }

        if (isServiceAPIErrorResponse(cause)) {
            throw new ServiceAPIError(cause);
        }

        if (axios.isAxiosError(cause)) {
            const payload = {
                message: cause.message ?? cause.response?.statusText ?? cause.status ?? 'Unknown Error',
                status: cause.status,
                code: cause.code ?? cause.response?.status,
                request: cause.request,
                response: cause.response,
                duration: Date.now() - start,
            };
            throw new ServiceAPIUnhandledError(payload, { cause });
        }

        throw cause;
    };

    return async (options: ServiceV1.ServiceAPIClientHttpOptions) => {
        const logger = console;
        const start = Date.now();

        const authHeaders: Record<string, string> = await (auth
            ? AuthService.get().then(auth => auth.toHeaders())
            : {});

        const headers: Record<string, any> = {
            ...authHeaders,
            ...(isObject(options.headers) ? options.headers : {}),
        };

        const axiosOptions: AxiosRequestConfig = {
            ...options,
            headers,
        };

        const response = await retry(() => axios(axiosOptions), {
            logger,
            onError: onError(options, start),
            delay: { max: seconds(20) },
        });

        const data = response.data;
        if (!isJSONResponse(response)) return { data };
        if (typeof data !== 'string') return { data };
        if (data === '') return {};

        try {
            return { data: JSON.parse(data) };
        } catch (error) {
            throw new ServiceAPIUnhandledError(
                {
                    message: 'Invalid JSON response',
                    status: response.status,
                    code: response.status,
                    request: options,
                    duration: Date.now() - start,
                },
                { cause: error },
            );
        }
    };
};

export type ServiceAPI = ServiceV1.ServiceAPI;
export type ServiceAPICall<T = any, V = any> = (params: T, httpOptions?: any) => Promise<V>;
export class ServiceAPIFactory<T extends ServiceV1.ServiceAPI = ServiceV1.ServiceAPI> {
    protected spec: null | ReturnType<ServiceAPIFactory['getApiSpec']> = null;
    protected config: null | ReturnType<ServiceAPIFactory['getApiConfig']> = null;
    protected client: null | Promise<T> = null;

    constructor(
        protected serviceName: keyof AppConfig['services'],
        // If true, then we'll add auth headers when fetching the api spec
        protected authenticatedSpecFetch = true,
    ) {}

    public get(options?: { signal?: AbortSignal; timeout?: number }): Promise<T> {
        this.client ??= this.getClient(options);
        return this.client.catch(error => {
            this.client = null;
            throw error;
        });
    }

    protected async getClient(options?: { signal?: AbortSignal; timeout?: number }): Promise<T> {
        const [config, spec] = await Promise.all([this.getApiConfig(options), this.getApiSpec(options)]);
        if (!config.base) throw new Error('Missing required service config base.');
        const createApi = ServiceV1.createApiClass<T>(spec);
        return createApi({
            base: config.base,
            client: ServiceHTTPClient(true),
            transform: this.getApiTransform(),
        });
    }

    protected getApiSpec(options?: { signal?: AbortSignal; timeout?: number }): Promise<any> {
        this.spec ??= this.getApiConfig(options).then(config => {
            const base = config.base;
            const client = ServiceHTTPClient(this.authenticatedSpecFetch);
            return ServiceV1.fetchSpec({ ...options, base, client });
        });
        return this.spec
            .then(x => _.cloneDeep(x))
            .catch(error => {
                this.spec = null;
                throw error;
            });
    }

    protected getApiConfig(options?: {
        signal?: AbortSignal;
        timeout?: number;
    }): Promise<NonNullable<AppConfig['services'][keyof AppConfig['services']]>> {
        this.config ??= AppConfigService.get(options).then(appConfig => {
            const config = appConfig.services[this.serviceName];
            if (!config) throw new Error(`No config found for service ${String(this.serviceName)}`);
            return config;
        });
        return this.config
            .then(x => _.cloneDeep(x))
            .catch(error => {
                this.config = null;
                throw error;
            });
    }

    protected getApiTransform() {
        // We inject the organization from the org config, if it's needed for the api call
        const transform: ServiceV1.ServiceAPITransform = ({ parameters, operation }) => {
            // Check if the api call needs an organization parameter...
            const orgParam = operation.parameters.find(
                x => ['path'].includes(x.paramType) && ['organization', 'organizationId'].includes(x.name),
            );
            // If the api call doesn't need an org param, then we exit early...
            if (!orgParam) return parameters;
            // If the called defined one already, we use it...
            if (parameters[orgParam.name]) return parameters;
            // Otherwise we pull it from the service config...
            return this.getApiOrganizationId().then(organizationId => {
                return { [orgParam.name]: organizationId, ...parameters };
            });
        };
        return transform;
    }

    protected getApiOrganizationId(options?: { signal?: AbortSignal; timeout?: number }) {
        return this.getApiConfig(options).then(config => {
            return config.organization ?? null;
        });
    }
}

export type ServiceAPIErrorResponse<T = any> = Required<AxiosError<T>>;

export class ServiceRequestAbortedError extends CustomError {
    static MESSAGE = 'Request aborted';
    readonly code: unknown;
    constructor(error?: Error | { code?: unknown }, options?: { cause: unknown }) {
        super(ServiceRequestAbortedError.MESSAGE, options);
        this.code = error && 'code' in error ? error?.code : null;
    }
}

export class ServiceRequestCancelledError extends CustomError {
    constructor(cause?: unknown) {
        super('Request cancelled', { cause });
    }
}

export class ServiceAPIUnhandledError<T = any> extends CustomError {
    readonly type = 'ServiceAPIUnhandledError';
    readonly status: unknown;
    readonly code: unknown;
    readonly duration: number;
    readonly request?: undefined | ServiceAPIErrorResponse<T>['request'];
    readonly response?: undefined | ServiceAPIErrorResponse<T>['response'];
    constructor(
        error: {
            message: string;
            duration: number;
            status: unknown;
            code: unknown;
            request?: undefined | ServiceAPIErrorResponse<T>['request'];
            response?: undefined | ServiceAPIErrorResponse<T>['response'];
        },
        options?: { cause: unknown },
    ) {
        super(error.message, options);
        this.status = error.status;
        this.duration = error.duration;
        this.code = error.code;
        this.request = error.request;
        this.response = error.response;
    }
}

export class ServiceAPIError<D = any> extends CustomError {
    readonly code: number;
    readonly type: string;
    readonly data: D;
    readonly request: ServiceAPIErrorResponse['request'];
    readonly response: ServiceAPIErrorResponse['response'];
    constructor(cause: ServiceAPIErrorResponse) {
        const { code, type, message, data } = cause.response.data ?? {};
        super(message, { cause });
        this.code = code;
        this.type = type;
        this.data = data;
        this.request = cause.request;
        this.response = cause.response;
    }
}

export function isRequestAbortedError(
    error: unknown,
): error is
    | ServiceRequestAbortedError
    | Error
    | { code: typeof AxiosError.ERR_CANCELED | typeof AxiosError.ECONNABORTED } {
    if (error instanceof ServiceRequestAbortedError) {
        return true;
    }
    if (!isObject(error)) {
        return false;
    }
    if (error.message === ServiceRequestAbortedError.MESSAGE) {
        return true;
    }
    if (typeof error.code === 'string' && [AxiosError.ERR_CANCELED, AxiosError.ECONNABORTED].includes(error.code)) {
        return true;
    }
    // if (error.message === 'Network Error' && Browser.isLocalhost()) {
    //     return true;
    // }
    return false;
}

function isRetryableError(error: unknown) {
    if (isRequestErrorWithStatus(error)) {
        const status = error.response.status;
        if ([429, 502, 503, 504].includes(status)) return true;
    }
    if (axios.isAxiosError(error)) {
        const code = error.code;
        if (typeof code === 'string' && [AxiosError.ERR_NETWORK].includes(code)) return true;
        const status = error.status;
        if (typeof status === 'string' && [AxiosError.ERR_NETWORK].includes(status)) return true;
    }
    return false;
}

function isRequestErrorWithStatus(
    error: unknown,
): error is { response: { status: number; statusText: undefined | string } } {
    if (!axios.isAxiosError(error)) return false;
    if (!error.response) return false;
    const status = error.response?.status;
    return typeof status === 'number';
}

export function isServiceAPIErrorResponse(error: unknown): error is ServiceAPIErrorResponse {
    if (!axios.isAxiosError(error)) return false;
    const data = error.response?.data;
    if (!isObject(data)) return false;
    return typeof data.code === 'number';
}

export type ServiceAPIResourceAlreadyExistsErrorResponse = ServiceAPIErrorResponse<{ argument: string; value: string }>;

export function isServiceAPIResourceAlreadyExistsError(
    error: unknown,
): error is ServiceAPIResourceAlreadyExistsErrorResponse {
    if (!isServiceAPIErrorResponse(error)) return false;
    return isServiceAPIErrorResponse(error) && error.response.data.type === 'ResourceAlreadyExistsError';
}
