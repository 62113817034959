import _ from 'lodash';
import { clamp } from './utils-misc';

/** @deprecated - use lodash 'chunk' instead **/
export function chunk<T>(array: T[], size: number) {
    return _.chunk(array, size);
}

/** @deprecated - use native 'findIndex' instead **/
export function indexOf<T>(array: T[], callback: (x: T) => boolean): number {
    return array.findIndex(callback);
}

export function rotate<T>(sourceIndex: number, array: T[]): T[];
export function rotate<T>(sourceIndex: number, targetIndex: number, array: T[]): T[];
export function rotate<T>(sourceIndex: number, targetIndex: T[] | number, array?: T[]): T[] {
    if (_.isNil(targetIndex) && Array.isArray(array)) {
        return rotate(sourceIndex, array.length - 1, array);
    }
    if (Array.isArray(targetIndex)) {
        const index = targetIndex.length - 1;
        return rotate(sourceIndex, index, targetIndex);
    }
    if (Array.isArray(array)) {
        const beforeSource = array.slice(0, +sourceIndex + 1 || undefined);
        const afterSource = array.slice(sourceIndex + 1);
        return afterSource.concat(beforeSource);
    }
    throw new Error('Missing required argument: array');
}

export function move<T>(array: T[], from: number, to: number) {
    if (from === to) return array;
    from = clamp(0, from, array.length - 1);
    to = clamp(0, to, array.length - 1);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const value = array[from] as T;
    const result: T[] = [];
    array.forEach((item: T, index: number) => {
        if (index === to && from > to) result.push(value);
        if (index !== from) result.push(item);
        if (index === to && from < to) result.push(value);
    });
    return result;
}

export function insertAt<T>(array: T[], index: number, item: T) {
    const result = array.slice(0);
    result.splice(index, 0, item);
    return result;
}

export function removeAt<T>(array: T[], index: number) {
    const result = array.slice(0);
    result.splice(index, 1);
    return result;
}

export function remove<T>(array: T[], callback: (x: T) => boolean) {
    const index = array.findIndex(callback);
    if (index === -1) return array;
    return removeAt(array, index);
}

export function split<T>(array: T[], condition: (x: T) => boolean): T[][] {
    return array.reduce(
        (accs: T[][], curr: T) => {
            const index = condition(curr) ? 0 : 1;
            accs[index].push(curr);
            return accs;
        },
        [[], []],
    );
}
