import _ from 'lodash';
import { isObject } from '../../lib/utils';

export function parseKpisArray(data: unknown): null | { field: string; [x: string]: unknown }[] {
    if (!Array.isArray(data)) return null;
    const kpis = data.flatMap(value => {
        if (typeof value === 'string') {
            return [{ field: value }];
        }
        if (isObject(value) && 'field' in value && typeof value.field === 'string') {
            return [{ ...value, field: value.field }];
        }
        return [];
    });
    return kpis.length > 0 ? kpis : null;
}

export const normalizeOverviewConfigProperties = (config: Record<string, unknown>) => {
    for (const value of [config.properties, config.property]) {
        if (typeof value === 'string') return [value];
        if (!Array.isArray(value)) continue;
        const properties = value.filter((x): x is string => {
            if (typeof x !== 'string') {
                setTimeout(() => {
                    throw new Error(`Property is not a string ${x} !`);
                }, 10);
                return false;
            }
            return typeof x === 'string';
        });
        if (properties.length > 0) return properties;
    }
    return null;
};

export const normalizeOverviewConfigKpis = (config: unknown) => {
    const data: unknown = _.get(config, 'views.sales.kpis');
    return parseKpisArray(data);
};

export const normalizeDefaultsItemsGroupByConfig = (config: unknown): string[] | null => {
    const groupBy: unknown = _.get(config, 'defaults.items.itemsGroupBy');
    return typeof groupBy === 'string' ? [groupBy] : null;
};
