import moment from 'moment'
require('moment-timezone')

import _ from 'lodash'
import cronstrue from 'cronstrue'
import Utils from '../../lib/utils'
import * as AuthServiceAPI from '../../lib/auth'
import * as Analytics from '../../lib/analytics'
import { createSortable } from '../../lib/dom/sortable'
import { DropObserver } from '../../lib/dom/drop-observer'
import { downloadFromJSON } from '../../lib/dom/download'
import { preventOverscroll } from '../../lib/dom/scroll'
import { deepStripAngularProperties } from '../../lib/angular'
import * as ConfigPageExperiments from '../../lib/config-experiments'
import * as RegularExpressions from '../../lib/parsers/regexp'
import { createCalendarModelService } from '../../modules/datepicker/datepicker'
import { SmartGroupFilterDescriptors, SmartGroupsPopupModel } from '../../modules/smart-groups/smart-groups.service'
import { CurrenciesService } from '../../modules/currency/currency.service'
import DatepickerModule from '../../modules/datepicker'


module = angular.module('42.controllers.scheduling.common', [
    DatepickerModule.name,
    '42.modules.services'
    '42.modules.storage'
])
export default module


module.config ($routeProvider, ROUTES, CONFIG) ->
    routes =
        reports:   _.extend {}, ROUTES.reportingReports,   _.pick(CONFIG.routes?.reportingReports,   'label', 'url')
        schedules: _.extend {}, ROUTES.reportingSchedules, _.pick(CONFIG.routes?.reportingSchedules, 'label', 'url')
    Object.keys(routes).forEach (k) -> $routeProvider.when(routes[k].url, routes[k])
    return


module.factory 'ReportingModels', (ReportTemplatesModel, SchedulesModel) ->
    return class ReportingModel
        @Create: ->
            ReportTemplatesModel.Create().then (templates) ->
                SchedulesModel.Create(templates).then (schedules) ->
                    return new ReportingModel({templates, schedules})
        constructor: ({templates, schedules}) ->
            @templates = templates
            @schedules = schedules


module.service 'ReportingState', ['ReportingModels', (ReportingModels) ->
    fetch: -> ReportingModels.Create().then (x) =>
        @models = x
        return x
    # This is used to optimize checks for `isInvalid` and `hasUnsavedChanges` across directives.
    schedule: {flags:{}}
    report:
        flags:{}
        invalidFields:{}
        updateInvalidFields: (invalidFields) ->
            @invalidFields = _.extend {}, @invalidFields, invalidFields
            @flags.isInvalid = Object.keys(@invalidFields).filter((k) => @invalidFields[k]).length > 0
]


module.service 'ReportingCalendarModel', [() ->
    return createCalendarModelService()
]

module.controller 'ReportingReportsController', [
    '$q', '$scope', 'ReportingState', 'ReportingCalendarModel',
    ($q, $scope, ReportingState, ReportingCalendarModel) ->
        $scope.reportTemplatesModel = null
        loadingPromise = $q.all([ReportingState.fetch(), ReportingCalendarModel.init()])
        loadingPromise.then ([models]) ->
            $scope.reportTemplatesModel = models.templates
            $scope.schedules            = models.schedules
        return
]


module.service 'ReportSmartGroups', ->
    popup: new SmartGroupsPopupModel()


module.controller 'ReportingSchedulesController', [
    '$q', '$routeParams', '$location', '$scope', 'ReportingModels', 'ReportingCalendarModel',
    ($q, $routeParams, $location, $scope, ReportingModels, ReportingCalendarModel) ->
        $scope.schedules = null
        loadingPromise = $q.all([ReportingModels.Create(), ReportingCalendarModel.init()])
        loadingPromise.then ([{schedules}]) ->
            id = $routeParams.id
            selectedSchedule = do ->
                return if schedules.available.length is 0
                return schedules.viewState.active if not id
                selected = schedules.available.find((schedule) -> schedule.id is id)
                return selected if selected
                return schedules.viewState.active
            if selectedSchedule
                if not id or id isnt selectedSchedule.id
                    $location.path(schedules.getScheduleLink(selectedSchedule), false)
                if selectedSchedule?.id isnt schedules.viewState.active
                    schedules.select(selectedSchedule)
            $scope.schedules = schedules
            return
        return
]


module.directive 'reportTemplateList', ->
    restrict: "E"
    scope:
        reportTemplates: "=model"
    template: \
    """
    <article class="list-container report-templates list-container-report-templates" ng-if="!reportTemplates.selected">
        <ul>
            <li ng-repeat="template in reportTemplates.available" ng-click="reportTemplates.select(template)">
                <h1 class="title">{{ template.label }}</h1>
                <p class="description">{{ template.description }}</p>
            </li>
        </ul>
    </article>
    """


module.directive 'viewReportingReports', ($rootScope, promiseTracker, CONFIG, ReportingState, ReportSmartGroups, QueryServiceExport) ->
    restrict: 'E'
    scope:
        model: '='
        schedules: '='
    replace: true
    template: \
    """
    <article
        class="view view-reporting view-reporting-list view-reporting-reports"
        ng-class="{ invalid: ReportingState.report.flags.isInvalid, unsaved: ReportingState.report.flags.hasUnsavedChanges}">
            <div class="loadable" ng-class="{loading: !model}"></div>
            <aside ng-class={'has-selected':model.selected}>
                <article class="report-select">
                    <report-template-list model="model"></report-template-list>
                    <report-list model="model" schedules="schedules" ng-if="model.selected"></report-list>
                </article>
            </aside>
            <main ng-show="model.selected.reports.viewState.active">
                <view-reporting-reports-header ng-if="model" model="model"></view-reporting-reports-header>
                <main>
                    <report-editor model="model"></report-editor>
                </main>
            </main>
            <article class="smart-groups-filter-container">
                <segments model="ReportSmartGroups.popup"></segments>
            </article>
    </article>
    """
    link: (scope, element) ->
        scope.ReportSmartGroups = ReportSmartGroups
        scope.ReportingState = ReportingState

        scope.hierarchyModel = $rootScope.hierarchySelectModel
        scope.$watch 'hierarchyModel.view.selected', (selected) ->
            return if not selected or not selected?.id
            scope.model?.selected.reports.viewState.active.data.hierarchy = selected?.id

        scope.$watch 'model.selected.reports.viewState.active.data.hierarchy', (hierarchyId) ->
            return if not scope.hierarchyModel
            if hierarchyId
                $rootScope.hierarchySelectModel.select(hierarchyId)
                scope.hierarchyModel = $rootScope.hierarchySelectModel
            scope.model?.selected?.reports.viewState.active.data.hierarchy = scope.hierarchyModel?.view?.selected?.id

        scope.$watch 'model.selected.reports.hasUnsavedChanges()', (unsavedChanges) ->
            ReportingState.report.flags.hasUnsavedChanges = unsavedChanges

        scope.$watch 'model.selected.reports.viewState.selected', () ->
            ReportingState.report.flags.isInvalid = false
            ReportingState.report.invalidFields = {}


module.directive 'reportList', () ->
    restrict: "E"
    scope:
        reportTemplates: "=model"
        schedules: "="
    replace: true
    template: \
    """
    <article class="list-container reports list-container-reports" ng-class="{'has-templates':reportTemplates.available.length > 1, 'drop-mode': state.isHoverDropbox}">
        <div class="drag-and-drop-zone"></div>

        <article class="list-action list-action-back report-template" ng-if="reportTemplates.available.length > 1" ng-click="reportTemplates.select(null)">
            <i class="icon-left-open-big"></i>
            <h1>{{ reportTemplates.selected.label }}</h1>
        </article>

        <article class="list-action list-action-new list-action-create report-create"
            ng-if="!reportTemplates.selected.reports.isInCreateMode()">
            <div class="ui-button" ng-click="createReport()">
                <i class="icon-plus-circled"></i>
                <span>Create Report</span>
            </div>
        </article>

        <article class="list-action list-action-create report-create"
            ng-if="reportTemplates.selected.reports.isInCreateMode()"
            ng-click="reportTemplates.selected.reports.cancel()">
            <div class="list-action-button">
                <i class="icon-cancel-circled"></i>
                <h1>Cancel</h1>
            </div>
        </article>

        <article class="list-action list-action-search"
            ng-if="reportTemplates.selected.reports.viewState.available.length > 1 && !reportTemplates.selected.reports.isInCreateMode()"
            ng-class="{filtered:reportFilter.label}">
            <i class="icon-clear-filter icon-cancel-circled" ng-click="reportFilter.label = ''" ng-show="reportFilter.label"></i>
            <input ng-model="reportFilter.label" type="text" placeholder="Filter reports..." />
            <i class="icon-search"></i>
        </article>

        <ul class="models sortable-ui">
            <li class="item item-new report report-new selected" ng-if="reportTemplates.selected.reports.isInCreateMode()">
                <h1>{{ reportTemplates.selected.reports.viewState.active.label }}</h1>
                <p class="description" ng-show="reportTemplates.selected.reports.viewState.active.description">{{ reportTemplates.selected.reports.viewState.active.description }}</p>
            </li>

            <li ng-repeat="report in reportTemplates.selected.reports.viewState.available | fuzzyBy: 'label': reportFilter.label"
                class="item"
                ng-click="selectReport(report)"
                ng-class="{selected: report === reportTemplates.selected.reports.viewState.active}">
                    <div class="report-new-header">
                        <div class="report-new-header-label">
                            <h1>{{ report.label }}</h1>
                        </div>
                        <div
                            class="report-new-header-download"
                            hover-msg="Share report..."
                            ng-click="downloadReportObject($event, report)">
                            <i class="icon-share"></i>
                        </div>
                    </div>
                    <report-schedule-info-tooltip
                        ng-if="reportSchedules && reportSchedules[report.id]"
                        schedules="reportSchedules[report.id]">
                    </report-schedule-info-tooltip>
                    <h2 class="updated-at">
                        <span class="date">{{ report.updatedAt | date:'MMM dd' }}</span>
                        <span class="separator">at</span>
                        <span class="time">{{ report.updatedAt | date:'HH:mm' }}</span>
                        <i class="icon-clock"></i>
                    </h2>
                    <p class="description" ng-show="report.description">{{ report.description }}</p>
            </li>
        </ul>

        <article
            class="list-action list-action-report-import"
            ng-if="!reportTemplates.selected.reports.isInCreateMode()"
        >
            <div class="ui-button" ng-click="openReportImportPopup($event)">
                <i class="icon-publish"></i>
                <span>Import report...</span>
            </div>
        </article>

        <input type="file"
            id="report-file"
            class="file-input"
            name="report-file"
            accept=".json, .txt"
            style="display: none;"
        >
    </article>
    """
    link: (scope, element) ->

        scope.reportFilter = {label:''}

        # FIXME: watcher on this?
        scope.reportSchedules = do ->
            result = {}
            return result if scope.schedules.available.length is 0
            scope.schedules.available.forEach (schedule) ->
                schedule.data.reportId.forEach (reportId) ->
                    result[reportId] = result[reportId] or []
                    result[reportId].push({ name: schedule.label, id: schedule.id })
            return result

        scope.createReport = ->
            scope.reportTemplates.selected.reports.create()

        # FIXME: better name for this...
        scope.state =
            isHoverDropbox: false
            enabledFileDrop: true

        cleanupSortable = do ->
            sortable = null
            onSortableEnd = (evt) ->
                scope.state.isHoverDropbox = false
                scope.state.enabledFileDrop = true
                scope.reportTemplates.selected.reports.reorder(evt.oldIndex, evt.newIndex)
                scope.$apply()
            sortableOptions =
                ghostClass: 'placeholder'
                filter: '.item-new'
                draggable: '.item'
                onEnd: onSortableEnd
            setTimeout (->
                sortableEl = element[0].querySelector('.sortable-ui')
                sortable = createSortable(sortableEl, sortableOptions)
            ), 200
            return -> sortable?.destroy()
        scope.$on('$destroy', cleanupSortable)


        cleanupDragSection = do ->
            onDragStart = ->
                scope.state.enabledFileDrop = false
            onDragEnter = (e) ->
                e.preventDefault()
                e.stopImmediatePropagation()
                e.stopPropagation()
                return if not scope.state.enabledFileDrop
                scope.state.isHoverDropbox = true
                return
            onDragEnd = (e) ->
                e.preventDefault()
                e.stopImmediatePropagation()
                e.stopPropagation()
                scope.state.isHoverDropbox = false
                scope.state.enabledFileDrop = true
                return
            dropboxEl = element[0]
            dropboxEl.addEventListener('dragstart', onDragStart, false)
            dropboxEl.addEventListener('dragenter', onDragEnter, false)
            dropboxEl.addEventListener('dragend', onDragEnd, false)
            return ->
                dropboxEl.removeEventListener('dragstart', onDragStart)
                dropboxEl.removeEventListener('dragenter', onDragEnter)
                dropboxEl.removeEventListener('dragend', onDragEnd)
        scope.$on('$destroy', cleanupDragSection)


        cleanupFileDropzone = do ->
            dropzoneEl = element[0].getElementsByClassName('drag-and-drop-zone')[0]
            observer = new DropObserver(dropzoneEl, { type: 'text' })
            observer.onDragLeave ->
                scope.state.isHoverDropbox = false
            observer.onDragOver ->
            observer.onFileDrop (file) ->
                scope.state.isHoverDropbox = false
                scope.state.enabledFileDrop = true
                onReportFileUploaded(file)
            return ->
                observer.detach()
        scope.$on('$destroy', cleanupFileDropzone)


        [openReportUploadPopup, cleanupReportUploadPopup] = do ->
            reader = new FileReader()
            inputElement = element[0].getElementsByClassName('file-input')[0]

            onFileLoaded = (event) ->
                onReportFileUploaded(event.target.result)

            onFileInputChanged = (event) ->
                reader.readAsText(inputElement.files[0]) if event.target.files.length > 0

            reader.addEventListener('load', onFileLoaded)
            inputElement.addEventListener('change', onFileInputChanged)

            open = ->
                inputElement.click()
            cleanup = ->
                reader.removeEventListener('load', onFileLoaded)
                inputElement.removeEventListener('change', onFileInputChanged)
            return [open, cleanup]

        scope.$on('$destroy', cleanupReportUploadPopup)


        onReportFileUploaded = (file) ->
            onError = -> alert('Sorry, but the file is invalid and cannot be imported.')
            try scope.reportTemplates.selected.reports.import(file)
            catch error
                onError()
                throw error

        scope.openReportImportPopup = ($event) ->
            $event.preventDefault()
            $event.stopImmediatePropagation()
            setTimeout(openReportUploadPopup, 0)
            return true

        scope.downloadReportObject = ($event, report) ->
            $event.preventDefault()
            $event.stopImmediatePropagation()
            return if not report
            reports = scope.reportTemplates.selected.reports
            reports.export(report).then ({filename, data}) -> downloadFromJSON(filename, data)

        scope.selectReport = (report) ->
            return if report.id is scope.reportTemplates.selected.reports.viewState.active.id
            scope.reportTemplates.selected.reports.select(report)


module.directive 'reportScheduleInfoTooltip', ($location) ->
    restrict: 'E'
    scope:
        schedules: "="
    replace: true
    template: \
    """
    <div class="report-schedule">
        <div
            ng-mouseover="showTooltip()"
            ng-mouseleave="hideTooltip()"
            class="schedule-tooltip-wrapper">
            <div class="schedule-label">Scheduled</div>
            <div ng-if="enableTooltip" class="schedule-tooltip">
                <div class="schedule-tooltip-header">Used in Schedules:</div>
                <div ng-repeat="schedule in schedules" class="schedule-tooltip-series">
                    <span class="schedule-tooltip-series-label" ng-click="navigateToSchedule(schedule)">- {{ schedule.name }}</span>
                </div>
            </div>
        </div>
    </div>
    """
    link: (scope) ->
        scope.enableTooltip = false
        scope.showTooltip = -> scope.enableTooltip = true
        scope.hideTooltip = -> scope.enableTooltip = false

        scope.navigateToSchedule = (schedule) ->
            $location.path('/reporting/schedules/' + schedule.id)


module.directive 'viewReportingReportsHeader', ($rootScope, promiseTracker, CONFIG, QueryServiceExport, ReportingState) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <header class="view-reporting-list-header view-reporting-reports-header">
        <section class="actions">
            <button class="button-run" hover-msg="{{ errorMessage }}" ng-mouseover="onSaveOrRunHover()" promise-tracker="runReportPromiseTracker" ng-click="actions.run()">Run</button>
            <button class="button-save" hover-msg="{{ errorMessage }}" ng-mouseover="onSaveOrRunHover()" ng-click="actions.save()">Save</button>
            <button class="button-cancel button-bare" ng-click="actions.cancel()" ng-if="reports.isInCreateMode()">Cancel</button>
            <button class="button-copy" ng-click="actions.copy()" ng-if="!reports.isInCreateMode()">Save As...</button>
            <button class="button-reset button-bare" ng-click="actions.reset()" ng-if="!reports.isInCreateMode()">Reset</button>
            <button class="button-delete button-bare" ng-click="actions.delete()" ng-if="!reports.isInCreateMode()">Delete</button>
        </section>
        <hierarchy-select ng-if="hierarchyModel" model="hierarchyModel"></hierarchy-select>
    </header>
    """
    link: (scope) ->
        # FIXME: rootScope
        scope.hierarchyModel = $rootScope.hierarchySelectModel
        scope.runReportPromiseTracker = promiseTracker()

        scope.$watch 'model.selected.reports', (reports) ->
            scope.reports = reports

        scope.errorMessage = ""
        scope.onSaveOrRunHover = ->
            scope.errorMessage = do ->
                return "" if not ReportingState.report.flags.isInvalid
                invalidFields = Object.keys(ReportingState.report.invalidFields).filter (key) -> ReportingState.report.invalidFields[key]
                return "Invalid or missing field(s): #{invalidFields.join(', ')}."

        getReportFilename = (report, response) ->
            DEFAULT_LABEL = "Report Export"
            timestamp = moment().format('YYYY-MM-DD HHmmss')
            label = report?.label or DEFAULT_LABEL
            label = label.replace(/&/g, 'and')
            label = label.replace(/[^A-Z0-9\-_ ]/gi, '')
            label = label.replace(/ +/g, ' ')
            label = label.trim()
            label = DEFAULT_LABEL if label.length is 0
            label = label.slice(0, 200)
            return "42 - #{label} - #{timestamp}.#{response.type}"

        scope.actions =
            save: ->
                return if not ReportingState.report.flags.hasUnsavedChanges
                return if ReportingState.report.flags.isInvalid
                return scope.reports.save()
            reset: ->
                return if not ReportingState.report.flags.hasUnsavedChanges
                return scope.reports.reset()
            cancel: ->
                return scope.reports.cancel()
            copy: ->
                return scope.reports.copy()
            delete: ->
                return if not window.confirm("""
                Are you sure you want to delete this report?
                This cannot be un-done.
                """)
                return scope.reports.delete()
            run: ->
                return if ReportingState.report.flags.isInvalid
                {report, promise} = scope.reports.run()
                scope.runReportPromiseTracker.addPromise do ->
                    promise.then (response) ->
                        data = {host:CONFIG.services.query.host, data:response}
                        filename = getReportFilename(report, response)
                        QueryServiceExport.downloadAs(filename)(data)
                    .catch (error) ->
                        alert "Could not run report due to error... sorry!"


module.directive 'reportInfoEditor', (ReportsModel) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="report-info report-info-editor">
        <main>
            <label class="report-label">
                <span>Report Name</span>
                <input type="text" ng-model="reports.viewState.active.label"></input>
            </label>
            <label class="report-description">
                <span>Description</span>
                <textarea ng-model="reports.viewState.active.description"></textarea>
            </label>
        </main>
    </article>
    """
    link: (scope) ->
        scope.$watch 'model.selected.reports', (reports) ->
            scope.reports = reports


module.directive 'reportEditor', () ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="report-editor" ng-if="model.selected.reports.viewState.active">
        <report-info-editor model="model"></report-info-editor>
        <report-params-editor model="model"></report-params-editor>
    </article>
    """


module.directive 'scheduleReportEditor', ->
    restrict: "E"
    scope:
        model:    "="
        schedule: "="
    replace: true
    template: \
    """
    <article class="report-viewer">
        <schedule-report-template-editor model="model" schedule="schedule"></schedule-report-template-editor>
        <report-params-viewer ng-if="model.hasReportId(model.selected.reports.viewState.active.id)" params="model.selected.reports.getActiveParams()"></report-params-viewer>
    </article>
    """


module.directive 'scheduleReportTemplateEditor', ($location, ReportingState) ->
    restrict: "E"
    scope:
        model:    "="
        schedule: "="
    replace: true
    template: \
    """
    <article class="report-info report-info-viewer">
        <main ng-if="model.hasReports()">
            <label class="report-template" ng-class="{error:model.selectedReportIsInvalid()}">
                <span>What is the report to be scheduled?</span>
                <p class="error-message">
                The report that was previously selected was deleted. Please select a new report.
                </p>
                <schedule-report-template-select model="model"></report-template-editor>
            </label>
            <label class="report-description" ng-if="model.selected.reports.viewState.active.description">
                <span>Description</span>
                <p>{{ reports.viewState.active.description }}</p>
            </label>
        </main>
        <main ng-if="!model.hasReports()">
            <label class="report-description error">
                <span>No reports available!</span>
                <p>You must create a report before you can save this schedule.</p>
            </label>
        </main>
        <aside>
            <button class="button-delete" ng-if="showDeleteButton()" ng-click="delete()">
                <span>Remove</span>
            </button>
            <!--
            <aside ng-if="model.selected.reports.viewState.active">
            <button class="button-edit" ng-click="edit()">Edit Report</button>
            <button class="button-copy" ng-click="copy()">Copy Report</button>
            <button class="button-create" ng-click="create()">Create Report</button>
            -->
        </aside>
    </article>
    """
    link: (scope) ->
        postEdit = (report) ->
            ReportingState.models.schedules.viewState.selected.data.reportId = report.id
            $location.path('/reporting/schedules')
        scope.$watch 'model.selected.reports', (reports) ->
            scope.reports = reports
        scope.create = ->
            scope.reports.create({postEdit})
            $location.path('/reporting/reports')
        scope.edit = ->
            scope.reports.edit({postEdit})
            $location.path('/reporting/reports')
        scope.copy = ->
            scope.reports.copy({postEdit})
            $location.path('/reporting/reports')
        scope.delete = ->
            scope.schedule.reportTemplatesListModel.remove(scope.model)
            scope.schedule.reportTemplatesListModel.add() if scope.schedule.reportTemplatesListModel.available.length is 0
        scope.showDeleteButton = ->
            scope.schedule.reportTemplatesListModel.available.length > 1


module.directive 'scheduleReportTemplateSelect', ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="schedule-report-template-select">
        <select ng-options="report as (report.label) group by (report.template.label) for report in view.available" ng-model="view.selected">
            <option value="" ng-if="!view.selected">Select a report...&nbsp;</option>
        </select>
    </article>
    """
    link: (scope) ->
        scope.view = {modelHash:null}

        modelChanged = ->
            current = do ->
                return null if not scope.model
                return Utils.Object.hash getAvailable(scope.model)
            previous = scope.view.modelHash
            scope.view.modelHash = current
            return current is previous

        getAvailable = (model) ->
            _.flatten model.available.map (template) ->
                reports = template.reports.viewState.available or []
                reports.map (report) -> {id:report.id, label:report.label, template:{id:template.id, label:template.label}}

        scope.$watch 'view.selected', (selected) ->
            return if not selected
            scope.model.selectByReportId(selected.id)

        scope.$watch 'model.selected.reports.viewState.active.id', (reportId, prev) ->
            return if not scope.model?.selected
            return if reportId is prev
            report = _.find scope.view.available, (x) -> x.id is reportId
            report = report or scope.view.available?[0]
            scope.view.selected = report if report

        scope.$watch modelChanged, ->
            scope.view.available = do ->
                return [] if not scope.model
                return getAvailable(scope.model)
            scope.view.selected = do ->
                return null if not scope.model?.selected
                reportId = scope.model.selected.reports.viewState.active.id
                report = _.find scope.view.available, (x) -> x.id is reportId
                return report or scope.view.available[0]


module.directive 'viewReportingSchedules', (ReportingState, CronExpressionUtils) ->
    restrict: 'E'
    scope:
        model: '='
    template: \
    """
    <article class="view view-reporting view-reporting-list view-reporting-schedules" ng-class="{invalid:ReportingState.schedule.flags.isInvalid, unsaved:ReportingState.schedule.flags.hasUnsavedChanges}">
        <div class="loadable" ng-class="{loading: model === null}"></div>
        <aside><schedules-list model="model"></schedules-list></aside>
        <main ng-show="model.viewState.active">
            <view-reporting-schedules-header ng-if="model" model="model"></view-reporting-schedules-header>
            <main>
                <article class="schedule-editor">
                    <article class="schedule-info schedule-info-editor">
                        <main>
                            <label class="schedule-name">
                                <span>Label</span>
                                <input type="text" ng-model="model.viewState.active.label"></input>
                            </label>
                            <div class="row-schedule-expression" ng-class="{error:!model.viewState.active.expression}">
                                <label>
                                    <span>Frequency</span>
                                    <span ng-if="translatedExpression" class="hint">({{ translatedExpression }})</span>
                                </label>
                                <span class="hint error-message" ng-if="errorMessage">{{ errorMessage }}</span>
                                <!-- <span class="hint">({{ model.viewState.active.expression }})</span> -->
                                <schedule-expression-editor
                                    ng-if="model.viewState.active"
                                    model="model.viewState.active"
                                    error-message="errorMessage">
                                </schedule-expression-editor>
                            </div>
                            <label class="schedule-timezone">
                                <span>Timezone</span>
                                <span class="hint">(current time: {{ currentTime }})</span>
                                <select ng-options="x.id as (x.label) group by (x.group) for x in model.TIMEZONES" ng-model="model.viewState.active.timezone"></select>
                            </label>
                        </main>
                    </article>
                </article>
                <section class="report-templates">
                    <section class="report-template" ng-repeat="reportTemplates in model.reportTemplatesListModel.available track by reportTemplates.id">
                        <schedule-report-editor model="reportTemplates" schedule="model"></schedule-report-editor>
                    </section>
                    <section class="report-templates-actions">
                        <div class="ui-button" ng-if="showReportAddButton()" ng-click="model.reportTemplatesListModel.add()">
                            <i class="icon-plus-circled"></i>
                            <span>Add Report</span>
                        </div>
                    </section>
                </section>
                <schedule-target-editor model="model.viewState.active.data.target"></schedule-target-editor>
            </main>
        </main>
    </article>
    """
    link: (scope) ->
        scope.ReportingState = ReportingState
        scope.translatedExpression = null

        scope.$watch 'model.hasUnsavedChanges()', (unsavedChanges) ->
            ReportingState.schedule.flags.hasUnsavedChanges = unsavedChanges

        scope.$watch 'model.isInvalid()', (invalid) ->
            ReportingState.schedule.flags.isInvalid = invalid

        scope.$watch 'model.viewState.active', ((active) ->
            scope.currentTime = do ->
                timezone = active?.timezone
                return null if not timezone
                return moment.tz(timezone).format('MMMM DD, h:mma Z')
            scope.translatedExpression = do ->
                expression = active?.expression
                return try CronExpressionUtils.cronToNaturalLanguage(expression) or null
        ), true

        hashReportTemplatesList = ->
            return if not scope.model?.reportTemplatesListModel
            result = scope.model.reportTemplatesListModel.available.map (x) -> x.selected?.reports?.viewState?.selected?.id
            return result.join('')

        hashSelectedReports = ->
            reportIds = scope.model?.viewState.active?.data.reportId ? []
            reportIds = [reportIds] if _.isString(reportIds)
            return reportIds.join('')

        scope.$watch hashSelectedReports, () ->
            scope.model?.updateReportTemplatesFromModel()

        scope.$watch hashReportTemplatesList, (hash) ->
            return if not hash
            scope.model?.updateModelFromReportTemplates()

        scope.showReportAddButton = ->
            available = scope.model?.reportTemplatesListModel?.available
            return false if not available or available.length is 0
            last = available[available.length-1]
            return last?.selected?.reports?.viewState.selected


module.directive 'scheduleEditor', ->
    restrict: "E"
    scope:
        model: "="
    # replace: true
    template: \
    """
    """

module.directive 'scheduleListItem', (CronExpressionUtils, ReportingCalendarModel) ->
    restrict: "E"
    scope:
        schedule: "="
        selected: "="
        view: "="
    replace: true
    template: \
    """
    <li class="schedule" ng-class="{selected: selected}">
        <h1 class="schedule-label">{{ schedule.label }}</h1>
        <section class="schedule-report-labels" ng-class="{error: getView(schedule).reportLabels.length == 0}">
            <h2 class="schedule-report-error">No reports assigned!</h2>
            <h2 ng-if="getView(schedule).reportLabelsHeader">{{ getView(schedule).reportLabelsHeader }}</h2>
            <span class="schedule-report-label" ng-repeat="label in getView(schedule).reportLabels track by $index">
                {{ label }}
            </span>
        </section>
        <span class="schedule-expression-label">{{ getScheduleExpressionLabel(schedule) }}</span>
    </li>
    """
    link: (scope, element) ->

        scope.getView = (schedule) ->
            scope.view[schedule.id]

        scope.getScheduleExpressionLabel = (schedule) ->
            CronExpressionUtils.expressionToNaturalLanguage(schedule.expression)

        scope.$watch 'selected', ->
            return if not scope.selected
            listContainerBottomHeigth = element.parent()[0].getBoundingClientRect().bottom
            elementBottomHeight = element[0].getBoundingClientRect().bottom
            element[0].scrollIntoView() if elementBottomHeight > listContainerBottomHeigth



module.directive 'schedulesList', ($location, CronExpressionUtils) ->
    restrict: "E"
    scope:
        schedules: "=model"

    template: \
    """
    <article class="list-container schedules list-container-schedules" ng-if="schedules">
        <article class="list-action list-action-create schedule schedule-create" ng-if="!schedules.isInCreateMode()" ng-click="createNewSchedule()">
            <div class="ui-button">
                <i class="icon-plus-circled"></i>
                <span>Create Schedule</span>
            </div>
        </article>

        <article class="list-action list-action-create schedule schedule-create"
            ng-if="schedules.isInCreateMode()"
            ng-click="schedules.cancel()">
            <div class="ui-button">
                <i class="icon-cancel-circled"></i>
                <span>Cancel</span>
            </div>
        </article>

        <article class="list-action list-action-search"
            ng-if="schedules.available.length > 1 && !schedules.isInCreateMode()"
            ng-class="{filtered:scheduleFilter.label}">
            <i class="icon-cancel-circled" ng-click="scheduleFilter.label = ''" ng-show="scheduleFilter.label"></i>
            <input ng-model="scheduleFilter.label" type="text" placeholder="Filter..." />
            <i class="icon-search"></i>
        </article>

        <ul class="schedules-list">
            <li class="schedule schedule-new selected" ng-if="schedules.isInCreateMode()">
                <h1 class="schedule-label">{{ schedules.viewState.active.label }}</h1>
                <span class="schedule-report-label" ng-repeat="label in getReportLabels(schedules)">{{ label }}</span>
                <span class="schedule-expression-label">{{ getScheduleExpressionLabel(schedules.viewState.active) }}</span>
            </li>
            <schedule-list-item
                ng-repeat="schedule in schedules.available | filter:scheduleFilter | orderBy:'createdAt':-1"
                ng-click="selectSchedule(schedule)"
                schedule="schedule"
                view="view.schedules"
                selected="schedule.id === schedules.viewState.active.id">
            </schedule-list-item>
        </ul>
    </article>
    """
    link: (scope) ->
        scope.view = {schedules:{}}

        scope.createNewSchedule = ->
            scope.schedules.create()

        scope.scheduleFilter = {label:''}

        scope.selectSchedule = (schedule) ->
            if schedule.id isnt scope.schedules.viewState.active.id
                $location.path(scope.schedules.getScheduleLink(schedule), false)
                scope.schedules.select(schedule)

        scope.getScheduleExpressionLabel = (schedule) ->
            CronExpressionUtils.expressionToNaturalLanguage(schedule.expression)

        getReportLabels = (schedule, schedules) ->
            reportIds = schedule.data.reportId
            reports = schedules.reportTemplatesListModel.getReportDataById(reportIds)
            return reports.map (x) -> x.label

        scope.getView = (schedule) ->
            scope.view.schedules[schedule.id]

        watchReportLabels = ->
            result = _.flatten (scope.schedules?.available or []).map (schedule) ->
                labels = getReportLabels(schedule, scope.schedules)
                return [schedule.id].concat(labels)
            return result.join('')

        scope.$watch watchReportLabels, ->
            scope.view.schedules = (scope.schedules?.available or []).reduce ((result, schedule) ->
                labels = getReportLabels(schedule, scope.schedules)
                result[schedule.id] =
                    reportLabels: labels
                    reportLabelsHeader: do ->
                        return 'Reports' if labels.length > 1
                        return 'Report'  if labels.length is 1
                        return null
                return result
            ), {}

module.directive 'viewReportingSchedulesHeader', ($timeout, promiseTracker, ReportingState) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <header class="view-reporting-list-header view-reporting-list-schedules-header">
        <section class="actions">
            <button class="button-run" hover-msg="{{ errorMessage }}" ng-mouseover="onSaveOrRunHover()" promise-tracker="runScheduleTracker" ng-click="actions.run()">Run</button>
            <button class="button-save" hover-msg="{{ errorMessage }}" ng-mouseover="onSaveOrRunHover()" ng-click="actions.save()" promise-tracker="updateScheduleTracker">Save</button>
            <button class="button-cancel button-bare" ng-click="actions.cancel()" ng-if="model.isInCreateMode()">Cancel</button>
            <button class="button-copy" ng-click="actions.copy()" ng-if="!model.isInCreateMode()">Save As...</button>
            <button class="button-reset button-bare"  ng-click="actions.reset()" ng-if="!model.isInCreateMode()">Reset</button>
            <button class="button-delete button-bare" ng-click="actions.delete()" ng-if="!model.isInCreateMode()">Delete</button>
            <button class="button-bare" ng-click="actions.toggleActive()" ng-if="!model.isInCreateMode()" promise-tracker="updateScheduleTracker">{{activeButtonLabel}}</button>
        </section>
    </header>
    """
    link: (scope) ->
        scope.runScheduleTracker = promiseTracker()
        scope.updateScheduleTracker = promiseTracker({ activationDelay: null })

        scope.errorMessage = ""
        scope.onSaveOrRunHover = ->
            scope.errorMessage = do ->
                return "" if not ReportingState.schedule.flags.isInvalid
                invalidFields = scope.model.getInvalidFields()
                return "Invalid or missing field(s): #{Object.keys(invalidFields).join(', ')}."
        scope.$watch 'model.viewState.active.active', (isActive) ->
            $timeout (->
                scope.activeButtonLabel = do ->
                    return 'Activate' if not isActive
                    return 'Deactivate'
            ), 50
            scope.actions =
                save: ->
                    return if not ReportingState.schedule.flags.hasUnsavedChanges
                    return if ReportingState.schedule.flags.isInvalid
                    scope.updateScheduleTracker.addPromise do ->
                        scope.model.save().catch((error) -> console.error(error))

                reset: ->
                    return if not ReportingState.schedule.flags.hasUnsavedChanges
                    return scope.model.reset()
                cancel: ->
                    return scope.model.cancel()
                copy: ->
                    return scope.model.copy()
                delete: ->
                    return if not window.confirm("""
                    Are you sure you want to delete this schedule?
                    This cannot be un-done.
                    """)
                    return scope.model.delete()
                toggleActive: ->
                    scope.updateScheduleTracker.addPromise do ->
                        scope.model.toggleActiveState()
                run: ->
                    return if ReportingState.schedule.flags.isInvalid
                    scope.runScheduleTracker.addPromise do ->
                        scope.model.run()
                        .then(-> alert("The schedule was run successfully!"))
                        .catch (error) ->
                            console.error('Error occurred after running schedule:', error)
                            alert("Could not run schedule due to error... sorry!")


module.directive 'scheduleInfoEditor', (CronExpressionUtils) ->
    restrict: "E"
    scope:
        model: "="
        reports: "="
    template: \
    """
    <article class="schedule-info schedule-info-editor">
        <main>
            <label class="schedule-name">
                <span>Label</span>
                <input type="text" ng-model="model.viewState.active.label"></input>
            </label>
            <div class="row-schedule-expression" ng-class="{error:!model.viewState.active.expression}">
                <label>
                    <span>Frequency</span>
                    <span ng-if="translatedExpression" class="hint">({{ translatedExpression }})</span>
                </label>
                <span class="hint error-message" ng-if="errorMessage">{{ errorMessage }}</span>
                <!-- <span class="hint">({{ model.viewState.active.expression }})</span> -->
                <schedule-expression-editor model="model.viewState.active" reports="reports" error-message="errorMessage"></schedule-expression-editor>
            </div>
            <label class="schedule-timezone">
                <span>Timezone</span>
                <span class="hint">(current time: {{ currentTime }})</span>
                <select ng-options="x.id as (x.label) group by (x.group) for x in model.TIMEZONES" ng-model="model.viewState.active.timezone"></select>
            </label>
        </main>
    </article>
    """
    link: (scope) ->
        scope.naturalLanguageExpression = null
        scope.$watch('model.viewState', ->
            timezone = scope.model?.viewState?.active?.timezone
            scope.currentTime = do ->
                return null if not timezone
                return moment.tz(timezone).format('MMMM DD, h:mma Z')
            expression = scope.model?.viewState?.active?.expression
            scope.naturalLanguageExpression = try CronExpressionUtils.expressionToNaturalLanguage(expression)
        , true)



module.constant 'ExpressionLevels', do ->
    DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    SPECIFIC_DAY = (day) -> "Every #{day}"
    EVERY_DAY          : 'Every Day'
    FIRST_DAY_OF_MONTH : 'Every First Day of Month (Gregorian)'
    CRON               : 'Cron'
    DAYS               : [...DAYS]
    SPECIFIC_DAYS      : DAYS.map(SPECIFIC_DAY)
    SPECIFIC_DAY       : SPECIFIC_DAY
    AT_TIME            : 'At Time'
    EVERY_HOUR         : 'Every Hour'


module.service 'CronExpressionUtils', (ExpressionLevels) ->
    # FIXME: refactor this thing
    EVERY_DAY          : ExpressionLevels.EVERY_DAY
    FIRST_DAY_OF_MONTH : ExpressionLevels.FIRST_DAY_OF_MONTH
    CRON               : ExpressionLevels.CRON
    DAYS               : ExpressionLevels.DAYS
    SPECIFIC_DAYS      : ExpressionLevels.SPECIFIC_DAYS
    SPECIFIC_DAY       : ExpressionLevels.SPECIFIC_DAY
    AT_TIME            : ExpressionLevels.AT_TIME
    EVERY_HOUR         : ExpressionLevels.EVERY_HOUR


    normalizeCron: (cron) ->
        cron = Utils.copy(cron or {})
        cron.minute = 0 if not cron.minute or cron.minute is '*'
        cron.hour       ?= '*'
        cron.dayOfMonth ?= '*'
        cron.month      ?= '*'
        cron.dayOfWeek  ?= '*'
        return cron

    timeToCron: (time) ->
        return null if not time
        matches = time.match(RegularExpressions.TIME)?[1..]
        return null if not matches
        tokens = matches.map (x) -> (x?.replace?(/\s/g, '')) or x
        [hour, minute, amPm] = [parseInt(tokens[2]), tokens[3]?.replace(':', ''), tokens[4].toLowerCase()]
        hour = do ->
            return 0         if hour is 12 and amPm is 'am'
            return hour + 12 if hour isnt 12 and amPm is 'pm'
            return hour
        minute = if minute then parseInt(minute) else 0
        return {minute, hour, dayOfMonth:'*', month:'*', dayOfWeek:'*'}

    cronToTime: (cron) ->
        return null if not cron
        return null if cron.hour is '*'
        {minute, hour} = cron
        [m, h, amPm] = do ->
            [m, h] = [minute, hour].map (x) -> Math.max(0, parseInt(x))
            [m, h] = [Math.min(59, m), Math.min(23, h)]
            m = do ->
                return "" if m is 0
                return ":0#{m}" if m <= 9
                return ":#{m}"
            return [m, h-12, 'pm'] if h > 12
            return [m, h, 'pm']    if h is 12
            return [m, 12, 'am']   if h is 0
            return [m, h, 'am']
        return "#{h}#{m}#{amPm}"

    cronToExpression: (cron) ->
        {minute, hour, dayOfMonth, month, dayOfWeek} = cron
        return "#{minute} #{hour} #{dayOfMonth} #{month} #{dayOfWeek}"

    expressionToCron: (expression) ->
        return null if not expression
        expression = expression.replace(/s+/g, ' ').trim()
        matches = expression.match(RegularExpressions.CRON)?[1..]
        return null if not matches
        [minute, hour, dayOfMonth, month, dayOfWeek] = matches.map (x) ->
            return x if x is '*'
            return parseInt(x)
        return {minute, hour, dayOfMonth, month, dayOfWeek}

    cronToLevels: (cron) ->
        return [@CRON] if not cron
        {minute, hour, dayOfMonth, month, dayOfWeek} = cron
        return [ExpressionLevels.FIRST_DAY_OF_MONTH, ExpressionLevels.AT_TIME] if dayOfMonth is 1 and month is '*'
        return [ExpressionLevels.CRON]                                         if dayOfMonth isnt '*' or month isnt '*'
        return [ExpressionLevels.EVERY_DAY, ExpressionLevels.AT_TIME]          if minute isnt '*' and hour isnt '*' and dayOfWeek is '*'
        return [ExpressionLevels.EVERY_DAY, ExpressionLevels.EVERY_HOUR]       if minute isnt '*' and hour is '*' and dayOfWeek is '*'
        dayLabel = ExpressionLevels.DAYS[parseInt(dayOfWeek)]
        if dayLabel
            return [ExpressionLevels.SPECIFIC_DAY(dayLabel), ExpressionLevels.AT_TIME]    if minute isnt '*' and hour isnt '*' and dayOfWeek isnt '*'
            return [ExpressionLevels.SPECIFIC_DAY(dayLabel), ExpressionLevels.EVERY_HOUR] if minute isnt '*' and hour is '*' and dayOfWeek isnt '*'
        return [ExpressionLevels.CRON]

    expressionToNaturalLanguage: (expression) ->
        cron = @expressionToCron(expression)
        return @cronToNaturalLanguage(cron)

    cronToNaturalLanguage: (cron) ->
        return null if not cron
        return try cronstrue.toString(cron, { verbose: true })


module.filter 'humanizeCron', (CronExpressionUtils) -> (expression) ->
    return CronExpressionUtils.expressionToNaturalLanguage(expression)


module.directive 'scheduleExpressionEditor', (CronExpressionUtils, ExpressionLevels) ->
    restrict: "E"
    scope:
        model: "="
        reports: "="
        errorMessage: "="
    # replace: true
    template: \
    """
    <article class="schedule-expression-editor" ng-class="{invalid:isInvalid}">

        <select ng-options="x for x in options" ng-model="levels[0]"></select>

        <div class="level-every-day" ng-if="levels[0] == ExpressionLevels.EVERY_DAY">
            <select ng-options="x for x in [ExpressionLevels.AT_TIME, ExpressionLevels.EVERY_HOUR]" ng-model="levels[1]"></select>

            <!-- <minute> <hour> * * * -->
            <input class="input-time"
                ng-if="levels[1] == ExpressionLevels.AT_TIME"
                placeholder="ex: 8:05am"
                type="text"
                ng-model="view.time"
                ng-pattern="regex.TIME">
            </input>

            <!-- <minute> * * * * -->
            <div ng-if="levels[1] == ExpressionLevels.EVERY_HOUR">
                <span>at minute</span>
                <input class="input-minute" data-tooltip="{{ errorMessage }}" type="number" ng-model="view.minute" min="0" max="59" integer></input>
            </div>
        </div>

        <div class="level-every-day" ng-if="levels[0] == ExpressionLevels.FIRST_DAY_OF_MONTH">
            <select ng-options="x for x in [ExpressionLevels.AT_TIME]" ng-model="levels[1]"></select>

            <!-- <minute> <hour> * * * -->
            <input class="input-time" placeholder="ex: 8:05am" type="text" ng-model="view.time" ng-pattern="regex.TIME"></input>
        </div>

        <!-- <minute> <hour> <day> * * -->
        <div class="level-day" ng-if="dayOfWeekSelected()">

            <select ng-options="x for x in [ExpressionLevels.AT_TIME, ExpressionLevels.EVERY_HOUR]" ng-model="levels[1]"></select>

            <!-- <minute> <hour> * * * -->
            <input class="input-time" type="text" ng-if="levels[1] == ExpressionLevels.AT_TIME" ng-model="view.time" ng-pattern="regex.TIME"></input>

            <!-- <minute> * * * * -->
            <div ng-if="levels[1] == ExpressionLevels.EVERY_HOUR">
                <span>at minute</span>
                <input class="input-minute" type="number" ng-model="view.minute" min="0" max="59" placeholder="0" integer></input>
            </div>
        </div>

        <!-- catchall -->
        <div class="level-cron" ng-if="levels[0] == ExpressionLevels.CRON">
            <input class="input-cron" type="text" ng-model="model.expression"></input>
        </div>
    </article>
    """
    link: (scope) ->
        scope.regex =
            TIME:  RegularExpressions.TIME
            CRON:  RegularExpressions.CRON
            EMAIL: RegularExpressions.EMAIL

        scope.options = [
            ExpressionLevels.EVERY_DAY,
            ...ExpressionLevels.SPECIFIC_DAYS,
            ExpressionLevels.FIRST_DAY_OF_MONTH,
            ExpressionLevels.CRON,
        ]
        scope.ExpressionLevels = _.cloneDeep(ExpressionLevels)

        getCronSelectedDayIndex = ->
            index = ExpressionLevels.SPECIFIC_DAYS.indexOf(scope.levels[0])
            return "*" if index is -1
            return index

        getCronSelectedDayOfMonth = (cron) ->
            # if scope.levels and scope.levels[0] and scope.levels[0] is 'Every First Day of Month'
            #     return '1'
            return cron?.dayOfMonth ? '*'

        scope.$watch 'model', (model) ->
            return if not model
            scope.view ?= {}
            scope.view.cron = CronExpressionUtils.expressionToCron(model.expression)
            scope.levels = CronExpressionUtils.cronToLevels(scope.view.cron)

        scope.dayOfWeekSelected = ->
            return scope.levels?[0] and ExpressionLevels.SPECIFIC_DAYS.includes(scope.levels[0])

        scope.$watch 'levels[0]', (level0) ->
            return if not level0
            if level0 is ExpressionLevels.CRON
                delete scope.levels[1]
                return
            scope.view.cron ?= {}
            scope.view.cron.month = '*'
            scope.view.cron.dayOfMonth = do ->
                return 1 if level0 is ExpressionLevels.FIRST_DAY_OF_MONTH
                return '*'
            scope.view.cron = CronExpressionUtils.normalizeCron(scope.view.cron or {})
            scope.view.cron.dayOfWeek = getCronSelectedDayIndex()
            scope.view.time = CronExpressionUtils.cronToTime(scope.view.cron)
            scope.levels = CronExpressionUtils.cronToLevels(scope.view.cron)
            scope.view.minute = scope.view.cron?.minute

        scope.$watch 'levels[1]', (level1) ->
            return if not level1
            if level1 is ExpressionLevels.AT_TIME
                scope.view.cron ?= {}
                scope.view.cron = CronExpressionUtils.normalizeCron(scope.view.cron)
                scope.view.cron.hour = 8 if scope.view.cron.hour is '*'
                scope.view.time = CronExpressionUtils.cronToTime(scope.view.cron)
            if level1 is ExpressionLevels.EVERY_HOUR
                minute = scope.view.cron?.minute
                dayOfWeek = getCronSelectedDayIndex()
                scope.view.cron = CronExpressionUtils.normalizeCron({minute, dayOfWeek})
                scope.view.time = CronExpressionUtils.cronToTime(scope.view.cron)
                scope.view.minute = 0

        scope.$watch 'view.cron', ((cron) ->
            return if not scope.model
            isInvalid = not cron or _.some _.values(cron), (x) -> _.isNull(x) or _.isUndefined(x)
            scope.view.cron = CronExpressionUtils.normalizeCron(cron) if not isInvalid
            scope.model.expression = do ->
                return scope.model.expression if scope.levels?[0] is ExpressionLevels.CRON
                return null if isInvalid
                return CronExpressionUtils.cronToExpression(cron)
            return if isInvalid or scope.levels[0] is ExpressionLevels.CRON
            scope.view.cron.dayOfWeek = getCronSelectedDayIndex()
            scope.view.cron.dayOfMonth = getCronSelectedDayOfMonth(scope.view.cron)
            scope.view.time = do ->
                return null if scope.levels[1] isnt ExpressionLevels.AT_TIME
                return CronExpressionUtils.cronToTime(cron)
            scope.view.minute = scope.view.cron?.minute
            return if scope.levels[1] is ExpressionLevels.AT_TIME
            scope.levels = CronExpressionUtils.cronToLevels(cron)
        ), true

        scope.$watch 'model.expression', (expression) ->
            scope.isInvalid = not expression
            return if not scope.model
            scope.view.cron = (try CronExpressionUtils.expressionToCron(expression)) or null
            if scope.view.cron
                scope.view.time = CronExpressionUtils.cronToTime(scope.view.cron)
                scope.view.minute = scope.view.cron.minute

        scope.$watch 'view.minute', (minute, prevMinute) ->
            return if not scope.model
            return if scope.levels[1] isnt ExpressionLevels.EVERY_HOUR
            before = minute
            minute = do ->
                return null if _.isUndefined(minute) or _.isNull(minute)
                result = parseInt(minute)
                return null if _.isNaN(result)
                return result
            scope.view.cron = CronExpressionUtils.normalizeCron({minute}) if not scope.view.cron
            scope.view.cron.dayOfWeek = getCronSelectedDayIndex()
            scope.view.cron.minute = minute

        scope.$watch 'view.time', (time) ->
            return if not scope.model
            return if scope.levels[1] isnt ExpressionLevels.AT_TIME
            cronTime = CronExpressionUtils.timeToCron(time)
            return scope.view.cron = null if not cronTime
            {minute, hour} = cronTime
            dayOfWeek = getCronSelectedDayIndex()

            cronToNormalize = {hour, minute, dayOfWeek}
            cronToNormalize.dayOfMonth = getCronSelectedDayOfMonth(scope.view.cron)

            scope.view.cron = CronExpressionUtils.normalizeCron(cronToNormalize)

        scope.$watch 'isInvalid', (isInvalid) ->
            scope.errorMessage = do ->
                return "" if not isInvalid or not scope.levels
                return "Invalid minute, must be a number between 0 and 59" if scope.levels[1] is ExpressionLevels.EVERY_HOUR
                return "Invalid time, must be formatted like '9am' or '6:45pm'" if scope.levels[1] is ExpressionLevels.AT_TIME


module.factory 'ListModel', (Utils) ->
    class ListModel

        @MODES: {'SELECT', 'EDIT', 'CREATE'}

        constructor: (@tracker) ->
            throw new Error("Missing required `@_fetch` method in subclass.") if not @_fetch
            @viewState = {}

        init: ->
            @_fetch().then (x) => @setAvailable(x)

        refresh: ->
            @init()

        setAvailable: (available) ->
            @available = Utils.copy(available or [])

            if not @viewState or not @viewState.active
                selected = @available[0]?.id
                @selectById(selected)
                return

            @viewState.available = Utils.copy(@available)

            savedInAvailableIndex = do =>
                return -1 if not @viewState.saved
                return Utils.indexOf @viewState.available, (x) => x.id is @viewState.saved.id

            savedInAvailable = @viewState.available[savedInAvailableIndex]

            savedChanged = do =>
                # If we don't have the saved item...
                not savedInAvailable or \
                    # If we have the selected item but it has changed...
                    Utils.object.hash(@viewState.saved) is Utils.object.hash(savedInAvailable)

            if savedChanged
                @viewState.selected = savedInAvailable
                @viewState.saved = Utils.copy(@viewState.selected)
            else
                @viewState.saved = Utils.copy(savedInAvailable)
                @viewState.available[savedInAvailableIndex] = @viewState.selected

            if @viewState.mode isnt ListModel.MODES.CREATE
                @viewState.active = @viewState.selected

            delete @viewState.saved?.$$hashKey

        getActiveParams: ->
            return @viewState.active?.data

        reorder: (oldIndex, newIndex) ->
            return if oldIndex is newIndex

            @available = Utils.move(@available, oldIndex, newIndex)
            @_saveOrder @available

        select: (item) ->
            return @selectById(item.id) if item isnt null
            @viewState ?= {}
            @viewState.mode = ListModel.MODES.SELECT
            @viewState.selected = null
            @viewState.active = null
            @viewState.saved = null
            return null

        selectById: (id) ->
            available = Utils.copy(@available)
            selected = _.find available, (x) -> x.id is id
            return null if not selected
            @viewState ?= {}
            @viewState.mode = ListModel.MODES.SELECT
            @viewState.available = available
            @viewState.selected = selected
            @viewState.active = @viewState.selected
            @viewState.saved = Utils.copy(@viewState.selected)
            delete @viewState.saved?.$$hashKey
            delete @viewState.postEdit
            return Utils.copy(@viewState.selected)

        hasUnsavedChanges: ->
            return true if @viewState.mode is ListModel.MODES.CREATE
            return false if (@viewState.available or []).length is 0

            [selected, saved] = [@viewState.selected, @viewState.saved]

            return true if _.some [selected, saved], (x) -> not x

            [selected, saved] = [selected, saved].map (x) ->
                result = Utils.copy(x)
                delete result.$$hashKey
                delete result.updatedAt
                return result
            return Utils.object.hash(selected) isnt Utils.object.hash(saved)

        # Reset the selected item to the saved value
        reset: ->
            @select(@viewState.saved)

        edit: ({postEdit} = {}) ->
            @select(@viewState.active)
            @viewState.postEdit = postEdit
            return @viewState.active

        copy: ({postEdit} = {}) ->
            return if not @_copy
            @viewState.mode = ListModel.MODES.CREATE
            @viewState.active = @_copy(Utils.copy @viewState.active)
            @viewState.available = Utils.copy(@available)
            @viewState.selected = _.find @viewState.available, (x) => x.id is @viewState.selected.id
            @viewState.saved = Utils.copy(@viewState.selected)
            delete @viewState.saved?.$$hashKey
            @viewState.postEdit = postEdit
            return @viewState.active

        delete: (item) ->
            item ?= @viewState.active
            index = Utils.indexOf @viewState.available, (x) -> x.id is item.id
            return if index is -1
            @_delete(item).then =>
                @viewState.available = Utils.removeAt @viewState.available, index
                @available = Utils.copy(@viewState.available)
                @viewState.mode = ListModel.MODES.SELECT
                @viewState.selected = @viewState.available[0]
                @viewState.saved = Utils.copy(@viewState.selected)
                @viewState.active = @viewState.selected

        # Create a new item
        create: ({postEdit} = {}) ->
            return if not @_create
            @viewState.mode = ListModel.MODES.CREATE
            @viewState.postEdit = postEdit
            @viewState.active = @_create()
            @viewState.available = Utils.copy(@available)
            @viewState.selected = _.find(@viewState.available, (x) => x.id is @viewState.selected.id) or null
            @viewState.saved = (try Utils.copy(@viewState.selected)) or null
            delete @viewState.saved?.$$hashKey
            return @viewState.active

        import: (data) ->
            return if not @_import
            @viewState.mode = ListModel.MODES.CREATE
            @viewState.active = @_import(data)
            @viewState.available = Utils.copy(@available)
            @viewState.selected = _.find(@viewState.available, (x) => x.id is @viewState.selected.id) or null
            @viewState.saved = (try Utils.copy(@viewState.selected)) or null
            delete @viewState.saved?.$$hashKey
            return @viewState.active

        # Cancel create
        cancel: ->
            @_cancel?()
            @select(@viewState.selected)

        _savePreprocess: ->
            return if not @hasUnsavedChanges()
            @viewState.mode = ListModel.MODES.SELECT
            item = @viewState.active
            delete item.$$hashKey
            activeIndex = Utils.indexOf @available, (x) -> x.id is item.id
            return [activeIndex, item]

        # Save changes to the selected, edited or created set
        save: ->
            [activeIndex, item] = @_savePreprocess()
            savePromise = do =>
                return @_save(item) if activeIndex is -1
                return @_update(item)
            savePromise
            .catch (error) ->
                console.error "Could not save item:", item
                throw error
            .then ([available, item]) =>
                @viewState.postEdit?(item)
                @setAvailable(available)
                @selectById(item.id)

        isInCreateMode: ->
            @viewState.mode is ListModel.MODES.CREATE


module.factory 'ReportTemplatesModel', ($q, Utils, PromiseGuard, SchedulingServiceAPI, ReportsModel) ->

    class ReportTemplatesModel

        @Create: ->
            model = new ReportTemplatesModel()
            model.init().then -> return model

        @Fetch: do ->
            guard = new PromiseGuard()
            fetchJobs = ->
                (new SchedulingServiceAPI).then (api) -> api.jobs.list()
            return (reportsModelData) -> guard do ->
                $q.all([
                    fetchJobs()
                    CurrenciesService.fetch()
                ]).then ([jobs, currencies]) ->
                    result = []
                    jobs = jobs.map (job) ->
                        id:          job.id
                        label:       job.label or 'Untitled'
                        description: job.description or ""
                        params: (job.data?.params or []).filter (x) ->
                            return true if x isnt 'currency'
                            return true if currencies.length > 1
                            return true if currencies?[0].id isnt 'usd'
                            return false
                        reports: new ReportsModel(job.id)
                    $q.all((jobs.map (x) -> x.reports.init())).then -> return jobs

        constructor: ->
            @id = Utils.uuid()

        init: -> ReportTemplatesModel.Fetch().then (available) =>
            @available = available or []
            @selected  = if @available.length is 1 then @available[0] else null

        refresh: -> ReportTemplatesModel.Fetch().then (available) =>
            @available = available
            @selected  = _.find(available, (x) -> x.id is @selected.id) or null
            @selected  = available[0] if @available.length is 1 and not @selected

        hasReports: ->
            for template in @available
                return true if template.reports?.viewState?.available?.length > 0
            return false

        selectedReportIsInvalid: ->
            return not @selected?.reports.viewState.active and @selected?.reports.viewState.saved

        hasReportId: (reportId) ->
            report = @findReportById(reportId)
            return !!report

        findReportById: (reportId) ->
            for template in @available
                for report in template.reports.available
                    return report if report?.id is reportId
            return null

        select: (reportTemplate) ->
            @selected = reportTemplate

        selectByReportId: (reportId) ->
            if not reportId
                @selected = null
                return null
            for template in @available
                report = template.reports.selectById(reportId)
                continue if not report
                return @select(template)
            @selected = null
            return null


module.factory 'ReportsModel', ($q, Utils, CONFIG, StorageAPI, SchedulingServiceAPI, ListModel) ->
    class ReportsModel extends ListModel
        @STORAGE_KEY: 'reports'

        @Create: ->
            model = new ReportsModel(arguments...)
            model.init().then -> model

        @Fetch: (templateId) ->
            StorageAPI(ReportsModel.STORAGE_KEY)
            .then((api) -> api.get())
            .then (templates) ->
                templates = null if _.isArray(templates)
                templates ?= {}
                reports = templates[templateId] or []
                return reports

        constructor: (templateId) ->
            super()
            throw new Error("Missing required `templateId` argument.") if not templateId
            @templateId = templateId

        run: ->
            serializedReport = try @_serialize(report) catch error then console.error(error)
            Analytics.track(Analytics.EVENTS.USER_RUN_REPORT, { report: serializedReport }) if serializedReport
            report = deepStripAngularProperties(@viewState.active)
            report: report
            promise: SchedulingServiceAPI().then((api) ->
                return api.jobs.run(report)
            ).catch (error) ->
                Analytics.track(Analytics.EVENTS.USER_RUN_REPORT_FAILED, { report: serializedReport, error }) if serializedReport
                throw error

        _fetch: ->
            return ReportsModel.Fetch(@templateId)

        _update: (report) ->
            try
                throw new Error("Missing required `report` argument.") if not report
                report = Utils.copy(report)
                report.updatedAt = Date.now()

                try Analytics.track(Analytics.EVENTS.USER_UPDATE_REPORT, {report: @_serialize(report)})
                catch error then console.error(error)

                return @__updateStorage((reports) ->
                    index = Utils.indexOf(reports, (x) -> x.id is report.id)
                    reports[index] = report
                    return reports
                ).then (reports) ->
                    return [reports, Utils.copy(report)]
            catch error
                return $q.reject(error)

        _delete: (report) ->
            return @__updateStorage((reports) ->
                index = Utils.indexOf(reports, (x) -> x.id is report.id)
                reports = Utils.removeAt(reports, index)
                return reports
            ).then (reports) ->
                return [reports, true]

        _save: (report) ->
            try
                report = Utils.copy(report)
                report.createdAt = Date.now()
                report.updatedAt = Date.now()

                try Analytics.track(Analytics.EVENTS.USER_CREATE_REPORT, {report: @_serialize(report)})
                catch error then console.error(error)

                return @__updateStorage((reports) ->
                    return [report, ...reports]
                ).then (reports) ->
                    return [reports, Utils.copy(report)]
            catch error
                return $q.reject(error)


        _serialize: (report = null) ->
            report ?= @viewState.active
            report = deepStripAngularProperties(report)
            report.data.metrics = report.data.metrics.map (metric) ->
                return metric if typeof metric is 'string'
                return metric.field
            return report

        export: (report) -> $q.when do =>
            getFilename = (report) ->
                filename = _.kebabCase(report.label.replace(/&/g,'-and-'))
                filename = "42-report-#{filename}.json"
                return filename
            getData = (report, userId, organizationId) =>
                report = @_serialize(report)
                meta = {type:'report', version:1, organizationId, createdAt: Date.now(), exportedByUserId:userId}
                data = {data:{report}, meta}
                return data
            return $q.all([
                AuthServiceAPI.getOrganization(),
                AuthServiceAPI.getUser()
            ]).then ([orgId, user]) ->
                payload = getData(report, user.id, orgId)
                Analytics.track(Analytics.EVENTS.USER_EXPORT_REPORT, {report: payload.data.report})
                filename = getFilename(report)
                return {filename, data:payload}

        _import: (payload) ->
            try
                try
                    payload = JSON.parse(payload) if typeof payload is 'string'
                catch error
                    console.error(error)
                    throw new Error("Report import error: bad json.")
                if not _.isPlainObject(payload)
                    throw new Error("Report import error: must be a string or object")
                { data, meta } = payload
                if _.isEmpty(data or {}) or _.isEmpty(meta or {}) or _.isEmpty(data.report or {})
                    throw new Error("Invalid uploaded report data: #{JSON.stringify(payload)}")
                if CONFIG.organization.id isnt meta.organizationId
                    throw new Error("Invalid uploaded report: organization mismatch.")
                Analytics.track(Analytics.EVENTS.USER_IMPORT_REPORT, {report: data.report})
                return {
                    ...@_copy(data.report),
                    label: data.report.label
                }
            catch error
                Analytics.track(Analytics.EVENTS.USER_IMPORT_REPORT_FAILED, {error})
                throw error

        _copy: (report) ->
            id:          Utils.uuid()
            createdAt:   null
            updatedAt:   null
            templateId:  report.templateId
            description: report.description
            label:       "#{report.label} (COPY)"
            data:        Utils.copy(report.data)

        _create: ->
            id:          Utils.uuid()
            createdAt:   null
            updatedAt:   null
            templateId:  @templateId
            description: ''
            label:       'Untitled'
            data:        {}

        _saveOrder: (reports) ->
            @__updateStorage -> return reports

        __updateStorage: (cb) ->
            StorageAPI(ReportsModel.STORAGE_KEY).then (api) =>
                api.get().then (templates) =>
                    templates = null if Array.isArray(templates)
                    templates ?= {}
                    reports = templates[@templateId] or []
                    $q.when(cb(reports)).then (reports) =>
                        templates[@templateId] = reports
                        return api.put(templates).then -> Utils.copy(reports)


module.service 'TIMEZONES', ($filter) ->
    WHITELIST = ['etc','america','europe']
    seen = {}
    result = []
    for timezone in moment.tz.names()
        [group, label] = timezone.split('/')
        label = group if not label
        skipFormatting = (label.toUpperCase() is label)
        if not seen[group+label]
            result.push({group, label:timezone, id:timezone})
            seen[group+label] = true
    result = result.filter (x) -> WHITELIST.includes(x.group.toLowerCase())
    result = _.sortBy result, (x) -> x.label
    return result


module.service 'EmailAddressMultiFieldParser', ->
    parse: (addresses) ->
        return null if not addresses
        tokens = do ->
            return addresses if _.isArray(addresses)
            return _.flatten (addresses or "").split('\n').map (x) -> x.split(',')
        return tokens.map((x) -> x.trim()).filter((x)-> x).map (x) ->
            value: x
            isValid: RegularExpressions.EMAIL.test(x)


module.factory 'SchedulesReportListModel', (Utils, ReportsModel, ReportTemplatesModel) ->

    class SchedulesReportListModel

        constructor: (reportTemplateData, reportsData) ->
            @reportTemplateData = reportTemplateData
            @reportsData = reportsData
            @available = []

        add: (index) ->
            model = new ReportTemplatesModel()
            model.available = Utils.copy(@reportTemplateData)
            model.available.forEach (job) =>
                job.reports = new ReportsModel(job.id)
                job.reports.setAvailable(Utils.copy @reportsData[job.id])
            index = @available.length if not _.isNumber(index)
            @available = Utils.insertAt(@available, index, model)
            return model

        push: ->
            @add()

        pop: ->
            @removeAt(@available.length-1)

        getReportDataById: (reportId) ->
            reports = _.flatten Object.keys(@reportsData).map (x) => @reportsData[x]
            reportId = [reportId] if not _.isArray(reportId)
            return _.compact reportId.map (id) -> _.find reports, (report) -> report.id is id

        remove: (reportTemplates) ->
            index = Utils.indexOf @available, (x) -> x.id is reportTemplates.id
            console.log "Removing `#{reportTemplates.id}` at index `#{index}`."
            if index is -1
                console.warn "Could not find report template `#{reportTemplates.id}`"
                return @
            return @removeAt(index)

        removeAt: (index) ->
            @available = Utils.removeAt(@available, Math.max(0, index))
            return @

        reset: ->
            @available = []
            return @


module.factory 'SchedulesModel', ($q, $timeout, Utils, TIMEZONES, SchedulingServiceAPI, EmailAddressMultiFieldParser, ListModel, SchedulesReportListModel, ReportsModel, ReportTemplatesModel) ->
    serializeEmailAddresses = (addresses) ->
        EmailAddressMultiFieldParser.parse(addresses)?.filter((x) -> x.isValid).map((x) -> x.value)

    class SchedulesModel extends ListModel

        @Create: (reportTemplates) ->
            SchedulesModel.CreateReportTemplatesListModel(reportTemplates)
            .then (reportTemplatesList) ->
                model = new SchedulesModel(reportTemplatesList)
                model.init().then -> model

        @CreateReportTemplatesListModel: (reportTemplates) ->
            reportTemplatesDataPromise = do ->
                return $q.when(reportTemplates.available) if reportTemplates
                return ReportTemplatesModel.Fetch()
            reportTemplatesDataPromise
            .then (reportTemplateData) ->
                result = {}
                $q.all(reportTemplateData.map (template) ->
                    ReportsModel.Fetch(template.id).then (x) -> result[template.id] = x
                ).then -> [reportTemplateData, result]
            .then ([reportTemplateData, reportsData]) ->
                reportTemplatesListModel = new SchedulesReportListModel(reportTemplateData, reportsData)
                reportTemplatesListModel.add()
                return reportTemplatesListModel

        TIMEZONES: Utils.copy(TIMEZONES)

        constructor: (reportTemplatesListModel) ->
            super()
            @reportTemplatesListModel = reportTemplatesListModel

        getScheduleLink: (schedule) ->
            return '/reporting/schedules/' + schedule.id

        getInvalidFields: ->
            return [] if not @viewState.active
            result =
                'Timezone':         @viewState.active.timezone
                'Expression':       @viewState.active.expression
                'Report':           @viewState.active.data.reportId
                'Email Recipients': @viewState.active.data.target.recipients
                'Email BCC':        @viewState.active.data.target.bcc
            # 'Email Subject':    @viewState.active.data.target.subject
            # 'Email Body':       @viewState.active.data.target.body
            validKeys = Object.keys(result).filter (key) ->
                value = result[key]
                if key is 'Email Recipients'
                    value = serializeEmailAddresses(value)
                if key is 'Email BCC'
                    return _.every EmailAddressMultiFieldParser.parse(value), (x) -> x.isValid
                return value.length > 0 if _.isArray(value)
                return !!value if not _.isString(value)
                value = value.trim()
                return value.length > 0
            validKeys.forEach (key) -> delete result[key]
            return result

        isInvalid: ->
            Object.keys(@getInvalidFields()).length > 0

        hasUnsavedChanges: ->
            return true if @viewState.mode is ListModel.MODES.CREATE
            return false if (@viewState.available or []).length is 0
            [selected, saved] = [@viewState.selected, @viewState.saved].map (x) ->
                result = Utils.copy(x)
                ['recipients', 'cc', 'bcc'].forEach (key) ->
                    try
                        result.data.target[key] ?= ''
                        result.data.target[key] = result.data.target[key].join('\n') if _.isArray(result.data.target[key])
                        result.data.target[key] = result.data.target[key].trim()
                delete result.$$hashKey
                delete result.updatedAt
                delete result.active
                return result
            return Utils.object.hash(selected) isnt Utils.object.hash(saved)

        updateModelFromReportTemplates: ->
            @viewState.active.jobId = "metrics-breakdown"
            @viewState.active.data.reportId = @reportTemplatesListModel.available.map((template) ->
                return template.selected?.reports.viewState.selected.id
            ).filter((x) -> x)
            return

        updateReportTemplatesFromModel: (reportIds) ->
            reportIds = reportIds or @viewState.active?.data?.reportId
            reportIds = [reportIds] if not _.isArray(reportIds)
            @reportTemplatesListModel.reset()
            reportIds.forEach (reportId, index) =>
                @reportTemplatesListModel.add()
                reportTemplates = @reportTemplatesListModel.available[index]
                reportTemplates.selectByReportId(reportId)
            @reportTemplatesListModel.add() if reportIds.length is 0
            return

        run: ->
            return @_serialize(@viewState.active).then (schedule) ->
                Analytics.track(Analytics.EVENTS.USER_RUN_SCHEDULE, {schedule})
                SchedulingServiceAPI().then (api) ->
                    return api.schedules.run(schedule)

        _fetch: ->
            SchedulingServiceAPI().then (api) ->
                return $q.all([
                    api.schedules.list()
                    SchedulesModel.CreateReportTemplatesListModel()
                    AuthServiceAPI.getUser()
                ])
            .then ([schedules, templatesList, user]) =>
                schedules = schedules
                    .filter((x) -> not _.isNil(x.data?.userId))
                    .filter((x) -> x.data?.userId is user.id)
                    .map (x) =>
                        reportIds = x.data.reportId
                        reportIds = [reportIds] if not _.isArray(reportIds)
                        @reportTemplatesListModel = templatesList
                        @reportTemplatesListModel.reset()
                        x.data.reportId = reportIds.filter (reportId) =>
                            reportTemplates = @reportTemplatesListModel.push()
                            if reportTemplates.hasReportId(reportId)
                                reportTemplates.selectByReportId(reportId)
                                return true
                            # @reportTemplatesList.pop()
                            return false
                        @reportTemplatesListModel.push() if x.data.reportId.length is 0
                        return x
                return _.sortBy schedules, (x) -> -1 * moment.utc(x.createdAt).unix()

        _delete: (schedule) ->
            id = schedule.id
            return SchedulingServiceAPI().then (api) -> api.schedules.delete(id)

        _serialize: (schedule, userId, organizationId) ->
            try
                schedule ?= @viewState.active
                schedule = Utils.copy(schedule)
                schedule.jobId = "metrics-breakdown"
                schedule.data ?= {}
                schedule.data.target = try @_serializeTarget(schedule.data.target)
                return $q.all([
                    $q.when(userId         or AuthServiceAPI.getUser().then((x) -> x.id)),
                    $q.when(organizationId or AuthServiceAPI.getOrganization()),
                ]).then ([userId, organizationId]) =>
                    schedule.data.userId = userId
                    schedule.data.organizationId = organizationId
                    schedule.data.reportId = _.compact @reportTemplatesListModel.available.map (x) ->
                        x.selected?.reports?.viewState.selected.id
                    return schedule
            catch error
                return $q.reject(error)

        _serializeTarget: (target) ->
            recipients: serializeEmailAddresses(target.recipients)
            bcc:        serializeEmailAddresses(target.bcc)
            body:       target.body?.trim() or ""
            # subject:    target.subject?.replace(/\n/g, ' ').trim()
            filename:   target.filename?.replace(/\n/g, ' ').trim()

        _save: (schedule) ->
            return @_api(schedule, 'create')

        _update: (schedule) ->
            return @_api(schedule, 'update')

        _api: (schedule, method) -> @_serialize(schedule).then (schedule) =>
            throw new Error("Cannot #{method} schedule: Missing `schedule.data.reportId` value.") if not schedule.data.reportId
            throw new Error("Cannot #{method} schedule: Missing `schedule.jobId` value.")         if not schedule.jobId
            event = do ->
                return Analytics.EVENTS.USER_CREATE_SCHEDULE if method is 'create'
                return Analytics.EVENTS.USER_UPDATE_SCHEDULE if method is 'update'
            Analytics.track(event, { schedule }) if event
            SchedulingServiceAPI().then (api) ->
                return api.schedules[method](schedule)
            .then (schedule) =>
                return @_fetch().then (schedules) -> [schedules, schedule]
            .then ([schedules, schedule]) =>
                @updateReportTemplatesFromModel()
                return [schedules, schedule]

        _copy: (schedule) ->
            label:       schedule.label
            expression:  schedule.expression
            timezone:    schedule.timezone
            jobId:       schedule.jobId
            data:        Utils.copy(schedule.data)

        _create: ->
            @reportTemplatesListModel.reset()
            @reportTemplatesListModel.add()
            label:      "Untitled Schedule"
            expression: "30 8 * * *"
            timezone:   moment.tz.guess()
            jobId:      null
            active: 1
            data:
                userId: null
                reportId: []
                target: {}

        toggleActiveState: ->
            @viewState.active.active = do (schedule = @viewState.active) ->
                return 1 if schedule.active is 0
                return 0
            {active, saved} = @viewState
            delete active.$$hashKey
            saved = Utils.copy(saved)
            saved.active = active.active
            @_update(saved).then ([available, updated]) =>
                active.active = updated.active
                active.objectVersion = updated.objectVersion
                @viewState.postEdit?(active)
                @setAvailable(available)

        save: ->
            [activeIndex, item] = @_savePreprocess()
            active = null
            savePromise = do =>
                return @_save(item) if activeIndex is -1
                savedViewState = Utils.copy(@viewState.saved)
                active = @viewState.active
                @viewState.saved = Utils.copy(@viewState.active)
                return @_update(item).catch (error) =>
                    @viewState.saved = savedViewState
                    throw error
            savePromise
                .catch (error) ->
                    console.error "Could not save item:", item
                    throw error
                .then ([available, item]) =>
                    @viewState.postEdit?(item)
                    @setAvailable(available)
                    if active and active.id is item.id
                        active.objectVersion = item.objectVersion
                    else
                        @selectById(item.id)


module.directive 'reportParamsViewer', (ReportParamsFilterItemsModel, ReportParamsFilterStoresModel) ->
    restrict: "E"
    scope:
        params: "="
    replace: true
    template: \
    """
    <article class="report-params-container report-params-viewers">
        <report-params-viewer-filter ng-repeat="model in models.filters" model="model"></report-params-viewer-filter>
        <report-params-viewer-timerange params="params"></report-params-viewer-timerange>
        <report-params-viewer-group-by params="params"></report-params-viewer-group-by>
        <report-params-viewer-metric-select params="params"></report-params-viewer-metric-select>
    </article>
    """
    link: (scope) ->
        scope.models = {}
        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)


            scope.models =
                filters: [
                    new ReportParamsFilterItemsModel(params)
                    new ReportParamsFilterStoresModel(params)
                ]


module.directive 'reportParamsEditor', () ->
    restrict: "E"
    scope:
        model: "="
    template: \
    """
    <article class="report-params-editors" ng-if="model.selected.params.length > 0">
        <section class="report-params-editor-group" ng-repeat="availableParams in editorGroups">
            <div ng-repeat="paramType in availableParams" class="report-params">
                <ng-switch on="paramType">
                    <report-params-metric-select     params="params" ng-switch-when="metrics"></report-params-metric-select>
                    <report-params-column-properties params="params" ng-switch-when="columns"></report-params-column-properties>
                    <report-params-column-style      params="params" ng-switch-when="columnStyle"></report-params-column-style>
                    <report-params-timerange         params="params" ng-switch-when="timerange"></report-params-timerange>
                    <report-params-group-by          params="params" ng-switch-when="hierarchyStore"></report-params-group-by>
                    <report-params-sort              params="params" ng-switch-when="sort"></report-params-sort>
                    <report-params-filter-stores     params="params" ng-switch-when="filterStores"></report-params-filter-stores>
                    <report-params-filter-items      params="params" ng-switch-when="filterItems"></report-params-filter-items>
                    <report-params-currency          params="params" ng-switch-when="currency"></report-params-currency>
                </ng-switch>
            </div>
        </section>
    </article>
    """
    link: (scope) ->
        scope.$watch 'model.selected', (selected) ->
            available = selected?.params or []
            scope.editorGroups = [
                _.intersection(available, ['filterStores', 'filterItems'])
                _.difference(available, ['filterStores', 'filterItems'])
            ].filter (x) -> x.length > 0
        scope.$watch 'model.selected.reports.getActiveParams()', (params) ->
            scope.params = params


module.factory 'ReportParamsFilterModel', ($q, ReportSmartGroups, SchedulingHierarchy) ->
    class ReportParamsFilterModel
        constructor: (@title, @descriptor, @filters) ->
            @descriptor = _.cloneDeep(@descriptor)

        init: ->
            @refresh()

        refresh: ->
            $q.when(SchedulingHierarchy.fetch()).then (properties) =>
                @_updateFromProperties(properties)
                return

        reset: ->
            Object.keys(@filters).forEach (key) => delete @filters[key]

        editFilter: ->
            popupState = _.cloneDeep({@descriptor, @filters})
            ReportSmartGroups.popup.open popupState, (update) =>
                Object.keys(@filters).forEach (key) => delete @filters[key]
                Object.keys(update).forEach (key) -> delete update[key] if _.isEmpty(update[key])
                # Using Object.assign to make sure we keep the same @filters ref
                # Unclear if this is required by the rest of the code, but this was the old behavior
                Object.assign(@filters, update)
                return

        _updateFromProperties: (properties) ->
            properties = properties.map((p) -> p.id)
            availableTables = _.uniq(properties.map((id) -> id.split('.')[0]))
            unsupportedTables = _.difference(Object.keys(@filters), availableTables)
            unsupportedTables.forEach (t) => delete @filters[t]
            Object.keys(@filters).forEach (key) =>
                @filters[key].$and = (@filters[key].$and ? []).filter (property) =>
                    propertyId = "#{key}.#{Object.keys(property)[0]}"
                    return properties.includes(propertyId)
                delete @filters[key].$and if @filters[key].$and.length is 0
            return



module.factory 'ReportParamsFilterItemsModel', (ReportParamsFilterModel, CONFIG) ->
    return (params) ->
        throw new Error("Missing required `params` argument.") if not params
        descriptor = _.cloneDeep(SmartGroupFilterDescriptors.get(CONFIG).find (x) -> x.id is 'items')
        params.filterItems ?= {}
        label = CONFIG.items?.label or "Item Filter"
        return new ReportParamsFilterModel(label, descriptor, params.filterItems)


module.factory 'ReportParamsFilterStoresModel', (ReportParamsFilterModel, CONFIG) ->
    return (params) ->
        throw new Error("Missing required `params` argument.") if not params
        descriptor = _.cloneDeep(SmartGroupFilterDescriptors.get(CONFIG).find (x) -> x.id is 'stores')
        params.filterStores ?= {}
        label = CONFIG.stores?.label or "Store Filter"
        return new ReportParamsFilterModel(label, descriptor, params.filterStores)


module.service 'QueryObjectFilterUtils', ->
    deleteValue: (filters, x) ->
        throw new Error("Missing required `value.collection` property.") if not x.collection
        throw new Error("Missing required `value.value` property.")      if not x.value
        collection = filters[x.collection]?.$and
        return if not collection
        propertyIndex = collection.findIndex (group) -> Object.keys(group)?[0] is x.property
        return if propertyIndex is -1
        property = do ->
            key = Object.keys(collection[propertyIndex])
            return if x.exclude then collection[propertyIndex]?[key]?.$nin else collection[propertyIndex]?[key]?.$in
        return if not property
        valueIndex = property.findIndex (value) -> value is x.value
        return if valueIndex is -1
        property.splice(valueIndex, 1)
        collection.splice(propertyIndex, 1) if property.length is 0
        delete filters[x.collection]  if collection.length is 0
        return filters
    getValues: (filters) ->
        return [] if _.isNil(filters)
        return Object.keys(filters).reduce ((result, collection) ->
            (filters[collection]?.$and or []).forEach (group) ->
                property = Object.keys(group)?[0]

                includeValues = (group[property]?.$in or [])
                includeValues.forEach (value) -> result.push({collection, property, value, exclude: false})

                excludeValues = (group[property]?.$nin or [])
                excludeValues.forEach (value) -> result.push({collection, property, value, exclude: true})
            return result
        ), []


module.directive 'reportParamsFilterSelected', (Utils, SchedulingHierarchy, QueryObjectFilterUtils) ->
    restrict: 'E'
    scope:
        filters: '='
    replace: true
    template: \
    """
    <article class="report-params-filter-selected">
        <ul class="pellets">
            <li ng-repeat="x in view.selected" class="pellet" ng-class="{'exclude': x.exclude }" ng-click="delete(x)">
                <span class="property">{{ x.label }}</span> |
                <span class="value">{{ x.value }}</span>
                <i class="close icon-cancel-circled"></i>
            </li>
        </ul>
    </article>
    """
    link: (scope) ->

        scope.getFilterHash = ->
            return null if not scope.filters
            return Utils.object.hash(scope.filters)

        scope.delete = (x) ->
            return QueryObjectFilterUtils.deleteValue(scope.filters, x)

        getHierarchyById = do ->
            cache = null
            return ->
                cache ?= SchedulingHierarchy.fetch()
                return cache.then (result) -> _.keyBy(Utils.copy(result), (x) -> x.id)

        getSelected = ->
            selected = QueryObjectFilterUtils.getValues(scope.filters)
            selected = selected.map (x) ->
                return {...x, id: "#{x.collection}.#{x.property}", label: x.collection}
            return getHierarchyById()
            .then (hierarchy) ->
                selected.forEach (x) -> x.label = hierarchy[x.id]?.label or x.label
                return selected
            .catch (error) ->
                console.error "Could not get hierarchy to update filter display:"
                console.error error
                return selected

        scope.view = {}
        scope.$watch 'getFilterHash()', ->
            return (scope.view.selected = []) if not scope.filters
            return getSelected().then (x) -> scope.view.selected = x


module.constant 'PreventScrollEventBubblingEventHandler', (e) ->
    # Usage:
    # $(element).on 'DOMMouseScroll mousewheel', PreventScrollEventBubblingEventHandler
    up = false
    up = e.originalEvent.wheelDelta / -1 < 0 if e.originalEvent?.wheelDelta
    up = e.originalEvent.deltaY < 0          if e.originalEvent?.deltaY
    up = e.originalEvent.detail < 0          if e.originalEvent?.detail


module.directive 'reportParamsFilterStores', ($rootScope, ReportParamsFilterStoresModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params-filter-stores">
        <report-params-filter model="model"></report-params-filter>
    </article>
    """
    link: (scope) ->
        scope.model = null
        scope.hierarchyModel = $rootScope.hierarchySelectModel
        scope.$watch 'hierarchyModel.view.selected', (selected) ->
            return if not selected
            scope.model?.reset()
        scope.$watch 'params', (params) ->
            scope.model = do ->
                return null if not params
                return new ReportParamsFilterStoresModel(params)


module.directive 'reportParamsFilterItems', ($rootScope, ReportParamsFilterItemsModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params-filter-items">
        <report-params-filter model="model"></report-params-filter>
    </article>
    """
    link: (scope) ->
        scope.model = null
        scope.hierarchyModel = $rootScope.hierarchySelectModel
        scope.$watch 'hierarchyModel.view.selected', (selected) ->
            return if not selected
            scope.model?.reset()
        scope.$watch 'params', (params) ->
            return (scope.model = null) if not params
            scope.model = new ReportParamsFilterItemsModel(params)
            scope.model.init()


module.directive 'reportParamsFilter', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-filter">
        <header>
            <h1>{{ model.title }}</h1>
            <section class="hint-right">
                <button class="button-edit-filters" ng-click="model.editFilter()">
                    <i class="icon-pencil"></i>
                    Edit Filter
                </button>
                <button class="button-bare action" ng-click="model.reset()">reset</button>
            </section>
        </header>
        <main>
            <report-params-filter-selected filters="model.filters"></report-params-filter-selected>
        </main>
    </article>
    """


module.directive 'reportParamsColumnProperties', (ReportParamsColumnPropertiesModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-column-properties">
        <header>
            <h1>How should the report's columns be broken down?</h1>
        </header>
        <main>
            <ul class="pellets">
                <li class="pellet null" ng-click="select()" ng-class="{selected:!model.selected}">None</li>
                <li class="pellet" ng-click="select(item)" ng-class="{selected:item === model.selected}" ng-repeat="item in model.available">
                    {{ item.label }}
                </li>
            </ul>
        </main>
    </article>
    """
    link: (scope) ->
        scope.select = (item) ->
            scope.model.selected = item or null
            scope.model.updateParamsFromModel()

        scope.$watch 'params', (params) ->
            if not scope.model
                scope.model = new ReportParamsColumnPropertiesModel(params)
                scope.model.init()
            else
                scope.model.params = params
                scope.model.updateModelFromParams()


module.directive 'reportParamsViewerFilter', (Utils, QueryObjectFilterUtils, SchedulingHierarchy) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="report-params-filter report-params-viewer-filter">
        <article class="report-params" ng-if="view.selected && view.selected.length > 0">
            <header>
                <h1>{{ model.title }}</h1>
            </header>
            <main>
                <article class="report-params-filter-selected">
                    <ul class="pellets">
                        <li class="pellet" ng-repeat="x in view.selected" ng-class="{'exclude': x.exclude }">
                            <span class="property">{{ x.label }}</span> |
                            <span class="value">{{ x.value }}</span>
                        </li>
                    </ul>
                </article>
            </main>
        </article>
    </article>
    """
    link: (scope) ->
        getHierarchyById = do ->
            cache = null
            return ->
                cache ?= SchedulingHierarchy.fetch()
                return cache.then (result) -> _.keyBy(Utils.copy(result), (x) -> x.id)

        getSelected = ->
            selected = QueryObjectFilterUtils.getValues(scope.model.filters)
            selected = selected.map (x) ->
                return {...x, id: "#{x.collection}.#{x.property}", label: x.collection}
            return getHierarchyById()
            .then (hierarchy) ->
                selected.forEach (x) -> x.label = hierarchy[x.id]?.label or x.label
                return selected
            .catch (error) ->
                console.error "Could not get hierarchy to update filter display:"
                console.error error
                return selected

        scope.view = {}
        scope.$watch 'model.filters', ->
            return (scope.view.selected = []) if not scope.model?.filters
            return getSelected().then (x) -> scope.view.selected = x


module.directive 'reportParamsViewerHierarchy', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="report-params-hierarchy">
        <report-params-viewer-hierarchy-list list-title="Selected Properties" properties="model.selected"></report-params-hierarchy-list>
    </article>
    """

module.directive 'reportParamsHierarchy', (ReportingState) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="report-params-hierarchy" ng-class="{invalid:isInvalid}">
        <report-params-hierarchy-list class="report-params-hierarchy-list-available" list-title="Available Properties" help="{{ text }}" properties="model.properties" model="model" on-click-method="add">
            <span ng-if="model.selected.length == 0">Drag or double-click on the properties below to select them.</span>
            <button class="button-bare" ng-if="model.selected.length >= 1" ng-click="model.resetSelected()">reset</button>
        </report-params-hierarchy-list>
        <report-params-hierarchy-list class="report-params-hierarchy-list-selected" list-title="Selected Properties" properties="model.selected" model="model" on-click-method="remove">
            <button class="button-bare" ng-if="model.selected.length >= 1" ng-click="model.resetSelected()">reset</button>
        </report-params-hierarchy-list>
    </article>
    """
    link: (scope) ->
        scope.$watch 'model.selected.length', ->
            return if not scope.model
            ReportingState.report.updateInvalidFields(scope.model.getInvalidFields())


module.directive 'reportParamsHierarchyList', (Utils) ->
    moved = false

    restrict: 'E'
    scope:
        listTitle:     '@'
        help:          '@'
        properties:    '='
        model:         '='
        onClickMethod: '@'
    transclude: true
    replace: true
    template: \
    """
    <article class="report-params-hierarchy-list">
        <header>
            <h1>{{ listTitle }}</h1>
            <h2 class="hint hint-right" ng-transclude></span>
        </header>
        <main>
            <ul class="properties"
                dnd-list="properties"
                dnd-drop="drop(event, index, item)"
                dnd-inserted="inserted(event, index, item)"
                dnd-horizontal-list="true">
                <li ng-repeat="property in properties"
                    dnd-draggable="property"
                    dnd-effect-allowed="move"
                    dnd-moved="moved($index, event)"
                    ng-double-click="onClick(property)">
                    <article class="property">
                        <span>{{ property.label }}</span>
                        <span class="button-close" ng-click="remove(property)">
                            <i class="icon-cancel"></i>
                            <i class="icon-cancel-circled button-close"></i>
                        </span>
                    </article>
                </li>
            </ul>
        </main>
    </article>
    """
    link: (scope) ->
        scope.remove = (item) ->
            index = scope.properties.findIndex((x) -> x.id is item.id)
            scope.properties = Utils.removeAt(scope.properties, index)
            scope.model.properties.push(item)
        scope.add = (item) ->
            index = scope.model.properties.findIndex (x) -> x.id is item.id
            scope.model.properties = Utils.removeAt(scope.model.properties, index)
            scope.model.selected.push(item)
        scope.onClick = scope[scope.onClickMethod]
        scope.drop = (event, nextIndex, item) ->
            currentIndex = scope.properties.findIndex (x) -> x.id is item.id
            # if the property is in the list already, then we move it
            if currentIndex >= 0
                moved = true
                # The `nextIndex` that's returned by the framework will be wrong if the item is moved
                # after it's current location because it is counting the index of the placeholder element.
                nextIndex = nextIndex - 1 if nextIndex > currentIndex
                scope.properties = Utils.move(scope.properties, currentIndex, nextIndex)
            # Otherwise we just insert the element.
            else
                moved = false
                scope.properties = Utils.insertAt(scope.properties, nextIndex, item)
            return true
        scope.moved = (index) ->
            scope.properties = Utils.removeAt(scope.properties, index) if not moved
            moved = false
            return true


module.directive 'reportParamsViewerGroupBy', ['ReportParamsViewerGroupByModel', (ReportParamsViewerGroupByModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params-viewer report-params-viewer-hierarchy-store">
        <header>
            <h1>How should the report be broken down?</h1>
        </header>
        <main ng-if="model.selected">
            <ul class="properties">
                <li class="ui-pellet active disabled" ng-repeat="property in model.selected">
                    {{ property.label }}
                </li>
            </ul>
        </main>
    </article>
    """
    link: (scope) ->
        scope.model = new ReportParamsViewerGroupByModel()
        scope.$watch 'params', (params) ->
            return if _.isNil(params)
            scope.model.updateModelFromParams(params)
]

# TODO: rename class: report-params-hierarchy-store -> report-params-group-by
module.directive 'reportParamsGroupBy', ($rootScope, $q, ReportParamsGroupByModel) ->
    restrict: 'E'
    scope: params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-hierarchy-store">
        <header>
            <h1>How should the report's rows be broken down?</h1>
        </header>
        <report-params-hierarchy model="model"></report-params-hierarchy>
    </article>
    """
    link: (scope) ->
        watchers = []
        scope.hierarchyModel = $rootScope.hierarchySelectModel

        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)

            watchers.forEach (x) -> x() # unregisters the existing watchers
            watchers = []

            modelPromise = do ->
                if not scope.model
                    scope.model = new ReportParamsGroupByModel(params)
                    scope.model.init()
                else
                    scope.model.resetSelected()
                    scope.model.params = params
                    scope.model.updateModelFromParams()
                    return $q.when()
            modelPromise.then ->
                watchers.push \
                    scope.$watch 'model.selected', ((properties) ->
                        return if _.isUndefined(properties)
                        scope.model.updateParamsFromModel()
                    ), true
                watchers.push \
                    scope.$watch 'params.hierarchyStore', (->
                        scope.model.updateModelFromParams()
                    ), true
                watchers.push \
                    scope.$watch 'hierarchyModel.view.selected.id', (old, id) ->
                        return if not id or old is id
                        scope.model?.resetSelected()
                        scope.model?.refresh()

            .catch (error) ->
                console.error error
                scope.$emit 'report-params-error',
                    error: error
                    message: "Could not load store hierarchy."
                scope.error = true
                throw error


module.directive 'reportParamsCurrency', ($q, ReportParamsCurrencyModel) ->
    restrict: 'E'
    scope: params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-currency">
        <header>
            <h1>What currency do you want to see?</h1>
        </header>
        <select ng-options="x as (x.label) for x in model.available" ng-model="model.selected"></select>
    </article>
    """
    link: (scope) ->
        watchers = []

        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            watchers.forEach (x) -> x() # unregisters the existing watchers
            watchers = []
            modelPromise = do ->
                if not scope.model
                    scope.model = new ReportParamsCurrencyModel(params)
                    scope.model.init()
                else
                    scope.model.resetSelected()
                    scope.model.params = params
                    scope.model.updateModelFromParams()
                    return $q.when()
            modelPromise.then ->
                watchers.push \
                    scope.$watch 'model.selected', ((properties) ->
                        return if _.isUndefined(properties)
                        scope.model.updateParamsFromModel()
                    ), true
                watchers.push \
                    scope.$watch 'params.currency', (->
                        scope.model.refresh()
                    ), true
            .catch (error) ->
                console.error error
                scope.$emit 'report-params-error',
                    error: error
                    message: "Could not load currencies."
                scope.error = true
                throw error


module.factory 'ReportParamsColumnPropertiesModel', (SchedulingHierarchy) ->

    class ReportParamsColumnPropertiesModel

        constructor: (@params) ->
            @available = []
            @selected  = null

        init: ->
            @refresh()

        refresh: ->
            SchedulingHierarchy.fetch().then (properties) =>
                @selected = null
                @available = properties.filter (x) ->
                    return x.id.startsWith('calendar.') and not x.id.includes('calendar.day_of_week_label')
                @updateModelFromParams()

        updateParamsFromModel: ->
            @params.columns = _.compact([@selected?.id])

        updateModelFromParams: ->
            @selected = do =>
                id = @params?.columns?[0]
                return null if not id
                return _.find @available, (x) -> x.id is id


# The params key for the group-by properties of the report.
# Named this way for historical reasons...
PARAMS_KEY_GROUP_BY = 'hierarchyStore'

module.factory 'ReportParamsGroupByModel', ['$q', 'SchedulingHierarchy', ($q, SchedulingHierarchy) ->
    class ReportParamsGroupByModel
        constructor: (@params) ->
            @available   = []
            @properties  = []
            @selected    = []
            @initialized = false
        init: ->
            @refresh()
        refresh: ->
            SchedulingHierarchy.fetch().then (properties) =>
                @initialized = true
                @available = properties
                @properties = properties
                @selected = []
                @updateModelFromParams()
        resetSelected: ->
            @properties = @available
            @selected = []
            @updateParamsFromModel()
        getInvalidFields: ->
            result = {}
            result['Breakdown Properties'] = @selected.length is 0
            return result
        updateParamsFromModel: ->
            @params[PARAMS_KEY_GROUP_BY] = @selected.map (x) -> x.id
            selectedById = _.keyBy @selected, (x) -> x.id
            @properties = @properties.filter (x) -> not selectedById[x.id]
        updateModelFromParams: ->
            availableById = _.keyBy @available, (x) -> x.id
            selectedIds = [...(@params[PARAMS_KEY_GROUP_BY] or [])]
            @selected = selectedIds.flatMap (id) =>
                property = availableById[id]
                return _.cloneDeep(property) if property
                console.error("Property `#{id}` was not found in list of available properties.") if not property
                return []
            selectedById = _.keyBy @selected, (x) -> x.id
            @properties = @properties.filter (x) -> not selectedById[x.id]
]


module.factory 'ReportParamsViewerGroupByModel', ['$q', 'SchedulingHierarchy', ($q, SchedulingHierarchy) ->
    return class ReportParamsViewerGroupByModel
        constructor: ->
            @selected = null

        refresh: ->
            SchedulingHierarchy.fetch().then (properties) =>
                return _.keyBy properties, (x) -> x.id

        updateModelFromParams: (params) ->
            selectedIds = [...(params?[PARAMS_KEY_GROUP_BY])]
            return if _.isEqual(selectedIds, @selectedIds)
            @selectedIds = [...selectedIds]
            @selected = null
            @refresh().then (available) =>
                return if @selected isnt null
                @selected = @selectedIds.flatMap (id) =>
                    property = _.cloneDeep(available[id])
                    return property if property
                    console.error("Property `#{id}` was not found in list of available properties.") if not property
                    return []
            return
]



module.factory 'ReportParamsCurrencyModel', ($q, $rootScope, $log, Utils) ->
    class ReportParamsCurrencyModel
        constructor: (@params) ->
            @available   = []
            @selected    = null
            @initialized = false
        init: ->
            @refresh()
        refresh: ->
            CurrenciesService.fetch().then (currencies) =>
                @initialized = true
                @available = currencies
                @updateModelFromParams()
        resetSelected: ->
            @updateParamsFromModel()
        updateParamsFromModel: ->
            @params.currency = @selected.id
        updateModelFromParams: ->
            selected = _.find @available, (currency) => currency.id is @params.currency
            selected ?= _.find @available, (currency) -> currency.id is $rootScope.currencyModel?.selected?.id
            @selected = selected or @available[0]


module.service 'SchedulingHierarchy', ($q, Hierarchy, HourProperty, CalendarProperties) ->
    fetch: ->
        $q.all([
            Hierarchy.fetch()
            CalendarProperties.fetch()
            HourProperty.fetch()
        ]).then ([hierarchy, calendarProperties, hourProperty]) ->
            calendarProperties ?= []
            hierarchy = [...hierarchy.pebbles]
            hierarchy = [...hierarchy, hourProperty] if hourProperty
            hierarchy = [...hierarchy, ...calendarProperties]
            return hierarchy


module.factory 'PromiseGuard', (Utils) -> return ->
    lastPromiseId = null
    return (promise) ->
        lastPromiseId = promiseId = Utils.uuid()
        promise.then (x) ->
            throw new Error('Function was called before this promise has resolved.') if lastPromiseId isnt promiseId
            return x
