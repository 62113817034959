import { AdsCardHeaderDirectiveFactory } from './ads-card-header.directive';
import { AdsChartDirectiveFactory, AdsSectionChartDirectiveFactory } from './ads-chart.directive';
import { AdsGridDirectiveFactory, AdsSectionGridDirectiveFactory } from './ads-grid.directive';
import { AdsSectionStatsDirectiveFactory, AdsStatsDirectiveFactory } from './ads-stats.directive';
import { AdsControllerFactory, AdsViewFactory } from './ads.controller';
import { AdsConfigServiceFactory, AdsServiceFactory } from './ads.service';

const module = angular.module('42.controllers.ads', []);
module.config(function AdsConfig($routeProvider: angular.route.IRouteProvider, ROUTES: any, CONFIG: any) {
    const routes = CONFIG.routes ? CONFIG.routes?.ads : {};
    const route = _.extend({}, ROUTES.ads, _.pick(routes, 'label', 'url'));
    $routeProvider.when(route.url, route).otherwise({ redirectTo: '/' });
});
module.controller('AdsController', AdsControllerFactory());
module.directive('adsStats', AdsStatsDirectiveFactory());
module.directive('adsCardHeader', AdsCardHeaderDirectiveFactory());
module.directive('adsSectionStats', AdsSectionStatsDirectiveFactory());
module.directive('adsGrid', AdsGridDirectiveFactory());
module.directive('adsSectionChart', AdsSectionChartDirectiveFactory());
module.directive('adsSectionGrid', AdsSectionGridDirectiveFactory());
module.directive('adsChart', AdsChartDirectiveFactory());
module.service('AdsView', AdsViewFactory());
module.service('AdsConfigService', AdsConfigServiceFactory());
module.service('AdsService', AdsServiceFactory());
