export interface IOutsideElementClick {
    (
        scope: angular.IScope,
        element: angular.IRootElementService | HTMLElement,
        handler: (event: JQuery.TriggeredEvent) => void,
    ): () => void;
}

export const OutsideElementClickInstance = () => [
    '$document',
    function OutsideElementClick($document: angular.IDocumentService): IOutsideElementClick {
        const isSelfTarget = ($element: angular.IRootElementService, event: JQuery.TriggeredEvent) => {
            const paths: EventTarget[] = event.originalEvent?.composedPath() ?? [event.target];

            const containsPaths = (element: HTMLElement, paths: EventTarget[]) => {
                return Boolean(
                    paths.find(path => path === element || (path instanceof Node && element.contains(path))),
                );
            };

            return $element.toArray().find(element => containsPaths(element, paths));
        };

        return function OutsideElementClickDirective(
            $scope: angular.IScope,
            element: angular.IRootElementService | HTMLElement,
            handler: (event: JQuery.TriggeredEvent) => void,
        ) {
            const $element = $(element);

            const clickListeners = {
                element: () => {
                    $scope.$apply();
                },
                document: (event: JQuery.TriggeredEvent) => {
                    if ($element[0] && isSelfTarget($element, event)) {
                        return true;
                    }
                    try {
                        handler(event);
                    } catch (error) {
                        console.error(error);
                    } finally {
                        $scope.$apply();
                    }
                },
            };

            $element.on('click', clickListeners.element);
            $document.on('click', clickListeners.document);
            // This is used to catch Dragging Events
            $element.on('mousedown', clickListeners.element);
            $document.on('mousedown', clickListeners.document);

            const cleanup = () => {
                $element.off('click', clickListeners.element);
                $document.off('click', clickListeners.document);
                $element.off('mousedown', clickListeners.element);
                $document.off('mousedown', clickListeners.document);
            };

            $scope.$on('$destroy', () => cleanup());

            return cleanup;
        };
    },
];
