export OverlayDirective = ['$log', ($log) ->
    restrict: 'E'
    scope:
        ngModel: '='
    template: '<div id="overlay" ng-click="clicked()" class="overlay overlay-{{ enabled && \'enabled\' || \'disabled\' }}"></div>'
    replace: true
    link: ($scope, element) ->
        ZINDEX_TIMEOUT = 600

        zIndexTimer = null
        onClick = null

        $element = $(element)

        $scope.clicked = -> onClick?()

        $scope.enabled = false
        $scope.ngModel = do ->
            $target = null

            # options:
            # - targetEl     | the element to focus on
            # - clickHandler | what happens when the overlay is clicked
            enable = (targetEl, clickHandler) ->
                $log.info "Enabling overlay, focus on `#{targetEl}`."
                clearTimeout zIndexTimer
                $target = $(targetEl)
                onClick = clickHandler
                $element.css 'zIndex': 1000
                $target.css 'zIndex':  1001
                $scope.enabled = true

            disable = ->
                $log.info "Disabling overlay."
                $target = null
                $scope.enabled = false
                zIndexTimer = setTimeout resetZIndex, ZINDEX_TIMEOUT

            toggle = ->
                fn = if enabled then disable else enable
                fn arguments...

            resetZIndex = ->
                $target?.css 'zIndex':''
                $element.css 'zIndex':-1

            return {enable, disable, toggle}
]
