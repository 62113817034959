export const DOMParser = globalThis.DOMParser;

export function htmlToPlaintext(value: string, type: DOMParserSupportedType = 'text/html') {
    const parser = new DOMParser();
    return parser.parseFromString(value, type);
}

export function isHTMLElement(node: null | Node | HTMLElement): node is HTMLElement {
    return !!node && 'querySelector' in node;
}
