import moment from 'moment';
import type { IConfigObj, IQuery } from './types';
import type { QueryResultFrom } from './api/api-query-service';
import { QueryServiceAPI } from './api';
import { ConfigAPI } from './config-api';

const MaxTimestampConfigService = {
    get(orgConfig: IConfigObj): string | undefined {
        // throw an error/warning on usage
        const value: unknown = orgConfig.defaults?.maxTimestamp;
        return typeof value === 'string' ? value.toLowerCase() : undefined;
    },
    async fetch() {
        const config = await ConfigAPI.get().then(api => api.organization.get());
        return this.get(config);
    },
};

// REVIEW: what does the scheduler do here????
export const DatabaseTimeSpanService = {
    get(
        timespan: QueryResultFrom<'getFullTimeSpan'>,
        maxTimestamp: string | undefined,
    ): {
        start: string;
        end: string;
    } {
        const { min_timestamp, max_timestamp } = timespan[0] ?? {};
        if (!min_timestamp) throw new Error('Could not get min timestamp from query service.');
        if (!max_timestamp) throw new Error('Could not get max timestamp from query service.');
        const min = moment(min_timestamp);
        const max = maxTimestamp === 'yesterday' ? moment().subtract(1, 'days') : moment(max_timestamp);
        const start = min.format('YYYY-MM-DD');
        const end = max.format('YYYY-MM-DD');
        return { start, end };
    },
    async fetch(query: IQuery = {}) {
        const timespanPromise = QueryServiceAPI.get().then(api => {
            return api.organizations.doQuery({ queryId: 'getFullTimeSpan', query });
        });
        const [timespan, maxTimestamp] = await Promise.all([timespanPromise, MaxTimestampConfigService.fetch()]);
        return this.get(timespan, maxTimestamp);
    },
};
