import _ from 'lodash'


export PeriodComparisonMode = () -> (selection, bounds) ->
    return null if not (selection.start and selection.end)
    comparison =
        start: selection.start.subtract(selection.end.diff(selection.start, 'days')+1, 'days')
        end:   selection.start.subtract(1, 'day')
    return null if comparison.start.lt(bounds.start)
    return comparison


normalizeComparisonModeConfig = (config) ->
    sameWeekdayLY: config?.sameWeekdayLY or false
    restated:      config?.restated or false


YearComparisonModeFactory = (yearCount) -> (config) -> (selection, bounds) ->
    config = normalizeComparisonModeConfig(config)

    {start, end} = selection

    calculateDayOffset = (ty, ly) ->
        return 0 if not config.sameWeekdayLY
        return 2 if ly.date.isLeapYear() and ly.date.month()  < 2
        return 2 if ty.date.isLeapYear() and ty.date.month() >= 2
        return 1

    if not start and not end
        return null

    start = do -> try
        start = start.add(1, 'week') if start.year.restate and start.month.offset > start.year.restateMonth
        start = start.add(1, 'week') if config.restated and start.year.prev.restate
        return start.subtract(yearCount, 'year')

    if not start or start?.lt(bounds.start)
        return null

    end = do -> try
        end = end.add(1, 'week') if end.year.restate and end.month.offset > end.year.restateMonth
        end = end.add(1, 'week') if config.restated and end.year.prev.restate
        return end.subtract(yearCount, 'year')

    if not end or end.gt(bounds.end)
        return null

    startDayOffset = calculateDayOffset(selection.start, start)
    start = start.add(startDayOffset, 'day')

    endDayOffset = calculateDayOffset(selection.end, end)
    end = end.add(endDayOffset, 'day')

    return {start, end}


COMPARISON_MODES =
    'year':   YearComparisonModeFactory(1)
    'year-2': YearComparisonModeFactory(2)
    'year-3': YearComparisonModeFactory(3)
    'period': PeriodComparisonMode

export getComparisonMode = (modeId) ->
    ComparisonMode = COMPARISON_MODES[modeId]
    throw new Error("Unhandled comparison mode: #{modeId}") if not ComparisonMode
    return ComparisonMode

export getComparisonModes = () -> [
    {id:'year',   shortLabel:'Last Year',   label:'This Year vs. Last Year'}
    {id:'year-2', shortLabel:'2 Years Ago', label:'This Year vs. Two Years Ago'}
    {id:'year-3', shortLabel:'3 Years Ago', label:'This Year vs. Three Years Ago'}
    {id:'period', shortLabel:'Last Period', label:'Period over Period'}
]

export ComparisonModeSelectModelFactory = (config, bounds) ->
    config = _.cloneDeep(config)
    bounds = {...bounds}

    return class ComparisonModeSelectModel

        @GetComparisonTimerange: (modeId, selection) ->
            ComparisonMode = getComparisonMode(modeId)
            comparisonMode = ComparisonMode(config)
            return comparisonMode(selection, bounds)

        constructor: (modeId) ->
            @modes = @getModes()
            try @setMode(modeId ? 'year')
            console.warn("Unsupported mode, falling back to default:", modeId) if not @mode
            @mode ?= @modes[0]

        getModes: ->
            return getComparisonModes()

        getMode: ->
            return {...@mode}

        setMode: (modeId) ->
            mode = @modes.find((x) -> x.id is modeId)
            throw new Error("Comparison mode '#{modeId}' not found") if not mode
            @mode = mode
            return @

        getComparisonTimerange: (selection) ->
            return ComparisonModeSelectModel.GetComparisonTimerange(@mode?.id, selection)
