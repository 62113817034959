import { IPromise } from 'angular';
import _ from 'lodash';
import { IQuery, IConfigObj, IMetricDefinition } from '../../lib/types';
import { IQueryServiceAPI } from '../../modules/services/query-service.types';
import { IAdStatsItem } from './ads-stats.directive';
import { remapData } from './ads.helper';

export interface IAdsGridData {
    properties: any;
    property: any;
    availableMetrics: any;
    metrics: any;
    stats: any;
    grid: any;
}

export interface IAdsFetchResponse {
    stats: IAdStatsItem[];
    grid: IAdsGridData;
}
export interface IAdsService {
    fetch: (
        availableMetrics: IMetricDefinition[],
        metrics: IMetricDefinition[],
        config: IAdPlatformConfig,
        property: any,
        query: IQuery,
    ) => IPromise<IAdsFetchResponse>;
    fetchStats: (platform: string, kpis: string[], query: IQuery) => IPromise<IAdStatsItem[]>;
    fetchGrid: (platform: string, query: IQuery) => IPromise<IAdsGridData>;
}

export interface IAdPlatformGridConfig {
    sortBy: string;
    gridLimit?: number;
}
export interface IAdPlatformConfig {
    property: string;
    propertyFilter: string;
    filterValue: string;
    groupBy: string;
    chart: {
        kpis: string[];
    };
    grid: IAdPlatformGridConfig;
    kpis: string[];
}

export interface IAdsConfig {
    platforms: Record<string, IAdPlatformConfig>;
}

export interface IAdsConfigService {
    fetch: () => IPromise<IAdsConfig>;
}

export const AdsConfigServiceFactory = () => [
    '$q',
    'CONFIG',
    function AdsConfigService($q: angular.IQService, CONFIG: IConfigObj) {
        return {
            fetch: () => $q.when(CONFIG.views?.ads),
        };
    },
];

export const AdsServiceFactory = () => [
    '$filter',
    '$q',
    'QueryServiceAPI',
    function AdsService($filter: angular.IFilterService, $q: angular.IQService, QueryServiceAPI: IQueryServiceAPI) {
        const fetchStats = (
            availableMetrics: IMetricDefinition[],
            metrics: IMetricDefinition[],
            config: IAdPlatformConfig,
            property: any,
            rootQuery: IQuery,
        ) => {
            return QueryServiceAPI().then(api => {
                const query = _.cloneDeep(rootQuery);
                query.options ??= {};
                query.options.property = ['stores.aggregate'];
                query.options.includeTotals = false;
                query.options.metrics = availableMetrics.map(metric => metric.field);
                query.filters = query.filters || {};
                query.filters[property.table] = {
                    [property.column]: config.filterValue,
                };

                return api.query.metricsFunnel(query).then((data: any) => {
                    data = data[0];

                    return remapData($filter, metrics, data);
                });
            });
        };

        const buildQuery = (
            rootQuery: IQuery,
            property: any,
            config: IAdPlatformConfig,
            availableMetrics: IMetricDefinition[],
        ) => {
            const GRID_ROWS_LIMIT = 5;
            const query = _.cloneDeep(rootQuery);
            const timestamp = query.filters?.transactions?.timestamp;
            const {
                filterValue,
                groupBy,
                grid: { sortBy, gridLimit },
            } = config;

            query.filters = query.filters || {};
            query.filters[property.table] = {
                [property.column]: filterValue,
            };

            query.modifiers ??= {};
            query.options ??= {};
            query.options.property = groupBy;
            query.options.sort = [{ field: sortBy, order: -1 }];
            query.options.metrics = availableMetrics.map(metric => metric.field);
            query.limit = (gridLimit || GRID_ROWS_LIMIT) + 1;

            if (timestamp) {
                query.filters.transactions = { timestamp };
            }

            return query;
        };

        const fetchGrid = (
            availableMetrics: IMetricDefinition[],
            config: IAdPlatformConfig,
            property: any,
            rootQuery: IQuery,
        ) => {
            return QueryServiceAPI().then(api => {
                const query = buildQuery(rootQuery, property, config, availableMetrics);

                return api.query.metricsFunnel(query).then(data => {
                    // Remove total line
                    data.shift();
                    const result = data.reduce(
                        (acc, row) => {
                            acc['rows'].push(row);
                            return acc;
                        },
                        { rows: [], total: null },
                    );

                    if (property.type) {
                        result.rows.forEach(row => {
                            if (property.type === 'numeric') {
                                const parsed = parseInt(row.property0);

                                if (!_.isNaN(parsed)) {
                                    row.property0 = parsed;
                                }
                            }
                        });
                    }

                    return result;
                });
            });
        };

        return {
            fetchStats,
            fetchGrid,
            fetch: (
                availableMetrics: IMetricDefinition[],
                metrics: IMetricDefinition[],
                config: IAdPlatformConfig,
                property: any,
                query: IQuery,
            ) => {
                return $q
                    .all([
                        fetchStats(availableMetrics, metrics, config, property, query),
                        fetchGrid(availableMetrics, config, property, query),
                    ])
                    .then(([stats, grid]) => ({
                        stats,
                        grid,
                    }));
            },
        };
    },
];
