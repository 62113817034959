import moment from 'moment';
import { IStorageAPI, StorageAPI } from '../../lib/storage-user-config';
import { ConfigAPI, IConfigAPI } from '../../lib/config-api';
import { DatabaseStatusMonitor } from '../../lib/services';
import { Parse } from '../../lib/parsers/values';

const DEFAULT_REALTIME_START_TIME = 11;
const DEFAULT_TIMERANGE = 'mtd';

class StorageDatepickerActionIdFactory {
    static USER_STORAGE_KEY = 'view.calendar.selectedActionId.v1' as const;

    constructor(protected StorageAPI: IStorageAPI<string>, protected ConfigAPI: IConfigAPI) {}

    set(timerangeId: string): Promise<void>;
    set(timerangeId: unknown): Promise<void> {
        const value = this.parseTimerangeId(timerangeId);
        if (!value) return Promise.reject(new Error(`Invalid datepicker action id: ${timerangeId}`));
        return this.StorageAPI(StorageDatepickerActionIdFactory.USER_STORAGE_KEY).then(api => api.put(value));
    }

    get(isRealtime?: boolean): Promise<string> {
        return Promise.all([
            this.getTimerangeIdFromUserConfig(),
            this.getTimerangeIdFromOrganizationConfig(),
            DEFAULT_TIMERANGE,
        ])
            .then(([user, org, fallback]) => user ?? org ?? fallback)
            .then(timerangeId => {
                if (timerangeId !== 'dtd') return timerangeId;
                const promise = Promise.resolve(isRealtime ?? this.isRealtime());
                return promise.then(realtime => (realtime ? 'dtd' : 'last-complete-day'));
            });
    }

    protected getTimerangeIdFromOrganizationConfig() {
        return this.getOrgConfigDefaults().then(defaults => {
            return this.parseTimerangeId(defaults.timerange) ?? this.parseTimerangeId(defaults.timeRange);
        });
    }

    protected getTimerangeIdFromUserConfig() {
        return this.StorageAPI(StorageDatepickerActionIdFactory.USER_STORAGE_KEY)
            .then(api => api.get())
            .then(value => this.parseTimerangeId(value));
    }

    protected getOrgConfigDefaults() {
        return this.ConfigAPI.get()
            .then(api => api.organization.get())
            .then(config => config.defaults ?? {});
    }

    // FIXME: This is some sort of hack...?
    protected isRealtime() {
        return this.getRealTimeStartTimeFromOrganizationConfig().then(realTimeStartTime => {
            return this.getLatestTransactionTimestamp().then(latestTime => {
                realTimeStartTime ??= DEFAULT_REALTIME_START_TIME;
                latestTime ??= moment();
                const currentTime = moment();
                const isToday = currentTime.dayOfYear() >= latestTime.dayOfYear();
                return isToday && currentTime.hour() < realTimeStartTime;
            });
        });
    }

    protected getRealTimeStartTimeFromOrganizationConfig(): Promise<null | number> {
        return this.getOrgConfigDefaults().then(defaults => {
            return Parse.Integer(defaults.realTimeStartTime);
        });
    }

    protected getLatestTransactionTimestamp(): Promise<null | moment.Moment> {
        // NOTE:
        // We're using the database status monitor here, to take advantage of its cache.
        // We could hit the database directly.
        return DatabaseStatusMonitor.getStatus().then(status => {
            if (!status?.latestTransactionTimestamp) return null;
            return moment.utc(status.latestTransactionTimestamp);
        });
    }

    protected parseTimerangeId(value: unknown): null | string {
        return Parse.String(value)?.toLowerCase() ?? null;
    }
}

export const StorageDatepickerActionId = new StorageDatepickerActionIdFactory(StorageAPI, ConfigAPI);
