import _ from 'lodash';
import type { IPromise } from 'angular';
import type { AngularInjected } from '../../../lib/angular';
import type { IConfigViewSalesChartGrid, IConfigViewSalesChartGridOptions } from '../../../lib/types';
import { ConfigAPI } from '../../../lib/config-api';
import { isObject } from '../../../lib/utils';
import { normalizeOverviewConfigProperties, parseKpisArray } from '../config-views-sales';

export class CardGridCharts {
    readonly grid: ISalesChartGridConfig[];
    constructor(options: { chartGrid: ISalesChartGridConfig[] }) {
        this.grid = _.cloneDeep(options.chartGrid);
    }
}

export type ISalesChartGridConfig = IConfigViewSalesChartGrid;
export type ISalesChartGridConfigService = AngularInjected<typeof SalesChartGridConfigFactory>;
export const SalesChartGridConfigFactory = () => [
    '$q',
    function SalesChartGridConfig($q: angular.IQService) {
        let promise: null | IPromise<ISalesChartGridConfig[] | Record<string, ISalesChartGridConfig[]> | null> = null;
        return {
            fetch: (hierarchyId?: string) => {
                promise ??= $q.when(fetchChartGridConfig());
                return promise
                    .then(config => {
                        if (!config) return null;
                        if (Array.isArray(config)) return config;
                        if (typeof hierarchyId === 'string') return config[hierarchyId] ?? null;
                        return null;
                    })
                    .then(config => _.cloneDeep(config));
            },
        };
    },
];

const fetchChartGridConfig = async (): Promise<
    ISalesChartGridConfig[] | Record<string, ISalesChartGridConfig[]> | null
> => {
    const api = await ConfigAPI.get();
    const [userConfig, orgConfig] = await Promise.all([
        api.user.getInternal().then(normalizeOverviewChartConfig),
        api.organization.get().then(normalizeOverviewChartConfig),
    ]);
    return userConfig ?? orgConfig ?? null;
};

const normalizeOverviewChartConfig = (
    config: unknown,
): ISalesChartGridConfig[] | Record<string, ISalesChartGridConfig[]> | null => {
    config = _.get(config, 'views.sales.chartGrid');

    if (!config) {
        return null;
    }

    if (Array.isArray(config)) {
        const parsed = normalizeChartConfigItems(config);
        return parsed.length > 0 ? parsed : null;
    }

    if (isObject(config)) {
        const parsed = Object.entries(config).reduce<Record<string, ISalesChartGridConfig[]>>(
            (acc, [hierarchyId, config]) => {
                if (!Array.isArray(config)) return acc;
                const parsed = normalizeChartConfigItems(config);
                if (parsed.length === 0) return acc;
                return { ...acc, [hierarchyId]: parsed };
            },
            {},
        );
        return _.isEmpty(parsed) ? null : parsed;
    }

    console.error("Invalid 'views.sales.chartGrid' config:", config);
    return null;
};

const normalizeChartConfigItems = (configs: unknown[]): ISalesChartGridConfig[] => {
    return configs.reduce((acc: ISalesChartGridConfig[], config: unknown) => {
        if (!isObject(config)) return acc;
        return [...acc, normalizeOverviewChart(config)];
    }, []);
};

const normalizeOverviewChart = (config: Record<string, unknown>): IConfigViewSalesChartGrid => {
    const metrics = parseKpisArray(config.metrics ?? config.metric);
    const properties = normalizeOverviewConfigProperties(config);
    const type: null | string = (() => {
        return typeof config.type === 'string' ? config.type : null;
    })();

    const options = (() => {
        return isObject(config.options) ? normalizeOverviewChartOptions(config.options) : {};
    })();

    return {
        ...(type ? { type } : {}),
        ...(metrics ? { metrics } : {}),
        ...(properties ? { properties } : {}),
        options,
    };
};

const normalizeOverviewChartOptions = (options: Record<string, unknown>): IConfigViewSalesChartGridOptions => {
    const resolved: IConfigViewSalesChartGridOptions = {};
    if (typeof options.showPercentage === 'boolean') {
        resolved.showPercentage = options.showPercentage;
    }
    if (typeof options.multiline === 'boolean') {
        resolved.multiline = options.multiline;
    }
    if (typeof options.maxNumberOfSlices === 'number') {
        resolved.maxNumberOfSlices = options.maxNumberOfSlices;
    }
    if (typeof options.groupSlicesThresholdValue === 'number') {
        resolved.groupSlicesThresholdValue = options.groupSlicesThresholdValue;
    }
    return resolved;
};
