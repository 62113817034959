import _ from 'lodash';
import { IQueryTableFilterColumns } from '../../lib/types';
import { isObject } from '../../lib/utils';
import { ICustomerStatsResponse } from '../../modules/services/query-service.types';
import { CustomerStatsItem, CUSTOMER_STATS_CONFIG } from './customer-main/customer-stats.directive';

export function buildStatsViewModel(
    data: ICustomerStatsResponse,
    $filter: angular.IFilterService,
): CustomerStatsItem[] {
    return Object.values(CUSTOMER_STATS_CONFIG).map(field => {
        const { value, avg } = field;
        return {
            title: field.title,
            type: field.type,
            value: typeof value === 'string' ? value : value(data, $filter),
            avg: typeof avg === 'string' ? avg : avg(data, $filter),
        };
    });
}

// Get's trunked value, meaning without rounding - maximum of two decimal places
// Example: 3    :  getIntervalWithoutRounding(3)     ===> { $gte: 2.5,   $lt: 3.4 }
// Example: 3.457:  getIntervalWithoutRounding(3.457) ===> { $gte: 3.445, $lt: 3.454 }

function getIntervalWithoutRounding(value: number): { $lt: number; $gte: number } {
    function getDecimalCount(value: number) {
        if (Math.floor(value) === value) return 0;
        return value.toString().split('.')[1]?.length || 0;
    }
    const lowerIntervalValue = 0.5;
    const higherIntervalValue = 0.4;
    let decimalPlaces = getDecimalCount(value);
    let $lt = value + higherIntervalValue;
    let $gte = value - lowerIntervalValue;

    if (decimalPlaces > 0) {
        // Maximum of two decimal places
        decimalPlaces = decimalPlaces > 2 ? 2 : decimalPlaces;
        const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimalPlaces}})?`);
        const match = value.toString().match(regex)?.pop();
        const valueWithRemovedDecimalCases = Number(match);

        $lt = valueWithRemovedDecimalCases + higherIntervalValue * Math.pow(10, -decimalPlaces);
        $gte = valueWithRemovedDecimalCases - lowerIntervalValue * Math.pow(10, -decimalPlaces);
    }

    return { $lt, $gte };
}

function isEqualsOnlyColumnFilter(filter: unknown): filter is { $equals: number; [x: string]: number } {
    if (!isObject(filter)) return false;
    if (!_.isNumber(filter.$equals)) return false;
    if (_.isNumber(filter.$lt)) return false;
    return true;
}

function isNumericColumn(key: string) {
    const type = CUSTOMER_STATS_CONFIG[key]?.type;
    if (typeof type !== 'string') return false;
    return ['money', 'percentage'].includes(type);
}

export const compileSubfilters = (filters: Record<string, IQueryTableFilterColumns>): IQueryTableFilterColumns => {
    if (!isObject(filters)) return {};
    filters = _.cloneDeep(filters);
    return Object.values(filters).reduce((result, columnFilters) => {
        if (!isObject(columnFilters)) return result;
        for (let [columnName, columnFilter] of Object.entries(columnFilters)) {
            if (isNumericColumn(columnName) && isEqualsOnlyColumnFilter(columnFilter)) {
                columnFilter = getIntervalWithoutRounding(columnFilter.$equals);
            }
            result[columnName] = columnFilter;
        }
        return result;
    }, {});
};
