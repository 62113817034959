import SchedulingTabSchedulesModule from './tab-schedules';
import SchedulingTabReportsModule from './tab-reports';
import SchedulingPageModule from './scheduling-controller.module.coffee';
import './components/report-params.coffee';

const module = angular.module('42.controllers.scheduling', [
    SchedulingTabReportsModule.name,
    SchedulingTabSchedulesModule.name,
    SchedulingPageModule.name,
]);

export default module;
