import _ from 'lodash';
import { titleize } from 'inflected';
import { isObject } from './utils';
import type { IConfigObj } from './types';
import type { DatabaseColumnDescriptor, DatabaseDescriptors } from './api/api-query-service';

// TODO: rename to Property
export interface IPropertyDefinition {
    id: string;
    choice?: string;
    table?: string;
    column?: string;
    label: string;
    plural?: string;
    filterable?: boolean;
    group?: string;

    /**
     * @deprecate This is used by the metrics page... unclear where else...
     * */
    type?: string;

    /**
     * To review:
     * Used by transaction_items.timestamp__hour
     **/
    sort?: { field: string; order: number };
}

export type IHierarchyItem = IPropertyDefinition;

// Most of the time, we only have one standard hierarchy...
export type IHierarchy = IPropertyDefinition[];

// When we have multiple hierarchies defined in the config...
export type IMultiHierarchy = Record<string, IHierarchy>;

export function getPropertyDefinitions(
    userConfig: IConfigObj,
    orgConfig: IConfigObj,
    descriptors?: DatabaseDescriptors,
    selectedHierarchyId?: null | string,
) {
    let { stores, items } = getStandardHierarchiesFromConfig(userConfig, orgConfig);
    if (descriptors?.stores) stores ??= getHierarchyFromDescriptors(descriptors.stores, 'store');
    if (descriptors?.items) items ??= getHierarchyFromDescriptors(descriptors.items, 'item');
    stores = stores ? selectHierarchyById(stores, selectedHierarchyId) : [];
    items = items ? selectHierarchyById(items, selectedHierarchyId) : [];
    const all = stores.concat(items);

    const groupByPropertyIds = getGroupByPropertyIdsFromConfig(userConfig, orgConfig, selectedHierarchyId);
    let groupBy = selectPropertiesById(all, groupByPropertyIds);
    if (groupBy.length === 0) groupBy = all;

    return {
        all,
        groupBy: groupBy,
        /** @deprecated filter on 'all' instead or something */
        stores,
        /** @deprecated filter on 'all' instead or something */
        items,
        /** @deprecated use groupBy instead */
        pebbles: groupBy,
    };
}

export function normalizePropertyDefinition(
    property: Partial<Omit<IPropertyDefinition, 'id'>> & { id: string },
): Omit<IPropertyDefinition, 'group'>;

export function normalizePropertyDefinition(
    property: Partial<Omit<IPropertyDefinition, 'table' | 'column'>> & { table: string; column: string },
): Omit<IPropertyDefinition, 'group'>;

export function normalizePropertyDefinition(property: unknown): Omit<IPropertyDefinition, 'group'>;
export function normalizePropertyDefinition(property: unknown): Omit<IPropertyDefinition, 'group'> {
    if (!isObject(property)) {
        throw new Error('Invalid hierarchy property, is null');
    }

    // Normalize ID
    const { id, table, column } = parsePropertyId(
        (() => {
            if (typeof property.id === 'string') {
                return property.id;
            }
            if (typeof property.table === 'string' && typeof property.column === 'string') {
                return `${property.table}.${property.column}`;
            }
            console.error('Invalid hierarchy property:', property);
            throw new Error(`Hierarchy property is misconfigured, must have an 'id', or 'table' and 'column'.`);
        })(),
    );

    const label = typeof property.label === 'string' ? property.label : titleize(column);
    const plural = typeof property.plural === 'string' ? property.plural : label;
    const filterable = typeof property.filterable === 'boolean' ? property.filterable : true;

    return { id, table, column, label, plural, filterable };
}

const PROPERTY_REGEXP = /^([a-z0-9_]+)\.([a-z0-9_]+)$/i;
function parsePropertyId(value: string): { id: string; table: string; column: string } {
    if (typeof value !== 'string') throw new Error('Invalid property; must be a string');
    const [table, column] = value.match(PROPERTY_REGEXP)?.slice(1) ?? [];
    if (!table || !column) {
        throw new Error(`Invalid property '${value}'; must be formatted like <table>.<column>`);
    }
    return { id: value, table, column };
}

function normalizeHierarchy(properties: IHierarchy, group?: string): IHierarchy;
function normalizeHierarchy(properties: IMultiHierarchy, group?: string): IMultiHierarchy;
function normalizeHierarchy(properties: IMultiHierarchy | IHierarchy, group?: string): IMultiHierarchy | IHierarchy;
function normalizeHierarchy(properties: IMultiHierarchy | IHierarchy, group?: string): IMultiHierarchy | IHierarchy {
    if (Array.isArray(properties)) {
        return properties.flatMap(value => {
            try {
                const def = normalizePropertyDefinition(value);
                return { ...(group ? { group } : {}), ...def };
            } catch (error) {
                console.error(error);
                return [];
            }
        });
    } else {
        return Object.entries(properties).reduce((result, [hierarchyId, childHierarchy]) => {
            return { ...result, [hierarchyId]: normalizeHierarchy(childHierarchy, group) };
        }, {});
    }
}

function getHierarchyFromDescriptors(descriptors: DatabaseColumnDescriptor[], group?: string): IHierarchy {
    try {
        return descriptors.map(descriptor => {
            const table = descriptor.table;
            const column = descriptor.name;
            const def = normalizePropertyDefinition({ table, column });
            return { ...(group ? { group } : {}), ...def };
        });
    } catch (error) {
        console.error(error);
        throw new Error('Could not convert descriptor to property.');
    }
}

function getStandardHierarchiesFromConfig(
    userConfig: IConfigObj,
    orgConfig: IConfigObj,
): Record<string, null | IHierarchy | IMultiHierarchy> {
    const stores = userConfig?.stores?.hierarchy || orgConfig?.stores?.hierarchy2 || orgConfig?.stores?.hierarchy;
    const items = userConfig?.items?.hierarchy || orgConfig?.items?.hierarchy2 || orgConfig?.items?.hierarchy;
    return {
        stores: stores ? normalizeHierarchy(stores, 'store') : null,
        items: items ? normalizeHierarchy(items, 'items') : null,
    };
}

function selectPropertiesById<T extends { id: string }>(
    properties: T[],
    propertyIds: undefined | null | string[],
): T[] {
    if (!Array.isArray(propertyIds)) return [];
    propertyIds = propertyIds.filter(x => typeof x === 'string');
    propertyIds = _.uniq(propertyIds);
    const propertiesById = _.keyBy(properties, 'id');
    return propertyIds.flatMap(propertyId => {
        const property = propertiesById[propertyId];
        if (property) return [{ ...property }];
        console.warn(`Property not found in hierarchy:`, propertyId);
        return [];
    });
}

function selectHierarchyById(properties: IHierarchy | IMultiHierarchy, hierarchyId?: null | string) {
    if (Array.isArray(properties)) return properties;
    const selectedHierarchy = hierarchyId ? properties[hierarchyId] : null;
    if (!Array.isArray(selectedHierarchy)) {
        console.warn('No hierarchy is defined for hierarchyId:', hierarchyId, properties);
        return [];
    } else {
        return selectedHierarchy;
    }
}

function getGroupByPropertyIdsFromConfig(
    userConfig: IConfigObj,
    orgConfig: IConfigObj,
    selectedHierarchyId: undefined | null | string,
) {
    const config: string[] | Record<string, string[]> =
        userConfig.views?.metrics?.properties ?? orgConfig.views?.metrics?.properties ?? [];

    if (Array.isArray(config)) {
        return config;
    }

    if (typeof selectedHierarchyId !== 'string') {
        throw new Error("Missing required 'selectedHierarchyId' argument; view.metrics.properties is an object.");
    }

    const selected = config[selectedHierarchyId];
    if (Array.isArray(selected)) return selected;
    console.error('Invalid view.metrics.properties, must be an object');
    return [];
}
