import type { AuthUserModel } from '@42technologies/client-lib-auth';
import { getUser, logout, setOrganization } from '../../lib/auth';
import { isRequestAbortedError } from '../../lib/api';
import { ConfigAPI } from '../../lib/config-api';
import type { IConfigObj, IOrganization } from '../../lib/types';
import { AppConfigService } from '../../lib/config-app';
import { fetch as fetchConfigFlags } from '../../lib/config-flags';
import type { IConfigFlags } from '../../lib/config-flags';
import type { DashboardRootScope } from '../../controllers/main-controller';

export type IUserMenuModel = InstanceType<typeof UserMenuModel>;

export class UserMenuModel {
    private organization: IOrganization;

    private user?: AuthUserModel;
    private flags?: IConfigFlags;
    public organizations?: IOrganization[];
    private adminUrl?: string;
    private reportingRoute?: string;

    // This needs $scope because we need to call $apply at various points during initialization.
    // We should remove it, ideally.
    constructor($scope: angular.IScope, organization: IOrganization) {
        this.organization = { ...organization };
        void this.init($scope);
    }

    init($scope?: angular.IScope) {
        const adminUrlPromise = AppConfigService.get().then(config => {
            this.adminUrl = config.clients?.admin?.base;
            this.adminUrl ??= config.clients?.user?.base;
            this.adminUrl ??= 'https://admin.42technologies.com';
            $scope?.$apply();
        });

        const pageFlagsPromise = fetchConfigFlags().then(flags => {
            this.flags = flags;
            $scope?.$apply();
        });

        const userPromise = getUser().then(user => {
            this.user = user;
            $scope?.$apply();
            return user;
        });

        const organizationsPromise = userPromise.then(user => {
            if (!user) return;
            return Promise.all(
                user.organizations.map(organizationId => {
                    if (this.organization.id === organizationId) return { ...this.organization };
                    return fetchOrganization(organizationId);
                }),
            ).then(organizations => {
                this.organizations = organizations;
                $scope?.$apply();
            });
        });

        return Promise.all([adminUrlPromise, pageFlagsPromise, userPromise, organizationsPromise]).then(() => this);
    }

    setOrganization(organization: string) {
        return setOrganization(organization);
    }

    logout() {
        return logout();
    }
}

const fetchOrganization = async (organizationId: string): Promise<IOrganization> => {
    try {
        const api = await ConfigAPI.get(organizationId);
        const config = await api.organization.get();
        return config.organization;
    } catch (error) {
        if (!isRequestAbortedError(error)) console.error('[user-menu] could not fetch organization:', error);
        return { id: organizationId, label: organizationId };
    }
};

export const UserMenuRunBlock = () => [
    '$rootScope',
    'CONFIG',
    function UserMenuRunBlock($rootScope: DashboardRootScope, CONFIG: IConfigObj) {
        $rootScope.userMenuModel = new UserMenuModel($rootScope, CONFIG.organization);
    },
];
