const UNIT: Record<string, number> = {
    k: 1000,
    m: 1000000,
    b: 1000000000,
};

const TOKENS = {
    operator: '<|>|<=|>=|==?|!=',
    number: '\\d+|\\d+\\.\\d+',
    unit: Object.keys(UNIT).join('|'),
};

const NUMBER_REGEX = new RegExp(`^(${TOKENS.operator})?(${TOKENS.number})(${TOKENS.unit})?$`);

const parseNumeric = (
    rawValue: unknown,
): null | {
    operator: string;
    value: number;
    unit: undefined | string;
} => {
    if (typeof rawValue !== 'string' && typeof rawValue !== 'number') return null;
    const strValue = rawValue.toString().trim().toLowerCase().replace(/,/g, '').replace(/\s/g, '');
    if (!strValue) return null;

    const tokens = strValue.match(NUMBER_REGEX)?.slice(1);
    if (!tokens) return null;

    const [operator, value, unit] = tokens;
    if (!value) throw new Error('Bug in parse numeric regexp');
    return {
        operator: !operator || operator === '=' ? '==' : operator,
        value: parseFloat(value),
        unit,
    };
};

const parseNumberFilter = (rawValue: unknown) => {
    const tokens = parseNumeric(rawValue);
    if (!tokens) return null;
    const { operator, value, unit } = tokens;
    const unitValue = (unit ? UNIT[unit] : null) ?? 1;
    return {
        operator,
        value: value * unitValue,
    };
};

// FIXME: This isn't locale aware...
const parseMoneyFilter = (value: unknown) => {
    value = typeof value === 'string' ? value.replace('$', '') : value;
    return parseNumberFilter(value);
};

const parsePercentFilter = (value: unknown) => {
    value = typeof value === 'string' ? value.replace('%', '') : value;
    const result = parseNumeric(value);
    if (!result) return null;
    result.value = result.value / 100;
    return result;
};

export type FilterExpressionParserTypes = 'number' | 'money' | 'percent';
export type FilterExpressionParserFunction = (value: unknown) => FilterExpressionParserFunctionResult | null;
export type FilterExpressionParserFunctionResult = {
    value: number;
    operator: string;
};
export const FilterExpressionParser: Record<FilterExpressionParserTypes, FilterExpressionParserFunction> = {
    number: parseNumberFilter,
    money: parseMoneyFilter,
    percent: parsePercentFilter,
};
